import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './reducers/userReducer';
// import other reducers as needed
import { setDarkMode } from './reducers/themeReducer';
import { notify } from './reducers/notificationReducer';
import NavBar from './components/NavBar';
import HybridScroll from './components/hybridanalysis/hybridfeedscroll';
import ToastNotif from './components/ToastNotif';
import Routes from './Routes';
import getErrorMsg from './utils/getErrorMsg';
import { Paper, useMediaQuery, useTheme } from '@material-ui/core/';
import customTheme from './styles/customTheme';
import { useMainPaperStyles } from './styles/muiStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import Chat from './components/Chat/chat';
import maybeBG from './assets/bg4.png'; // Use WebP for better compression
import maybeBGMobile from './assets/bg4-mobile.webp'; // Mobile-optimized WebP image
import BigBar from './components/big/bigBar';

const App = () => {
  const classes = useMainPaperStyles();
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed

  const isUserAuthenticated = () => {
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    return readifyUserKey !== null;
  };

  return (
    <ThemeProvider theme={customTheme}>
      {/* Background Image */}
      <img
        src={isMobile ? maybeBGMobile : maybeBG}
        alt="Background"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />

      {/* Main Content */}
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          position: 'relative',
          zIndex: 1, // Ensure content is above the background
          minHeight: '100vh', // Ensure the content covers at least the viewport height
          backgroundColor: 'transparent', // Make sure the Paper background is transparent
          overflow: 'visible', // Allow content to scroll without affecting background
        }}
      >
        {isUserAuthenticated() ? (
          <>
            <ToastNotif />
            <BigBar style={{ zIndex: 5 }} />
            <Routes />
            {/* <Chat /> Uncomment if needed */}
          </>
        ) : (
          <Routes />
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default App;
