// DomainToExplorer.js (Updated)
import React, { useState } from 'react';
import DomainLineChart from '../persexplorer/biology/DomainsGraph';
import KeptActiveShapePieChart from '../persexplorer/biology/KeptRadar'; // Update the path accordingly
import AddedActiveShapePieChart from '../persexplorer/biology/AddedRadar'; // Update the path accordingly
import Explorer from './explorer';
import SentimentsBox from './sentimentbox'; // Adjust the path as needed
import { Dialog, DialogContent } from '@material-ui/core';
import Box from '@material-ui/core/Box'; // Added import
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  dialogPaperMobile: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  dialogContent: {
    padding: 0,
    color: 'white',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  },
  desktopContainer: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '80vw',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  pieChartsContainerDesktop: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  sentimentsBox: {
    width: '100%',
    maxWidth: '800px', // Adjust as needed
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '6.42vw', // Override for mobile to ensure centering
    },
  },
  pieChartsWithSentimentsDesktop: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

const DomainToExplorer = ({ username }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogs, setDialogs] = useState([]);

  const handleDomainClick = (ids) => {
    setDialogs([...dialogs, { ids, isOpen: true }]);
  };

  const handleDialogClose = (index) => {
    const updatedDialogs = dialogs.map((dialog, i) => 
      i === index ? { ...dialog, isOpen: false } : dialog
    );
    setDialogs(updatedDialogs);
  };

  // New handler for viewing entries from SentimentsBox
  const handleViewEntry = (postId) => {
    setDialogs([...dialogs, { ids: postId, isOpen: true }]);
  };

  return (
    <div>
      {isMobile ? (
        <div className={classes.mobileContainer}>
          <DomainLineChart username={username} onDomainClick={handleDomainClick} />
          <AddedActiveShapePieChart username={username} onDomainClick={handleDomainClick} />
          <Box className={classes.sentimentsBox}>
            <SentimentsBox username={username} onViewEntry={handleViewEntry} /> {/* Passed the handler */}
          </Box>
          <KeptActiveShapePieChart username={username} onDomainClick={handleDomainClick} />
        </div>
      ) : (
        <div className={classes.desktopContainer}>
          <DomainLineChart username={username} onDomainClick={handleDomainClick} />
          <div className={classes.pieChartsWithSentimentsDesktop}>
            <AddedActiveShapePieChart username={username} onDomainClick={handleDomainClick} />
            <Box className={classes.sentimentsBox}>
              <SentimentsBox username={username} onViewEntry={handleViewEntry} /> {/* Passed the handler */}
            </Box>
            <KeptActiveShapePieChart username={username} onDomainClick={handleDomainClick} />
          </div>
        </div>
      )}
      {dialogs.map((dialog, index) => (
        <Dialog
          key={index}
          open={dialog.isOpen}
          onClose={(event, reason) => {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
              handleDialogClose(index);
            }
          }}
          fullScreen
          classes={{ paper: classes.dialogPaperMobile }}
          BackdropProps={{
            classes: {
              root: classes.backdrop,
            },
            onClick: () => handleDialogClose(index),
          }}
        >
          <DialogContent
            className={classes.dialogContent}
            onClick={(e) => e.stopPropagation()}
          >
            <Explorer ids={dialog.ids} onClose={() => handleDialogClose(index)} />
          </DialogContent>
        </Dialog>
      ))}
    </div>
  );
};

export default DomainToExplorer;
