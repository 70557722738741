import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar'; // Assuming you are using Material-UI

const RequestorAvatar = ({ requestor, size = 42 }) => {
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const response = await fetch(`/api/authors/avatars/${requestor}`);
        if (response.ok) {
          const avatarData = await response.json();
          if (avatarData.avatar) {
            setAvatarUrl(avatarData.avatar);
          } else {
            setAvatarUrl('/default-avatar.png'); // You can specify a default avatar URL here
          }
        } else {
          console.error('Error fetching avatar:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching avatar:', error);
      }
    };

    fetchAvatar();
  }, [requestor]);

  return (
    <Avatar
      src={avatarUrl}
      style={{ width: size, height: size, marginRight: '5px' }}
    />
  );
};

export default RequestorAvatar;