const storageKeyToken = 'readifyUserKey';

const saveUser = (user) =>
  localStorage.setItem(storageKeyToken, JSON.stringify(user));

const loadUser = () => JSON.parse(localStorage.getItem(storageKeyToken));

const logoutUser = () => localStorage.removeItem(storageKeyToken);



const storage = {
  saveUser,
  loadUser,
  logoutUser,
};

export default storage;
