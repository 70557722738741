// OfficialPools.jsx
import React, { useState, useEffect } from 'react';
import { TextField, Button, makeStyles, Dialog, DialogContent } from '@material-ui/core';
import PoolCreationForm from './PoolCreationForm';
import PoolCard from './PoolCard';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    'html, body': {
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    color: "#fff",
    textTransform: "none",
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  searchField: {
    margin: theme.spacing(2, 0),
    width: '69%', // Set to 69% or use '80vw' as needed
    maxWidth: 600,
    color: "#fff",
    backgroundColor: "#004d40",
    border: "none",
    textTransform: "none",
    borderRadius: "25px", // Moved borderRadius here for consistency
    [theme.breakpoints.down('sm')]: { // Responsive adjustments for small screens
      width: '69%', // Adjust width for mobile
      maxWidth: '69vw',
    },
  },
  poolList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
    overflow: 'hidden',
  },
  selectedPoolDetails: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    color: "#fff",
    overflow: 'hidden',
  },
  poolTitle: {
    color: "#fff",
    fontWeight: 'bold',
    overflow: 'hidden',
  },
  poolDescription: {
    color: "#fff",
    overflow: 'hidden',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: "#fff",
    textTransform: "none",
    overflow: 'hidden',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#004d40",
    color: "white",
    textTransform: "none",
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: "#00332e",
    },
  },
  resetButton: {
    margin: theme.spacing(1),
    backgroundColor: "#FF1744",
    color: "white",
    textTransform: "none",
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: "#c50039",
    },
  },
  addButton: {
    margin: theme.spacing(2),
    backgroundColor: "#57c0bf",
    color: "#fff",
    textTransform: "none",
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: "#459aa7",
    },
  },
}));

const OfficialPools = () => {
  const classes = useStyles();
  const [pools, setPools] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [myPoolsSelected, setMyPoolsSelected] = useState(false);
  const [joinedPoolsSelected, setJoinedPoolsSelected] = useState(false);
  const [isOfficial, setIsOfficial] = useState(false); // New state for isOfficial
  const [loadingOfficialStatus, setLoadingOfficialStatus] = useState(true); // Loading state
  const [isFormOpen, setIsFormOpen] = useState(false); // State to control PoolCreationForm dialog

  // Retrieve the logged-in user's username from localStorage
  const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  // Fetch isOfficial status on component mount
  useEffect(() => {
    const fetchOfficialStatus = async () => {
      if (!loggedInUser) {
        setIsOfficial(false);
        setLoadingOfficialStatus(false);
        return;
      }

      try {
        const response = await fetch(`/api/get-official-user?username=${encodeURIComponent(loggedInUser)}`);
        if (response.ok) {
          const data = await response.json();
          setIsOfficial(data.isOfficial);
        } else {
          console.error('Failed to fetch official status');
          setIsOfficial(false);
        }
      } catch (error) {
        console.error('Error fetching official status:', error);
        setIsOfficial(false);
      } finally {
        setLoadingOfficialStatus(false);
      }
    };

    fetchOfficialStatus();
  }, [loggedInUser]);

  // Fetch all official pools
  const fetchPools = async () => {
    try {
      const response = await fetch('/api/get-official-pools-list');
      if (response.ok) {
        const data = await response.json();
        setPools(data);
        setFilteredPools(data);
      } else {
        throw new Error('Failed to fetch pools');
      }
    } catch (error) { 
      console.error('Error fetching pools:', error);
    }
  };

  // Fetch pools created by the logged-in user
  const fetchMyPools = async () => {
    if (!loggedInUser) {
      console.error('User not logged in');
      return;
    }

    try {
      const response = await fetch(`/api/get-my-pools?username=${encodeURIComponent(loggedInUser)}`);
      if (response.ok) {
        const data = await response.json();
        setPools(data);
        setFilteredPools(data);
        setMyPoolsSelected(true);
        setJoinedPoolsSelected(false);
      } else {
        throw new Error('Failed to fetch my pools');
      }
    } catch (error) {
      console.error('Error fetching my pools:', error);
    }
  };

  // Fetch pools the logged-in user has joined
  const fetchJoinedPools = async () => {
    if (!loggedInUser) {
      console.error('User not logged in');
      return;
    }

    try {
      const response = await fetch(`/api/get-joined-pools?username=${encodeURIComponent(loggedInUser)}`);
      if (response.ok) {
        const data = await response.json();
        setPools(data);
        setFilteredPools(data);
        setJoinedPoolsSelected(true);
        setMyPoolsSelected(false);
      } else {
        throw new Error('Failed to fetch joined pools');
      }
    } catch (error) {
      console.error('Error fetching joined pools:', error);
    }
  };

  // Reset filters and fetch all pools
  const resetPools = () => {
    fetchPools();
    setSearchTerm('');
    setMyPoolsSelected(false);
    setJoinedPoolsSelected(false);
  };

  // Fetch all pools on component mount
  useEffect(() => {
    fetchPools();
  }, []);

  // Filter pools based on search term
  useEffect(() => {
    setFilteredPools(
      pools.filter(pool =>
        pool.normalizedName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pool.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, pools]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle opening the PoolCreationForm dialog
  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  // Handle closing the PoolCreationForm dialog
  const handleCloseForm = () => {
    setIsFormOpen(false);
    fetchPools(); // Refresh the pools list after creating a new pool
  };

  return (
    <div className={classes.container}>
      {/* Conditionally render the "Add Pool" button if the user is official */}
      {!loadingOfficialStatus && isOfficial && (
       <PoolCreationForm onClose={handleCloseForm} />
      )}

      {/* PoolCreationForm Dialog */}
      <Dialog
        open={isFormOpen}
        onClose={handleCloseForm}
        aria-labelledby="add-pool-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          
        </DialogContent>
      </Dialog>

      {/* Filter Buttons 
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={fetchMyPools}
          style={{
            backgroundColor: myPoolsSelected ? "#fff" : "#004d40",
            color: myPoolsSelected ? "#004d40" : "white",
          }}
        >
          My Pools
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          onClick={fetchJoinedPools}
          style={{
            backgroundColor: joinedPoolsSelected ? "#fff" : "#004d40",
            color: joinedPoolsSelected ? "#004d40" : "white",
          }}
        >
          Joined Pools
        </Button>
        <Button
          variant="contained"
          className={classes.resetButton}
          onClick={resetPools}
        >
          Reset
        </Button>
      </div>
*/}
      {/* Search Field */}
      <TextField
        className={classes.searchField}
        margin="dense"
        id="search"
        placeholder="Official Pool Name or Description"
        type="text"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setSearchTerm(e.target.value.toLowerCase());
          }
        }}
        style={{
          width: "69%",
          maxWidth: 600,
          padding: "10px 20px",
          fontSize: "16px",
          margin: "3px auto",
          color: "#fff",
          backgroundColor: "#004d40",
          borderRadius: "25px",
          border: "none",
          textTransform: "none",
          display: "block",
        }}
        InputProps={{
          disableUnderline: true, // To match the input style
          style: {
            color: '#fff', // Text color
          },
        }}
        InputLabelProps={{
          style: {
            color: '#fff', // Placeholder color
          },
        }}
      />

      {/* Pool List */}
      <div className={classes.poolList}>
        {filteredPools.map(pool => (
          <PoolCard key={pool._id} pool={pool} onClick={() => setSelectedPool(pool)} />
        ))}
      </div>

      {/* Selected Pool Details */}
      {selectedPool && (
        <div className={classes.selectedPoolDetails}>
          <h2 className={classes.poolTitle}>{selectedPool.name}</h2>
          <p className={classes.poolDescription}>{selectedPool.description}</p>
        </div>
      )}
    </div>
  );
};

export default OfficialPools;
