import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

const SavedDialog = ({ open, onClose, isMobile, handleSelect, resetSelection }) => {
  const [savedSorts, setSavedSorts] = useState({
    branches: [],
    fields: [],
    categories: [],
    origins: [],
    originNames: []
  });
  const [activeCategory, setActiveCategory] = useState('branches');
  const [selectedItemsByType, setSelectedItemsByType] = useState({
    branches: [],
    fields: [],
    categories: [],
    origins: [],
    originNames: []
  });

  // Reset the selected items whenever resetSelection prop changes
  useEffect(() => {
    if (resetSelection) {
      setSelectedItemsByType(resetSelection);
    }
  }, [resetSelection]);
  

  // Fetch saved sorts when the dialog opens
  useEffect(() => {
    if (open && loggedInUser) {
      axios.get(`/api/get-all-saved-sorts/${loggedInUser}`)
        .then(response => {
          setSavedSorts(response.data);
        })
        .catch(error => {
          console.error('Error fetching saved sorts:', error);
        });
    }
  }, [open, loggedInUser]);

  // Handle item click to select/deselect an item
  const handleItemClick = (item) => {
    setSelectedItemsByType((prevSelectedItemsByType) => {
      const isSelected = prevSelectedItemsByType[activeCategory].includes(item);
      const updatedCategoryItems = isSelected
        ? prevSelectedItemsByType[activeCategory].filter((selected) => selected !== item)
        : [...prevSelectedItemsByType[activeCategory], item];

      const updatedSelectedItemsByType = {
        ...prevSelectedItemsByType,
        [activeCategory]: updatedCategoryItems,
      };

      handleSelect(activeCategory, updatedCategoryItems);
      return updatedSelectedItemsByType;
    });
  };

  // Render the buttons for the saved sorts
  const renderButtons = (items) => (
    <List style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
      {items.map((item, index) => (
        <ListItem
          button
          key={index}
          onClick={() => handleItemClick(item)}
          style={{
            padding: '10px',
            backgroundColor: selectedItemsByType[activeCategory].includes(item) ? '#fff' : '#004d40',
            color: selectedItemsByType[activeCategory].includes(item) ? '#004d40' : '#fff',
            textAlign: 'center',
            width: 'fit-content',
            borderRadius: '10px',
            transition: 'background-color 0.3s, color 0.3s'
          }}
        >
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: isMobile
          ? { width: '90vw', height: 'auto', backgroundColor: 'transparent' }
          : { width: '50vw', height: '50vh', backgroundColor: 'transparent' },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.96)',
        },
      }}
    >
      <DialogTitle style={{ backgroundColor: 'transparent', color: '#fff' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: 'transparent', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          {['branches', 'fields', 'categories', 'origins', 'originNames'].map((category) => (
            <Button
              key={category}
              onClick={() => setActiveCategory(category)}
              style={{
                margin: '0 10px',
                backgroundColor: activeCategory === category ? '#fff' : '#004d40',
                color: activeCategory === category ? '#004d40' : '#fff',
                textTransform: 'none'
              }}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </Button>
          ))}
        </div>
        {renderButtons(savedSorts[activeCategory])}
      </DialogContent>
    </Dialog>
  );
};

export default SavedDialog;
