import React from 'react';
import RequestorAvatar from '../requestorAvatar';
import './Cccss.css';
const ChatPopup = ({ acceptedRequest, handlePopupClick, sendMessageToChat, newMessage, setNewMessage }) => {


  return (
    <div className="chat-popups">
      <div className="chat-header">
      <div className="avatar-container1">
  <RequestorAvatar requestor={acceptedRequest.requestor} />
</div>
        <button onClick={() => handlePopupClick(acceptedRequest.requestor)}>
          {acceptedRequest.requestor}
        </button>
      </div>
    </div>
  );
};

export default ChatPopup;