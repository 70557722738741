import React from 'react';
import { Typography, List, ListItem, ListItemText, makeStyles, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: '#E8F0F2', // light tiffany blue
    borderRadius: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    color: '#4F6D7A', // complementary color to tiffany blue
  },
  list: {
    backgroundColor: '#F3F7F0', // off white with a hint of sage green
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItemTextPrimary: {
    fontWeight: 'bold',
    color: '#4F6D7A', // complementary color to tiffany blue
  },
  listItemTextSecondary: {
    color: '#8E9A9A', // natural looking secondary text color
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

function HelpInfoDialog() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Help Information
      </Typography>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="1. Thoughts"
            secondary={
              <>
                <div>Can be in Plain Text, HTML, Markdown, JSON Format.</div>
              </>
            }
            classes={{
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemTextSecondary,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
<ListItemText
    primary="2. Branches"
    secondary={
      <>
        <div>Choose a personal branch name or phrase based on what the entry means to you or the key information it holds.</div>
        <div>Type one and then enter to include each for the entry.</div>
        <div>This helps others find it if you share it with them.</div>
        <div>Maximum of 7 branches per entry.</div>
      </>
    }
    classes={{
      primary: classes.listItemTextPrimary,
      secondary: classes.listItemTextSecondary,
    }}
  />
</ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="3. Images, Videos, Documents"
            secondary={
              <>
                <div>Supported formats: docx, ppt, pdf, odf, odt, etc.</div>
                <div>Maximum of 3 images, 3 documents, and 1 video per entry.</div>
                <div>Total size limit: 100MB.</div>
              </>
            }
            classes={{
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemTextSecondary,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="4. Origin"
            secondary={
              <>
                <div>Select from the list.</div>
                <div>Provide the Origin Name.</div>
              </>
            }
            classes={{
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemTextSecondary,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
        <ListItemText
  primary="5. Field"
  secondary={
    <>
      <div>Select from the available options in the drop-down.</div>
      <div>
        If you have ideas for new Fields, please visit our{' '}
        <Link href="/polls" target="_blank" className={classes.link}>Polls</Link> page.
      </div>
    </>
  }
  classes={{
    primary: classes.listItemTextPrimary,
    secondary: classes.listItemTextSecondary,
  }}
/>
</ListItem>
<ListItem className={classes.listItem}>
  <ListItemText
    primary="6. Category"
    secondary={
      <>
        <div>This is a list of categories under the selected Field.</div>
        <div>
          If you have suggestions for new Categories within a Field, please visit our{' '}
          <Link href="/polls" target="_blank" className={classes.link}>Polls</Link> page.
        </div>
      </>
    }
    classes={{
      primary: classes.listItemTextPrimary,
      secondary: classes.listItemTextSecondary,
    }}
  />
</ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText
            primary="7. Links"
            secondary={
              <>
                <div>Add up to 5 Supportive Links For the Entry</div>
              </>
            }
            classes={{
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemTextSecondary,
            }}
          />
        </ListItem>
      </List>
    </div>
  );
}

export default HelpInfoDialog;
