import React, { useState } from 'react';
import { TextareaAutosize, IconButton, TextField, InputAdornment, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import AddVideoIcon from '@material-ui/icons/VideoLibrary';
import DescriptionIcon from '@material-ui/icons/Description';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { GiTreeBranch } from "react-icons/gi";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { handleInputChange, handleMediaUpload } from './formhandler';
import useStyles from './submitStyles';
import HelpInfoDialog from './HelpInfoDialog'; // Assuming this is the imported help information dialogue

function OpenForm({ formData, setFormData, setImagePreview }) {
  const classes = useStyles();
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const handleFileInput = (accept, id) => {
    const inputElement = document.getElementById(id);
    inputElement.accept = accept;
    inputElement.onchange = (e) => handleMediaUploadInternal(e, setFormData, setImagePreview);
  };

  const handleMediaRemove = (index) => {
    const updatedMedia = formData.mediaData.filter((_, i) => i !== index);
    setFormData({ ...formData, mediaData: updatedMedia });
  };

  const handleMediaUploadInternal = async (e) => {
    await handleMediaUpload(e, setFormData, setImagePreview);
    // Reset the input element to ensure the change event triggers correctly next time
    e.target.value = '';
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newKey = e.target.value.trim();
      if (newKey && (!formData.keys || !formData.keys.includes(newKey))) {
        const updatedKeys = [...(formData.keys || []), newKey];
        if (updatedKeys.length <= 7) {
          setFormData({ ...formData, keys: updatedKeys });
        } else {
          alert('You can only add up to 7 keys.');
        }
      }
      e.target.value = '';
    }
  };

  const removeKey = (index) => {
    const updatedKeys = formData.keys.filter((_, i) => i !== index);
    setFormData({ ...formData, keys: updatedKeys });
  };

  const handleHelpDialogOpen = () => {
    setHelpDialogOpen(true);
  };

  const handleHelpDialogClose = () => {
    setHelpDialogOpen(false);
  };

  return (
    <>
      <TextareaAutosize
        className={classes.descriptionField}
        minRows={3}
        maxRows={10}
        placeholder="Thoughts"
        name="description"
        value={formData.description}
        onChange={(e) => handleInputChange(e, formData, setFormData)}
        maxLength={6969}
        required
      />
      <TextField
        className={classes.keysField}
        placeholder="Add Branch Words/Phrases"
        onKeyPress={handleKeyPress}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <GiTreeBranch />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleHelpDialogOpen}>
                <HelpOutlineIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.keysPreview}>
        {(formData.keys || []).map((key, index) => (
          <span key={index} className={classes.keyTag}>
            {key}
            <IconButton size="small" onClick={() => removeKey(index)}>
              &times;
            </IconButton>
          </span>
        ))}
      </div>
      <div className={classes.uploadContainer}>
        <input
          id="image-upload"
          type="file"
          multiple
          style={{ display: 'none' }}
        />
        <label
          htmlFor="image-upload"
          className={classes.uploadLabel}
          onClick={() => handleFileInput('image/*', 'image-upload')}
        >
          <IconButton component="span" color="primary">
            <AddPhotoAlternateIcon fontSize="large" />
          </IconButton>
        </label>
        <input
  id="video-upload"
  type="file"
  accept="video/*"
  multiple
  style={{ display: 'none' }}
/>
<label
  htmlFor="video-upload"
  className={classes.uploadLabel}
  onClick={() => handleFileInput('video/*', 'video-upload')}
>
  <IconButton component="span" color="secondary">
    <AddVideoIcon fontSize="large" />
  </IconButton>
</label>
        <input
          id="document-upload"
          type="file"
          multiple
          style={{ display: 'none' }}
        />
        <label
          htmlFor="document-upload"
          className={classes.uploadLabel}
          onClick={() => handleFileInput('.pdf,.doc,.docx,.ppt,.pptx,.odt,.xlsx,.xls,.ods,.ots,.odp', 'document-upload')}
        >
          <IconButton component="span" color="default">
            <DescriptionIcon fontSize="large" />
          </IconButton>
        </label>
      </div>
      <div className={classes.previewContainer}>
        {formData.mediaData && formData.mediaData.map((media, index) => (
          <div key={index} className={
            media.type === 'image' ? classes.previewImageContainer :
            media.type === 'video' ? classes.previewVideoContainer :
            classes.previewDocumentContainer
          }>
            {media.type === 'image' && (
              <img src={media.url} alt={`Uploaded Preview ${index + 1}`} className={classes.previewImage} />
            )}
            {media.type === 'video' && (
              <video controls className={classes.previewVideo}>
                <source src={media.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {media.type === 'document' && (
              <a href={media.url} target="_blank" rel="noopener noreferrer" className={classes.previewDocumentLink}>
                {media.name}
              </a>
            )}
            <IconButton onClick={() => handleMediaRemove(index)} className={classes.removeButton}>
              &times;
            </IconButton>
          </div>
        ))}
      </div>
      <Dialog open={helpDialogOpen} onClose={handleHelpDialogClose}>
        <DialogContent>
          <HelpInfoDialog />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OpenForm;
