import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import Explorer from '../components/Explorers2/utils/explorer/officialpoolexplorer';
import PoolStats from './PoolStats';
import axios from 'axios';

const PoolCardSelect = ({ name, _id, open, onClose }) => {
  const [statsOpen, setStatsOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [poolInfo, setPoolInfo] = useState({});
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [isInPool, setIsInPool] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    if (readifyUserKey) {
      const { username } = JSON.parse(readifyUserKey);
      setUsername(username);
    }
  }, []);

  useEffect(() => {
    const checkUserInPool = async () => {
      try {
        const response = await axios.post('/api/check-user-in-pool', { poolId: _id, username });
        if (response.data.isInPool) {
          setIsInPool(true);
        } else {
          setIsInPool(false);
        }
      } catch (error) {
        console.error('Error checking if user is in pool:', error);
      }
    };

    if (username) {
      checkUserInPool();
    }
  }, [username, _id]);

  const handleStatsClick = () => {
    setStatsOpen(true);
  };

  const handleStatsClose = () => {
    setStatsOpen(false);
  };

  const handleInfoClick = async () => {
    try {
      const response = await axios.get(`/api/official-pool-info/${_id}`);
      const data = response.data.poolInfo;

      const formattedPoolInfo = {
        ...data,
        firstEntryDate: new Date(data.firstEntryDate),
        mostRecentEntryDate: new Date(data.mostRecentEntryDate),
      };

      setPoolInfo(formattedPoolInfo);
      setInfoOpen(true);
    } catch (error) {
      console.error('Error fetching pool info:', error);
    }
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleJoinPool = async () => {
    try {
      const response = await axios.post('/api/join-pool', { poolId: _id, username });
      if (response.data.success) {
        setMessage(response.data.message || 'Joined pool successfully');
        onClose(); // Close the dialog after successful join
      } else {
        setMessage(response.data.message || 'Failed to join the pool');
      }
    } catch (error) {
      console.error('Error joining pool:', error);
      setMessage('An error occurred while joining the pool.');
    }
  };

  return (
    <>
      {/* Main Dialog */}
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth={!fullScreen}
        PaperProps={{
          style: {
            width: fullScreen ? '100%' : '100%',
            height: fullScreen ? '100%' : '100%',
            backgroundColor: 'rgba(245, 245, 245, 0.8)',
          },
        }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // 30% opacity
        },
      }}
      >
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5" sx={{ color: '#004d40', fontWeight: 'bold' }}>
              {name}
            </Typography>
          </Box>
        </DialogTitle>

        <DialogActions>
          <Box width="100%" display="flex" justifyContent="center" gap={2}>
          <Button
  onClick={handleInfoClick}
  sx={{
    color: '#f5f5f5',
    backgroundColor: '#004d40',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      color: '#004d40',
    },
    fontSize: '1rem',
  }}
>
  About
</Button>
<Button
  onClick={onClose}
  sx={{
    color: '#f5f5f5',
    backgroundColor: '#004d40',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      color: '#004d40',
    },
    fontSize: '1rem',
  }}
>
  Close
</Button>
          </Box>
        </DialogActions>

        <DialogContent>
          {message && (
            <Box textAlign="center" my={2}>
              <Typography variant="body1" sx={{ color: '#d32f2f' }}>
                {message}
              </Typography>
            </Box>
          )}
          <Explorer poolId={_id} />
        </DialogContent>
      </Dialog>

      {/* Stats Dialog */}
      <Dialog open={statsOpen} onClose={handleStatsClose}>
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h6" sx={{ color: '#004d40', fontWeight: 'bold' }}>
              Pool Statistics
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <PoolStats poolId={_id} />
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="center">
            <Button
              onClick={handleStatsClose}
              sx={{
                color: '#ffffff',
                backgroundColor: '#2f4f4f',
                '&:hover': { backgroundColor: '#3c5a5a' },
                fontSize: '1rem',
              }}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Info Dialog */}
      <Dialog open={infoOpen} onClose={handleInfoClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h6" sx={{ color: '#0abab5', fontWeight: 'bold' }}>
              About
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#ffffff' }}>
            <Grid container spacing={2}>
              {/* Creator */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                  Creator:
                </Typography>
                <Typography variant="body1" sx={{ color: '#556b2f' }}>
                  {poolInfo.creator || 'N/A'}
                </Typography>
              </Grid>
              {/* Description */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                  Description:
                </Typography>
                <Typography variant="body1" sx={{ color: '#556b2f' }}>
                  {poolInfo.description || 'N/A'}
                </Typography>
              </Grid>
              {/* First Entry Date */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                  First Entry Date:
                </Typography>
                <Typography variant="body1" sx={{ color: '#556b2f' }}>
                  {poolInfo.firstEntryDate
                    ? poolInfo.firstEntryDate.toLocaleString()
                    : 'N/A'}
                </Typography>
              </Grid>
              {/* Most Recent Entry Date */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                  Most Recent Entry Date:
                </Typography>
                <Typography variant="body1" sx={{ color: '#556b2f' }}>
                  {poolInfo.mostRecentEntryDate
                    ? poolInfo.mostRecentEntryDate.toLocaleString()
                    : 'N/A'}
                </Typography>
              </Grid>
              {/* Business Information */}
              {poolInfo.businessInfo && (
                <>
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Typography variant="h6" sx={{ color: '#0abab5', fontWeight: 'bold' }}>
                        Business Information
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                      Business Name:
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#556b2f' }}>
                      {poolInfo.businessInfo.businessName || 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                      Website:
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#556b2f' }}>
                      <a
                        href={poolInfo.businessInfo.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#0abab5', textDecoration: 'none' }}
                      >
                        {poolInfo.businessInfo.website}
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                      Contact Email:
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#556b2f' }}>
                      {poolInfo.businessInfo.contactEmail || 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                      Contact Phone:
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#556b2f' }}>
                      {poolInfo.businessInfo.contactPhone || 'N/A'}
                    </Typography>
                  </Grid>
                  {/* Address */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                      Address:
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#556b2f' }}>
                      {poolInfo.businessInfo.address
                        ? `${poolInfo.businessInfo.address.street}, ${poolInfo.businessInfo.address.city}, ${poolInfo.businessInfo.address.state} ${poolInfo.businessInfo.address.zipCode}, ${poolInfo.businessInfo.address.country}`
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  {/* Official Entity */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ color: '#2f4f4f', fontWeight: 'bold' }}>
                      Official Entity:
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#556b2f' }}>
                      {poolInfo.businessInfo.officialEntity ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="center">
            <Button
              onClick={handleInfoClose}
              sx={{
                color: '#ffffff',
                backgroundColor: '#0abab5',
                '&:hover': { backgroundColor: '#17c4c9' },
                fontSize: '1rem',
              }}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PoolCardSelect;
