// src/components/Pathways/EditPathwayDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import { updatePathway } from './pathwaysapi'; // Ensure this path is correct

const EditPathwayDialog = ({ open, onClose, pathway = {}, onUpdate }) => {
  const [editedPathway, setEditedPathway] = useState(pathway);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const username = readifyUserKey ? readifyUserKey.username : 'anonymous';

  const theme = useTheme();

  useEffect(() => {
    setEditedPathway(pathway);
  }, [pathway]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const path = name.replace(/\[(\d+)\]/g, '.$1'); // Convert array indices to dot notation
    setEditedPathway((prev) => _.set({ ...prev }, path, value));
  };

  const handleAddMainStep = () => {
    setEditedPathway((prev) => ({
      ...prev,
      mainSteps: [
        ...prev.mainSteps,
        { _id: uuidv4(), description: '', midsteps: [], resources: [] },
      ],
    }));
  };

  const handleAddMidStep = (mainStepId) => {
    setEditedPathway((prev) => {
      const updatedMainSteps = prev.mainSteps.map((step) => {
        if (step._id === mainStepId) {
          return {
            ...step,
            midsteps: [
              ...step.midsteps,
              { _id: uuidv4(), description: '', substeps: [], resources: [] },
            ],
          };
        }
        return step;
      });
      return { ...prev, mainSteps: updatedMainSteps };
    });
  };

  const handleAddSubStep = (mainStepId, midStepId) => {
    setEditedPathway((prev) => {
      const updatedMainSteps = prev.mainSteps.map((step) => {
        if (step._id === mainStepId) {
          const updatedMidsteps = step.midsteps.map((mid) => {
            if (mid._id === midStepId) {
              return {
                ...mid,
                substeps: [
                  ...mid.substeps,
                  { _id: uuidv4(), description: '', resources: [] },
                ],
              };
            }
            return mid;
          });
          return { ...step, midsteps: updatedMidsteps };
        }
        return step;
      });
      return { ...prev, mainSteps: updatedMainSteps };
    });
  };

  // Utility function to read file as base64
  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleAddResource = async (indices, event) => {
    const { mainStepIndex, midStepIndex, subStepIndex } = indices;

    const files = event.target.files;
    if (!files || files.length === 0) return;

    try {
      setUploading(true);
      const mediaData = await Promise.all(
        Array.from(files).map(async (file) => {
          const base64 = await readFileAsDataURL(file);
          let mediaType = 'document';
          if (file.type.startsWith('image/')) {
            mediaType = 'image';
          } else if (file.type.startsWith('video/')) {
            mediaType = 'video';
          }
          return {
            _id: uuidv4(),
            base64: base64, // Set base64 here
            mediaLink: base64, // Use base64 for display purposes
            mediaType,
            name: file.name,
          };
        })
      );

      mediaData.forEach((media) => {
        setEditedPathway((prev) => {
          const pathwayCopy = _.cloneDeep(prev);
          let currentLevel = pathwayCopy;

          if (mainStepIndex !== null && mainStepIndex !== undefined) {
            currentLevel = currentLevel.mainSteps[mainStepIndex];
          }

          if (midStepIndex !== null && midStepIndex !== undefined) {
            currentLevel = currentLevel.midsteps[midStepIndex];
          }

          if (subStepIndex !== null && subStepIndex !== undefined) {
            currentLevel = currentLevel.substeps[subStepIndex];
          }

          if (!currentLevel.resources) {
            currentLevel.resources = [];
          }

          currentLevel.resources.push(media);
          return pathwayCopy;
        });
      });
      setError('');
    } catch (error) {
      console.error("Error handling media upload:", error);
      setError('Failed to upload media. Please try again.');
    } finally {
      setUploading(false);
      // Reset the input value to allow uploading the same file again if needed
      event.target.value = null;
    }
  };

  const handleRemoveResource = (indices, resourceId) => {
    const { mainStepIndex, midStepIndex, subStepIndex } = indices;

    setEditedPathway((prev) => {
      const pathwayCopy = _.cloneDeep(prev);
      let currentLevel = pathwayCopy;

      if (mainStepIndex !== null && mainStepIndex !== undefined) {
        currentLevel = currentLevel.mainSteps[mainStepIndex];
      }

      if (midStepIndex !== null && midStepIndex !== undefined) {
        currentLevel = currentLevel.midsteps[midStepIndex];
      }

      if (subStepIndex !== null && subStepIndex !== undefined) {
        currentLevel = currentLevel.substeps[subStepIndex];
      }

      if (currentLevel.resources) {
        currentLevel.resources = currentLevel.resources.filter(
          (res) => res._id !== resourceId
        );
      }

      return pathwayCopy;
    });
  };

  const handleSave = async () => {
    try {
      // Validate required fields
      if (!editedPathway.goal.trim()) {
        setError('Goal is required.');
        return;
      }
  
      if (
        editedPathway.mainSteps.some(
          (step) => !step.description.trim()
        )
      ) {
        setError('All main steps must have a description.');
        return;
      }
  
      // Optionally, add more validations for midsteps and substeps
  
      setUploading(true);
      setError('');
  
      // Prepare pathway data by removing any unnecessary fields
      const pathwayData = _.cloneDeep(editedPathway);
  
      // Do not remove mediaLink from resources
      // This ensures existing resources retain their mediaLink when sent to the backend
  
      // Convert pathway data to JSON string as per server expectations
      const pathwayJson = JSON.stringify(pathwayData);
  
      // Construct the payload with username and pathway
      const payload = {
        username: username, // Include the username
        pathway: pathwayJson, // Include the pathway as a JSON string
      };
  
      // Send the pathway data as per server expectations
      const response = await updatePathway(editedPathway._id, payload);
  
      if (response) {
        onUpdate(response);
        onClose();
      } else {
        setError('Failed to save changes. Please try again.');
      }
    } catch (err) {
      console.error(err);
      setError(
        err.response?.data?.error ||
          'An error occurred while saving the pathway.'
      );
    } finally {
      setUploading(false);
    }
  };

  // Updated renderResources function with horizontal scroll and preserved mediaId
  const renderResources = (resources, indices) => (
    <div
      style={{
        display: 'flex',
        overflowX: 'auto',
        padding: '10px 0',
        gap: '10px',
      }}
    >
      {resources.map((resource) => (
        <div
          key={resource._id}
          style={{
            position: 'relative',
            minWidth: '100px',
            minHeight: '100px',
            maxWidth: '100px',
            maxHeight: '100px',
            flex: '0 0 auto',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '8px',
            backgroundColor: '#ffffff',
          }}
        >
          {resource.mediaType === 'image' && (
            <img
              src={resource.mediaLink || resource.base64}
              alt="Resource"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          )}
          {resource.mediaType === 'video' && (
            <video
              src={resource.mediaLink || resource.base64}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
              controls
            />
          )}
          {resource.mediaType === 'document' && (
            <a
              href={resource.mediaLink || resource.base64}
              download={resource.name}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: '#e0f7fa',
                borderRadius: '8px',
                textDecoration: 'none',
                color: '#004d40',
                fontSize: '0.8rem',
                textAlign: 'center',
                padding: '5px',
              }}
            >
              {resource.name}
            </a>
          )}
          <IconButton
            size="small"
            onClick={() =>
              handleRemoveResource(indices, resource._id)
            }
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: 'rgba(255,255,255,0.8)',
              padding: '2px',
              color: '#ff1744',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      ))}
      {/* Scrollbar Styling (Optional) */}
      <style>
        {`
          /* Hide scrollbar for Chrome, Safari and Opera */
          div::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge and Firefox */
          div {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        `}
      </style>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#ffffff',
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#0abab5',
          color: '#ffffff',
          fontWeight: 'bold',
        }}
      >
        Edit Pathway
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '24px',
        }}
      >
        {error && (
          <Typography
            color="#ff1744"
            variant="body2"
            gutterBottom
            sx={{ fontWeight: '500' }}
          >
            {error}
          </Typography>
        )}
        <TextField
          label="Goal"
          name="goal"
          value={editedPathway.goal || ''}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            style: { color: '#2f4f4f', fontWeight: '500' },
          }}
          InputProps={{
            style: { color: '#2f4f4f' },
          }}
          disabled={username !== pathway.createdBy} // Disable if not creator
        />
        {/* Add other pathway fields as needed, conditionally disabled based on isCreator */}

        <Typography
          variant="h6"
          sx={{
            marginTop: '30px',
            color: '#004d40',
            fontWeight: '600',
          }}
        >
          Main Steps
        </Typography>
        {editedPathway.mainSteps && editedPathway.mainSteps.length > 0 ? (
          editedPathway.mainSteps.map((step, index) => (
            <Grid
              container
              spacing={2}
              key={step._id || index}
              sx={{
                marginBottom: '30px',
                paddingLeft: '20px',
                borderLeft: `4px solid #556b2f`,
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
                padding: '16px',
              }}
            >
              <Grid item xs={12}>
                <TextField
                  label={`Step ${index + 1}`}
                  name={`mainSteps[${index}].description`}
                  value={step.description || ''}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#2f4f4f', fontWeight: '500' },
                  }}
                  InputProps={{
                    style: { color: '#2f4f4f' },
                  }}
                  disabled={username !== pathway.createdBy}
                />
              </Grid>

              {/* Media Upload for Main Step */}
              <Grid item xs={12}>
                <input
                  accept="image/*,video/*,application/pdf"
                  style={{ display: 'none' }}
                  id={`mainstep-media-upload-${step._id}`}
                  type="file"
                  multiple
                  onChange={(e) => {
                    handleAddResource(
                      {
                        mainStepIndex: index,
                        midStepIndex: null,
                        subStepIndex: null,
                      },
                      e
                    );
                  }}
                />
                <label htmlFor={`mainstep-media-upload-${step._id}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<AddPhotoAlternateIcon />}
                    sx={{
                      backgroundColor: '#0abab5',
                      '&:hover': {
                        backgroundColor: '#009e93',
                      },
                    }}
                  >
                    Add Media
                  </Button>
                </label>
              </Grid>
              {/* Display Resources for Main Step */}
              {step.resources && step.resources.length > 0 && (
                <Grid item xs={12}>
                  {renderResources(step.resources, {
                    mainStepIndex: index,
                    midStepIndex: null,
                    subStepIndex: null,
                  })}
                </Grid>
              )}

              {/* Add Midstep Button */}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleAddMidStep(step._id)}
                  sx={{
                    borderColor: '#556b2f',
                    color: '#556b2f',
                    '&:hover': {
                      backgroundColor: '#556b2f',
                      color: '#ffffff',
                    },
                  }}
                >
                  Add Midstep
                </Button>
              </Grid>

              {/* Midsteps */}
              {step.midsteps && step.midsteps.map((mid, midIndex) => (
                <Grid
                  container
                  spacing={2}
                  key={mid._id || midIndex}
                  sx={{
                    marginLeft: '20px',
                    marginBottom: '30px',
                    paddingLeft: '20px',
                    borderLeft: `4px solid #2f4f4f`,
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                    padding: '16px',
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      label={`Midstep ${midIndex + 1}`}
                      name={`mainSteps[${index}].midsteps[${midIndex}].description`}
                      value={mid.description || ''}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        style: { color: '#2f4f4f', fontWeight: '500' },
                      }}
                      InputProps={{
                        style: { color: '#2f4f4f' },
                      }}
                      disabled={username !== pathway.createdBy}
                    />
                  </Grid>

                  {/* Media Upload for Midstep */}
                  <Grid item xs={12}>
                    <input
                      accept="image/*,video/*,application/pdf"
                      style={{ display: 'none' }}
                      id={`midstep-media-upload-${mid._id}`}
                      type="file"
                      multiple
                      onChange={(e) => {
                        handleAddResource(
                          {
                            mainStepIndex: index,
                            midStepIndex: midIndex,
                            subStepIndex: null,
                          },
                          e
                        );
                      }}
                    />
                    <label htmlFor={`midstep-media-upload-${mid._id}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<AddPhotoAlternateIcon />}
                        sx={{
                          backgroundColor: '#0abab5',
                          '&:hover': {
                            backgroundColor: '#009e93',
                          },
                        }}
                      >
                        Add Media
                      </Button>
                    </label>
                  </Grid>
                  {/* Display Resources for Midstep */}
                  {mid.resources && mid.resources.length > 0 && (
                    <Grid item xs={12}>
                      {renderResources(mid.resources, {
                        mainStepIndex: index,
                        midStepIndex: midIndex,
                        subStepIndex: null,
                      })}
                    </Grid>
                  )}

                  {/* Add Substep Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleAddSubStep(step._id, mid._id)}
                      sx={{
                        borderColor: '#556b2f',
                        color: '#556b2f',
                        '&:hover': {
                          backgroundColor: '#556b2f',
                          color: '#ffffff',
                        },
                      }}
                    >
                      Add Substep
                    </Button>
                  </Grid>

                  {/* Substeps */}
                  {mid.substeps && mid.substeps.map((sub, subIndex) => (
                    <Grid
                      container
                      spacing={2}
                      key={sub._id || subIndex}
                      sx={{
                        marginLeft: '20px',
                        marginBottom: '30px',
                        paddingLeft: '20px',
                        borderLeft: `4px solid #004d40`,
                        backgroundColor: '#e8f5e9',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <Grid item xs={12}>
                        <TextField
                          label={`Substep ${String.fromCharCode(97 + subIndex).toUpperCase()}`}
                          name={`mainSteps[${index}].midsteps[${midIndex}].substeps[${subIndex}].description`}
                          value={sub.description || ''}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: '#2f4f4f', fontWeight: '500' },
                          }}
                          InputProps={{
                            style: { color: '#2f4f4f' },
                          }}
                          disabled={username !== pathway.createdBy}
                        />
                      </Grid>

                      {/* Media Upload for Substep */}
                      <Grid item xs={12}>
                        <input
                          accept="image/*,video/*,application/pdf"
                          style={{ display: 'none' }}
                          id={`substep-media-upload-${sub._id}`}
                          type="file"
                          multiple
                          onChange={(e) => {
                            handleAddResource(
                              {
                                mainStepIndex: index,
                                midStepIndex: midIndex,
                                subStepIndex: subIndex,
                              },
                              e
                            );
                          }}
                        />
                        <label htmlFor={`substep-media-upload-${sub._id}`}>
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<AddPhotoAlternateIcon />}
                            sx={{
                              backgroundColor: '#0abab5',
                              '&:hover': {
                                backgroundColor: '#009e93',
                              },
                            }}
                          >
                            Add Media
                          </Button>
                        </label>
                      </Grid>
                      {/* Display Resources for Substep */}
                      {sub.resources && sub.resources.length > 0 && (
                        <Grid item xs={12}>
                          {renderResources(sub.resources, {
                            mainStepIndex: index,
                            midStepIndex: midIndex,
                            subStepIndex: subIndex,
                          })}
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          ))
        ) : (
          <Typography
            sx={{
              color: '#2f4f4f',
              fontStyle: 'italic',
            }}
          >
            No main steps available.
          </Typography>
        )}
        {username === pathway.createdBy && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMainStep}
            sx={{
              backgroundColor: '#556b2f',
              '&:hover': {
                backgroundColor: '#3e5625',
              },
              marginTop: '10px',
            }}
          >
            Add Main Step
          </Button>
        )}
        {username === pathway.createdBy && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            sx={{
              backgroundColor: '#004d40',
              '&:hover': {
                backgroundColor: '#00332e',
              },
              marginTop: '20px',
              marginLeft: '10px',
            }}
            disabled={uploading}
          >
            {uploading ? 'Saving...' : 'Save Changes'}
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

EditPathwayDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pathway: PropTypes.shape({
    _id: PropTypes.string,
    goal: PropTypes.string,
    createdBy: PropTypes.string, // Ensure createdBy is part of pathway
    mainSteps: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        description: PropTypes.string,
        resources: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            mediaType: PropTypes.string,
            name: PropTypes.string,
            mediaLink: PropTypes.string, // Use mediaLink for display
            base64: PropTypes.string, // Include base64 for backend
          })
        ),
        midsteps: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            description: PropTypes.string,
            resources: PropTypes.arrayOf(
              PropTypes.shape({
                _id: PropTypes.string,
                mediaType: PropTypes.string,
                name: PropTypes.string,
                mediaLink: PropTypes.string, // Use mediaLink for display
                base64: PropTypes.string, // Include base64 for backend
              })
            ),
            substeps: PropTypes.arrayOf(
              PropTypes.shape({
                _id: PropTypes.string,
                description: PropTypes.string,
                resources: PropTypes.arrayOf(
                  PropTypes.shape({
                    _id: PropTypes.string,
                    mediaType: PropTypes.string,
                    name: PropTypes.string,
                    mediaLink: PropTypes.string, // Use mediaLink for display
                    base64: PropTypes.string, // Include base64 for backend
                  })
                ),
              })
            ),
          })
        ),
      })
    ),
  }),
  onUpdate: PropTypes.func.isRequired,
  // Removed username as a prop since it's derived from localStorage
};

export default EditPathwayDialog;
