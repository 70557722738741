// src/components/Explorers2/utils/add/submitStyles.js

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%', // Ensure the container takes full height of the parent
  },
  scrollableContainer: { 
    overflowY: 'auto',
    maxHeight: '80vh',
    width: '100%',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2), // Added top padding
    [theme.breakpoints.down('sm')]: {
      maxHeight: '69vh',
    },
    // Hide scrollbar for Chrome, Safari and Opera
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // Hide scrollbar for IE, Edge and Firefox
    '-ms-overflow-style': 'none', // IE and Edge
    'scrollbar-width': 'none', // Firefox
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    textTransform: 'none',
  },
  toggleButton: {
    margin: theme.spacing(1),
    textTransform: 'none',
    backgroundColor: '#004d40',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00332e',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '80vw',
    padding: theme.spacing(3), // Increased padding
    borderRadius: theme.spacing(1),
    backgroundColor: '#fff',
    boxSizing: 'border-box', // Ensure padding is included in the total width and height
  },
  descriptionField: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    '& label': {
      color: 'black',
    },
    '& .MuiInputBase-input': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    '& label': {
      color: 'black',
    },
  },
  dropdown: {
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'black',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#f0f0f0',
      padding: theme.spacing(1),
    },
    '& .MuiSelect-icon': {
      color: 'black',
    },
    '& .MuiInputLabel-root': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
    backgroundColor: '#004d40',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00332e',
    },
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center the upload button
    marginBottom: theme.spacing(2), // Add spacing below the upload container
  },
  uploadLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'none',
  },
  previewContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  previewImageContainer: {
    position: 'relative',
    width: '100px',
    height: '100px',
  },
  previewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  removeButton: { // Added remove button styling
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    width: '24px',
    height: '24px',
    minWidth: '24px',
    padding: '0',
  },
  dialogTitle: {
    backgroundColor: '#004d40',
    color: '#fff',
  },
  dialogActions: {
    justifyContent: 'space-between',
    color: '#fff',
  },
  dialogPaper: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(8px)',
    borderRadius: '15px',
    border: '1px solid #004d40',
    boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.5)',
    color: '#fff',
  },
  dialogBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.96)',
  },
  inputLabel: {
    color: 'black',
  },
}));

export default useStyles;
