import React from 'react';
import { Link } from 'react-router-dom';
import HumanityImage from '../../../assets/humanity.png';
import ArtImage from '../../../assets/art.png';
import ScienceImage from '../../../assets/sciencefront1.png';
import PoolImage from '../../../assets/pool.png';
import { useMediaQuery } from 'react-responsive';

const Front = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', marginTop: '20vh', justifyContent: 'space-evenly', alignItems: 'center' }}>
      <Link to="/humanity">
        <img
          src={HumanityImage} 
          alt="Humanity"
          style={{ width: isMobile ? "280px" : "420px", height: isMobile ? "280px" : "420px", marginBottom: isMobile ? '20px' : '0' }}
        />
      </Link>
      <Link to="/art">
        <img
          src={ArtImage}
          alt="Art"
          style={{ width: isMobile ? "280px" : "420px", height: isMobile ? "280px" : "420px", marginBottom: isMobile ? '20px' : '0' }}
        />
      </Link>
      <Link to="/science">
        <img
          src={ScienceImage}
          alt="Science"
          style={{ width: isMobile ? "280px" : "420px", height: isMobile ? "280px" : "420px", marginBottom: isMobile ? '20px' : '0' }}
        />
      </Link>
      <Link to="/pools">
        <img
          src={PoolImage}
          alt="Pool"
          style={{ width: isMobile ? "280px" : "420px", height: isMobile ? "280px" : "420px", marginBottom: isMobile ? '20px' : '0' }}
        />
      </Link>
    </div>
  );
};

export default Front;