import React, { useState, useEffect } from 'react';
import {
  useMediaQuery,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import styled from 'styled-components';
import Explorer from './explorer/explorer';
import ChatRoomsList from '../../lounge/ChatRoomsList';
import Pools from '../../../pools/pools';
import { makeStyles } from '@material-ui/core/styles';
import compassImage from '../../../assets/compass.png';
import explorerImage from '../../../assets/explore.png';
import poolsImage from '../../../assets/pools.png';
import pollsImage from '../../../assets/polls.png';
import statsImage from '../../../assets/stats.png';
import pathwaysImage from '../../../assets/pathways.png';
import trendsImage from '../../../assets/trends.png';
import timelineImage from '../../../assets/timeline.png';
import settingsImage from '../../../assets/settings.png';
import patternsImage from '../../../assets/patterns.png';
import notificationsImage from '../../../assets/notifications.png';
import conversationsIcon from '../../../assets/conversation.png';
//import lovePanelImage from '../../../assets/lovePanel.png'; // Ensure this image exists
import FeedbackContentRequest from '../../feedbackrequests/all';
import Insights from '../../analytics/insights';
import UserSettingsMenu from '../../Person/settings';
import Trends from '../../analytics/trends';
import ChatRoomsDialogue from '../../lounge/ChatRoomsDialogue';
import PathwayExplorer from '../../Pathways/PathwayExplorer';
import AdminPanel from '../../lovepanel/panel';

const NavList = styled.div`
  width: 100%;
  padding: 2rem;
  color: white;
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
  justify-content: space-between;
  overflow-y: auto;
  flex-grow: 1;
  height: calc(100% - 4rem);

  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 768px) {
    margin-left: -1.5rem; /* Remove left margin on mobile */
    justify-content: center; /* Center items on mobile */
    overflow-y: initial;
    height: auto;
  }
`;

const NavListItem = styled.div`
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #004d40;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  flex-grow: 1;
  justify-content: center;
  min-width: 30%;

  &:hover {
    background-color: #fff;
    color: #004d40;
  }
`;

const NavListItemText = styled.span`
  margin-left: 0.5rem;
`;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '17%',
    position: 'fixed',
    bottom: 0,
    height: '69vh',
    transition: 'height 0.3s ease-in-out',
    backgroundColor: 'none',
    zIndex: 1300,
    display: 'flex',
    flexDirection: 'column',
  },
  collapsed: {
    height: '60px',
  },
  expandButton: {
    position: 'absolute',
    top: '-42px',
    left: '50%',
    zIndex: 1400,
    backgroundColor: 'transparent',
    borderRadius: '50%',
    padding: '8px',
  },
  compassImage: {
    width: '69px',
    height: '69px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    borderRadius: '50%', // Make the icon circular
    animation: '$breathingGlow 2s ease-in-out infinite',
  },
  '@keyframes breathingGlow': {
    '0%': {
      boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)',
    },
    '50%': {
      boxShadow: '0 0 20px rgba(255, 255, 255, 1)',
    },
    '100%': {
      boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)',
    },
  },
  compassImageActive: {
    transform: 'rotate(360deg)',
  },
  arrowIcon: {
    width: '0vw',
    height: '0vh',
    color: '#000000',
  },
  arrowContainer: {
    position: 'absolute',
    top: '-9vh',
    left: 'calc(50% + 25px)',
    zIndex: 1400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  dialogPaperMobile: {
    height: '100vh',
    width: '90vw',
    marginLeft: '5.69vw',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  dialogContent: {
    padding: 0,
    color: 'white',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  },
}));

const MainLayout = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [selectedComponent, setSelectedComponent] = useState('Explorer');
  const [open, setOpen] = useState(true);
  const [compassActive, setCompassActive] = useState(false);
  const [compassDialogOpen, setCompassDialogOpen] = useState(false);
  const [chatRoomsDialogOpen, setChatRoomsDialogOpen] = useState(false);

  // Retrieve the logged-in user
  const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  const requestFullscreen = () => {
    if (document.fullscreenEnabled && !document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
    setCompassActive(!compassActive);
  };

  const handleSelect = (component) => {
    setSelectedComponent(component);
  };

  const handleOpenCompassDialog = () => {
    setCompassDialogOpen(true);
  };

  const handleCloseCompassDialog = () => {
    setCompassDialogOpen(false);
  };

  const handleOpenChatRoomsDialog = () => {
    setChatRoomsDialogOpen(true);
  };

  const handleCloseChatRoomsDialog = () => {
    setChatRoomsDialogOpen(false);
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Explorer':
        return <Explorer />;
      case 'Pools':
        return <Pools />;
      case 'Polls':
        return <FeedbackContentRequest />;
      case 'Stats':
        return <Insights />;
      case 'Pathways':
        return <PathwayExplorer />;
      case 'Trends':
        return <Trends />;
      case 'Timeline':
        return <FeedbackContentRequest />;
      case 'Patterns':
        return <FeedbackContentRequest />;
      case 'Notifications':
        return <FeedbackContentRequest />;
      case 'Settings':
        return <UserSettingsMenu />;
      case 'LovePanel':
        return <AdminPanel />; // Ensure this component is created and imported
      default:
        return <Explorer />;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
      {!isMobile && (
        <div style={{ width: '15vw', position: 'relative' }}>
          <div className={`${classes.drawer} ${!open && classes.collapsed}`}>
            <IconButton onClick={toggleDrawer} className={classes.expandButton}>
              <img
                src={compassImage}
                alt="Compass"
                className={`${classes.compassImage} ${compassActive && classes.compassImageActive}`}
              />
            </IconButton>
            <div className={classes.arrowContainer}>
              {open ? (
                <ExpandMore className={classes.arrowIcon} />
              ) : (
                <ExpandLess className={classes.arrowIcon} />
              )}
            </div>
            {open && (
              <NavList>
                {/* Conditionally render the "Love Panel" button */}
                {loggedInUser === 'Love' && (
                  <NavListItem onClick={() => handleSelect('LovePanel')}>
                    <img src={settingsImage} alt="Love Panel" style={{ width: '42.69px', height: '42.69px' }} />
                    <NavListItemText>Love Panel</NavListItemText>
                  </NavListItem>
                )}
                <NavListItem onClick={() => handleSelect('Explorer')}>
                  <img src={explorerImage} alt="Explorer" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Explorer</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Pools')}>
                  <img src={poolsImage} alt="Pools" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Pools</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Polls')}>
                  <img src={pollsImage} alt="Polls" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Polls</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Stats')}>
                  <img src={statsImage} alt="Stats" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Stats</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Trends')}>
                  <img src={trendsImage} alt="Trends" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Trends</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Timeline')}>
                  <img src={timelineImage} alt="Timeline" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Timeline</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Pathways')}>
                  <img src={pathwaysImage} alt="Pathways" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Pathways</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Patterns')}>
                  <img src={patternsImage} alt="Patterns" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Patterns</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Notifications')}>
                  <img src={notificationsImage} alt="Notifications" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Notifications</NavListItemText>
                </NavListItem>
                <NavListItem onClick={() => handleSelect('Settings')}>
                  <img src={settingsImage} alt="Settings" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Settings</NavListItemText>
                </NavListItem>

                {/* Add more NavListItems for other components */}
              </NavList>
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <div>
          {/* Compass button */}
          <IconButton
            onClick={() => {
              handleOpenCompassDialog();
              requestFullscreen();
            }}
            style={{
              position: 'fixed',
              bottom: '4.2vh', // Adjust bottom to keep it visible
              left: '-2.9vw', // Adjust left to a relative position
              zIndex: 1299,
            }}
          >
            <img
              src={compassImage}
              alt="Compass"
              className={`${classes.compassImage} ${compassActive && classes.compassImageActive}`}
              style={{ width: '49.69px', height: '49.69px' }}
            />
          </IconButton>

          <IconButton
            onClick={handleOpenChatRoomsDialog}
            style={{
              position: 'fixed',
              bottom: '4.2vh', // Adjust bottom to keep it visible
              right: '-2.9vw', // Align to the right for balance
              zIndex: 1299,
            }}
          >
            <img src={conversationsIcon} alt="Chat Rooms" style={{ width: '42.69px', height: '42.69px' }} />
          </IconButton>

          {/* Compass dialog */}
          <Dialog
            open={compassDialogOpen}
            onClose={handleCloseCompassDialog}
            fullWidth
            maxWidth={false}
            classes={{ paper: classes.dialogPaperMobile }}
            BackdropProps={{
              classes: {
                root: classes.backdrop,
              },
            }}
          >
            {/* Remove DialogTitle to avoid any default styling */}
            <DialogContent className={classes.dialogContent}>
              <NavList>
                <NavListItem
                  onClick={() => {
                    handleSelect('Explorer');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={explorerImage} alt="Explorer" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Explorer</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Pools');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={poolsImage} alt="Pools" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Pools</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Polls');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={pollsImage} alt="Polls" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Polls</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Stats');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={statsImage} alt="Stats" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Stats</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Trends');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={trendsImage} alt="Trends" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Trends</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Timeline');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={timelineImage} alt="Timeline" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Timeline</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Pathways');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={pathwaysImage} alt="Pathways" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Pathways</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Patterns');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={patternsImage} alt="Patterns" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Patterns</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Notifications');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={notificationsImage} alt="Notifications" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Notifications</NavListItemText>
                </NavListItem>
                <NavListItem
                  onClick={() => {
                    handleSelect('Settings');
                    handleCloseCompassDialog();
                  }}
                >
                  <img src={settingsImage} alt="Settings" style={{ width: '42.69px', height: '42.69px' }} />
                  <NavListItemText>Settings</NavListItemText>
                </NavListItem>

                {/* Conditionally render the "Love Panel" button */}
                {loggedInUser === 'Love' && (
                  <NavListItem
                    onClick={() => {
                      handleSelect('LovePanel');
                      handleCloseCompassDialog();
                    }}
                  >
                    <img src={settingsImage} alt="Love Panel" style={{ width: '42.69px', height: '42.69px' }} />
                    <NavListItemText>Love Panel</NavListItemText>
                  </NavListItem>
                )}

                {/* Add more NavListItems for other components */}
                {/* ... */}
              </NavList>
            </DialogContent>
          </Dialog>
          {/* ChatRoomsList dialog */}
          <Dialog
            open={chatRoomsDialogOpen}
            onClose={handleCloseChatRoomsDialog}
            fullWidth
            maxWidth={false}
            classes={{ paper: classes.dialogPaperMobile }}
            BackdropProps={{
              classes: {
                root: classes.backdrop,
              },
            }}
          >
            <ChatRoomsDialogue />
          </Dialog>
        </div>
      )}
      <div style={{ width: isMobile ? '100vw' : '74vw' }}>{renderComponent()}</div>
      {!isMobile && (
        <div style={{ width: '10vw' }}>
          <ChatRoomsList />
        </div>
      )}
    </div>
  );
};

export default MainLayout;
