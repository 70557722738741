import axios from 'axios';
import backendUrl from '../backendUrl';

const baseUrl = `/api/users`;

const setConfig = (token) => {
  return {
    headers: { 'x-auth-token': token },
  };
};

const getUser = async (username, limit, page) => {
  const response = await axios.get(
    `${baseUrl}/${username}`
  );
  return response.data;
};

const uploadAvatar = async (avatarObj, token) => {
  const response = await axios.post(
    `${baseUrl}/avatar`,
    avatarObj,
    setConfig(token)
  );
  return response.data;
};

const removeAvatar = async (token) => {
  const response = await axios.delete(`${baseUrl}/avatar`, setConfig(token));
  return response.data;
};

const userService = { getUser, uploadAvatar, removeAvatar };

export default userService;
