import generateBase64Encode from '../../../../utils/genBase64Encode';

export const handleInputChange = (e, formData, setFormData) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};

export const handleLinkChange = (index, value, formData, setFormData) => {
  const links = [...formData.links];
  links[index] = value;
  setFormData({
    ...formData,
    links,
  });
};

export const addLinkField = (numLinks, setNumLinks) => {
  if (numLinks < 5) {
    setNumLinks(numLinks + 1);
  }
};

export const removeLinkField = (index, formData, setFormData, numLinks, setNumLinks) => {
  const links = [...formData.links];
  links.splice(index, 1);
  setFormData({
    ...formData, 
    links,
  });
  setNumLinks(numLinks - 1);
};

export const handleMediaUpload = async (event, setFormData, setImagePreview) => {
  const files = Array.from(event.target.files);

  const encodedFiles = await Promise.all(files.map(file => generateBase64Encode(file)));

  const mediaData = encodedFiles.map((base64, index) => ({
    url: URL.createObjectURL(files[index]), // Blob URL for preview
    type: files[index].type.startsWith('image') ? 'image' :
          files[index].type.startsWith('video') ? 'video' :
          'document',
    name: files[index].name,
    file: files[index], // Keep the file for blob usage
    base64, // Add the base64 string for server submission
  })); 

  const mediaType = mediaData[0]?.type || '';

  setFormData(prevFormData => ({
    ...prevFormData,
    mediaData: [...(prevFormData.mediaData || []), ...mediaData],
    mediaType: mediaType
  }));

  if (setImagePreview && mediaType === 'image') {
    setImagePreview(mediaData.map(media => media.url));
  }
};
