import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    textTransform: 'none',
  },
  toggleButton: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '600px',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: '#fff',
  },
  descriptionField: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    '& label': {
      color: 'black',
    },
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    '& label': {
      color: 'black',
    },
  },
  dropdown: {
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'black',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#f0f0f0',
      padding: theme.spacing(1),
    },
    '& .MuiSelect-icon': {
      color: 'black',
    },
    '& .MuiInputLabel-root': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  uploadLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'none',
  },
  previewContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  previewImageContainer: {
    flex: '1 0 30%',
  },
  previewImage: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  previewVideo: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
