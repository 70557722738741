import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@material-ui/core/Avatar';
import { getCircularAvatar } from '../../../../utils/cloudinaryTransform';
import AuthorFrame from '../../../../assets/art/AvatarFrame.png';
import DefaultAvatar from '../../../../assets/hmmm.png';

const AuthorInfo = ({ open, onClose, authorInfo, isMobile }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="author-dialog-title"
      aria-describedby="author-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: 'transparent !important',
          border: 'none',
          boxShadow: 'none'
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.69)'
        }
      }}
    >
      <DialogContent
        style={{
          backgroundImage: `url(${AuthorFrame})`,
          width: isMobile ? '49.69vw' : '13.69vw',
          height: isMobile ? '35.69vh' : '20.69vh',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundColor: 'transparent',
          backgroundRepeat: 'no-repeat',
          textAlign: 'center',
        }}
      >
        <a
          href={`/u/${authorInfo.username}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
          }}
        >
          <Avatar
            alt={authorInfo.username}
            src={authorInfo.avatar ? getCircularAvatar(authorInfo.avatar) : DefaultAvatar}
            style={{
              width: isMobile ? '38.69vw' : '6.69vw',
              height: isMobile ? '20.69vh' : '12.69vh',
              margin: '25.69% auto',
              background: 'transparent',
            }}
          />
        </a>
      </DialogContent>
    </Dialog>
  );
};

export default AuthorInfo;
