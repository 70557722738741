// src/components/Pathways/FilterBar.js

import React from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    'html, body': {
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
  },
  centeredText: {
    textAlign: 'center',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1.5), // Reduced from theme.spacing(2)
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2.5), // Reduced from theme.spacing(3)
    color: '#fff',
    overflow: 'hidden',
  },
  searchField: {
    margin: theme.spacing(1.5, 0), // Reduced from theme.spacing(2, 0)
    width: '100%',
    maxWidth: 600,
    backgroundColor: '#004d40', // Teal
    borderRadius: '25px',
    '& .MuiInputBase-input': {
      color: '#fff',
    }
  },
  formControl: {
    margin: theme.spacing(1.5, 0), // Reduced from theme.spacing(2, 0)
    minWidth: 200,
    width: '100%',
    maxWidth: 600,
    backgroundColor: '#004d40', // Teal
    borderRadius: '25px',
  },
  select: {
    color: '#fff',
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(1.5), // Reduced from theme.spacing(2)
    marginTop: theme.spacing(2.5), // Reduced from theme.spacing(3)
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0.5), // Reduced from theme.spacing(1)
    backgroundColor: '#004d40', // Teal to match Pools buttons
    color: '#fff',
    textTransform: 'none',
    borderRadius: '25px',
    padding: theme.spacing(0.5, 2.5), // Reduced padding
    '&:hover': {
      backgroundColor: '#fff', // Tiffany Blue on hover
      color: '#004d40',
    },
  },
  activeButton: {
    backgroundColor: '#fff', // Active state background
    color: '#004d40', // Active state text color
    '&:hover': {
      backgroundColor: 'fff',
      color: '#004d40',
    },
  },
}));


const FilterBar = ({ filters, setFilters }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleStatusFilter = (status) => {
    setFilters((prev) => ({
      ...prev,
      status,
    }));
  };

  return (
    <Box className={classes.filterContainer}>
      <Box className={classes.buttonGroup}>
        <Button
          variant="contained"
          className={`${classes.button} ${filters.status === '' ? classes.activeButton : ''}`}
          onClick={() => handleStatusFilter('')}
        >
          All Pathways
        </Button>
        <Button
          variant="contained"
          className={`${classes.button} ${filters.status === 'Completed' ? classes.activeButton : ''}`}
          onClick={() => handleStatusFilter('Completed')}
        >
          Completed
        </Button>
        <Button
          variant="contained"
          className={`${classes.button} ${filters.status === 'In Progress' ? classes.activeButton : ''}`}
          onClick={() => handleStatusFilter('In Progress')}
        >
          In Progress
        </Button>
        <Button
          variant="contained"
          className={`${classes.button} ${filters.status === 'Needs Help' ? classes.activeButton : ''}`}
          onClick={() => handleStatusFilter('Needs Help')}
        >
          Needs Help
        </Button>
      </Box>
      <TextField
  placeholder="Search Pathways"
  name="search"
  value={filters.search}
  onChange={handleChange}
  fullWidth
  className={classes.searchField}
  InputProps={{
    disableUnderline: true, // To match the input style
    classes: {
      input: classes.centeredText, // Add a custom class for the input
    },
  }}
  InputLabelProps={{
    style: { color: '#fff' }, // Placeholder color
  }}
/>
<TextField
  placeholder="Created By"
  name="createdBy"
  value={filters.createdBy}
  onChange={handleChange}
  fullWidth
  className={classes.searchField}
  InputProps={{
    disableUnderline: true, // To match the input style
    classes: {
      input: classes.centeredText, // Add a custom class for the input
    },
  }}
  InputLabelProps={{
    style: { color: '#fff' }, // Placeholder color
  }}
/>
    </Box>
  );
};

export default FilterBar;
