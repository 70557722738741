import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, CardContent, Typography, CardMedia, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import PoolCardSelect from './PoolCardSelect';

const useStyles = makeStyles((theme) => ({
    card: {
        width: 340,
        margin: theme.spacing(2),
        borderRadius: 12,
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)',
        },
    },
    media: {
        height: 220,
        width: '100%',
        backgroundSize: 'cover',
    },
    content: {
        padding: theme.spacing(3),
        backgroundColor: '#f5f5f5',
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 700,
        marginBottom: theme.spacing(0.5),
        textAlign: 'center',
        color: '#3c3c3c',
    },
    description: {
        fontSize: '1rem',
        color: '#777',
        textAlign: 'center',
    },
    button: {
        textTransform: 'none',
    },
    cancelButton: {
        backgroundColor: '#f5f5f5',
        color: '#000',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    submitButton: {
        backgroundColor: '#3f51b5',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2c387e',
        },
    },
}));

const PoolCard = ({ pool }) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        const readifyUserKey = localStorage.getItem('readifyUserKey');
        if (readifyUserKey) {
            const { username } = JSON.parse(readifyUserKey);
            setUsername(username);
        }
    }, []);

    const handleCardClick = async () => {
        try {
            const response = await axios.get(`/api/pools/${pool._id}/check-password`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: { username }
            });

            if (response.data.hasPassword) {
                setPasswordDialogOpen(true);
            } else {
                setDialogOpen(true);
            }
        } catch (error) {
            console.error('Error checking pool password:', error);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setPasswordDialogOpen(false);
        setPassword('');
        setPasswordError('');
    };

    const handlePasswordSubmit = async () => {
        try {
            const response = await axios.post(`/api/pools/${pool._id}/verify-password`, {
                username,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.valid) {
                setPasswordDialogOpen(false);
                setDialogOpen(true);
            } else {
                setPasswordError('Invalid password');
            }
        } catch (error) {
            console.error('Error verifying pool password:', error);
            setPasswordError('Error verifying password');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handlePasswordSubmit();
        }
    };

    return (
        <>
            <Card className={classes.card} onClick={handleCardClick}>
                <CardActionArea>
                    {pool.imageData?.imageLink && (
                        <CardMedia
                            className={classes.media}
                            image={pool.imageData.imageLink}
                            title={pool.name}
                        />
                    )}
                    <CardContent className={classes.content}>
                        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                            {pool.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <PoolCardSelect
                name={pool.name}
                _id={pool._id}
                open={dialogOpen}
                onClose={handleCloseDialog}
            />
            <Dialog open={passwordDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>What's the Password?</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        fullWidth
                        error={!!passwordError}
                        helperText={passwordError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        className={`${classes.button} ${classes.cancelButton}`}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handlePasswordSubmit}
                        className={`${classes.button} ${classes.submitButton}`}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PoolCard;
