import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import generateBase64Encode from '../utils/genBase64Encode';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: '10px',
    },
    imageUpload: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2), 
        marginTop: theme.spacing(2),
        color: '#fff',
    },
    imagePreview: {
        maxWidth: '100%',
        maxHeight: '200px',
        marginTop: theme.spacing(2),
        borderRadius: '10px',
    },
    dialogTitle: {
        backgroundColor: '#004d40',
        color: '#fff',
    },
    dialogActions: {
        justifyContent: 'space-between',
        color: '#fff',
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#004d40',
        color: '#fff',
        textTransform: 'none',
    },
    cancelButton: {
        backgroundColor: '#FF1744',
        color: '#fff',
        textTransform: 'none',
    },
    dialogPaper: {
        backgroundColor: 'transparent',
        backdropFilter: 'blur(8px)',
        borderRadius: '15px',
        border: '1px solid #004d40',
        boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.5)',
        color: '#fff',
    },
    dialogBackdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.96)',
    },
}));

const PoolCreationForm = ({ fetchPools }) => {
    const classes = useStyles();
    const [poolName, setPoolName] = useState('');
    const [description, setDescription] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [password, setPassword] = useState('');
    const [imageError, setImageError] = useState(false);

    const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
    const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const base64Data = await generateBase64Encode(file);
            setImageData(base64Data);
            setImagePreview(URL.createObjectURL(file));
            setImageError(false);
        }
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const createPool = async (e) => {
        e.preventDefault();
        if (!imageData) {
            setImageError(true);
            return;
        }
        try {
            const response = await fetch('/api/add-pools', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: poolName,
                    description,
                    creator: loggedInUser,
                    imageData,
                    password,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Pool created:', data);
                setPoolName('');
                setDescription('');
                setImageData(null);
                setImagePreview(null);
                setPassword('');
                fetchPools();
                handleDialogClose();
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to create pool');
            }
        } catch (error) {
            console.error('Error creating pool:', error);
        }
    };

    return (
        <div>
            <Button 
                variant="contained" 
                className={classes.button} 
                onClick={handleDialogOpen}
                style={{
                    backgroundColor: openDialog ? "#fff" : "#004d40",
                    color: openDialog ? "#004d40" : "#fff",
                }}
            >
                Add Pool
            </Button>
            <Dialog 
                open={openDialog} 
                onClose={handleDialogClose} 
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackdrop,
                    },
                }}
            >
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                    Add Pool
                </DialogTitle>
                <form onSubmit={createPool} className={classes.form}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Pool Name"
                            type="text"
                            fullWidth
                            value={poolName}
                            onChange={(e) => setPoolName(e.target.value)}
                            required
                            InputProps={{
                                style: {
                                    color: '#fff', // White text color
                                    backgroundColor: '#004d40', // Background color for the input area
                                    borderRadius: '10px', // Rounded corners
                                    padding: '10px', // Padding inside the input
                                    zIndex: 1, // Ensure the input text is above the background
                                },
                            }}
                            InputLabelProps={{
                                style: { 
                                    color: '#fff', // White placeholder color
                                    zIndex: 2, // Ensure the placeholder text is above the background
                                },
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="password"
                            label="Password (Optional)"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                style: {
                                    color: '#fff', // White text color
                                    backgroundColor: '#004d40', // Background color for the input area
                                    borderRadius: '10px', // Rounded corners
                                    padding: '10px', // Padding inside the input
                                    zIndex: 1, // Ensure the input text is above the background
                                },
                            }}
                            InputLabelProps={{
                                style: { 
                                    color: '#fff', // White placeholder color
                                    zIndex: 2, // Ensure the placeholder text is above the background
                                },
                            }}
                        />
                        <div className={classes.imageUpload}>
                            <input
                                accept="image/*"
                                id="image-upload"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                            <label htmlFor="image-upload">
                                <IconButton color="primary" component="span">
                                    <AddPhotoAlternateIcon style={{ color: '#fff' }} />
                                </IconButton>
                            </label>
                            <Typography variant="body1" style={{ color: '#fff' }}>Upload Image</Typography>
                        </div>
                        {imagePreview && (
                            <img
                                src={imagePreview}
                                alt="Uploaded Preview"
                                className={classes.imagePreview}
                            />
                        )}
                        {imageError && (
                            <Typography color="error" variant="body2">
                                Image is required to create a pool.
                            </Typography>
                        )}
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            InputProps={{
                                style: {
                                    color: '#fff', // White text color
                                    backgroundColor: '#004d40', // Background color for the input area
                                    borderRadius: '10px', // Rounded corners
                                    padding: '10px', // Padding inside the input
                                    zIndex: 1, // Ensure the input text is above the background
                                },
                            }}
                            InputLabelProps={{
                                style: { 
                                    color: '#fff', // White placeholder color
                                    zIndex: 2, // Ensure the placeholder text is above the background
                                },
                            }}
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button 
                            onClick={handleDialogClose} 
                            className={classes.cancelButton}
                            style={{
                                backgroundColor: openDialog ? "#FF1744" : "#fff",
                                color: openDialog ? "#fff" : "#FF1744",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type="submit" 
                            className={classes.button}
                            style={{
                                backgroundColor: openDialog ? "#004d40" : "#fff",
                                color: openDialog ? "#fff" : "#004d40",
                            }}
                        >
                            Create
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default PoolCreationForm;
