import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, useTheme } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import axios from 'axios';

const PoolStats = ({ poolId }) => {
  const [memberCount, setMemberCount] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [entryTagsData, setEntryTagsData] = useState([]);
  const [entryDomainsData, setEntryDomainsData] = useState([]);
  const theme = useTheme();

  const colors = {
    darkSlateGray: '#2f4f4f',
    darkOliveGreen: '#556b2f',
    white: '#ffffff',
    darkTeal: '#004d40',
    tiffanyBlue: '#0abab5',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/pools/${poolId}/stats`);
        const { members, entryTags, entryDomains, totalEntries } = response.data;

        setMemberCount(members.length);
        setTotalEntries(totalEntries);

        const entryTagsCount = entryTags.reduce((acc, tag) => {
          acc[tag] = (acc[tag] || 0) + 1;
          return acc;
        }, {});

        const entryDomainsCount = entryDomains.reduce((acc, domain) => {
          acc[domain] = (acc[domain] || 0) + 1;
          return acc;
        }, {});

        setEntryTagsData(
          Object.keys(entryTagsCount).map((key) => ({
            id: key,
            label: key,
            value: entryTagsCount[key],
          }))
        );

        setEntryDomainsData(
          Object.keys(entryDomainsCount).map((key) => ({
            id: key,
            label: key,
            value: entryDomainsCount[key],
          }))
        );
      } catch (error) {
        console.error('Error fetching pool stats:', error);
      }
    };

    fetchData();
  }, [poolId]);

  const pieTheme = {
    background: colors.darkSlateGray,
    textColor: colors.white,
    fontSize: 14,
    tooltip: {
      container: {
        background: colors.darkOliveGreen,
        color: colors.white,
      },
    },
    legends: {
      text: {
        fill: colors.white,
      },
    },
  };

  const commonProperties = {
    margin: { top: 40, right: 80, bottom: 80, left: 80 },
    innerRadius: 0.5,
    padAngle: 0.7,
    cornerRadius: 3,
    colors: { scheme: 'nivo' },
    borderWidth: 1,
    borderColor: {
      from: 'color',
      modifiers: [['darker', 0.2]],
    },
    radialLabelsTextColor: colors.white,
    radialLabelsLinkColor: { from: 'color' },
    slicesLabelsTextColor: colors.tiffanyBlue,
  };

  return (
    <Card
      sx={{
        backgroundColor: colors.darkSlateGray,
        color: colors.white,
        borderRadius: 2,
        padding: 2,
      }}
    >
      <CardContent>
        <Box textAlign="center">
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            Members: <span style={{ color: colors.tiffanyBlue }}>{memberCount}</span>
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: 4 }}>
            Entries: <span style={{ color: colors.tiffanyBlue }}>{totalEntries}</span>
          </Typography>
          {entryTagsData.length > 0 ? (
            <>
              <Typography variant="h5" sx={{ marginBottom: 2 }}>
                Fields
              </Typography>
              <Box height={400}>
                <ResponsivePie
                  data={entryTagsData}
                  {...commonProperties}
                  theme={pieTheme}
                  tooltip={({ datum }) => (
                    <div
                      style={{
                        padding: 12,
                        background: colors.darkOliveGreen,
                        color: colors.white,
                      }}
                    >
                      <strong>{datum.label}</strong>: {datum.value} ({((datum.value / totalEntries) * 100).toFixed(2)}%)
                    </div>
                  )}
                />
              </Box>
            </>
          ) : (
            <Typography>Loading Fields Data...</Typography>
          )}
          {entryDomainsData.length > 0 ? (
            <>
              <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                Categories
              </Typography>
              <Box height={400}>
                <ResponsivePie
                  data={entryDomainsData}
                  {...commonProperties}
                  theme={pieTheme}
                  tooltip={({ datum }) => (
                    <div
                      style={{
                        padding: 12,
                        background: colors.darkOliveGreen,
                        color: colors.white,
                      }}
                    >
                      <strong>{datum.label}</strong>: {datum.value} ({((datum.value / totalEntries) * 100).toFixed(2)}%)
                    </div>
                  )}
                />
              </Box>
            </>
          ) : (
            <Typography>Loading Categories Data...</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PoolStats;
