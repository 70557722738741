import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { getCircularAvatar } from '../utils/cloudinaryTransform';
import {
  Avatar, TextareaAutosize,
} from '@material-ui/core';
import Link from '@mui/material/Link';
import SentimentManager from './EcologySentiment';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import niceIcon from '../assets/humanity/NiceButton.png'; // Replace with actual path
import interestingIcon from '../assets/humanity/InterestingButton.png'; // Replace with actual path
import notSureIcon from '../assets/humanity/NotSureButton.png'; // Replace with actual path
import whistleIcon from '../assets/humanity/Whistle.png';
import missingIMG from '../assets/yep.png'; // Replace with actual path
import Author from '../assets/humanity/author.png';
import Keep from '../assets/humanity/keep.png';
import Sentiments from '../assets/humanity/sentiment.png';
import Feeling from '../assets/humanity/feeling.png';
import Sources from '../assets/humanity/sources.png';
import AuthorFrame from '../assets/humanity/AvatarFrame.png';
import EntryFrame from '../assets/humanity/SentimentFrame.png';
import DefaultAvatar from '../assets/hmmm.png';
const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

// Define the makeStyles function
const useStyles = makeStyles({
  dialogBackground: {
    background: 'none !important', // This will make the dialog background transparent
    boxShadow: 'none !important',
    // You can add more styles to override defaults here
  },
  
  media: {
    display: 'block', // default display
    maxWidth: '69%', // default max width
    maxHeight: '69vh', // default max height
    objectFit: 'contain', // default object fit
    width: 'auto', // default width
    height: 'auto', // default height
    // ... any other default styles ...
  },
  glowingBorder: {
    animation: '$glow 3.69s ease-in-out infinite',
  },
  
  '@keyframes glow': {
    '0%, 100%': {
      boxShadow: '0 0 8px 2px #57c0bf',
    },
    '50%': {
      boxShadow: '0 0 12px 3px #57c0bf',
    },
  },
  mobileMedia: {
    display: 'block', // mobile display
    maxWidth: '100%', // mobile max width
    maxHeight: '69vh', // mobile max height to ensure it doesn't take up too much vertical space
    objectFit: 'contain', // keeps the entire image visible
    width: 'auto', // adjust width to 'auto' for maintaining aspect ratio
    height: 'auto', // adjust height to 'auto' for maintaining aspect ratio
    // ... any other mobile-specific styles ...
  },
  // ... 
  mobileImage: {
    width: '30%', // Full width on mobile
    // ... other styles as needed ...
  },
  mobileButtonContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr', // Two columns
    gridColumnGap: '3.69px', // Space between columns
    gridRowGap: '3.69px', // Space between rows
    // Adjust padding and other styles as necessary
  },
  mobileDialog: {
    width: 'fit-content !important', // Mobile devices take up full width
    margin: 0,
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: "transparent !important",
  },
  PoolDialog: {
    width: 'fit-content !important', // Maintain the width based on content
    margin: 'auto',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: 'transparent !important',
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },  
  mobileDialogTwo: {
    width: 'fit-content !important', // Mobile devices take up full width
    margin: 0,
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: "#fefadc",
  },
  mobileBigDialog: {
    width: '100% !important', // Mobile devices take up full width
    margin: 0,
    marginTop: '-4.2vh',
    maxHeight: '80.69vh',
    overflowY: 'auto',
    backgroundColor: "transparent !important",
    border: "none",
    boxShadow: "none",
  },
  mobileAvatarDialog: {
    width: '62.69% !important', // Mobile devices take up full width
    margin: 0,
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: "transparent !important",
  },
  PoolAvatarDialog: {
    width: '62.69% !important',
    margin: 'auto', // Center horizontally
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: 'transparent !important',
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'fixed', // Ensures it stays centered
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  mobileButton: {
    width: '100%', // Full width buttons on mobile
    '& img': {
      width: '33px', // Smaller icons on mobile
      height: '33px',
    },
  },
  mobilePopover: {
    // Adjust popover styles for mobile
    position: 'fixed',
    width: '90%', // Most of the screen width
    left: '5%', // Centered horizontally
    top: '10%', // Slightly down from the top
    margin: 0,
    padding: '0px'
  },
  mobileSentimentList: {
    width: 'fit-content', // Full width on mobile
    maxHeight: '50vh', // Half of the viewport height
    overflowY: 'scroll', // Add scroll on Y axis if content is too tall
    border: 'none',
    boxShadow: 'none !important',
  },
  customPopover: {
  position: 'absolute',
  zIndex: 1470, // Ensure it's above other elements
  backgroundColor: '#fefadc',
  border: '1px solid black',
  padding: '0px',
  // You can add more styling here
},
customBackdrop: {
  backgroundColor: 'rgba(0, 0, 0, .94)', // Adjust the alpha value (0.5) for transparency
},
sentimentList: {
  position: 'fixed',
  top: '27.5%',
  right: '1.69%',
  padding: '1px',
  maxHeight: '69vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  wordWrap: 'break-word',
  width: '17.69%',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Optional: adds shadow for depth
  borderRadius: '4px', // Optional: rounds the corners
  // Additional styling as needed
},
fullScreen: {
  position: 'fixed',
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  zIndex: 1469,
  backgroundColor: 'black',
},
feedbackButtonIcon: {
  marginRight: '8px',
  height: '3.69%',
  width: '3.69%', // Adjust as needed
},
sentimentItem: {
  backgroundColor: '#fefadc',
  marginBottom: '10px',
  padding: '2px',
  borderBottom: '1px solid #eee',
  borderRadius: '5px', // Optional: for rounded corners // Style for separation of items
},
  dialog: {
    backgroundColor: 'transparent !important',
    border: 'none',
    boxShadow: 'none !important',
    width: 'fit-content !important', // Set the width to 80% and use !important to ensure override
    maxHeight: '90vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    maxWidth: '58.69% !important', // Use !important to ensure override
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '0.42rem',
    marginLeft: '0.42rem', // Adjust as needed
  },
  rightButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.42rem', // Adjust as needed
  },
  imageContainer: {
    height: '100%',
    width: '69%',
    justifyContent: 'center',
  },
  secondaryDialog: {
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: 'none !important', // Optional: Remove shadow for a less modal-like appearance
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '1px',
  },
  button: {
    marginBottom: '1vh',
  },
});

const KeepEntryDialog = ({ open, onClose, onSaveForSelf, onSaveForPool, isAlreadyInPool, userPools }) => {
  const [poolName, setPoolName] = useState('');
  const [selectedPool, setSelectedPool] = useState(null);

  const handleSaveForPool = () => {
    if (isAlreadyInPool) {
      alert("Already in Pool");
    } else if (selectedPool) {
      onSaveForPool(selectedPool.name);
    } else {
      alert("Please select a pool");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{
      style: {
        backgroundColor: '#fefadc',
      },
    }}>
      <DialogContent>
        <Typography variant="h6" style={{ margin: '6.9px 37.69% 10px' }}>Pools</Typography>
        {userPools.length > 0 ? (
          userPools.map((pool) => (
            <Box
              key={pool._id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '6.9px',
                marginBottom: '6.9px',
                border: `2px solid ${selectedPool && selectedPool._id === pool._id ? '#57c0bf' : 'transparent'}`,
                borderRadius: '6.9px',
                cursor: 'pointer',
                '&:hover': {
                  borderColor: '#57c0bf',
                },
              }}
              onClick={() => setSelectedPool(pool)}
            >
              <CardMedia
  component="img"
  sx={{ width: 50, height: 50, borderRadius: '50%' }}
  image={pool.imageData.imageLink || missingIMG}
  alt="Missing image"
/>
              <Typography variant="subtitle1" sx={{ marginLeft: '10px' }}>
                {pool.name}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography>No pools available.</Typography>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'flex', flexDirection: 'column' }}>
  <button style={{ backgroundColor: '#57c0bf' }} onClick={onSaveForSelf}>Keep for Self</button>
</DialogActions>
    </Dialog>
  );
};


const SelectPoolEntry = ({ isOpen, onClose, post }) => {
  const classes = useStyles();
  const [showAuthorInfo, setShowAuthorInfo] = useState(false);
  const [authorInfo, setAuthorInfo] = useState({ username: post.username, avatar: null });
  const [sourceInfo, setSourceInfo] = useState({}); // Assuming sourceInfo structure
  const [showSourceInfo, setShowSourceInfo] = useState(false);
  const [showFeedbackPopover, setShowFeedbackPopover] = useState(false);
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackCountsVisible, setFeedbackCountsVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null); // Initialize selectedFeedback
  const [showSentimentManager, setShowSentimentManager] = useState(false);
  const [sentiments, setSentiments] = useState([]);
  const [newSentiment, setNewSentiment] = useState('');
  const svgInputRef = useRef(null); // Ref for the SVG input element
  const [isSentimentDialogOpen, setIsSentimentDialogOpen] = useState(false);
  const [sentimentAlreadySubmitted, setSentimentAlreadySubmitted] = useState(false);
  const [isKeepEntryDialogOpen, setIsKeepEntryDialogOpen] = useState(false);
  const [userPools, setUserPools] = useState([]);
  const [isAlreadyInPool, setIsAlreadyInPool] = useState(false);
  const [showSentimentJournal, setShowSentimentJournal] = useState(false);
  const isMobile = useMediaQuery('(max-width:1328px)');
  const [isFullScreen, setIsFullScreen] = useState(false);


  function handleImageClick(imageLink) {
    const backdrop = document.createElement("div");
    backdrop.style.position = 'fixed';
    backdrop.style.top = '0';
    backdrop.style.left = '0';
    backdrop.style.width = '100vw';
    backdrop.style.height = '100vh';
    backdrop.style.zIndex = '10000';
    backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.69)'; // semi-transparent background
    backdrop.style.display = 'flex';
    backdrop.style.alignItems = 'center';
    backdrop.style.justifyContent = 'center';
  
    const img = document.createElement("img");
    img.src = imageLink;
    img.style.top = '5vh';
    img.style.left = '5vw';
    img.style.height = 'fit-content';
    img.style.width = '90vw';
    img.style.maxHeight = '90vh';
    img.style.objectFit = 'fill';
    img.style.border = 'none';
    img.style.outline = 'none';
    img.style.background = 'none';
    img.style.boxShadow = 'none';
    img.className = classes.glowingBorder;
    backdrop.appendChild(img);
    document.body.appendChild(backdrop);
  
    backdrop.addEventListener('click', (e) => {
      if (e.target === backdrop) { // Close only if the backdrop (not the image) is clicked
        backdrop.remove();
      }
    });
  }

  const openSentimentDialog = () => {
    setIsSentimentDialogOpen(true); // Assuming you have this state defined to control the dialog visibility
  };
  
  const handleFeedbackButtonContent = (feedbackType) => {
    switch (feedbackType) {
      case 'nice':
        return niceIcon;
      case 'interesting':
        return interestingIcon;
      case 'notSure':
        return notSureIcon;
      case 'whistle':
        return whistleIcon;
      default:
        // Handle the default case where there is no icon
        return null;
    }
  };

  const openKeepEntryDialog = async () => {
    setIsKeepEntryDialogOpen(true);
    try {
      // Assuming you have a way to get the current logged-in user's ID or username
      const userId = loggedInUser; // You'll need to implement getCurrentUserId or similar to get the user's ID
      const response = await fetch(`/api/get-pools?userId=${userId}`);
      if (response.ok) {
        const pools = await response.json();
        setUserPools(pools);
      } else {
        console.error('Failed to fetch pools');
      }
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };
  
  const FeedbackButton = ({ type, count, isSelected, onClick }) => {
    const iconSrc = handleFeedbackButtonContent(type);
    return (
      <div style={{ display: 'inline-block', margin: '3.69px', right: '0', textAlign: 'center', position: 'relative' }}>
        {count > 0 && (
  <Typography
    variant="body1"
    style={{
      position: 'absolute',
      top: '62.69px',
      left: '50%',
      zIndex: 1469,
      transform: 'translateX(-50%)',
      background: 'transparent',
      fontSize: '90%',
      fontWeight: 'bold',
      color: '#57c0bf',
      textAlign: 'center',
    }}
  >
    {count}
  </Typography>
)}
        <Button
          variant="outlined"
          disabled={isSubmittingFeedback}
          onClick={onClick}
          style={{
            padding: '0',
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none !important',
            borderRadius: '50%',
            overflow: 'visible', // Set the width of the circle
            height: '52px',
            width: '10px' // Set the height of the circle, should be equal to width for a perfect circle
          }}
        >
          {iconSrc && (
            <img
              src={iconSrc}
              alt={type}
              style={{
                width: '69px',
                height: '69px',
              }}
            />
          )}
          {!iconSrc && type}
        </Button>
      </div>
    );
  };
  
  const handleKeepForSelf = async () => {
    await handleKeepEntry();
    setIsKeepEntryDialogOpen(false);
  };

  const handleKeepForPool = async (poolName) => {
    try {
      const response = await fetch('/api/add-to-pool', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          poolName: poolName,
          entryId: post._id, 
          username: loggedInUser,
          poolName: poolName,
          entryTag: "Politics"
      ,
          entryDomain: post.domain
        }),
      });

      if (response.ok) {
        console.log('Entry added to pool successfully');
      } else {
        console.error('Failed to add entry to pool');
      }
    } catch (error) {
      console.error('Error adding entry to pool:', error);
    } finally {
      setIsKeepEntryDialogOpen(false);
    }
  };

  const checkIfSentimentSubmitted = async () => {
    try {
      const response = await fetch(`/api/sentiments/check/${post._id}/${loggedInUser}`);
      if (response.ok) {
        const { submitted } = await response.json();
        setSentimentAlreadySubmitted(submitted);
      } else {
        console.error('Failed to check sentiment status');
      }
    } catch (error) {
      console.error('Error checking sentiment status:', error);
    }
  };

  const handleSvgInputClick = async () => {
    await checkIfSentimentSubmitted();
    if (!sentimentAlreadySubmitted) {
      openSentimentDialog();
    } else {
      alert("You have already submitted a sentiment for this post.");
    }
  };

  const SentimentDialog = () => {
    const [tempSentiment, setTempSentiment] = useState(newSentiment);
  
    const handleClose = () => {
      setIsSentimentDialogOpen(false);
    };
  
    const handleSave = async () => {
      await sendNewSentimentToServer(tempSentiment);
      onSentimentSaved(); // Call the callback prop when sentiment is saved
      handleClose();
    };
    
    // Add the sendNewSentimentToServer function
    const sendNewSentimentToServer = async (sentimentText) => {
  try {
    const response = await fetch(`/api/sentiments/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ postId: post._id, sentiment: sentimentText, username: loggedInUser, tag: "Politics"
       }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to send sentiment to server');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};
  
    return (
      <Dialog open={isSentimentDialogOpen} onClose={handleClose} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none !important', overflow: 'hidden' } }}>
    <DialogContent style={{ backgroundColor: 'transparent' }}>
        <TextareaAutosize
            autoFocus
            margin="dense"
            id="sentiment"
            label="Sentiment"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={4}
            value={tempSentiment}
            onChange={(e) => setTempSentiment(e.target.value)}
            style={{ maxWidth: '90%', maxHeight: '80%' }}
        />
    </DialogContent>
    <DialogActions style={{ backgroundColor: 'transparent' }}>
        <button style={{ marginRight: '31.69%', backgroundColor: '#57c0bf', border: 'none', boxShadow: 'none' }} onClick={handleSave}>Send</button>
    </DialogActions>
</Dialog>
    );
  };
  
  const [feedbackData, setFeedbackData] = useState({
    nice: 0,
    interesting: 0,
    notSure: 0,
    whistle: 0,
  }); 

  const fetchSentiments = async () => {
    // Assume you have a function to fetch sentiments from the server
    try {
      const response = await fetch(`/api/sentiments/${post._id}`);
      if (response.ok) {
        const data = await response.json();
        setSentiments(data.sentiments); // Update your state with the fetched sentiments
      } else {
        // Handle server errors or no data scenarios
        console.error("Failed to fetch sentiments");
      }
    } catch (error) {
      console.error("Error fetching sentiments:", error);
    }
  };
  
  // Call this function initially and after adding a new sentiment
  useEffect(() => {
    fetchSentiments();
  }, []); 

  const onSentimentSaved = () => {
    fetchSentiments(); // Assuming this function fetches sentiments and updates state
  };
  
  const handleFeedback = async (feedbackType) => {
    if (selectedFeedback === feedbackType) {
      setSelectedFeedback(null);
      return;
    }
    setIsSubmittingFeedback(true);
  
    try {
      const response = await fetch(`/api/feeling/${post._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          feedbackType,
          username: loggedInUser
        }),
      });
  
      if (response.ok) {
        const updatedFeedback = await response.json();
        setFeedbackData(updatedFeedback.feedback);
        setSelectedFeedback(feedbackType);
        setFeedbackCountsVisible(true); // Enable feedback counts visibility
      } else {
        console.error('Failed to send feedback');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  

  const handleKeepEntry = async () => {
    try {
      const response = await fetch('/api/keep-entry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entryId: post._id, 
          username: loggedInUser, 
          tag: "Politics"
      ,
          domain: post.domain, // Sending "Ecology" as a part of the tags array
        }),
      });
  
      if (response.ok) {
        console.log('Entry saved successfully');
      } else {
        console.error('Failed to save entry');
      }
    } catch (error) {
      console.error('Error saving entry:', error);
    }
  };

  const handleToggleFeedbackPopover = async () => {
    try {
      const response = await fetch(`/api/feeling/${post._id}/${loggedInUser}`);
      if (response.ok) {
        const feedbackCounts = await response.json();
  
        setFeedbackCountsVisible(true);
        setFeedbackData(feedbackCounts.feedbackCounts);
        setSelectedFeedback(feedbackCounts.feedbackType);
      } else {
        console.error('Failed to fetch feedback status');
      }
    } catch (error) {
      console.error('Error fetching feedback status:', error);
    }
  
    setShowFeedbackPopover(!showFeedbackPopover);
  };

  useEffect(() => {
    const fetchAuthorAvatar = async () => {
      try {
        const response = await fetch(`/api/authors/avatars/${post.username}`);
        if (response.ok) {
          const avatarData = await response.json();
          setAuthorInfo({ ...authorInfo, avatar: avatarData.avatar || null });
        } else {
          console.error('Error fetching author avatar:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching author avatar:', error);
      }
    };

    fetchAuthorAvatar();
  }, [post.username]);

  const handleToggleAuthorInfo = () => {
    setShowAuthorInfo(!showAuthorInfo);
  };
  const handleToggleSourceInfo = () => setShowSourceInfo(!showSourceInfo);
  const handleToggleSentimentPopup = async () => {
    setShowSentimentManager(!showSentimentManager);
    if (!showSentimentManager) {
      await checkIfSentimentSubmitted();
      await fetchSentiments();
      
    }
  };
  
  const videoExtensions = ['mp4', 'mov', 'avi', 'flv', 'wmv'];

// Utility function to check if a URL ends with a video extension
const isVideo = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  return videoExtensions.includes(extension);
};
  

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        backgroundColor="#fefadc"
        classes={{ 
          paper: isMobile ? classes.mobileBigDialog : classes.dialog, // Use mobile styles if on mobile
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.69)' // Adjust the alpha value for darkness (0.5 is 50% opacity)
          }
        }}
      >
        
        <Box style={{ overflowY: 'auto', maxHeight: '90vh' }}>
        <DialogContent className={classes.content}>
        {!isMobile && (
  <Box className={classes.leftButtonContainer}>
    <Button 
      variant="contained" 
      onClick={handleToggleAuthorInfo} 
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Author} alt="Person" style={{ width: 120.69, height: 96.69 }} />}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
    <Button 
      variant="contained" 
      onClick={handleToggleSourceInfo} 
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Sources} alt="Person" style={{ width: 120.69, height: 96.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
  </Box>
   )}

{
  post.mediaData?.mediaLink && (
    isVideo(post.mediaData.mediaLink) ? (
      <div 
        className={`${isMobile ? classes.mobileMediaContainer : classes.mediaContainer} ${classes.glowingBorder}`}
      >
        <video
  autoPlay
  controls
  muted 
  loop
  playsInline // Prevent fullscreen on autoplay
  controlsList="nodownload" // Remove download control
  style={{ maxWidth: '100%', maxHeight: '30%' }}
  onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu on the video
  onTouchStart={(e) => e.preventDefault()} // Suppress the default long-press behavior
  onTouchEnd={(e) => e.stopPropagation()} // Stop the event from bubbling up
>
          <source src={post.mediaData.mediaLink} type="video/mp4" />
        </video>
      </div>
    ) : (
      <CardMedia
        style={{ border: 'none' }}
        component="img"
        src={post.mediaData?.mediaLink}
        alt=""
        onClick={() => handleImageClick(post.mediaData?.mediaLink)}
        className={`${isMobile ? classes.mobileMedia : classes.media} ${classes.glowingBorder}`}
        onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu on the image
        onTouchStart={(e) => e.preventDefault()} // Suppress the default long-press behavior
        onTouchEnd={(e) => e.stopPropagation()} // Stop the event from bubbling up      
      />
    )
  )
}
{!isMobile && (
  <Box className={classes.rightButtonContainer}>
  <Button 
      variant="contained" 
      onClick={openKeepEntryDialog} 
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Keep} alt="Person" style={{ width: 120.69, height: 96.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
    <KeepEntryDialog
        open={isKeepEntryDialogOpen}
        onClose={() => setIsKeepEntryDialogOpen(false)}
        onSaveForSelf={handleKeepForSelf}
        onSaveForPool={handleKeepForPool}
        isAlreadyInPool={isAlreadyInPool}
        userPools={userPools}
      />
    <Button 
      variant="contained" 
      onClick={handleToggleFeedbackPopover} 
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Feeling} alt="Person" style={{ width: 120.69, height: 96.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
    <Button
      variant="contained"
      onClick={handleToggleSentimentPopup}
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Sentiments} alt="Person" style={{ width: 120.69, height: 96.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
  </Box>
)}

  

  
</DialogContent>

{isMobile && (
            <Box className={classes.mobileButtonContainer}>
              {/* Render each button with smaller size and full width for mobile */}
              {/* Button to toggle author info */}
              <Button 
                variant="contained" 
                onClick={handleToggleAuthorInfo}
                className={isMobile ? classes.mobileButton : classes.button}
                startIcon={<img src={Author} alt="Person" style={{ width: 96.69, height: 77.69 }}/>}
                style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                {/* ... button content ... */}
              </Button>
              <Button 
      variant="contained" 
      onClick={openKeepEntryDialog} 
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Keep} alt="Person" style={{ width: 96.69, height: 77.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
    <KeepEntryDialog
        open={isKeepEntryDialogOpen}
        onClose={() => setIsKeepEntryDialogOpen(false)}
        onSaveForSelf={handleKeepForSelf}
        onSaveForPool={handleKeepForPool}
        isAlreadyInPool={isAlreadyInPool}
        userPools={userPools}
      />
    <Button 
      variant="contained" 
      onClick={handleToggleFeedbackPopover} 
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Feeling} alt="Person" style={{ width: 96.69, height: 77.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
    <Button
      variant="contained"
      onClick={handleToggleSentimentPopup}
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Sentiments} alt="Person" style={{ width: 96.69, height: 77.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
    <Button 
      variant="contained" 
      onClick={handleToggleSourceInfo} 
      className={isMobile ? classes.mobileButton : classes.button}
      startIcon={<img src={Sources} alt="Person" style={{ width: 96.69, height: 77.69 }}/>}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      
    </Button>
              {/* ... other buttons ... */}
            </Box>
          )}
          
          <Typography
  variant="body1"
  style={{
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    margin: '0.169rem',
    width: 'fit-content',
    color: '#f4e8c1', // Light cream color for text, luxurious and soft
    fontFamily: "'Playfair Display', serif", // Changed to a luxurious, elegant font
    fontSize: '1.2rem',
    backgroundColor: '#29506D', // Deep seafoam blue, rich and luxurious
    padding: '0.5rem 1rem',
    borderRadius: '10px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
    border: '2px solid #d3a97c' // Sandy orange for the border, elegant and consistent
}}
>
  {post.description}
</Typography>
  </Box>
      </Dialog>
  
      {showAuthorInfo && (
  <>
    {isMobile ? (
      <Dialog
      open={showAuthorInfo}
      onClose={() => setShowAuthorInfo(false)}
        aria-labelledby="author-dialog-title"
        aria-describedby="author-dialog-description"
        classes={{ paper: classes.mobileAvatarDialog }}
        PaperProps={{
          style: { backgroundColor: "transparent !important", border: "none", boxShadow: "none" }
          }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.69)', // Adjust the alpha value for darkness
          },
        }}
      >
        <DialogContent
          style={{
            backgroundImage: `url(${AuthorFrame})`,
            width: '49.69vw', 
            height: '35.69vh', 
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundColor: 'transparent',
            backgroundRepeat: 'no-repeat',
            textAlign: 'center',
          }}
        >
          <a
            href={`/u/${authorInfo.username}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Avatar
              alt={authorInfo.username}
              src={authorInfo.avatar ? getCircularAvatar(authorInfo.avatar) : DefaultAvatar}
              style={{
                width: '38.69vw',
                height: '20.69vh',
                margin: '25.69% auto',
                background: 'transparent',
              }}
            />
          </a>
        </DialogContent>
      </Dialog>
    ) : (
      <Dialog
      open={showAuthorInfo}
      onClose={() => setShowAuthorInfo(false)}
        aria-labelledby="author-dialog-title"
        aria-describedby="author-dialog-description"
        classes={{ paper: classes.PoolAvatarDialog }}
        PaperProps={{
          style: { backgroundColor: "transparent !important", border: "none", boxShadow: "none" }
          }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.69)', // Adjust the alpha value for darkness
          },
        }}
      >
        <DialogContent
          style={{
            backgroundImage: `url(${AuthorFrame})`,
            width: '49.69vw', 
            height: '35.69vh', 
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundColor: 'transparent',
            backgroundRepeat: 'no-repeat',
            textAlign: 'center',
          }}
        >
          <a
            href={`/u/${authorInfo.username}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Avatar
              alt={authorInfo.username}
              src={authorInfo.avatar ? getCircularAvatar(authorInfo.avatar) : DefaultAvatar}
              style={{
                width: '12.69vw',
                height: '20.69vh',
                margin: '7.69% auto',
                background: 'transparent',
              }}
            />
          </a>
        </DialogContent>
      </Dialog>
    )}
  </>
)}
      {showSourceInfo && (
  isMobile ? (
    // Mobile: Render as a Dialog
    <Dialog
      open={showSourceInfo}
      onClose={() => setShowSourceInfo(false)} // Assuming you have a method to toggle showSourceInfo
      aria-labelledby="source-info-title"
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.mobileDialogTwo }}  // Adjust size as needed
    >
      <DialogContent>
      <Typography variant="h6" style={{ marginBottom: '8px', textAlign: 'center' }}>{sourceInfo.title}</Typography>
      {post.sourceType && <p style={{ marginBottom: '8px' }}>{post.sourceType}</p>}
      {post.companyInfo && <p style={{ marginBottom: '8px' }}>{post.companyInfo}</p>}
      {post.newspaperInfo && <p style={{ marginBottom: '8px' }}>{post.newspaperInfo}</p>}
      {post.televisionInfo && <p style={{ marginBottom: '8px' }}>{post.televisionInfo}</p>}
      {post.bookTitle && <p style={{ marginBottom: '8px' }}>{post.bookTitle}</p>}
      {post.movieInfo && <p style={{ marginBottom: '8px' }}>{post.movieInfo}</p>}
      {post.institutionInfo && <p style={{ marginBottom: '8px' }}>{post.institutionInfo}</p>}
      {post.bookInfo && <p style={{ marginBottom: '8px' }}>{post.bookInfo}</p>}
      {post.links.map((link, index) => {
      try {
        const hostname = new URL(link).hostname;
        return (
          <li key={index} style={{ marginBottom: '4px' }}>
            <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: '#57c0bf', textDecoration: 'none' }}>
              {hostname}
            </a>
          </li>
        );
      } catch (error) {
        // Handle the error or return null to ignore this link
        console.error("Invalid URL:", link);
        return null;
      }
    })}
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      open={showSourceInfo}
      onClose={() => setShowSourceInfo(false)} // Assuming you have a method to toggle showSourceInfo
      aria-labelledby="source-info-title"
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.mobileDialogTwo }}  // Adjust size as needed
    >
      <DialogContent>
      <Typography variant="h6" style={{ marginBottom: '8px', textAlign: 'center' }}>{sourceInfo.title}</Typography>
      {post.sourceType && <p style={{ marginBottom: '8px' }}>{post.sourceType}</p>}
      {post.companyInfo && <p style={{ marginBottom: '8px' }}>{post.companyInfo}</p>}
      {post.newspaperInfo && <p style={{ marginBottom: '8px' }}>{post.newspaperInfo}</p>}
      {post.televisionInfo && <p style={{ marginBottom: '8px' }}>{post.televisionInfo}</p>}
      {post.bookTitle && <p style={{ marginBottom: '8px' }}>{post.bookTitle}</p>}
      {post.movieInfo && <p style={{ marginBottom: '8px' }}>{post.movieInfo}</p>}
      {post.institutionInfo && <p style={{ marginBottom: '8px' }}>{post.institutionInfo}</p>}
      {post.bookInfo && <p style={{ marginBottom: '8px' }}>{post.bookInfo}</p>}
      {post.links.map((link, index) => {
      try {
        const hostname = new URL(link).hostname;
        return (
          <li key={index} style={{ marginBottom: '4px' }}>
            <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: '#57c0bf', textDecoration: 'none' }}>
              {hostname}
            </a>
          </li>
        );
      } catch (error) {
        // Handle the error or return null to ignore this link
        console.error("Invalid URL:", link);
        return null;
      }
    })}
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  )
)}

{showFeedbackPopover && (
  <>
    {isMobile ? (
      <Dialog
      open={showFeedbackPopover}
      onClose={() => setShowFeedbackPopover(false)} 
      aria-labelledby="feedback-dialog-title"
      aria-describedby="feedback-dialog-description"
      classes={{ paper: classes.mobileDialog }}
      style={{ width: '400px' }} // Add this to your Dialog component directly
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.9369)' // Adjust the alpha value for darkness
        }
      }}
      PaperProps={{
        style: {
          border: 'none', 
          boxShadow: 'none',// Remove border
          height: '130px',// Increase height, adjust as needed
        }
      }}
    >
      <DialogContent>
        <div>
          {['nice', 'interesting', 'notSure', 'whistle'].map((type) => (
            <FeedbackButton
              key={type}
              type={type}
              count={feedbackData[type]}
              isSelected={selectedFeedback === type}
              onClick={() => handleFeedback(type)}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
    ) : (
      <Dialog
      open={showFeedbackPopover}
      onClose={() => setShowFeedbackPopover(false)} 
      aria-labelledby="feedback-dialog-title"
      aria-describedby="feedback-dialog-description"
      classes={{ paper: classes.PoolDialog }}
      style={{ width: '400px' }} // Add this to your Dialog component directly
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.9369)' // Adjust the alpha value for darkness
        }
      }}
      PaperProps={{
        style: {
          border: 'none', 
          boxShadow: 'none',// Remove border
          height: '130px',// Increase height, adjust as needed
        }
      }}
    >
      <DialogContent>
        <div>
          {['nice', 'interesting', 'notSure', 'whistle'].map((type) => (
            <FeedbackButton
              key={type}
              type={type}
              count={feedbackData[type]}
              isSelected={selectedFeedback === type}
              onClick={() => handleFeedback(type)}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
    )}
  </>
)}


{showSentimentManager && (
  isMobile ? (
    // Mobile: Render as a dialog
    <Dialog
      open={true}
      onClose={() => setShowSentimentManager(false)}
      aria-labelledby="sentiment-dialog-title"
      fullWidth={true}
      maxWidth={'sm'}
      maxHeight='69vh'
      classes={{ paper: classes.dialogBackground }} 
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.69)' // Adjust the alpha value for darkness (0.5 is 50% opacity)
        }
      }}// You can adjust this as needed
    >
      <DialogContent>
    {sentiments.length > 0 ? (
        <ul style={{ paddingInlineStart: '0px' }}>
            {sentiments.map((sentimentObj, index) => (
                <li key={index} className={`${classes.sentimentItem} sentimentItem`} style={{ textAlign: 'center', marginBottom: '20px', marginTop: '3.69vh', listStyleType: 'none' }}>
                    <p style={{ margin: '0 0 5px 0', fontWeight: 'bold', color: "#57c0bf", fontSize: '1.17rem' }}>
                        <a href={`/u/${sentimentObj.username}`} target="_blank" rel="noopener noreferrer" style={{ color: '#57c0bf', textDecoration: 'none' }}>
                            {sentimentObj.username}
                        </a>
                    </p>
                    <p style={{ margin: '0 0 15px 0', color: '#6c757d', fontSize: '1.1069rem', fontStyle: 'italic' }}>
                        {new Date(sentimentObj.createdDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                    </p>
                    <p style={{ wordWrap: 'break-word', overflowWrap: 'break-word', fontStyle: 'italic', fontSize: '1.069rem', maxWidth: '100%' }}>{sentimentObj.sentiment}</p>
                </li>
            ))}
        </ul>
    ) : (
        ""
    )}
</DialogContent>
      <DialogActions>
  {!sentimentAlreadySubmitted && (
    <>
      <button style={{ backgroundColor: '#fefadc' }} onClick={handleSvgInputClick}>
  Add Sentiment
</button>
      {/* Assuming SentimentDialog's visibility is controlled within itself or via props */}
      <SentimentDialog onSentimentSaved={onSentimentSaved} />
    </>
  )}
</DialogActions>
    </Dialog>
  ) : (
    <Dialog
      open={true}
      onClose={() => setShowSentimentManager(false)}
      aria-labelledby="sentiment-dialog-title"
      fullWidth={true}
      maxWidth={'sm'}
      maxHeight='69vh'
      classes={{ paper: classes.dialogBackground }} 
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.69)' // Adjust the alpha value for darkness (0.5 is 50% opacity)
        }
      }}// You can adjust this as needed
    >
      <DialogContent>
    {sentiments.length > 0 ? (
        <ul style={{ paddingInlineStart: '0px' }}>
            {sentiments.map((sentimentObj, index) => (
                <li key={index} className={`${classes.sentimentItem} sentimentItem`} style={{ textAlign: 'center', marginBottom: '20px', marginTop: '3.69vh', listStyleType: 'none' }}>
                    <p style={{ margin: '0 0 5px 0', fontWeight: 'bold', color: "#57c0bf", fontSize: '1.17rem' }}>
                        <a href={`/u/${sentimentObj.username}`} target="_blank" rel="noopener noreferrer" style={{ color: '#57c0bf', textDecoration: 'none' }}>
                            {sentimentObj.username}
                        </a>
                    </p>
                    <p style={{ margin: '0 0 15px 0', color: '#6c757d', fontSize: '1.1069rem', fontStyle: 'italic' }}>
                        {new Date(sentimentObj.createdDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                    </p>
                    <p style={{ wordWrap: 'break-word', overflowWrap: 'break-word', fontStyle: 'italic', fontSize: '1.069rem', maxWidth: '100%' }}>{sentimentObj.sentiment}</p>
                </li>
            ))}
        </ul>
    ) : (
        ""
    )}
</DialogContent>
      <DialogActions>
  {!sentimentAlreadySubmitted && (
    <>
      <button style={{ backgroundColor: '#fefadc' }} onClick={handleSvgInputClick}>
  Add Sentiment
</button>
      {/* Assuming SentimentDialog's visibility is controlled within itself or via props */}
      <SentimentDialog onSentimentSaved={onSentimentSaved} />
    </>
  )}
</DialogActions>
    </Dialog>
  )
)}

      {/* Similar custom popovers for other buttons */}
    </>
  );
};

export default SelectPoolEntry;