import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    'html, body': {
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '0.4269vw', // Default margin for desktop
    // Mobile-specific styles
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0vw', // Adjust this value as needed for mobile
    },
    width: '100%',
    marginTop: '3vh'
  },
  addButton: {
    width: '170px',
    height: '70px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    color: 'white',
    textTransform: 'none',
    border: 'none',
    boxShadow: 'none',
    zIndex: 1100,
    marginBottom: theme.spacing(2),
  },
  searchField: {
    width: '300px',
    padding: '10px',
    fontSize: '16px',
    margin: '10px auto',
    display: 'block',
    color: '#57c0bf',
  },
  userKeysContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    marginBottom: theme.spacing(2),
  },
  textField: {
    width: '48%',
  },
  keysContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    width: '90%',
    marginBottom: theme.spacing(2),
  },
  keyChip: {
    backgroundColor: '#e0e0e0',
  },
  filterButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    marginBottom: theme.spacing(2),
  },
  filterButton: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2),
    gap: theme.spacing(2),
    marginTop: '3vh'
  },
  card: {
    textAlign: 'center',
    maxWidth: '42%',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: '10px',
    transform: 'perspective(1000px) rotateY(0deg) rotateX(0deg)',
    transition: 'transform 0.5s',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '42%',
  },
  thumbnailWrapper: {
    flex: '1',
    position: 'center',
    overflow: 'hidden',
    borderRadius: '4px',
    '&:hover': {
      '& $thumbnailOverlay': {
        opacity: 1,
      },
    },
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  thumbnailOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '69%',
    height: '69%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
  },
  cardHovered: {
    transform: 'perspective(1000px) translateZ(69px) ',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  feedbackButton: {
    background: 'none !important',
    border: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    margin: '0',
    fontSize: '14px',
  },
  flippedCard: {
    transform: 'rotateY(180deg)',
  },
  scrollToTopButton: {
    position: 'fixed',
    bottom: '6%',
    right: '47%',
    color: '#fff',
    zIndex: 1000,
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#004d40',
  },
  addIcon: {
    margin: '3px',
    cursor: 'pointer',
    width: '4.2vw',
    height: '6.9vh',
    borderRadius: '50%', // Make the icon circular
    animation: '$breathingGlow 2s ease-in-out infinite',
    '@media (max-width: 600px)': { // Mobile condition
      width: '12vw',
      height: '7vh',
    },
  },
  '@keyframes breathingGlow': {
    '0%': {
      boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)',
    },
    '50%': {
      boxShadow: '0 0 20px rgba(255, 255, 255, 1)',
    },
    '100%': {
      boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)',
    },
  },  
}));
