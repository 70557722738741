// EntriesGrid.js

import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import MarkdownIt from 'markdown-it';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import './mediaTransition.css';
import { followUser, unfollowUser } from './followUser';
import axios from 'axios';
import Person from '../../../Person/Person';
import { makeStyles } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const md = new MarkdownIt();
const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
const videoExtensions = ['mp4', 'mov', 'avi', 'flv', 'wmv'];
const documentExtensions = ['pdf', 'odt', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

const isVideo = (url) => {
  if (!url) return false;
  const extension = url.split('.').pop().toLowerCase();
  return videoExtensions.includes(extension);
};

const isDocument = (url) => {
  if (!url) return false;
  const extension = url.split('.').pop().toLowerCase();
  return documentExtensions.includes(extension);
};

const isHtml = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    overflowY: "hidden",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '99%',
    margin: '0 auto',
  },
  card: {
    // Define your card styles here
  },
  cardWithoutImage: {
    // Define your cardWithoutImage styles here
  },
  descriptionText: {
    // Define your descriptionText styles here
  },
  thumbnail: {
    // Define your thumbnail styles here
  },
}));

const EntryCard = ({
  entry,
  isMobile,
  classes,
  handleCardClick,
  currentMediaIndex,
  setCurrentMediaIndex,
  inViewState,
  setInViewState,
  globalFollowingState,
  setGlobalFollowingState,
  onAvatarClick,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
    onChange: (inView) => setInViewState((prev) => ({ ...prev, [entry._id]: inView })),
  });

  const isFollowing = globalFollowingState[entry.username]?.isFollowing || false;
  const isFriend = globalFollowingState[entry.username]?.isFriend || false;
  const hasPendingRequest = globalFollowingState[entry.username]?.hasPendingRequest || false;
  const isBlocked = globalFollowingState[entry.username]?.isBlocked || false;

  const handleFollow = async () => {
    try {
      if (isFollowing) {
        await unfollowUser(loggedInUser, entry.username);
        setGlobalFollowingState((prev) => ({
          ...prev,
          [entry.username]: { 
            ...prev[entry.username],
            isFollowing: false 
          }
        }));
      } else {
        await followUser(loggedInUser, entry.username);
        setGlobalFollowingState((prev) => ({
          ...prev,
          [entry.username]: { 
            ...prev[entry.username],
            isFollowing: true 
          }
        }));
      }
    } catch (error) {
      console.error('Error handling follow/unfollow:', error);
    }
  };

  const handleBefriend = async () => {
    try {
      if (isFriend) {
        await axios.post('/api/unfriend', {
          requesterUsername: loggedInUser,
          requestedUsername: entry.username,
        });
        setGlobalFollowingState((prev) => ({
          ...prev,
          [entry.username]: { 
            ...prev[entry.username],
            isFriend: false 
          },
        }));
      } else if (hasPendingRequest) {
        // Optionally notify the user that the request is pending
      } else {
        await axios.post('/api/befriend', {
          requesterUsername: loggedInUser,
          requestedUsername: entry.username,
        });
        setGlobalFollowingState((prev) => ({
          ...prev,
          [entry.username]: { 
            ...prev[entry.username],
            hasPendingRequest: true 
          },
        }));
      }
    } catch (error) {
      console.error('Error handling friend request/unfriend:', error);
    }
  };

  const handleBlock = async () => {
    try {
      if (isBlocked) {
        // Unblock the user
        await axios.post('/api/unblock', {
          blockerUsername: loggedInUser,
          blockedUsername: entry.username,
        });
        setGlobalFollowingState((prev) => ({
          ...prev,
          [entry.username]: { 
            ...prev[entry.username],
            isBlocked: false 
          },
        }));
      } else {
        // Block the user
        await axios.post('/api/block', {
          blockerUsername: loggedInUser,
          blockedUsername: entry.username,
        });
        setGlobalFollowingState((prev) => ({
          ...prev,
          [entry.username]: { 
            ...prev[entry.username],
            isBlocked: true 
          },
        }));
      }
    } catch (error) {
      console.error('Error handling block/unblock:', error);
    }
  };

  useEffect(() => {
    if (inView) {
      setInViewState((prev) => ({ ...prev, [entry._id]: true }));
    } else {
      setInViewState((prev) => ({ ...prev, [entry._id]: false }));
    }
  }, [inView, entry._id, setInViewState]);

  const getMediaType = (media) => {
    // Prefer mediaType if available
    if (media.mediaType) {
      return media.mediaType;
    }
    // Fallback to extension-based detection
    const url = media.mediaLink;
    if (isVideo(url)) return 'video';
    if (isDocument(url)) return 'document';
    return 'image';
  };

  const [isViewingDocument, setIsViewingDocument] = useState({});

  useEffect(() => {
    // If there is a video in the entry, set it to be displayed first
    if (entry.mediaData && entry.mediaData.length > 0) {
      const videoIndex = entry.mediaData.findIndex(media => getMediaType(media) === 'video');
      if (videoIndex !== -1) {
        setCurrentMediaIndex(prevIndexes => ({ ...prevIndexes, [entry._id]: videoIndex }));
      }
    }
  }, []);

  const handlePrev = () => {
    setCurrentMediaIndex((prevIndexes) => {
      const newIndexes = { ...prevIndexes };
      if (newIndexes[entry._id] > 0) {
        newIndexes[entry._id] -= 1;
      } else {
        newIndexes[entry._id] = entry.mediaData.length - 1;
      }
      return newIndexes;
    });
  };

  const handleNext = () => {
    setCurrentMediaIndex((prevIndexes) => {
      const newIndexes = { ...prevIndexes };
      newIndexes[entry._id] = (newIndexes[entry._id] + 1) % entry.mediaData.length;
      return newIndexes;
    });
  };

  const renderMedia = () => {
    const currentIndex = currentMediaIndex[entry._id] || 0;
    const currentMedia = entry.mediaData[currentIndex];
    const mediaType = getMediaType(currentMedia);
    const { mediaLink } = currentMedia;

    if (mediaType === 'image') {
      return (
        <div style={{ position: 'relative' }}>
          <img
            className={`${classes.thumbnail} nonInteractive`}
            src={mediaLink}
            alt=""
            style={{ width: '100%', maxWidth: '100%', maxHeight: '100%' }}
          />
        </div>
      );
    } else if (mediaType === 'video') {
      return (
        <div style={{ position: 'relative' }}>
          <video
            src={mediaLink}
            controls
            playsInline
            autoPlay
            muted
            width="100%"
            height="100%"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: '100vw',
              height: 'auto',
            }}
          />
        </div>
      );
    }
     else if (mediaType === 'document') {
      const viewing = isViewingDocument[entry._id] || false;
      return (
        <div style={{ position: 'relative' }}>
          {!viewing ? (
            <div style={{ position: 'relative' }}>
              {/* Display a static preview of the document */}
              <iframe
                src={`https://docs.google.com/gview?url=${mediaLink}&embedded=true&viewport=false`}
                width="100%"
                height="600px"
                style={{ border: 'none', pointerEvents: 'none' }}
                title="Document Preview"
              />
              {/* Overlay View button */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsViewingDocument(prev => ({ ...prev, [entry._id]: true }));
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  View
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ position: 'relative' }}>
              {/* Display the document with scrolling */}
              <iframe
                src={`https://docs.google.com/gview?url=${mediaLink}&embedded=true`}
                width="100%"
                height="600px"
                style={{ border: 'none' }}
                title="Document View"
              />
              {/* Overlay End Viewing button */}
              <Button
      variant="contained"
      color="secondary"
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        textTransform: 'none',
        fontSize: '1rem', // Increases font size
        padding: '12px 20px', // Increases padding
        minWidth: '120px' // Ensures the button is larger
      }}
      onClick={(e) => {
        e.stopPropagation();
        setIsViewingDocument(prev => ({ ...prev, [entry._id]: false }));
      }}
    >
      End Viewing
    </Button>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
  };

  const trimHtml = (html, maxLength) => {
    let trimmedHtml = "";
    let inTag = false;
    let totalLength = 0;

    for (let i = 0; i < html.length; i++) {
      if (html[i] === '<') {
        inTag = true;
      } else if (html[i] === '>') {
        inTag = false;
      }

      if (!inTag && totalLength >= maxLength) {
        break;
      }

      if (!inTag) {
        totalLength++;
      }

      trimmedHtml += html[i];
    }

    return trimmedHtml + (totalLength >= maxLength ? "..." : "");
  };

  const sanitizeHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    doc.querySelectorAll('style, link[rel="stylesheet"]').forEach(e => e.remove());
    return doc.body.innerHTML;
  };

  return (
    <div
      className={`${classes.card} ${entry.mediaData?.length > 0 ? "" : classes.cardWithoutImage}`}
      style={{
        overflowY: "hidden",
        width: isMobile ? '100%' : '90%',
        height: isMobile ? 'fit-content' : '42.69%',
        marginBottom: isMobile ? '1%' : '0px',
        maxWidth: isMobile ? '100%' : '90%',
        maxHeight: isMobile ? '42.69%' : '42.69%',
        marginLeft: isMobile ? '4.2%' : '0%',
        position: 'relative',
      }}
      key={entry._id}
      onClick={() => handleCardClick(entry)}
      onContextMenu={(e) => e.preventDefault()}
      ref={ref}
    >
      <div className="card-front" style={{ position: 'relative', width: '100%' }}>
        {entry.mediaData?.length > 0 && (
          <div style={{ position: 'relative' }}>
            {renderMedia()}
            {entry.mediaData.length > 1 && (
              <div style={{ marginTop: '10px', overflowX: 'hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', overflowX: 'auto', flexWrap: 'nowrap' }}>
                    {entry.mediaData.map((media, index) => {
                      const mediaType = getMediaType(media);
                      return (
                        <div 
                          key={index} 
                          onClick={(e) => { 
                            e.stopPropagation(); 
                            setCurrentMediaIndex(prevIndexes => ({ ...prevIndexes, [entry._id]: index }));
                          }} 
                          style={{ cursor: 'pointer', flex: '0 0 auto' }}
                        >
                          {mediaType === 'video' ? (
                            <video
                              src={media.mediaLink}
                              muted
                              loop
                              autoPlay
                              playsInline
                              style={{
                                width: '69px',
                                height: '69px',
                                margin: '0 5px',
                                border: currentMediaIndex[entry._id] === index ? '2px solid #004d40' : 'none',
                                maxWidth: '69px',
                                maxHeight: '69px',
                              }}
                            />
                          ) : mediaType === 'document' ? (
                            <div
                              style={{
                                width: '69px',
                                height: '69px',
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '4px',
                                border: '1px solid #ddd',
                              }}
                            >
                              <InsertDriveFileIcon style={{ fontSize: '2rem', color: '#757575' }} />
                            </div>
                          ) : (
                            <img
                              src={media.mediaLink} 
                              alt=""
                              style={{
                                width: '69px',
                                height: '69px',
                                margin: '0 5px',
                                border: currentMediaIndex[entry._id] === index ? '2px solid #004d40' : 'none',
                                maxWidth: '69px',
                                maxHeight: '69px',
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!entry.mediaData?.length && entry.description && (
          <div className={classes.descriptionText} style={{ whiteSpace: "pre-wrap", color: "#004d40", fontSize: '1.2rem' }}>
            {isHtml(entry.description) ? (
              <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(trimHtml(entry.description, 1000)) }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: md.render(entry.description.slice(0, 333)) }} />
            )}
          </div>
        )}
        <div style={{ marginTop: '10px', borderTop: '1px solid #ccc', paddingTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 2 }}>
          <div
            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginBottom: '10px' }}
            onClick={(e) => {
              e.stopPropagation();
              onAvatarClick(entry.username);
            }}
          >
            <Avatar
              alt={entry.username}
              src={entry.avatar}
              style={{
                width: isMobile ? '12vw' : '42px',
                height: isMobile ? '12vw' : '42px',
                marginRight: isMobile ? '2%' : '2%',
              }}
            />
            <span style={{ color: '#004d40', fontWeight: 'bold' }}>{entry.username}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color={isFollowing ? "secondary" : "primary"}
              style={{ marginLeft: '10px', textTransform: 'none' }}
              onClick={(e) => {
                handleButtonClick(e);
                handleFollow();
              }}
            >
              {isFollowing ? 'Unfollow' : 'Follow'}
            </Button>
            <Button
              variant="contained"
              color={isFriend ? 'secondary' : (hasPendingRequest ? 'default' : 'primary')}
              style={{ marginLeft: '10px', textTransform: 'none' }}
              onClick={(e) => {
                handleButtonClick(e);
                handleBefriend();
              }}
              disabled={hasPendingRequest}
            >
              {isFriend ? 'Unfriend' : hasPendingRequest ? 'Pending' : 'Befriend'}
            </Button>
            <Button
              variant="contained"
              style={{
                marginLeft: '10px',
                textTransform: 'none',
                backgroundColor: isBlocked ? 'white' : '#9B2335',
                color: isBlocked ? '#9B2335' : 'white',
                fontSize: '0.69rem',
              }}
              onClick={(e) => {
                handleButtonClick(e);
                handleBlock();
              }}
            >
              {isBlocked ? 'Unblock' : 'Block'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EntriesGrid = ({ entries, isMobile, classes, handleCardClick }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState({});
  const [inViewState, setInViewState] = useState({});
  const [globalFollowingState, setGlobalFollowingState] = useState({});
  const [personUsername, setPersonUsername] = useState(null);
  const styles = useStyles();

  useEffect(() => {
    const fetchFollowStatuses = async () => {
      if (loggedInUser) {
        const usernames = [...new Set(entries.map(entry => entry.username))];
        try {
          const followResponse = await axios.post('/api/following-status', {
            loggedInUser,
            usernames,
          });
          setGlobalFollowingState(prev => {
            const newState = { ...prev };
            followResponse.data.forEach(({ username, isFollowing }) => {
              newState[username] = { 
                ...newState[username], 
                isFollowing 
              };
            });
            return newState;
          });
        } catch (error) {
          console.error('Error fetching following statuses:', error);
        }
      }
    };

    const fetchFriendStatuses = async () => {
      if (loggedInUser) {
        const usernames = [...new Set(entries.map(entry => entry.username))];
        try {
          const friendResponse = await axios.post('/api/friend-status', {
            loggedInUser,
            usernames,
          });
          setGlobalFollowingState(prev => {
            const newState = { ...prev };
            friendResponse.data.forEach(({ username, isFriend, hasPendingRequest }) => {
              newState[username] = { 
                ...newState[username], 
                isFriend, 
                hasPendingRequest 
              };
            });
            return newState;
          });
        } catch (error) {
          console.error('Error fetching friend statuses:', error);
        }
      }
    };

    const fetchBlockedStatuses = async () => {
      if (loggedInUser) {
        const usernames = [...new Set(entries.map(entry => entry.username))];
        try {
          const blockResponse = await axios.post('/api/blocked-status', {
            loggedInUser,
            usernames,
          });
          setGlobalFollowingState(prev => {
            const newState = { ...prev };
            blockResponse.data.forEach(({ username, isBlocked }) => {
              newState[username] = { 
                ...newState[username], 
                isBlocked 
              };
            });
            return newState;
          });
        } catch (error) {
          console.error('Error fetching blocked statuses:', error);
        }
      }
    };

    fetchFollowStatuses();
    fetchFriendStatuses();
    fetchBlockedStatuses();
  }, [loggedInUser, entries]);

  return (
    <div
      style={{
        overflowY: "hidden",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: isMobile ? '100%' : '93.69%',
        margin: isMobile ? '0 auto' : '0 auto',
      }}
    >
      {entries.map((entry, index) => (
        <EntryCard
          key={`${entry._id}-${index}`}
          entry={entry}
          isMobile={isMobile}
          classes={classes}
          handleCardClick={handleCardClick}
          currentMediaIndex={currentMediaIndex}
          setCurrentMediaIndex={setCurrentMediaIndex}
          inViewState={inViewState}
          setInViewState={setInViewState}
          globalFollowingState={globalFollowingState}
          setGlobalFollowingState={setGlobalFollowingState}
          onAvatarClick={setPersonUsername}
        />
      ))}
      {personUsername && (
        <Person
          username={personUsername}
          onClose={() => setPersonUsername(null)}
        />
      )}
    </div>
  );
};

export default EntriesGrid;
