import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios';

const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

const SourceDialogContent = {
  backgroundColor: '#ffffff',
  borderRadius: '15px',
  padding: '32px',
  boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)',
  // Removed fixed padding to allow content to manage its own spacing
};

const SourceContainer = {
  marginBottom: '24px',
  padding: '16px',
  border: '2px solid #004d40',
  borderRadius: '12px',
};

const SourceTypography = {
  color: '#004d40',
  fontFamily: 'Times New Roman, Times, serif',
  fontSize: '1.2rem',
};

const SourceLinksList = {
  listStyle: 'none',
  padding: 0,
  textAlign: 'center',
  marginTop: '20px',
};

const SourceLinkItem = {
  marginBottom: '10px',
};

const SourceLink = {
  color: '#004d40',
  textDecoration: 'none',
  fontFamily: 'Times New Roman, Times, serif',
  fontSize: '1.2rem',
  backgroundColor: '#ffffff',
  padding: '6px 12px',
  borderRadius: '12px',
  border: '2px solid #004d40',
};

const SelectionButton = ({ selected, onClick, children }) => (
  <Button
    onClick={onClick}
    style={{
      marginLeft: '5.69px',
      margin: '3.69px 4.69px',
      backgroundColor: selected ? '#808080' : '#004d40',
      color: selected ? '#004d40' : '#fff',
      zIndex: 1100,
      textTransform: 'none',
    }}
  >
    {children}
  </Button>
);

const SourceInfo = ({ open, onClose, sourceInfo }) => {
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedFields, setSelectedFields] = useState({
    fields: '',
    categories: '',
    origins: '',
    originNames: '',
  });

  useEffect(() => {
    if (loggedInUser && open && sourceInfo) {
      const { modelName, domain, sourceType, sourceTypeName, keys } = sourceInfo;
  
      const queryParams = new URLSearchParams({
        modelName: modelName || '',
        domain: domain || '',
        sourceType: sourceType || '',
        sourceTypeName: sourceTypeName || '',
        keys: keys ? keys.join(',') : ''
      }).toString();
  
      axios
        .get(`/api/get-saved-sorts/${loggedInUser}?${queryParams}`)
        .then((response) => {
          const { branches, fields, categories, origins, originNames } = response.data;
          setSelectedBranches(branches || []);
          setSelectedFields({
            fields: fields[0] || '',
            categories: categories[0] || '',
            origins: origins[0] || '',
            originNames: originNames[0] || '',
          });
        })
        .catch((error) => {
          console.error('Error fetching saved data:', error);
        });
    }
  }, [loggedInUser, open, sourceInfo]);

  const handleBranchClick = (branch) => {
    const isSelected = selectedBranches.includes(branch);
    const updatedBranches = isSelected
      ? selectedBranches.filter((b) => b !== branch)
      : [...selectedBranches, branch];
    setSelectedBranches(updatedBranches);
    saveData({ branches: updatedBranches });
  };

  const handleFieldClick = (field, value) => {
    const isSelected = selectedFields[field] === value;
    setSelectedFields({
      ...selectedFields,
      [field]: isSelected ? '' : value,
    });
    saveData({ [field]: isSelected ? '' : value });
  };

  const saveData = (data) => {
    axios
      .post(`/api/save-sorts/${loggedInUser}`, { ...selectedFields, branches: selectedBranches, ...data })
      .then((response) => {
        if (response.status === 409) {
          console.log('Sort already exists, not saving.');
        } else {
          console.log('Data saved successfully.');
        }
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });
  };

  const isValueSelected = (value, field) => selectedFields[field] === value;
  const isBranchSelected = (branch) => selectedBranches.includes(branch);

  if (!sourceInfo) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="source-info-title"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: '#ffffff',
          border: '2px solid #004d40',
          borderRadius: '20px',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
          padding: '16px',
          overflowY: 'auto', // Enable vertical scrolling
          maxHeight: '80vh', // Set maximum height relative to viewport
        },
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
    >
      <div style={SourceDialogContent}>
        <Typography variant="h5" style={{ textAlign: 'center', letterSpacing: '1px' }}>
          About
        </Typography>

        {sourceInfo.modelName && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Field
              <SelectionButton
                selected={isValueSelected(sourceInfo.modelName, 'fields')}
                onClick={() => handleFieldClick('fields', sourceInfo.modelName)}
              >
                {sourceInfo.modelName}
              </SelectionButton>
            </Typography>
          </div>
        )}

        {sourceInfo.domain && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Category
              <SelectionButton
                selected={isValueSelected(sourceInfo.domain, 'categories')}
                onClick={() => handleFieldClick('categories', sourceInfo.domain)}
              >
                {sourceInfo.domain}
              </SelectionButton>
            </Typography>
          </div>
        )}

        {sourceInfo.sourceType && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Origin
              <SelectionButton
                selected={isValueSelected(sourceInfo.sourceType, 'origins')}
                onClick={() => handleFieldClick('origins', sourceInfo.sourceType)}
              >
                {sourceInfo.sourceType}
              </SelectionButton>
            </Typography>
          </div>
        )}

        {sourceInfo.sourceTypeName && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Origin Name
              <SelectionButton
                selected={isValueSelected(sourceInfo.sourceTypeName, 'originNames')}
                onClick={() => handleFieldClick('originNames', sourceInfo.sourceTypeName)}
              >
                {sourceInfo.sourceTypeName}
              </SelectionButton>
            </Typography>
          </div>
        )}

        {sourceInfo.createdAt && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Added {new Date(sourceInfo.createdAt).toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
            </Typography>
          </div>
        )}

        {sourceInfo.promptmodel && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Ai Model {sourceInfo.promptmodel}
            </Typography>
          </div>
        )}

        {sourceInfo.prompttext && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Text Request <em>{sourceInfo.prompttext}</em>
            </Typography>
          </div>
        )}

        {sourceInfo.imageprompttext && (
          <div style={SourceContainer}>
            <Typography style={SourceTypography}>
              Image Request <em>{sourceInfo.imageprompttext}</em>
            </Typography>
          </div>
        )}

        {sourceInfo.keys && sourceInfo.keys.length > 0 && (
          <div style={SourceContainer}>
            <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '16px' }}>
              Branches
            </Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {sourceInfo.keys.map((branch, index) => (
                <SelectionButton
                  key={index}
                  selected={isBranchSelected(branch)}
                  onClick={() => handleBranchClick(branch)}
                >
                  {branch}
                </SelectionButton>
              ))}
            </div>
          </div>
        )}

        {sourceInfo.links && sourceInfo.links.length > 0 && (
          <div style={SourceContainer}>
            <ul style={SourceLinksList}>
              {sourceInfo.links.map((link, index) => {
                try {
                  const hostname = new URL(link).hostname;
                  return (
                    <li style={SourceLinkItem} key={index}>
                      <a style={SourceLink} href={link} target="_blank" rel="noopener noreferrer">
                        {hostname}
                      </a>
                    </li>
                  );
                } catch (error) {
                  console.error('Invalid URL:', link);
                  return null;
                }
              })}
            </ul>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default SourceInfo;
