const generateBase64Encode = (file) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      console.error('Expected instance of Blob, got:', file);
      return reject(new TypeError('Parameter is not of type Blob'));
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default generateBase64Encode;
