import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import axios from 'axios';
import Somethin from '../../../../assets/border.png';

const DomainLineChart = ({ username, onDomainClick }) => {
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [timeRange, setTimeRange] = useState('24hours'); // Default to 24 hours
  const containerRef = useRef(null);

  const handleDotClick = (payload) => {// Debugging
    if (onDomainClick && payload.ids) {
      onDomainClick(payload.ids);
    } else {
      console.warn("No IDs found for the clicked domain.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/user-interactions-graph/${username}`, {
          params: { range: timeRange }
        });
        const { domainCountsEntry } = response.data;

        const combinedData = domainCountsEntry.map(item => ({
          name: item.name,
          count: item.count,
          ids: item.ids, // Ensure ids are included
        }));

        setData(combinedData);
      } catch (error) {
        console.error("Error fetching user interactions:", error);
      }
    };

    fetchData();
  }, [username, timeRange]);

  // Animate data points appearing one by one
  useEffect(() => {
    if (data.length > 0) {
      let index = 0;
      const interval = setInterval(() => {
        setDisplayedData(
          data.map((d, i) => ({
            ...d,
            count: i <= index ? d.count : null,
          }))
        );
        index++;
        if (index >= data.length) {
          clearInterval(interval);
        }
      }, 100); // Adjust the interval for speed of animation
      return () => clearInterval(interval);
    }
  }, [data]);

  // Update every minute
  useEffect(() => {
    const interval = setInterval(() => {
      axios.get(`/api/user-interactions-graph/${username}`, { params: { range: timeRange } })
        .then(response => {
          const { domainCountsEntry } = response.data;
          const combinedData = domainCountsEntry.map(item => ({
            name: item.name,
            count: item.count,
            ids: item.ids, // Ensure ids are included
          }));
          setData(combinedData);
        })
        .catch(error => console.error("Error fetching data:", error));
    }, 60000); // 1 minute interval

    return () => clearInterval(interval);
  }, [username, timeRange]);

  const onWheel = (e) => {
    e.preventDefault();
    containerRef.current.scrollLeft += e.deltaY;
  };

  // Custom dot component with larger clickable area
  const CustomDot = (props) => {
    const { cx, cy, stroke, index, payload } = props;
    return (
      <g>
        {/* Invisible larger circle for better click area */}
        <circle
          cx={cx}
          cy={cy}
          r={10} // Increased radius for clickable area
          fill="transparent"
          onClick={() => handleDotClick(payload)}
          style={{ cursor: 'pointer' }}
        />
        {/* Visible smaller circle with pulsing animation */}
        <circle
          cx={cx}
          cy={cy}
          r={4}
          stroke={stroke}
          strokeWidth={2}
          fill="#fff"
          style={{
            opacity: 0,
            animation: 'pulse 0.5s ease-out forwards',
            animationDelay: `${index * 0.1}s`,
          }}
        />
      </g>
    );
  };

  // Include keyframes for the pulse animation
  const pulseAnimation = `
    @keyframes pulse {
      0% {
        r: 0;
        opacity: 0;
      }
      100% {
        r: 4;
        opacity: 1;
      }
    }
  `;

  return (
    <div
      style={{ position: 'relative', width: '100%', overflow: 'hidden', cursor: 'grab' }}
    >
      <style>{pulseAnimation}</style>
      <img src={Somethin} alt="border" style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 0 }} />
      
      {/* Buttons for filtering */}
      <div style={{ marginBottom: '10px', zIndex: 2, position: 'relative', display: 'flex', justifyContent: 'center', marginTop: '1vh' }}>
        <button onClick={() => setTimeRange('24hours')} style={{ marginRight: '10px' }}>24 Hours</button>
        <button onClick={() => setTimeRange('1week')} style={{ marginRight: '10px' }}>1 Week</button>
        <button onClick={() => setTimeRange('1month')} style={{ marginRight: '10px' }}>1 Month</button>
        <button onClick={() => setTimeRange('6months')} style={{ marginRight: '10px' }}>6 Months</button>
        <button onClick={() => setTimeRange('alltime')}>All Time</button>
      </div>

      <div
        ref={containerRef}
        style={{ width: '100%', height: '420px', overflowX: 'hidden', overflowY: 'hidden', zIndex: 1 }}
        onWheel={onWheel}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={window.innerWidth - 30}
            height={420}
            data={displayedData}
          >
            <defs>
              <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#2f4f4f" />
                <stop offset="25%" stopColor="#556b2f" />
                <stop offset="50%" stopColor="#ffffff" />
                <stop offset="75%" stopColor="#004d40" />
                <stop offset="100%" stopColor="#0ABAB5" /> {/* Tiffany blue */}
              </linearGradient>
            </defs>
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" opacity={0.3} />
            <XAxis dataKey="name" hide />
            <Tooltip
  contentStyle={{ backgroundColor: '#004d40', borderRadius: '10px', padding: '5px', width: 'fit-content' }}
  labelStyle={{ color: '#ffffff' }}
  formatter={(value, name) => [null, null]} 
/>
            <Line
              type="monotone"
              dataKey="count"
              stroke="url(#lineGradient)"
              dot={<CustomDot />} // Use CustomDot without extra props
              isAnimationActive={false}
              strokeDasharray="none"
              strokeWidth={2}
              connectNulls={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DomainLineChart;
