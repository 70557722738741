// SearchAndSort.js

import React, { useState, useEffect } from 'react';
import {
  Button, Chip, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, IconButton, Collapse, Typography
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { GiTreeBranch } from "react-icons/gi";
import { FaPeopleRoof } from "react-icons/fa6";
import { IoPeopleCircleOutline } from "react-icons/io5";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import domainsByModel from './domainsByModel';
import SavedDialog from './SavedDialog';
import styled from 'styled-components';
import PhotoIcon from '@mui/icons-material/Photo';
import VideocamIcon from '@mui/icons-material/Videocam';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from 'axios';

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 5px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const InputWrapper = styled.div`
  flex: 1;
  max-width: 45%;
  margin-right: 0px;
  position: relative;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
    align-self: flex-start;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;

  &.people-icon {
    left: 61%;

    @media (max-width: 768px) {
      left: 52%;
      margin-top: 10px;
    }
  }

  &.branches-icon {
    left: 20%;

    @media (max-width: 768px) {
      left: 36%;
      margin-top: 10px;
    }
  }

  &.entry-icon {
    left: -20%;

    @media (max-width: 768px) {
      left: 52%;
      margin-top: 10px;
    }
  }
`;

const InputField = styled.input`
  width: 40%;
  padding: 10px 10px 10px 40px;
  font-size: 16px;
  display: block;
  color: #fff;
  background-color: #004d40;
  border: none;
  text-transform: none;
  margin-left: 0;
  margin-right: 0;

  &.people-input {
    margin-left: 57%;

    @media (max-width: 768px) {
      margin-left: 47%;
      width: 42%;
      margin-bottom: -20px;
    }
  }

  &.branches-input {
    margin-left: 15%;

    @media (max-width: 768px) {
      margin-left: 31%;
      width: 69%;
      margin-bottom: -20px;
    }
  }

  &.entry-input {
    margin-left: -25%;

    @media (max-width: 768px) {
      margin-left: 47%;
      width: 42%;
      margin-bottom: -20px;
    }
  }
`;

const SearchAndSort = ({
  isMobile, sortOrder, setSortOrder, setActiveDomains, setSelectedDomains,
  setSearchTerm, setDomainsDialogOpen, setValuesDialogOpen, activeDomains = [],
  selectedDomains, domainsDialogOpen, valuesDialogOpen, usernames, setUsernames,
  keys, setKeys, handleDeleteKey, searchEntries, setSearchEntries, timeParameter, setTimeParameter,
  handleSelectFromSaved,
  setBranches,
  setFields,
  setCategories,
  setOrigins,
  setOriginNames,
  setSelectedItemsByTypeInDialog,
  setFollowed,
  followed,
  selectedMediaTypes = [],
  setSelectedMediaTypes,
}) => {
  const [keyInput, setKeyInput] = useState('');
  const [usernameInput, setUsernameInput] = useState('');
  const [searchEntriesInput, setSearchEntriesInput] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [savedDialogOpen, setSavedDialogOpen] = useState(false);
  const [followedSelected, setFollowedSelected] = useState(followed);
  const [selectedItemsByType, setSelectedItemsByType] = useState({
    branches: [],
    fields: [],
    categories: [],
    origins: [],
    originNames: [],
  });

  const [blockedDialogOpen, setBlockedDialogOpen] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);

  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  useEffect(() => {
    if (blockedDialogOpen && loggedInUser) {
      // Fetch the blocked users
      axios.post('/api/get-blocked-users', { loggedInUser })
        .then(response => {
          setBlockedUsers(response.data.blockedUsernames);
        })
        .catch(error => {
          console.error('Error fetching blocked users:', error);
        });
    }
  }, [blockedDialogOpen]);

  const handleUnblockUser = (username) => {
    axios.post('/api/sort-unblock', {
      blockerUsername: loggedInUser,
      blockedUsername: username
    })
    .then(response => {
      // Remove the user from the blockedUsers state
      setBlockedUsers(prevBlockedUsers => prevBlockedUsers.filter(user => user !== username));
    })
    .catch(error => {
      console.error('Error unblocking user:', error);
    });
  };

  const handleClearAll = () => {
    setActiveDomains([]);
    setSelectedDomains([]);
    setSearchTerm('');
    setSortOrder('');
    setSearchEntries('');
    setTimeParameter('');
    setKeys([]);
    setUsernames([]);
    setBranches([]);
    setFields([]);
    setCategories([]);
    setOrigins([]);
    setOriginNames([]);
    setSearchEntriesInput('');
    setFollowed(false);
    setFollowedSelected(false);
    setSelectedMediaTypes([]);
    const resetSelection = {
      branches: [],
      fields: [],
      categories: [],
      origins: [],
      originNames: [],
    };
    setSelectedItemsByType(resetSelection);
    setSelectedItemsByTypeInDialog(resetSelection);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && keyInput.trim() !== '') {
      setKeys([...keys, keyInput.trim()]);
      setKeyInput('');
    }
  };

  const handleMediaTypeClick = (mediaType) => {
    setSelectedMediaTypes(prevSelected => {
      const newSelected = prevSelected.includes(mediaType)
        ? prevSelected.filter(type => type !== mediaType)
        : [...prevSelected, mediaType];
      return newSelected;
    });
  };

  const handleUsernameKeyDown = (event) => {
    if (event.key === 'Enter' && usernameInput.trim() !== '') {
      setUsernames([...usernames, usernameInput.trim()]);
      setUsernameInput('');
    }
  };

  const handleSearchEntriesKeyDown = (event) => {
    if (event.key === 'Enter' && searchEntriesInput.trim() !== '') {
      setSearchEntries(searchEntriesInput.trim());
      setSearchEntriesInput('');
    }
  };

  const handleDomainClick = (domain) => {
    setActiveDomains(prevDomains => 
      prevDomains.includes(domain) 
      ? prevDomains.filter(d => d !== domain)
      : [...prevDomains, domain]
    );
  };

  const handleFollowedClick = () => {
    const newFollowedState = !followedSelected;
    setFollowedSelected(newFollowedState);
    setFollowed(newFollowedState);
  };

  const handleValueClick = (value) => {
    setSelectedDomains(prevSelected => 
      prevSelected.includes(value) 
      ? prevSelected.filter(d => d !== value)
      : [...prevSelected, value]
    );
  };

  return (
    <div style={{ textAlign: "center", marginTop: "3px" }}>
      <input
        type="text"
        placeholder="What do you Seek?"
        onKeyDown={(e) => e.key === 'Enter' && setSearchTerm(e.target.value.toLowerCase())}
        style={{
          width: "300px",
          padding: "10px",
          fontSize: "16px",
          margin: "3px auto",
          display: "block",
          color: "#fff",
          backgroundColor: "#004d40",
          border: "none",
          textTransform: "none"
        }}
      />
      <Button
        onClick={() => setShowAdvanced(!showAdvanced)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '3px auto',
          backgroundColor: "#004d40",
          color: "white",
          textTransform: "none"
        }}
      >
        <SearchIcon />
        {showAdvanced ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '3px' }}>
          <IconButton
            onClick={() => handleMediaTypeClick('image')}
            style={{
              color: selectedMediaTypes.includes('image') ? '#004d40' : '#fff',
              backgroundColor: selectedMediaTypes.includes('image') ? '#fff' : '#004d40',
              margin: '0 5px',
            }}
          >
            <PhotoIcon />
          </IconButton>
          <IconButton
            onClick={() => handleMediaTypeClick('video')}
            style={{
              color: selectedMediaTypes.includes('video') ? '#004d40' : '#fff',
              backgroundColor: selectedMediaTypes.includes('video') ? '#fff' : '#004d40',
              margin: '0 5px',
            }}
          >
            <VideocamIcon />
          </IconButton>
          <IconButton
            onClick={() => handleMediaTypeClick('document')}
            style={{
              color: selectedMediaTypes.includes('document') ? '#004d40' : '#fff',
              backgroundColor: selectedMediaTypes.includes('document') ? '#fff' : '#004d40',
              margin: '0 5px',
            }}
          >
            <DescriptionIcon />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDomainsDialogOpen(true)}
            style={{ margin: "3px", backgroundColor: "#004d40", textTransform: "none" }}
          >
            Fields
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setValuesDialogOpen(true)}
            disabled={activeDomains.length === 0}
            style={{ margin: "3px", backgroundColor: "#004d40", textTransform: "none" }}
          >
            Categories
          </Button>
        </div>
        <InputContainer>
          {/* People Input */}
          <InputWrapper>
            <Icon as={FaPeopleRoof} className="people-icon" />
            <InputField
              value={usernameInput}
              onChange={(e) => setUsernameInput(e.target.value)}
              onKeyDown={handleUsernameKeyDown}
              className="people-input"
              placeholder="People"
            />
          </InputWrapper>

          {/* Branches Input */}
          <InputWrapper>
            <Icon as={GiTreeBranch} className="branches-icon" />
            <InputField
              value={keyInput}
              onChange={(e) => setKeyInput(e.target.value)}
              onKeyDown={handleKeyDown}
              className="branches-input"
              placeholder="Branches"
            />
          </InputWrapper>
        </InputContainer>
        <div style={{ textAlign: "center", margin: "3px 0" }}>
          <div style={{ textAlign: "center", margin: "3px 0" }}>
            <Button
              onClick={() => setTimeParameter("24h")}
              style={{
                marginLeft: "5.69px", 
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "24h" ? "#fff" : "#004d40",
                color: timeParameter === "24h" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last Day
            </Button>
            <Button
              onClick={() => setTimeParameter("week")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "week" ? "#fff" : "#004d40",
                color: timeParameter === "week" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last Week
            </Button>
            <Button
              onClick={() => setTimeParameter("month")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "month" ? "#fff" : "#004d40",
                color: timeParameter === "month" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last Month
            </Button>
            <Button
              onClick={() => setTimeParameter("6months")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "6months" ? "#fff" : "#004d40",
                color: timeParameter === "6months" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last 6 Months
            </Button>
            <Button
              onClick={() => setTimeParameter("all")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "all" ? "#fff" : "#004d40",
                color: timeParameter === "all" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              All Time
            </Button>
          </div>
          <Button
            onClick={handleFollowedClick}
            style={{
              marginLeft: "5.69px",
              margin: "3.69px 4.69px",
              backgroundColor: followedSelected ? "#fff" : "#004d40",
              color: followedSelected ? "#004d40" : "white",
              zIndex: 1100,
              textTransform: "none"
            }}
          >
            Followed
          </Button>
          <Button
            onClick={() => setSavedDialogOpen(true)}
            style={{
              marginLeft: "5.69px",
              margin: "3.69px 4.69px",
              backgroundColor: "#004d40",
              color: "white",
              zIndex: 1100,
              textTransform: "none"
            }}
          >
            Saved
          </Button>
          <Button
            onClick={() => setBlockedDialogOpen(true)}
            style={{
              marginLeft: "5.69px",
              margin: "3.69px 4.69px",
              backgroundColor: "#004d40",
              color: "white",
              zIndex: 1100,
              textTransform: "none"
            }}
          >
            Blocked
          </Button>
          <Button
            onClick={handleClearAll}
            style={{
              marginLeft: "5.69px",
              margin: "3.69px 4.69px",
              backgroundColor: "#004d40",
              color: "white",
              zIndex: 1100,
              textTransform: "none"
            }}
          >
            Clear All
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', margin: '3px' }}>
          {keys.map((key) => (
            <Chip
              key={key}
              label={key}
              onDelete={() => handleDeleteKey(key)}
              style={{ margin: "5px", backgroundColor: "#fff", color: "#004d40", textTransform: "none" }}
            />
          ))}
          {usernames.map((username) => (
            <Chip
              key={username}
              label={username}
              onDelete={() => handleDeleteKey(username)}
              style={{ margin: "5px", backgroundColor: "#fff", color: "#004d40", textTransform: "none" }}
            />
          ))}
          {searchEntries && (
            <Chip
              label={searchEntries}
              onDelete={() => setSearchEntries('')}
              style={{ margin: "5px", backgroundColor: "#fff", color: "#004d40", textTransform: "none" }}
            />
          )}
        </div>
      </Collapse>
      <Dialog
        open={domainsDialogOpen}
        onClose={() => setDomainsDialogOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: isMobile
            ? { width: '90vw', height: 'auto', backgroundColor: 'transparent' }
            : { width: '80vw', height: '80vh', backgroundColor: 'transparent' },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: 'transparent', color: '#fff' }}>
          <IconButton
            aria-label="close"
            onClick={() => setDomainsDialogOpen(false)}
            style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: 'transparent' }}>
          <List
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '5px',
              justifyContent: 'center'
            }}
          >
            {Object.keys(domainsByModel)
              .sort((a, b) => a.localeCompare(b))
              .map((domain) => (
                <ListItem
                  button
                  onClick={() => handleDomainClick(domain)}
                  key={domain}
                  selected={activeDomains.includes(domain)}
                  style={{
                    flex: '0 0 auto',
                    boxSizing: 'border-box',
                    padding: '10px',
                    backgroundColor: activeDomains.includes(domain) ? '#fff' : '#004d40',
                    color: activeDomains.includes(domain) ? '#004d40' : '#fff',
                    textAlign: 'center',
                    width: 'fit-content',
                    borderRadius: '10px'
                  }}
                >
                  <ListItemText primary={domain.replace(/([a-z])([A-Z])/g, '$1 $2')} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
      <Dialog
        open={valuesDialogOpen}
        onClose={() => setValuesDialogOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: isMobile
            ? { width: '90vw', height: 'auto', backgroundColor: 'transparent' }
            : { width: '80vw', height: '80vh', backgroundColor: 'transparent' },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: 'transparent', color: '#fff' }}>
          <IconButton
            aria-label="close"
            onClick={() => setValuesDialogOpen(false)}
            style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: 'transparent' }}>
          <List style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
            {activeDomains.map((domain) => (
              <div key={domain} style={{ width: '100%' }}>
                <Typography variant="h6" style={{ textAlign: 'center', margin: '10px 0', color: '#fff' }}>
                  {domain.replace(/([a-z])([A-Z])/g, '$1 $2')}
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                  {domainsByModel[domain]?.map((value) => (
                    <ListItem 
                      button 
                      onClick={() => handleValueClick(value)} 
                      key={value}
                      selected={selectedDomains.includes(value)}
                      style={{ padding: '10px', backgroundColor: selectedDomains.includes(value) ? '#fff' : '#004d40', color: selectedDomains.includes(value) ? '#004d40' : '#fff', textAlign: 'center', width: 'fit-content', borderRadius: '10px' }}
                    >
                      <ListItemText primary={value} />
                    </ListItem>
                  ))}
                </div>
              </div>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <SavedDialog
        open={savedDialogOpen}
        onClose={() => setSavedDialogOpen(false)}
        isMobile={isMobile}
        handleSelect={handleSelectFromSaved}
        resetSelection={selectedItemsByType}
      />
      <Dialog
  open={blockedDialogOpen}
  onClose={() => setBlockedDialogOpen(false)}
  fullWidth
  maxWidth="sm"
  PaperProps={{
    style: isMobile
      ? { width: '90vw', height: 'auto', backgroundColor: 'transparent' }
      : { width: '50vw', height: 'auto', backgroundColor: 'transparent' },
  }}
  BackdropProps={{
    style: {
      backgroundColor: 'rgba(0, 0, 0, 0.96)',
    },
  }}
>
  <DialogTitle style={{ backgroundColor: 'transparent', color: '#fff' }}>
    <IconButton
      aria-label="close"
      onClick={() => setBlockedDialogOpen(false)}
      style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent
    style={{
      backgroundColor: 'transparent',
      maxHeight: '70vh',
      overflowY: 'auto',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        padding: '10px',
      }}
    >
      {blockedUsers.map((username) => (
        <div
          key={username}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: '#004d40',
            color: '#fff',
            borderRadius: '10px',
            flex: '0 1 fit-content',
          }}
        >
          <span style={{ marginRight: '10px' }}>{username}</span>
          <Button
            variant="contained"
            style={{
              backgroundColor: 'white',
              color: '#0ABAB5',
              textTransform: 'none',
              border: '1px solid #0ABAB5',
              transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = 'white';
              e.target.style.color = '#0ABAB5';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#0ABAB5';
              e.target.style.color = 'white';
            }}
            onClick={() => handleUnblockUser(username)}
          >
            Unblock
          </Button>
        </div>
      ))}
    </div>
  </DialogContent>
</Dialog>
    </div>
  );
};

export default SearchAndSort;
