import React, { useState } from 'react';
import { TextField, TextareaAutosize, IconButton, Dialog, DialogContent, DialogActions, Button, InputAdornment } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import AddVideoIcon from '@material-ui/icons/VideoLibrary';
import DescriptionIcon from '@material-ui/icons/Description';
import LinkIcon from '@material-ui/icons/Link';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { GiTreeBranch } from "react-icons/gi";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Autocomplete } from '@material-ui/lab';
import { handleInputChange, handleMediaUpload } from './formhandler';
import useStyles from './submitStyles';
import domainsByModel from '../explorer/domainsByModel'; // Adjust the import path as needed
import HelpInfoDialog from './DetailedHelpInfoDialog'; // Assuming this is the imported help information dialogue
import generateBase64Encode from '../../../../utils/genBase64Encode';
const sourceTypes = [
  'Institution',
  'Company',
  'Book',
  'Movie',
  'Personal',
  'Social Media',
  'Television',
  'Newspaper',
];

function DetailedForm({ formData, setFormData, setImagePreview }) {
  const classes = useStyles();
  const [selectedModel, setSelectedModel] = useState('');
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [numLinks, setNumLinks] = useState(0);

  const handleFileInput = (accept, id) => {
    const inputElement = document.getElementById(id);
    inputElement.accept = accept;
    inputElement.onchange = (e) => handleMediaUploadInternal(e, setFormData, setImagePreview);
  };

  const handleMediaRemove = (index, type) => {
    const updatedMedia = formData.mediaData.filter((_, i) => i !== index || formData.mediaData[i].type !== type);
    setFormData({ ...formData, mediaData: updatedMedia });
  };

  const handleMediaUploadInternal = async (e, setFormData, setImagePreview) => {
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    const maxSize = 100 * 1024 * 1024; // 100MB

    if (!Array.isArray(formData.mediaData)) {
      formData.mediaData = [];
    }

    const imageCount = formData.mediaData.filter(media => media.type === 'image').length;
    const videoCount = formData.mediaData.filter(media => media.type === 'video').length;
    const documentCount = formData.mediaData.filter(media => media.type === 'document').length;

    if (files.length > 3 || totalSize > maxSize) {
      alert('You can upload up to 3 files at a time, with a maximum total size of 100MB.');
      return;
    }

    if (files.some(file => file.type.startsWith('image/') && imageCount >= 3)) {
      alert('You have already selected 3 images. Please remove one before adding another.');
      return;
    }

    if (files.some(file => file.type.startsWith('video/') && videoCount >= 1)) {
      alert('You can only upload 1 video.');
      return;
    }

    if (files.some(file => (file.type === 'application/pdf' || file.type.startsWith('application/msword') || file.type.startsWith('application/vnd.openxmlformats-officedocument')) && documentCount >= 3)) {
      alert('You have already selected 3 documents. Please remove one before adding another.');
      return;
    }

    const encodedFiles = await Promise.all(files.map(file => generateBase64Encode(file)));

    const updatedMediaData = [
      ...formData.mediaData,
      ...files.map((file, index) => ({
        url: URL.createObjectURL(file),
        type: file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : 'document',
        name: file.name,
        file,
        base64: encodedFiles[index],
      }))
    ];

    setFormData({ ...formData, mediaData: updatedMediaData });
    setImagePreview(updatedMediaData.map(media => media.url));

    // Reset the input element to ensure the change event triggers correctly next time
    e.target.value = '';
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newKey = e.target.value.trim();
      if (newKey && (!formData.keys || !formData.keys.includes(newKey))) {
        const updatedKeys = [...(formData.keys || []), newKey];
        if (updatedKeys.length <= 7) {
          setFormData({ ...formData, keys: updatedKeys });
        } else {
          alert('You can only add up to 7 keys.');
        }
      }
      e.target.value = '';
    }
  };

  const removeKey = (index) => {
    const updatedKeys = formData.keys.filter((_, i) => i !== index);
    setFormData({ ...formData, keys: updatedKeys });
  };

  const removeLink = (index) => {
    const updatedLinks = formData.links.filter((_, i) => i !== index);
    setFormData({ ...formData, links: updatedLinks });
    setNumLinks(updatedLinks.length);
  };

  const handleLinkChange = (index, value) => {
    const updatedLinks = [...formData.links];
    updatedLinks[index] = value;
    setFormData({ ...formData, links: updatedLinks });
  };

  const validateLink = (url) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/i;
    return urlPattern.test(url);
  };
  

  const handleHelpDialogOpen = () => {
    setHelpDialogOpen(true);
  };

  const handleHelpDialogClose = () => {
    setHelpDialogOpen(false);
  };

  return (
    <>
      <TextareaAutosize
        className={classes.descriptionField}
        minRows={3}
        maxRows={10}
        placeholder="Thoughts"
        name="description"
        value={formData.description}
        onChange={(e) => handleInputChange(e, formData, setFormData)}
        maxLength={6969}
        required
      />
      <TextField
        className={classes.keysField}
        placeholder="Add Branch Words/Phrases"
        onKeyPress={handleKeyPress}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <GiTreeBranch />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleHelpDialogOpen}>
                <HelpOutlineIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.keysPreview}>
        {(formData.keys || []).map((key, index) => (
          <span key={index} className={classes.keyTag}>
            {key}
            <IconButton size="small" onClick={() => removeKey(index)}>
              &times;
            </IconButton>
          </span>
        ))}
      </div>
      <div className={classes.uploadContainer}>
        <input
          id="image-upload"
          type="file"
          multiple
          style={{ display: 'none' }}
        />
        <label
          htmlFor="image-upload"
          className={classes.uploadLabel}
          onClick={() => handleFileInput('image/*', 'image-upload')}
        >
          <IconButton component="span" color="primary">
            <AddPhotoAlternateIcon fontSize="large" />
          </IconButton>
        </label>
        <input
          id="video-upload"
          type="file"
          multiple
          style={{ display: 'none' }}
        />
        <label
          htmlFor="video-upload"
          className={classes.uploadLabel}
          onClick={() => handleFileInput('video/*', 'video-upload')}
        >
          <IconButton component="span" color="secondary">
            <AddVideoIcon fontSize="large" />
          </IconButton>
        </label>
        <input
          id="document-upload"
          type="file"
          multiple
          style={{ display: 'none' }}
        />
        <label
          htmlFor="document-upload"
          className={classes.uploadLabel}
          onClick={() => handleFileInput('.pdf,.doc,.docx,.ppt,.pptx,.odt,.xlsx,.xls,.ods,.ots,.odp', 'document-upload')}
        >
          <IconButton component="span" color="default">
            <DescriptionIcon fontSize="large" />
          </IconButton>
        </label>
        <IconButton component="span" color="primary" onClick={() => numLinks < 3 && setNumLinks(numLinks + 1)}>
          <LinkIcon fontSize="large" />
        </IconButton>
      </div>
      {[...Array(numLinks)].map((_, index) => (
        <div key={index} className={classes.linkFieldContainer}>
          <TextField
            className={classes.textField}
            label={`Link ${index + 1}`}
            value={formData.links[index] || ''}
            onChange={(e) => handleLinkChange(index, e.target.value)}
            error={!validateLink(formData.links[index])}
            helperText={!validateLink(formData.links[index]) ? 'Invalid URL' : ''}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => removeLink(index)}>
                    &times;
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      ))}
      <div className={classes.previewContainer}>
        {formData.mediaData && formData.mediaData.map((media, index) => (
          <div key={index} className={
            media.type === 'image' ? classes.previewImageContainer :
            media.type === 'video' ? classes.previewVideoContainer :
            classes.previewDocumentContainer
          }>
            {media.type === 'image' && (
              <img src={media.url} alt={`Uploaded Preview ${index + 1}`} className={classes.previewImage} />
            )}
            {media.type === 'video' && (
              <video controls className={classes.previewVideo}>
                <source src={media.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {media.type === 'document' && (
              <a href={media.url} target="_blank" rel="noopener noreferrer" className={classes.previewDocumentLink}>
                {media.name}
              </a>
            )}
            <IconButton onClick={() => handleMediaRemove(index, media.type)} className={classes.removeButton}>
              &times;
            </IconButton>
          </div>
        ))}
      </div>
      <Autocomplete
        className={classes.dropdown}
        options={sourceTypes}
        getOptionLabel={(option) => option}
        onChange={(e, newValue) => setFormData({ ...formData, sourceType: newValue })}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Origin"
            variant="outlined"
            required
          />
        )}
      />
      {formData.sourceType && formData.sourceType !== 'Personal' && (
        <TextField
          className={classes.textField}
          label="Origin Name"
          name="sourceTypeName"
          variant="outlined"
          value={formData.sourceTypeName}
          onChange={(e) => handleInputChange(e, formData, setFormData)}
          required
        />
      )}
      <Autocomplete
        className={classes.dropdown}
        options={Object.keys(domainsByModel)}
        getOptionLabel={(option) => option}
        onChange={(e, newValue) => {
          setSelectedModel(newValue);
          setFormData({ ...formData, domain: '', model: newValue });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Field"
            variant="outlined"
            required
          />
        )}
      />
      {selectedModel && (
        <Autocomplete
          className={classes.dropdown}
          options={domainsByModel[selectedModel]}
          getOptionLabel={(option) => option}
          onChange={(e, newValue) => handleInputChange({ target: { name: 'domain', value: newValue }}, formData, setFormData)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              variant="outlined"
              required
            />
          )}
        />
      )}
      <Dialog open={helpDialogOpen} onClose={handleHelpDialogClose}>
        <DialogContent>
          <HelpInfoDialog />
        </DialogContent> 
        <DialogActions>
          <Button onClick={handleHelpDialogClose} color="primary" className={classes.dialogButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DetailedForm;
