import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { logoutUser } from "../../reducers/userReducer";
import DesktopUserMenu from "../DesktopUserMenu";
import newButtonImage from '../../assets/yep.png';
import { IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    fontSize: "1.2rem",
    fontWeight: 600,
    borderRadius: "2%",
    color: theme.palette.primary.main,
    transition: "0.3s",
    [theme.breakpoints.down("sm")]: {
      width: "42px",
      height: "42px",
      fontSize: "1rem",
      padding: "0px 1px",
    },
  },
  menuButtonBreathing: {
    animation: '$borderAnimation 4s linear infinite',
  },
  "@keyframes borderAnimation": {
    '0%': {
      boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
    },
    '33%': {
      boxShadow: `0 0 7px 5px ${theme.palette.primary.main}`,
    },
    '66%': {
      boxShadow: `0 0 7px 5px ${theme.palette.info.main}`,
    },
    '100%': {
      boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
    },
  },
  fixedTopLeft: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 3,
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      top: "-1%",
    },
  },
  fixedTopRight: {
    position: 'fixed',
    top: "3%",
    right: 0,
    zIndex: 3,
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      top: "0%",
      right: "1%",
    },
  },
  appBar: {
    zIndex: 1,
  },
  logo: {
    zIndex: 1500,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${newButtonImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  clickableArea: {
    width: '50%',
    height: '50%',
    position: 'absolute',
    top: '25%',
    left: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const BigBar = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const handleLogout = () => {
    if (user) {
      dispatch(logoutUser());
      window.location.href = '/start';
    }
  };

  return (
    <div>
  <div className={classes.fixedTopLeft} style={{ width: isMobile ? "80px" : "120px", height: isMobile ? "80px" : "120px" }}>
    <div className={classes.logo}>
      <RouterLink
        to="/"
        className={classes.clickableArea}
      >
        <div></div>
      </RouterLink>
    </div>
  </div>
  <div className={classes.fixedTopRight}>
    <DesktopUserMenu user={user} handleLogout={handleLogout} isMobile={isMobile} />
  </div>
</div>
  );
};

export default BigBar;
