import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  Button,
  Avatar,
} from '@material-ui/core';
import { Input, TextArea } from './StyledComponents'; // Ensure you have these styled components
import PersonIcon from '@material-ui/icons/Person';
import ImageIcon from '@material-ui/icons/Image';
import LockIcon from '@material-ui/icons/Lock';
import GroupAddIcon from '@material-ui/icons/GroupAdd'; // Icon for Friend Requests
import ConversationButtonImage from '../../assets/addconversation.png';
import axios from 'axios';

// Define color palette
const colors = {
  background: '#f5f5f5',
  primary: '#004d40',
  secondary: '#2f4f4f',
  accent: '#0ABAB5', // Tiffany blue
  textPrimary: '#333333',
  textSecondary: '#666666',
  white: '#ffffff',
  lightGray: '#e0e0e0',
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.lightGray};
`;

const StyledButton = styled(Button)`
  && {
    background-color: ${colors.primary};
    color: ${colors.white};
    text-transform: none;
    &:hover {
      background-color: ${colors.secondary};
    }
  }
`;

const FilterButton = styled(Button)`
  && {
    margin: 0.5rem;
    background-color: ${colors.accent};
    color: ${colors.white};
    text-transform: none;
    &:hover {
      background-color: ${colors.primary};
    }
  }
`;

const RoomCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem 0;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.lightGray};
  cursor: pointer;
  color: ${colors.textPrimary};

  img {
    margin-right: 1rem;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
  }

  &:hover {
    background-color: ${colors.background};
  }
`;

const DialogTitleStyled = styled(DialogTitle)`
  && {
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.lightGray};
    text-align: center;
    font-weight: bold;
    color: ${colors.textPrimary};
  }
`;

const DialogActionsStyled = styled(DialogActions)`
  && {
    background-color: ${colors.white};
    border-top: 1px solid ${colors.lightGray};
  }
`;

const TopButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const TopButton = styled(Button)`
  && {
    flex: 1;
    max-width: 200px;
    margin: 0 0.5rem;
    background-color: ${({ active }) =>
      active ? colors.primary : colors.white};
    color: ${({ active }) =>
      active ? colors.white : colors.textPrimary};
    border: 1px solid ${colors.lightGray};
    text-transform: none;
    &:hover {
      background-color: ${({ active }) =>
        active ? colors.secondary : colors.background};
    }
  }
`;

const CreateRoomButton = styled(StyledButton)`
  && {
    margin-top: 1rem;
    width: 100%;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PasswordInputModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PasswordInput = styled.div`
  background-color: ${colors.white};
  padding: 2rem;
  border-radius: 8px;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
    color: ${colors.textPrimary};
  }

  input {
    width: 100%;
    max-width: 300px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid ${colors.lightGray};
    border-radius: 4px;
  }

  button {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-transform: none;
    border-radius: 4px;
    &:hover {
      background-color: ${colors.secondary};
    }
  }
`;

const ConversationButton = styled.button`
  background: url(${ConversationButtonImage}) no-repeat center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
`;

const FriendRequestCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem 0;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.lightGray};
  color: ${colors.textPrimary};
`;

const FriendRequestButton = styled(Button)`
  && {
    margin-left: 0.5rem;
    background-color: ${colors.primary};
    color: ${colors.white};
    text-transform: none;
    &:hover {
      background-color: ${colors.secondary};
    }
  }
`;

const ImageUploadLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${colors.white};
  color: ${colors.textPrimary};
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-transform: none;

  &:hover {
    background-color: ${colors.background};
  }
`;

const OptionsButton = ({
  createRoom,
  handleImageUpload,
  imagePreview,
  newRoomName,
  setNewRoomName,
  password,
  setPassword,
  description,
  setDescription,
  searchName,
  setSearchName,
  searchRoomsByName,
  fetchMostConnectedRooms,
  fetchLeastConnectedRooms,
  fetchMostMessagesRooms,
  filterResults,
  roomUserCounts,
  setSelectedRoom,
  roomsWithPasswords,
  loggedInUser,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('create'); // 'create', 'find', 'friendRequests'
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [roomToJoin, setRoomToJoin] = useState(null);
  const [friendRequests, setFriendRequests] = useState([]);

  useEffect(() => {
    if (activeTab === 'friendRequests') {
      fetchFriendRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const fetchFriendRequests = async () => {
    try {
      const response = await axios.get('/api/friend-requests', {
        params: { username: loggedInUser },
      });
      // Filter friend requests to include only those with status 'pending'
      const pendingRequests = response.data.friendRequestsReceived.filter(
        (request) => request.status === 'pending'
      );
      setFriendRequests(pendingRequests);
    } catch (error) {
      console.error('Error fetching friend requests:', error);
    }
  };

  const handleAcceptFriendRequest = async (requesterUsername) => {
    try {
      await axios.post('/api/accept-friend-request', {
        requesterUsername,
        requestedUsername: loggedInUser,
      });
      // Remove the request from the list
      setFriendRequests((prevRequests) =>
        prevRequests.filter((req) => req.username !== requesterUsername)
      );
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const handleDeclineFriendRequest = async (requesterUsername) => {
    try {
      await axios.post('/api/decline-friend-request', {
        requesterUsername,
        requestedUsername: loggedInUser,
      });
      // Remove the request from the list
      setFriendRequests((prevRequests) =>
        prevRequests.filter((req) => req.username !== requesterUsername)
      );
    } catch (error) {
      console.error('Error declining friend request:', error);
    }
  };

  const handleRoomClick = (room) => {
    if (roomsWithPasswords[room.roomId]) {
      axios
        .get(`/api/chat/${room.roomId}/is-authenticated`, {
          params: { username: loggedInUser },
        })
        .then((response) => {
          if (response.data.isAuthenticated) {
            setSelectedRoom(room.roomId);
          } else {
            setRoomToJoin(room);
            setPasswordModalOpen(true);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedRoom(room.roomId);
    }
  };

  const handlePasswordSubmit = () => {
    if (passwordInput) {
      axios
        .post(`/api/chat/${roomToJoin.roomId}/verify-password`, {
          password: passwordInput,
          username: loggedInUser,
        })
        .then((response) => {
          if (response.data.success) {
            setSelectedRoom(roomToJoin.roomId);
            setPasswordModalOpen(false);
            setPasswordInput('');
          } else {
            alert('Incorrect password. Please try again.');
          }
        })
        .catch((error) => console.error('Error in handlePasswordSubmit:', error));
    }
  };

  return (
    <>
      <ConversationButton onClick={() => setDialogOpen(true)} />
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitleStyled>Create & Find Conversations</DialogTitleStyled>
        <DialogContent>
          <TopButtonsContainer>
            <TopButton
              onClick={() => setActiveTab('create')}
              active={activeTab === 'create'}
            >
              Create Conversation
            </TopButton>
            <TopButton
              onClick={() => setActiveTab('find')}
              active={activeTab === 'find'}
            >
              Find Conversation
            </TopButton>
            <TopButton
              onClick={() => setActiveTab('friendRequests')}
              active={activeTab === 'friendRequests'}
              startIcon={<GroupAddIcon />}
            >
              Friend Requests
            </TopButton>
          </TopButtonsContainer>
          {activeTab === 'create' && (
            <form onSubmit={createRoom}>
              <FormContainer>
                <Input
                  type="text"
                  value={newRoomName}
                  onChange={(e) => setNewRoomName(e.target.value)}
                  placeholder="Conversation Topic"
                  required
                />
                <Input
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password (Optional)"
                />
                <TextArea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Conversation Starter"
                  rows="3"
                />
                <div>
                  <ImageUploadLabel>
                    <ImageIcon />
                    Add Image
                    <HiddenFileInput
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </ImageUploadLabel>
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        marginTop: '0.5rem',
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                        borderRadius: '5px',
                      }}
                    />
                  )}
                </div>
                <CreateRoomButton type="submit">
                  Create Conversation
                </CreateRoomButton>
              </FormContainer>
            </form>
          )}
          {activeTab === 'find' && (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  marginBottom: '1rem',
                }}
              >
                <FilterButton onClick={fetchMostConnectedRooms}>
                  Most Participants
                </FilterButton>
                <FilterButton onClick={fetchLeastConnectedRooms}>
                  Least Participants
                </FilterButton>
                <FilterButton onClick={fetchMostMessagesRooms}>
                  Most Messages
                </FilterButton>
              </div>
              <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
                <Input
                  type="text"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  placeholder="Search by name"
                  style={{ width: '60%', maxWidth: '400px', marginRight: '0.5rem' }}
                />
                <StyledButton onClick={searchRoomsByName}>Search</StyledButton>
              </div>
              <div>
                {filterResults.map((room) => (
                  <RoomCard key={room.roomId} onClick={() => handleRoomClick(room)}>
                    {room.imageData?.imageLink && (
                      <img src={room.imageData.imageLink} alt={room.name} />
                    )}
                    <div style={{ flexGrow: 1 }}>
                      <strong>{room.name}</strong>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PersonIcon style={{ marginRight: '0.25rem' }} />
                      {roomUserCounts[room.roomId] || 0}
                      {roomsWithPasswords[room.roomId] && (
                        <LockIcon style={{ marginLeft: '0.5rem' }} />
                      )}
                    </div>
                  </RoomCard>
                ))}
              </div>
            </div>
          )}
          {activeTab === 'friendRequests' && (
            <div>
              {friendRequests.length > 0 ? (
                friendRequests.map((request) => (
                  <FriendRequestCard key={request.username}>
                    <Avatar
                      alt={request.username}
                      src={request.avatar} // Ensure you have an avatar field or handle it accordingly
                      style={{ marginRight: '1rem' }}
                    />
                    {request.username}
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                      <FriendRequestButton
                        variant="contained"
                        onClick={() => handleAcceptFriendRequest(request.username)}
                      >
                        Accept
                      </FriendRequestButton>
                      <FriendRequestButton
                        variant="contained"
                        onClick={() => handleDeclineFriendRequest(request.username)}
                      >
                        Not Yet
                      </FriendRequestButton>
                    </div>
                  </FriendRequestCard>
                ))
              ) : (
                <p style={{ textAlign: 'center', color: colors.textSecondary }}>
                  No friend requests at the moment.
                </p>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActionsStyled>
          <Button
            onClick={() => setDialogOpen(false)}
            style={{ color: colors.textPrimary }}
          >
            Close
          </Button>
        </DialogActionsStyled>
      </Dialog>
      <PasswordInputModal
        open={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
      >
        <PasswordInput>
          <h2>Enter Password</h2>
          <input
            type="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
          />
          <button onClick={handlePasswordSubmit}>Submit</button>
        </PasswordInput>
      </PasswordInputModal>
    </>
  );
};

export default OptionsButton;
