import axios from 'axios';

export const followUser = async (followerUsername, followingUsername) => {
  try {
    const response = await axios.post('/api/follow', { followerUsername, followingUsername });
    if (response.status === 200) {
      console.log('User followed successfully');
    } else {
      console.error('Failed to follow user');
    }
  } catch (error) {
    console.error('Error following user:', error);
  }
};

export const unfollowUser = async (followerUsername, followingUsername) => {
  try {
    const response = await axios.post('/api/unfollow', { followerUsername, followingUsername });
    if (response.status === 200) {
      console.log('User unfollowed successfully');
    } else {
      console.error('Failed to unfollow user');
    }
  } catch (error) {
    console.error('Error unfollowing user:', error);
  }
};
