// src/components/Pathways/PathwayCard.js

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import PathwayDetail from './PathwayDetail';
import { getPathwayId } from './getPathwayId';

const PathwayCard = ({ pathway }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [username, setUsername] = useState('Public'); // Initialize with 'Public'

  // Initialize theme before using it
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    //console.log('Pathway Object:', pathway); // For debugging
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    if (readifyUserKey) {
      try {
        const parsedKey = JSON.parse(readifyUserKey);
        if (parsedKey && parsedKey.username) {
          setUsername(parsedKey.username);
        }
      } catch (error) {
        console.error('Error parsing readifyUserKey:', error);
        // If parsing fails, retain 'Public' as the username
      }
    }
  }, [pathway]);

  const handleCardClick = async () => {
    try {
      const pathwayId = getPathwayId(pathway);
      if (!pathwayId) {
        console.error('Pathway ID is undefined');
        setPasswordError('Unable to identify the pathway. Please try again later.');
        return;
      }

      // Check if the pathway is password-protected
      const response = await axios.get(`/api/pathways/${pathwayId}/access`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.hasPassword) {
        setPasswordDialogOpen(true);
      } else {
        setDialogOpen(true);
      }
    } catch (error) {
      console.error('Error checking pathway password:', error);
      setPasswordError('An error occurred while checking pathway access. Please try again.');
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setPasswordDialogOpen(false);
    setPassword('');
    setPasswordError('');
  };

  const handlePasswordSubmit = async () => {
    try {
      const pathwayId = getPathwayId(pathway);
      if (!pathwayId) {
        console.error('Pathway ID is undefined');
        setPasswordError('Unable to identify the pathway. Please try again later.');
        return;
      }

      const response = await axios.post(
        `/api/pathways/${pathwayId}/access`,
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.valid) {
        setPasswordDialogOpen(false);
        setDialogOpen(true);
      } else {
        setPasswordError('Invalid password');
      }
    } catch (error) {
      console.error('Error verifying pathway password:', error);
      setPasswordError('An error occurred while verifying the password. Please try again.');
    }
  };

  return (
    <>
      <Card
        onClick={handleCardClick}
        sx={{
          width: 340,
          '@media (max-width:600px)': {
          width: '90%',
          marginLeft: '4.42vw',
          },
          margin: 2,
          borderRadius: 2,
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
          transition: 'transform 0.3s, box-shadow 0.3s',
          cursor: 'pointer',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <CardActionArea>
          {pathway.imageData?.imageLink ? (
            <CardMedia
              component="img"
              height="220"
              image={pathway.imageData.imageLink}
              alt={pathway.goal}
              sx={{
                width: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <CardMedia
              component="img"
              height="220"
              image="/default-image.jpg" // Ensure this default image exists in your public folder
              alt="Default Image"
              sx={{
                width: '100%',
                objectFit: 'cover',
              }}
            />
          )}
          <CardContent
            sx={{
              padding: 3,
              backgroundColor: '#f5f5f5',
              textAlign: 'center',
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                marginBottom: 0.5,
                textAlign: 'center',
                color: '#3c3c3c',
              }}
            >
              {pathway.goal}
            </Typography>
            {/* If you have additional labels or descriptions, include them here */}
          </CardContent>
        </CardActionArea>
      </Card>

      {/* Pathway Detail Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            width: fullScreen ? '100vw' : '90vw',
            height: fullScreen ? '100vh' : '90vh',
            margin: 0,
            maxWidth: 'none',
          },
        }}
        aria-labelledby="pathway-detail-dialog-title"
      >
        <DialogContent
          sx={{
            padding: 0,
            height: '100%',
          }}
        >
          <PathwayDetail pathwayId={getPathwayId(pathway)} onClose={handleCloseDialog} />
        </DialogContent>
      </Dialog>

      {/* Password Dialog */}
      <Dialog
  open={passwordDialogOpen}
  onClose={handleCloseDialog}
  aria-labelledby="pathway-password-dialog-title"
>
  <DialogTitle id="pathway-password-dialog-title" style={{ color: "#004d40", textTransform: "none" }}>
    What's the Password?
  </DialogTitle>
  <DialogContent>
    <TextField
      label="Password"
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      fullWidth
      error={!!passwordError}
      helperText={passwordError}
      autoFocus
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handlePasswordSubmit();
        }
      }}
      InputProps={{
        style: { color: "#004d40", textTransform: "none" }
      }}
      InputLabelProps={{
        style: { color: "#004d40", textTransform: "none" }
      }}
    />
  </DialogContent>
  <DialogActions>
    <Button
      onClick={handleCloseDialog}
      style={{ color: "#004d40", textTransform: "none" }}
    >
      Cancel
    </Button>
    <Button
      onClick={handlePasswordSubmit}
      style={{ backgroundColor: "#004d40", color: "#fffff", textTransform: "none" }}
    >
      Submit
    </Button>
  </DialogActions>
</Dialog>
    </>
  );
};

export default PathwayCard;
