const domainsByModel = {
    'Physics': ['Classical Mechanics',
    'Quantum Mechanics',
    'Thermodynamics',
    'Electromagnetism',
    'Relativity',
    'Nuclear Physics',
    'Particle Physics',
    'Astrophysics',
    'Condensed Matter Physics',
    'Optics',
    'Acoustics',
    'Fluid Dynamics',
    'Plasma Physics',
    'Field Theory',
    'Cosmology',
    'Statistical Mechanics',
    'Computational Physics',
    'Biophysics',
    'Environmental Physics',
    'Medical Physics',
    'Geophysics',
    'Photonics',
    'Nanotechnology',
    'Rockets',
    'Other Physics'
  ],
    'Chemistry': ['Analytical',
    'Biochemistry',
    'Organic',
    'Inorganic',
    'Physical',
    'Theoretical',
    'Environmental',
    'Industrial',
    'Medicinal',
    'Pharmaceutical',
    'Supramolecular',
    'Materials',
    'Nuclear',
    'Polymer',
    'Quantum',
    'Solid State',
    'Surface', 
    'Green',
    'Chemical Engineering',
    'Electrochemistry',
    'Photochemistry',
    'Computational Chemistry',
    'Chemoinformatics',
    'Nanochemistry',
    ],  
    'Biology': [
      'Cell ',
      'Molecular ',
      'Developmental ',
      'Evolutionary ',
      'Genetics',
      'Bioinformatics',
      'Structural ',
      'Physiology',
      'Ecology',
      'Microbiology',
      'Neurobiology',
      'Biophysics',
      'Immunology',
      'Marine ',
      'Botany',
      'Zoology',
      'Conservation ',
      'Entomology',
      'Mycology',
      'Virology',
      'Pharmacology',
      'Toxicology',
      'Endocrinology',
      'Paleobiology',],
    'Astronomy': [
      'Astrophysics',
      'Astronomical Instrumentation',
      'Cosmology',
      'Galactic ',
      'Extragalactic ',
      'Planetary Science',
      'Stellar ',
      'Solar ',
      'Celestial Mechanics',
      'Astrochemistry',
      'Astrobiology',
      'Astrometry',
      'Radio ',
      'Optical ',
      'Infrared ',
      'Ultraviolet ',
      'X-ray ',
      'Gamma-ray ',
      'Space Science',
      'Theoretical ',
      'Observational ',
      'Computational ',
      'Interstellar Medium',
      'High Energy Astrophysics',
      'Dark Matter',
      'Dark Energy',
      'Other Astronomy'],
    'Mathematics': [
      'Algebra',
      'Geometry',
      'Calculus',
      'Analysis',
      'Probability',
      'Statistics',
      'Applied ',
      'Computational ',
      'Numerical Analysis',
      'Algebraic Geometry',
      'Topology',
      'Logic',
      'Number Theory',
      'Dynamical Systems',
      'Differential Equations',
      'Mathematical Physics',
      'Operations Research',
      'Combinatorics',
      'Mathematical Finance',
      'Mathematical Biology',
      'Mathematical Education',
      'History of Mathematics',
      'Discrete ',
      'Theory of Computation',
      'Cryptography',
      'Game Theory',
      'Other Mathematics'],
    'Computers': [
      'Computer Science',
      'Software Engineering',
      'Information Technology',
      'Cybersecurity',
      'Data Science',
      'Artificial Intelligence',
      'Machine Learning',
      'Human-Computer Interaction',
      'Networks and Communications',
      'Databases',
      'Parallel and Distributed Computing',
      'Operating Systems',
      'Computer Graphics',
      'Computer Vision',
      'Web Development',
      'Mobile Computing',
      'Cloud Computing',
      'Quantum Computing',
      'Blockchain',
      'Virtual Reality',
      'Augmented Reality',
      'Computational Biology',
      'Computational Physics',
      'Information Systems',
      'Algorithm Design',
      'Theory of Computation',
      'Cryptography',
      'Game Development',
      'Software Testing',
      'Other Computer Science'],
    'Painting': [
      'Portrait ',
      'Landscape ',
      'Still Life ',
      'Abstract ',
      'Figurative ',
      'Genre ',
      'Historical ',
      'Narrative ',
      'Religious ',
      'Mythological ',
      'Surrealist ',
      'Expressionist ',
      'Impressionist ',
      'Realist ',
      'Neo-Classical ',
      'Romantic ',
      'Cubist ',
      'Fauvist ',
      'Minimalist ',
      'Conceptual ',
      'Pop Art ',
      'Street Art ',
      'Photorealism ',
      'Other Painting '],
    'Sculpting': [
      'Figurative ',
      'Abstract ',
      'Monumental ',
      'Kinetic ',
      'Relief ',
      'Bust ',
      'Equestrian ',
      'Mythological ',
      'Religious ',
      'Public Art ',
      'Installation ',
      'Environmental ',
      'Digital ',
      'Minimalist ',
      'Conceptual ',
      'Neo-Classical ',
      'Modern ',
      'Contemporary ',
      'Ice ',
      'Sand ',
      'Glass ',
      'Metal ',
      'Wood ',
      'Stone ',
      'Other Sculpture '],
    'Literature': [
      'Fiction',
      'Non-Fiction',
      'Poetry',
      'Drama',
      'Fantasy',
      'Science Fiction',
      'Historical Fiction',
      'Romance',
      'Mystery',
      'Thriller',
      'Horror',
      'Biography',
      'Autobiography',
      'Memoir',
      'Essay',
      'Critique',
      'Journalism',
      'Classic Literature',
      'Modern Literature',
      'Postmodern Literature',
      'Experimental Literature',
      'Graphic Novel',
      'Young Adult',
      'Youth ',
      'Other Literature '],
    'Music': [
      'Classical Music',
      'Jazz',
      'Blues',
      'Rock',
      'Metal',
      'Punk',
      'Pop',
      'Hip-Hop',
      'Rap',
      'Electronic',
      'Country',
      'Folk',
      'Reggae',
      'Soul',
      'Funk',
      'Gospel',
      'R&B',
      'World ',
      'Latin ',
      'Opera',
      'Soundtrack',
      'Experimental ',
      'Ambient ',
      'Indie ',
      'Other Music '],
    'Theatre': ['Drama',
    'Comedy',
    'Musical ',
    'Opera',
    'Ballet',
    'Physical ',
    'Puppetry',
    'Mime',
    'Improvisational ',
    'Stand-up Comedy',
    'Cabaret',
    'Circus',
    'Experimental ',
    'Street ',
    'Children’s ',
    'Classical ',
    'Historical ',
    'Epic ',
    'Absurdist ',
    'Horror ',
    'Fantasy ',
    'Science Fiction ',
    'Tragedy',
    'Docudrama',
    'Other Theatre Styles'],
    'Architecture': [
      'Classical ',
      'Gothic ',
      'Renaissance ',
      'Baroque ',
      'Neoclassical ',
      'Romantic ',
      'Victorian ',
      'Modern ',
      'Contemporary ',
      'Futurist ',
      'Brutalist ',
      'Postmodern ',
      'Deconstructivism',
      'Eco-Friendly ',
      'Sustainable ',
      'Green Building',
      'High-Tech ',
      'Minimalist ',
      'Expressionist ',
      'Art Nouveau ',
      'Art Deco ',
      'Bauhaus ',
      'Regional ',
      'Vernacular ',
      'Other Architecture '],
    'Cinema': [
      'Action',
      'Comedy',
      'Drama',
      'Fantasy',
      'Horror',
      'Mystery',
      'Romance',
      'Science Fiction',
      'Thriller',
      'Western',
      'Documentary',
      'Animation',
      'Biographical',
      'Crime',
      'Historical',
      'Musical',
      'War',
      'Adventure',
      'Sports',
      'Family',
      'Noir',
      'Experimental',
      'Art House',
      'Silent Cinema',
      'Other Cinema '],
    'DigitalArt': [
      'Digital Painting',
      '3D Modeling',
      'Digital Sculpture',
      'Vector Art',
      'Pixel Art',
      'Digital Illustration',
      'Concept Art',
      'Character Design',
      'Environmental Design',
      'Motion Graphics',
      'Digital Collage',
      'Photo Manipulation',
      'Web Art',
      'Interactive Art',
      'Generative Art',
      'Virtual Reality Art',
      'Augmented Reality Art',
      'Data Visualization',
      'Digital Installation',
      'Game Art',
      'NFT Art',
      'UI/UX Design',
      'Typography',
      '3D Animation',
      'Other Digital Art'],
    'Nature': [
      'Mountains',
      'Rivers',
      'Forests',
      'Deserts',
      'Oceans',
      'Lakes',
      'Wetlands',
      'Prairies',
      'Canyons',
      'Waterfalls',
      'Glaciers',
      'Volcanoes',
      'Coral Reefs',
      'Islands',
      'Caves',
      'Fjords',
      'Rainforests',
      'Mangroves',
      'Sand Dunes',
      'Estuaries',
      'Savannas',
      'Tundra',
      'Bays',
      'Cliffs',
      'Delta',
      'Geysers',
      'Hot Springs',
      'Icebergs',
      'Marshes',
      'Other Nature'],
    'Fashion': [
      'Activewear',
      'Haute Couture',
      'Luxury Fashion',
      'Streetwear',
      'Eco-Fashion',
      'Vintage Fashion',
      'Bridal Wear',
      'Workwear',
      'Casual Wear',
      'Formal Wear',
      'Sportswear',
      'Knitwear',
      'Denim',
      'Leather Goods',
      'Accessories',
      'Footwear',
      'Jewelry',
      'Bags and Purses',
      'Eyewear',
      'Headwear',
      'Swimwear',
      'Lingerie',
      'Maternity Wear',
      'Pet Fashion',
      'Techwear',
      'Outerwear',
      'Bespoke Tailoring',
      'Couture',
      'Prêt-à-Porter',
      'Artisanal ',
      'Sustainable ',
      'Vintage & Heritage',
      'Urban Style',
      'Green ',
      'Fair Trade Apparel',
      'Slow Crafted',
      'Heritage Textiles',
      'Avant-Garde',
      'Seasonal Collections',
      'Capsule Wardrobes',
      'Evening Wear',
      'Tailored Suits',
      'High Fashion Accessories',
      'Handcrafted Jewelry',
      'Designer Footwear',
      'Innovative Textiles',
      'Fashion Tech',
      'Wearable Art',
      'Cultural Attire',
      'Timeless Classics',
      'Upcycled Fashion',
      'Minimalist Wardrobe',
      'Gender Fluid Fashion',
      'Conceptual Fashion',
      'Eco-Conscious Collections',
      'Other Fashion'],
    'Travel': [
      'Adventure Travel',
      'Cultural Exploration',
      'Eco-Tourism',
      'Luxury Travel',
      'Budget Backpacking',
      'Culinary Tours',
      'Historical Sites',
      'Beach Holidays',
      'Ski Resorts',
      'Cruise Vacations',
      'Wellness Retreats',
      'Wildlife Safaris',
      'City Breaks',
      'Road Trips',
      'Volunteer Tourism',
      'Spiritual Journeys',
      'Island Hopping',
      'Underwater Adventures',
      'Mountain Climbing',
      'River Cruising',
      'Desert Expeditions',
      'Polar Expeditions',
      'Rainforest Excursions',
      'Agritourism',
      'Festival Travel',
      'Photography Tours',
      'Archaeological Tours',
      'Family Vacations',
      'Solo Travel',
      'Romantic Getaways',
      'Educational Travel',
      'Sporting Events',
      'Business Travel',
      'Accessible Travel',
      'Off-the-Beaten-Path'],
    'Countries': ['Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo (Congo-Brazzaville)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Democratic Republic of the Congo',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini (fmr. "Swaziland")',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar (formerly Burma)',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Korea',
    'North Macedonia (formerly Macedonia)',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine State',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe'],
    'Parenting': [
      'Newborn Care',
      'Toddler Development',
      'Child Nutrition',
      'Education Planning',
      'Behavioral Guidance',
      'Health & Wellness',
      'Parent-Child Bonding',
      'Safety & First Aid',
      'Sleep Training',
      'Potty Training',
      'Language Development',
      'Emotional Intelligence',
      'Play & Creativity',
      'Digital Parenting',
      'Special Needs Support',
      'Teenage Challenges',
      'Family Dynamics',
      'Parental Wellbeing',
      'Financial Planning for Families',
      'Legal Guardianship',
      'Adoption & Fostering',
      'Single Parenting',
      'Co-Parenting Strategies',
      'Cultural & Spiritual Education',
      'Outdoor Activities & Nature',
      'Travel with Kids',
      'Crafts & DIY Projects',
      'Parent Support Networks',
      'Home Schooling',
      'Discipline & Limits',
      'Celebrating Milestones',
      'Parenting Styles',
      'Work-Life Balance',
      'Healthcare Navigation',
      'Nutrition & Cooking',
      'Physical Fitness for Families'],
    'Beauty': ['Skincare Routines',
    'Makeup Artistry',
    'Haircare Techniques',
    'Nail Care & Art',
    'Facial Treatments',
    'Body Care & Treatments',
    'Beauty Tools & Gadgets',
    'Cosmetic Ingredients',
    'Dermatological Solutions',
    'Anti-Aging Strategies',
    'Natural & Organic Beauty',
    'Beauty Supplements',
    'Fragrances & Perfumery',
    'Men’s Grooming',
    'Ethical & Sustainable Beauty',
    'DIY Beauty Recipes',
    'Beauty Trends & Innovations',
    'Cultural Beauty Practices',
    'Beauty Industry Insights',
    'Beauty Education & Careers',
    'Personal Styling',
    'Beauty & Fashion Synergy',
    'Event Makeup & Styling',
    'Beauty Therapy & Wellness',
    'Cosmetic Procedures',
    'Beauty Brands & Reviews',
    'Beauty Communities',
    'Skin Health & Nutrition',
    'Makeup for Different Occasions',
    'Beauty Subscription Services',
    'Self-Care Practices',
    'Beauty on a Budget',
    'Inclusive Beauty',
    'Beauty Travel Kits'],
    'Sports': [
      'Team Sports & Tactics',
      'Individual Sports Techniques',
      'Strength & Conditioning Training',
      'Sports Nutrition & Supplements',
      'Sports Medicine & Injuries',
      'Physical Rehabilitation & Recovery',
      'Sports Psychology & Motivation',
      'Outdoor & Adventure Sports',
      'Fitness & Personal Training',
      'Youth Sports Development',
      'Sports Equipment & Gear',
      'Athletic Performance Analysis',
      'Sports Science & Research',
      'Professional Sports Careers',
      'Sports Coaching & Strategy',
      'Sports Event Management',
      'Sports Marketing & Sponsorships',
      'Sports Law & Ethics',
      'Sports Technology & Innovation',
      'Extreme Sports & Challenges',
      'Water Sports & Activities',
      'Winter Sports & Snow Activities',
      'Equestrian Sports & Training',
      'Motorsports & Racing',
      'Sports History & Culture',
      'Sports Fan Communities',
      'Sports Broadcasting & Media',
      'Sports Betting & Fantasy Leagues',
      'Olympic Sports & International Competitions',
      'Mind Sports & Strategy Games',
      'Sports Philanthropy & Community Engagement',
      'Inclusive & Adaptive Sports',
      'Sports Travel & Destinations',
      'Varsity & Collegiate Sports'],
    'Health': ['Nutritional Science & Dietetics',
    'Physical Fitness & Exercise',
    'Mental Health & Wellbeing',
    'Public Health Initiatives',
    'Chronic Disease Management',
    'Preventive Healthcare',
    'Alternative & Complementary Medicine',
    'Pediatric Health & Development',
    'Geriatric Care & Aging',
    'Women’s Health Issues',
    'Men’s Health Issues',
    'Sexual & Reproductive Health',
    'Dental Health & Oral Care',
    'Vision Care & Ophthalmology',
    'Dermatology & Skin Health',
    'Pharmaceuticals & Medications',
    'Medical Research & Innovations',
    'Healthcare Policy & Ethics',
    'Health Technology & Digital Tools',
    'Personal Health Monitoring',
    'Sleep Science & Sleep Health',
    'Occupational Health & Safety',
    'Environmental Health & Wellness',
    'Global Health Challenges',
    'Health Education & Literacy',
    'Emergency Medicine & First Aid',
    'Surgical Advances & Procedures',
    'Hospital & Clinical Care',
    'Health Insurance & Financing',
    'Mental Disorders & Treatment',
    'Addiction & Recovery',
    'Holistic Health Practices',
    'Family Health & Dynamics',
    'Personalized Medicine & Genetics'],
    'Cooking': [
      'Culinary Techniques & Skills',
      'International Cuisine & Recipes',
      'Baking & Pastry Arts',
      'Food Science & Nutrition',
      'Kitchen Equipment & Gadgets',
      'Meal Planning & Prep',
      'Food Preservation & Canning',
      'Fermentation & Brewing',
      'Dietary Restrictions & Allergies',
      'Sustainable Cooking & Zero Waste',
      'Cooking for Health & Wellness',
      'Spices & Seasonings',
      'Food Styling & Photography',
      'Cooking Classes & Education',
      'Food Trends & Innovations',
      'Restaurant Reviews & Dining Out',
      'Home Cooking & Family Meals',
      'Cookbooks & Recipe Development',
      'Celebrity Chefs & Culinary Personalities',
      'Food Competitions & Shows',
      'Wine Pairing & Sommelier Studies',
      'Cocktails & Mixology',
      'Coffee & Tea Brewing',
      'Vegetarian & Vegan Cooking',
      'Barbecue & Grilling',
      'Seafood Preparations',
      'Desserts & Sweet Treats',
      'Snacks & Appetizers',
      'Holiday & Seasonal Recipes',
      'Culinary Travel & Food Destinations',
      'Farm-to-Table Movements',
      'Food Safety & Hygiene',
      'Ethnic Food Markets & Ingredients'],
    'PersonalFinance': [
      'Budgeting & Expense Tracking',
      'Saving Strategies & Accounts',
      'Investing Basics & Strategies',
      'Retirement Planning & Accounts',
      'Tax Planning & Filing',
      'Credit Scores & Reports',
      'Debt Management & Reduction',
      'Insurance Policies & Coverage',
      'Real Estate & Mortgages',
      'Estate Planning & Wills',
      'Personal Finance Software & Tools',
      'Financial Planning & Advice',
      'Wealth Building & Management',
      'Frugal Living & Saving Tips',
      'Cryptocurrency & Digital Assets',
      'Side Hustles & Additional Income',
      'Financial Independence & Early Retirement',
      'College Savings & Education Funds',
      'Family Finance & Money Management',
      'Economic Trends & Personal Impact',
      'Consumer Rights & Protection',
      'Banking Services & Products',
      'Loan Types & Considerations',
      'Charitable Giving & Philanthropy',
      'Travel Hacking & Rewards Programs',
      'Credit Cards & Rewards Strategies',
      'Job Negotiation & Salary Increases',
      'Financial Literacy Education',
      'Emergency Funds & Safety Nets',
      'Healthcare Costs & HSA Accounts',
      'Mobile Payment Systems & Technology',
      'Peer-to-Peer Lending & Crowdfunding',
      'Inflation & Purchasing Power',
      'International Finance & Currency Exchange'],
    'Stocks': [
      'Stock Market Fundamentals',
      'Equity Research & Analysis',
      'Technical Analysis & Chart Patterns',
      'Dividend Investing & Income',
      'Growth vs. Value Stocks',
      'ETFs & Mutual Funds Investing',
      'Portfolio Management Strategies',
      'Risk Management in Investing',
      'Market Trends & Economic Indicators',
      'International Stock Markets',
      'Sector & Industry Analysis',
      'Small-Cap vs. Large-Cap Stocks',
      'Trading Psychology & Investor Behavior',
      'Options Trading & Strategies',
      'Futures & Commodities Trading',
      'Real Estate Investment Trusts (REITs)',
      'Sustainable & ESG Investing',
      'IPOs & New Stock Listings',
      'Mergers & Acquisitions Impact',
      'Stock Market History & Crashes',
      'Financial Statements & Ratios',
      'Short Selling & Market Speculation',
      'Insider Trading & Market Manipulation',
      'Stock Brokers & Trading Platforms',
      'Dividend Reinvestment Plans (DRIPs)',
      'Tax Implications of Stock Investing',
      'Market Regulation & Compliance',
      'Quantitative Analysis & Algorithms',
      'Blockchain & Stock Market Applications',
      'Angel Investing & Venture Capital',
      'Market Sentiment & News Analysis',
      'Stock Market Education & Training',
      'Penny Stocks & Micro-Cap Investing',
      'Investment Clubs & Communities',
      'Financial Advisors & Wealth Management'
    ],
    'Crypto': [
      'Cryptocurrency Basics & Concepts',
      'Blockchain Technology & Applications',
      'Bitcoin & Altcoins',
      'Crypto Trading & Investment Strategies',
      'DeFi (Decentralized Finance) & Protocols',
      'NFTs (Non-Fungible Tokens) & Digital Art',
      'Crypto Mining & Consensus Mechanisms',
      'Wallets & Crypto Storage Solutions',
      'Smart Contracts & DApps (Decentralized Applications)',
      'Crypto Exchanges & Trading Platforms',
      'ICO (Initial Coin Offering) & Token Sales',
      'Regulatory & Legal Landscape in Crypto',
      'Crypto Security & Fraud Prevention',
      'Cryptoeconomics & Tokenomics',
      'Layer 1 & Layer 2 Solutions',
      'Cross-Chain Technology & Interoperability',
      'Crypto Taxation & Reporting',
      'DAOs (Decentralized Autonomous Organizations)',
      'Stablecoins & Digital Currencies',
      'Crypto Payment Systems & Gateways',
      'Privacy Coins & Anonymity Features',
      'Crypto Lending & Borrowing',
      'Yield Farming & Liquidity Mining',
      'Crypto Philanthropy & Donations',
      'Educational Resources in Cryptocurrency',
      'Future Trends in Crypto & Speculations',
      'Community & Social Impact Projects',
      'Technical Analysis in Crypto Markets',
      'Crypto Market Sentiment & Psychology',
      'Blockchain in Supply Chain & Logistics',
      'Crypto in Gaming & Virtual Realities',
      'Environmental Impact of Cryptocurrency',
      'Innovations in Crypto & Emerging Technologies',
      'Global Adoption & Cryptocurrency Use Cases'],
    'Technology': [
      'Healthcare Diagnostics',
      'Education Platforms',
      'Financial Security',
      'Communication Networks',
      'Food Production',
      'Energy Sustainability',
      'Transportation Efficiency',
      'Home Automation',
      'Workplace Automation',
      'Environmental Monitoring',
      'Disaster Response',
      'Public Safety',
      'Digital Identity',
      'Social Interaction',
      'Cultural Preservation',
      'Mental Health Support',
      'Physical Fitness',
      'Leisure & Entertainment',
      'Community Building',
      'Accessibility Solutions'],
    'Gaming': [
      'Action & Adventure Games',
      'Role-Playing Games (RPGs)',
      'First-Person Shooters (FPS)',
      'Strategy & Simulation Games',
      'Sports & Racing Games',
      'Multiplayer Online Battle Arenas (MOBAs)',
      'Massively Multiplayer Online Games (MMOs)',
      'Puzzle & Logic Games',
      'Indie Games',
      'Virtual Reality (VR) Games',
      'Augmented Reality (AR) Games',
      'Mobile Gaming',
      'Retro & Classic Gaming',
      'Esports Competitions',
      'Game Streaming & Content Creation',
      'Gaming Hardware & Peripherals',
      'Game Development & Design',
      'Gaming Communities & Forums',
      'Gaming Events & Conventions',
      'Educational & Serious Games'],
    'Politics': [
      'Political Theories & Ideologies',
      'Election Campaigns & Strategies',
      'Voting Systems & Electoral Reform',
      'Political Parties & Movements',
      'Legislative Processes & Governance',
      'International Relations & Diplomacy',
      'Public Policy & Social Welfare',
      'Human Rights & Civil Liberties',
      'Environmental Policy & Climate Change',
      'Economic Policy & Development',
      'Defense & National Security',
      'Political Communication & Media',
      'Political Corruption & Accountability',
      'Judicial Systems & Constitutional Law',
      'Local & Municipal Politics',
      'Globalization & World Politics',
      'Activism & Grassroots Movements',
      'Political History & Analysis',
      'Political Ethics & Philosophy',
      'Public Administration & Bureaucracy',
      'Political Science Research & Theory',
      'Political Psychology & Behavior',
      'Conflict Resolution & Peace Studies',
      'Immigration Policy & Integration',
      'Healthcare Policy & Reform',
      'Education Policy & Innovation',
      'Technology & Digital Policy',
      'Trade Policies & International Economics',
      'Political Leadership & Decision Making',
      'Minority Rights & ReSendation',
      'Political Satire & Commentary',
      'Political Campaign Finance',
      'Geopolitical Strategies & Analysis'
    ],
    'CurrentEvents': [
      'Global Health Crises',
      'International Conflicts & Wars',
      'Climate Change & Environmental Disasters',
      'Economic Trends & Financial Markets',
      'Technological Breakthroughs & Innovations',
      'Major Political Elections & Changes',
      'Social Justice Movements & Protests',
      'Key Legal Trials & Justice Issues',
      'Cultural & Entertainment Milestones',
      'Sports Championships & Events',
      'Scientific Discoveries & Research',
      'Educational Reforms & Developments',
      'Cybersecurity Threats & Data Breaches',
      'Space Exploration Missions & Discoveries',
      'Trade Agreements & Economic Policies',
      'Energy Developments & Sustainability Efforts',
      'Healthcare Advances & Policy Changes',
      'Immigration & Refugee Crises',
      'Artificial Intelligence & Ethical Debates',
      'Blockchain & Cryptocurrency Developments',
      'Virtual & Augmented Reality Applications',
      'Social Media Trends & Digital Culture',
      'Automotive Innovations & Electric Vehicles',
      'Fashion Trends & Industry Shifts',
      'Music & Film Industry Highlights',
      'Literary Awards & Book Releases',
      'Philanthropy & Humanitarian Efforts',
      'International Summits & Diplomatic Meetings',
      'Infrastructure Projects & Urban Development',
      'Public Safety & Law Enforcement Challenges',
      'Religious Events & Interfaith Dialogues',
      'Tourism Trends & Travel Restrictions',
      'Agricultural Advances & Food Security',
      'Retail & E-commerce Shifts',
      'Workforce Trends & Remote Work Dynamics'
    ],
    'Religion': [
      'Christianity',
      'Islam',
      'Hinduism',
      'Buddhism',
      'Sikhism',
      'Judaism',
      'Morman',
      'Catholocism',
      'Baha\'i Faith',
      'Jainism',
      'Shinto',
      'Taoism',
      'Confucianism',
      'Zoroastrianism',
      'Paganism',
      'Wicca',
      'Rastafarianism',
      'Unitarian Universalism',
      'Scientology',
      'Tenrikyo',
      'Cao Dai',
      'Vodou',
      'Santería',
      'Indigenous Traditional Religions',
      'New Religious Movements',
      'Atheism',
      'Agnosticism',
      'Druidism',
      'Neo-Paganism',
      'Pantheism',
      'Satanism',
      'Shamanism',
      'Spiritism',
      'Thelema',
      'Gnosticism',
      'Sufism',
      'Kabbalah',
      'Falun Gong',
      'Raëlism',
      'Pastafarianism',
      'Cargo Cults',
      'Animism',
      'Polytheism',
      'Monotheism',
      'Dualism',
      'Nontheism',
      'Eastern Orthodoxy',
      'Roman Catholicism',
      'Protestantism',
      'Orthodox Judaism',
      'Conservative Judaism',
      'Reform Judaism',
      'Theravada Buddhism',
      'Mahayana Buddhism',
      'Vajrayana Buddhism',
      'Shi\'a Islam',
      'Sunni Islam',
      'Ibadi Islam',
      'Ahmadiyya Islam',
      'Druze',
      'Yoruba Religion',
      'Hellenism',
      'Kemeticism',
      'Asatru',
      'Slavic Native Faith',
      'Romuva',
      'Tengrism',
      'Zuni Religion',
      'Hopi Religion',
      'Inca Religion',
      'Maya Religion',
      'Australian Aboriginal Religions',
      'Maori Religion',
      'Sami Shamanism',
      'Tibetan Bon',
      'Yazidism',
      'Mandaeism',
      'Manichaeism',
      'Alawites',
      'Alevism',
      'Universal Sufism',
      'Eckankar',
      'Oomoto',
      'Platonic Solids',
      'Hermeticism',
      'Rosicrucianism',
      'Freemasonry',
      'Theosophy',
      'Anthroposophy',
      'Nichiren Buddhism',
      'Pure Land Buddhism',
      'Zen Buddhism',
      'Shingon Buddhism',
      'Tendai Buddhism',
      'Won Buddhism',
      'Hoahaoism',
      'Chondogyo',
      'Mazdaznan',
      'Zuism'
    ],
    'Home': [
      'Safety',
      'Security',
      'Comfort',
      'Privacy',
      'Organization',
      'Hygiene',
      'Maintenance',
      'Energy Efficiency',
      'Sustainability',
      'Accessibility',
      'Aesthetics',
      'Space Utilization',
      'Noise Control',
      'Temperature Control',
      'Lighting',
      'Ventilation',
      'Indoor Air Quality',
      'Family Dynamics',
      'Community Engagement',
      'Neighborhood',
      'Home Insurance',
      'Emergency Preparedness',
      'Budgeting',
      'Financial Planning',
      'Homeownership',
      'Renting',
      'Renovation',
      'Interior Design',
      'Furniture Arrangement',
      'Appliance Selection',
      'Technology Integration',
      'Property Value',
      'Resale Potential',
      'Local Regulations',
      'Homeowners Association Rules',
      'Utility Bills',
      'Waste Management',
      'Green Spaces',
      'Outdoor Living',
      'Home Entertainment',
      'Home Office Setup',
      'Remote Work',
      'Learning Environment',
      'Child Safety',
      'Pet-Friendly Features',
      'Natural Disasters Preparedness',
      'Emergency Evacuation Plans',
      'Home Healthcare',
      'Aging in Place',
      'Multigenerational Living',
      'Cultural Considerations',
      'Religious Practices at Home',
      'Customs and Traditions',
      'Celebrations and Festivities',
      'Memorabilia and Keepsakes',
      'Sentimental Value',
      'Personalization',
      'Memories and Sentiments',
      'Home Security Systems',
      'Smart Home Technology',
      'Internet Connectivity',
      'Home Automation',
      'Surveillance',
      'Privacy Concerns',
      'Legal Considerations',
      'Property Taxes',
      'Residence Permits',
      'Home Loans',
      'Mortgages',
      'Debt Management',
      'Homeownership Assistance Programs'
    ],
    'Volunteering': ['Teaching',
    'Mentoring',
    'Tutoring',
    'Coaching',
    'Fundraising',
    'Event Planning',
    'Community Outreach',
    'Food Distribution',
    'Homeless Shelter Assistance',
    'Soup Kitchen Volunteering',
    'Elderly Care',
    'Youth Programs',
    'Animal Shelter Volunteering',
    'Environmental Cleanup',
    'Conservation Projects',
    'Disaster Relief',
    'Blood Donation',
    'Organ Donation',
    'Medical Mission Trips',
    'International Aid',
    'Refugee Assistance',
    'Human Rights Advocacy',
    'Legal Aid',
    'Crisis Counseling',
    'Hotline Volunteering',
    'Hospice Volunteering',
    'Hospital Volunteering',
    'Library Volunteering',
    'Museum Volunteering',
    'Park Cleanup',
    'Prison Volunteering',
    'Literacy Programs',
    'Special Olympics Volunteering',
    'Veterans Assistance',
    'Health Education',
    'Community Garden Volunteering',
    'Art Therapy',
    'Music Therapy',
    'Dance Therapy',
    'Sports Coaching for Special Needs',
    'Disability Support',
    'Sustainable Development Projects',
    'Microfinance',
    'Social Entrepreneurship',
    'Fair Trade Advocacy',
    'Gender Equality Initiatives',
    'Youth Empowerment Programs',
    'STEM Education Outreach',
    'Adult Education Programs',
    'Job Training Programs',
    'Economic Development Projects',
    'Public Health Campaigns',
    'Public Policy Advocacy',
    'Peacebuilding Initiatives',
    'Interfaith Dialogue',
    'Cultural Exchange Programs',
    'Volunteer Coordination',
    'Grant Writing',
    'Marketing and Promotion for Nonprofits',
    'Event Photography/Videography for Nonprofits',
    'Graphic Design for Nonprofits',
    'Web Development for Nonprofits',
    'Translation Services for Nonprofits',
    'Volunteering for Political Campaigns',
    'Community Organizing',
    'Social Media Management for Nonprofits',
    'Data Entry and Analysis for Nonprofits',
    'Transportation Assistance for Seniors or Disabled Individuals',
    'Home Repair and Maintenance for Low-Income Families',
    'Legal Clinics',
    'Mobile Health Clinics',
    'Public Speaking and Advocacy',
    'Practical Skills Training Workshops',
    'Food Banks',
    'Community Centers',
    'Shelter Construction and Maintenance',
    'Refugee Resettlement Support',
    'Mentoring Ex-Convicts',
    'Environmental Education Programs',
    'Park Ranger Volunteering',
    'Animal Conservation Projects',
    'Volunteer Firefighting',
    'Emergency Medical Response Teams',
    'International Humanitarian Trips',
    'Community Radio Volunteering',
    'Theater or Arts Programs for At-Risk Youth',
    'Community Sports Programs',
    'School Support Programs',
    'Refugee Tutoring and Mentoring',
    'Digital Literacy Training for Underserved Communities'
  ],
    'College': [
      'Lectures',
      'Exams',
      'Completing Assignments',
      'Discussions',
      'Studying',
      'Reading Textbooks',
      'Writing Papers',
      'Researching',
      'Seminars',
      'Joining Clubs and Organizations',
      'Networking',
      'Interning',
      'Volunteering',
      'Part-Time Jobs',
      'Workshops',
      'Career Fairs',
      'Conferences',
      'Sending Research',
      'Collaborating on Projects',
      'Office Hours',
      'Study Groups',
      'Peer Tutoring',
      'Mentoring',
      'Cultural Events',
      'Sports',
      'Fitness Classes',
      'Campus Events',
      'Student Government',
      'Guest Lectures',
      'Hackathons',
      'Studying Abroad',
      'College Parties',
      'Community Service Projects',
      'Joining Honor Societies',
      'Research Assistantships',
      'Joining Professional Organizations',
      'Diversity and Inclusion Programs',
      'Career Workshops',
      'Academic Advising Sessions',
      'Language Exchanges',
      'Counseling Services',
      'Seeking Mentorship from Faculty',
      'Financial Aid Workshops',
      'Alumni Events',
      'Alumni Networking Events',
      'Career Counseling',
      'Resume Workshops',
      'Leadership Development Programs',
      'Campus Tours',
      'Peer Support Groups',
      'Health and Wellness Workshops',
      'Study Abroad Information Sessions',
      'Academic Success Workshops',
      'Student Research Symposia',
      'Graduation Ceremonies',
      'Graduation Parties',
      'Graduate School Fairs',
      'Pre-Professional Programs',
      'Alumni Reunions',
      'Job Shadowing',
      'Career Panels',
      'Mock Interviews',
      'Graduate School Preparation Workshops',
      'Leadership Retreats',
      'College Tours',
      'Peer Mentorship Programs',
      'Financial Planning Workshops',
      'Study Skills Workshops',
      'Academic Advising Workshops',
      'Career Development Programs',
      'Alumni Mentorship Programs',
      'Research Conferences',
      'Graduate School Information Sessions',
      'Thesis/Dissertation Defenses',
      'Student Awards Ceremonies',
      'Academic Honesty Workshops',
      'Graduate School Application Workshops',
      'Industry Networking Events',
      'Portfolio Reviews',
      'Grant Writing Workshops',
      'Professional Development Seminars'
    ],
    'Career': [
      'Job Searching',
      'Networking',
      'Resume Writing',
      'Cover Letter Writing',
      'Interviewing',
      'Negotiating Job Offers',
      'Professional Development',
      'Skill Building',
      'Conferences',
      'Workshops',
      'Taking Online Courses',
      'Training Programs',
      'Mentorship',
      'Career Counseling',
      'Career Fairs',
      'Professional Organizations',
      'Seminars',
      'Reading Professional Literature',
      'Following Industry Trends',
      'Professional Portfolio',
      'Personal Brand',
      'Online Presence',
      'Webinars',
      'Continuing Education Programs',
      'Obtaining Certifications',
      'Open Source Projects',
      'Meetups',
      'Side Projects',
      'Freelancing',
      'Starting a Business',
      'Consulting',
      'Taking on Leadership Roles',
      'Publishing Research',
      'Sending at Conferences',
      'Writing Articles or Blogs',
      'Giving Talks or Lectures',
      'Mentoring Others',
      'Networking Events',
      'Professional Panels',
      'Toastmasters or Public Speaking Clubs',
      'Career Coaching',
      'Professional Network',
      'Feedback',
      'Updating Skills Regularly',
      'Career Goals',
      'Tracking Achievements',
      'Building Relationships with Colleagues',
      'Cross-Training Opportunities',
      'Industry Events',
      'Performance Reviews',
      'Development Plans',
      'Stretch Assignments',
      'Leadership Development Programs',
      'Employee Resource Groups',
      'Taking on Special Projects',
      'Continuous Learning',
      'Job Rotation Opportunities',
      'Mentorship Network',
      'Personal Learning Plan',
      'Professional Development Workshops',
      'Industry Associations',
      'Strategic Planning',
      'International Opportunities',
      'Executive Education Programs',
      'Feedback Sessions',
      'Diversity and Inclusion Committees',
      'Career Development Plan',
      'Job Shadowing Opportunities',
      'Executive Coaching Sessions',
      'Performance Improvement Programs',
      'Employee Engagement Initiatives',
      'Succession Planning',
      'Skill Enhancement Programs',
      'Knowledge Sharing Network',
      'Succession Plan',
      'Employee Recognition Events',
      'Team ctivities',
      'Strategic Planning Committees',
      'Change Management Initiatives'
    ],
    'Relationships': [
      'Communication',
      'Spending Quality Time Together',
      'Active Listening',
      'Showing Appreciation',
      'Expressing Affection',
      'Supporting Each Other',
      'Resolving Conflicts',
      'Compromising',
      'Building Trust',
      'Setting Boundaries',
      'Respecting Differences',
      'Sharing Responsibilities',
      'Making Decisions Together',
      'Planning for the Future',
      'Celebrating Milestones',
      'Creating Rituals and Traditions',
      'Fostering Intimacy',
      'Maintaining Independence',
      'Being Honest',
      'Forgiving and Letting Go',
      'Being Patient',
      'Showing Empathy',
      'Understanding Each Other\'s Love Languages',
      'Managing Finances Together',
      'Navigating Life Transitions Together',
      'Supporting Each Other\'s Goals',
      'Being Flexible',
      'Taking Interest in Each Other\'s Hobbies and Interests',
      'Respecting Privacy',
      'Being Open-Minded',
      'Being Reliable and Dependable',
      'Being Grateful',
      'Showing Compassion',
      'Respecting Each Other\'s Space',
      'Embracing Change Together',
      'Adapting to Challenges Together',
      'Making Time for Each Other',
      'Building and Maintaining Friendships Together',
      'Laughing Together',
      'Having Fun Together',
      'Going on Dates',
      'Surprising Each Other',
      'Exploring New Things Together',
      'Supporting Each Other\'s Personal Growth',
      'Being Vulnerable',
      'Creating Shared Goals',
      'Nurturing Emotional Connection',
      'Expressing Gratitude',
      'Resolving Relationship Issues Constructively',
      'Seeking Relationship Counseling or Therapy',
      'Expressing Love',
      'Building a Strong Foundation',
      'Being Transparent',
      'Maintaining Respect',
      'Showing Empathy and Understanding',
      'Being Supportive During Tough Times',
      'Being Loyal',
      'Being Considerate',
      'Reconnecting After Time Apart',
      'Building Emotional Intelligence Together',
      'Balancing Individual and Relationship Needs',
      'Celebrating Each Other\'s Achievements',
      'Being Present',
      'Setting Relationship Goals',
      'Being Affectionate',
      'Creating Shared Memories',
      'Prioritizing Each Other',
      'Acknowledging Each Other\'s Contributions',
      'Being Thoughtful',
      'Reflecting on the Relationship Regularly',
      'Working Together Towards Common Goals',
      'Embracing Each Other\'s Families and Friends',
      'Being Understanding of Each Other\'s Stressors',
      'Building Mutual Respect',
      'Appreciating Each Other\'s Differences',
      'Showing Support During Difficult Times',
      'Practicing Patience',
      'Being Committed',
      'Finding Joy in Each Other\'s Successes',
      'Being Playful',
      'Being Consistent',
      'Encouraging Each Other',
      'Being Reliable Partners',
      'Building Emotional Resilience Together'
    ],
    'Entertainment': [
      'Modern Cinema & Filmmaking',
      'TV Series Trends & Streaming',
      'Music Innovation & Artists',
      'Video Gaming & Esports',
      'Live Theater & Musicals',
      'Comedy & Performative Arts',
      'Literary Works & Publishing',
      'Celebrity Culture & Media',
      'Digital Content Creation',
      'Awards & Entertainment Events',
      'Podcasting & Digital Broadcasts',
      'Reality Shows & Documentaries',
      'Animation & Digital Arts',
      'Concerts & Music Festivals',
      'Fan Communities & Theories',
      'Tech in Entertainment',
      'Crafts & DIY Content',
      'Dance & Performance Art',
      'Fashion in Pop Culture',
      'Magic & Street Performance',
      'Festivals & Cultural Events',
      'Non-fiction & Educational Content',
      'Entertainment Marketing',
      'Indie Films & Short Films',
      'Voice Acting & Audio Dramas',
      'Comics & Graphic Novels',
      'Entertainment Law & IP',
      'Photography & Art Exhibits',
      'Collectibles & Nostalgia',
      'Hobbies & Leisure Activities',
      'Entertainment Careers & Education',
      'Social Media & Influencers',
      'Home Entertainment Tech'
    ],
    'Environment': [
      'Climate Change',
      'Pollution Control',
      'Biodiversity Conservation',
      'Sustainable Development',
      'Renewable Energy',
      'Waste Management',
      'Water Conservation',
      'Deforestation Prevention',
      'Environmental Policy',
      'Eco-Friendly Practices',
      'Green Technology',
      'Wildlife Protection',
      'Environmental Activism',
      'Climate Policy',
      'Sustainable Agriculture',
      'Recycling Initiatives',
      'Air Quality Improvement',
      'Habitat Restoration',
      'Ocean Conservation',
      'Environmental Education',
      'Energy Efficiency',
      'Carbon Footprint Reduction',
      'Sustainable Living',
      'Environmental Justice',
      'Conservation Efforts',
      'Alternative Energy Sources',
      'Ecosystem Services',
      'Green Infrastructure',
      'Sustainable Forestry',
      'Urban Green Spaces'
  ],
  'Education': [
      'Access to Education',
      'Quality of Education',
      'Educational Equity',
      'Curriculum Development',
      'Teacher Training',
      'Educational Technology',
      'Special Education',
      'Higher Education',
      'Online Learning',
      'Educational Policy',
      'Lifelong Learning',
      'Student Engagement',
      'Parental Involvement',
      'Educational Research',
      'Global Education',
      'STEM Education',
      'Early Childhood Education',
      'Adult Education',
      'Vocational Training',
      'Educational Assessment',
      'Inclusive Education',
      'Educational Leadership',
      'Educational Funding',
      'Literacy Programs',
      'Scholarships and Grants',
      'Educational Innovation',
      'Learning Disabilities',
      'Multilingual Education',
      'Educational Psychology',
      'Peer Learning'
  ],
  'HumanRights': [
      'Freedom of Speech',
      'Gender Equality',
      'Racial Equality',
      'LGBTQ+ Rights',
      'Children\'s Rights',
      'Disability Rights',
      'Freedom of Religion',
      'Human Trafficking Prevention',
      'Labor Rights',
      'Right to Education',
      'Right to Health',
      'Privacy Rights',
      'Refugee Rights',
      'Political Freedom',
      'Economic Rights',
      'Freedom from Discrimination',
      'Indigenous Rights',
      'Right to Housing',
      'Right to Water and Sanitation',
      'Access to Justice',
      'Civil Liberties',
      'Social Justice',
      'Human Rights Advocacy',
      'International Humanitarian Law',
      'Freedom of Assembly',
      'Digital Rights',
      'Cultural Rights',
      'Environmental Rights',
      'Right to Life',
      'Anti-Torture Measures'
  ],
  'Ethics': [
      'Moral Philosophy',
      'Bioethics',
      'Business Ethics',
      'Environmental Ethics',
      'Medical Ethics',
      'Research Ethics',
      'Ethics in Technology',
      'Professional Ethics',
      'Workplace Ethics',
      'Political Ethics',
      'Ethical Decision Making',
      'Legal Ethics',
      'Media Ethics',
      'Ethics in Education',
      'Ethics of Artificial Intelligence',
      'Animal Ethics',
      'Ethical Leadership',
      'Ethics in Journalism',
      'Cultural Ethics',
      'Ethics in Sports',
      'Data Ethics',
      'Nanoethics',
      'Ethics Committees',
      'Global Ethics',
      'Ethics Training',
      'Consumer Ethics',
      'Ethics in Marketing',
      'Ethical Dilemmas',
      'Virtue Ethics',
      'Deontological Ethics'
  ],
  'Innovation': [
      'Creative Thinking',
      'Technological Advancements',
      'Entrepreneurship',
      'Research and Development',
      'Product Development',
      'Innovation Management',
      'Disruptive Technologies',
      'Innovation Strategies',
      'Open Innovation',
      'Social Innovation',
      'Design Thinking',
      'Innovation Ecosystems',
      'Business Model Innovation',
      'Innovation Culture',
      'Collaborative Innovation',
      'Innovation Hubs',
      'Innovation Policy',
      'Innovation Funding',
      'Intellectual Property',
      'Lean Innovation',
      'User-Centered Design',
      'Innovation Metrics',
      'Innovation Labs',
      'Corporate Innovation',
      'Frugal Innovation',
      'Sustainable Innovation',
      'Innovation in Education',
      'Public Sector Innovation',
      'Innovation Workshops',
      'Cross-Functional Teams'
  ],
  'Communication': [
      'Verbal Communication',
      'Non-Verbal Communication',
      'Written Communication',
      'Active Listening',
      'Public Speaking',
      'Interpersonal Skills',
      'Cross-Cultural Communication',
      'Digital Communication',
      'Communication Barriers',
      'Effective Presentation',
      'Conflict Resolution',
      'Communication Styles',
      'Body Language',
      'Communication Technology',
      'Mass Communication',
      'Interpersonal Relationships',
      'Persuasive Communication',
      'Communication Ethics',
      'Crisis Communication',
      'Negotiation Skills',
      'Feedback Techniques',
      'Emotional Intelligence',
      'Media Communication',
      'Communication in Teams',
      'Organizational Communication',
      'Communication Training',
      'Communication Psychology',
      'Social Media Communication',
      'Communication Strategies',
      'Listening Skills'
  ],
  'Transportation': [
      'Public Transportation',
      'Sustainable Transportation',
      'Urban Mobility',
      'Electric Vehicles',
      'Autonomous Vehicles',
      'Transportation Infrastructure',
      'Traffic Management',
      'Air Transportation',
      'Rail Transportation',
      'Maritime Transportation',
      'Logistics and Supply Chain',
      'Transportation Policy',
      'Transportation Safety',
      'Transportation Planning',
      'Bicycle Transportation',
      'Ride-Sharing Services',
      'Transportation Technology',
      'Intermodal Transportation',
      'High-Speed Rail',
      'Transportation Economics',
      'Freight Transportation',
      'Public Transit Accessibility',
      'Smart Transportation Systems',
      'Transportation Emissions Reduction',
      'Road Safety Education',
      'Alternative Fuels',
      'Aviation Innovation',
      'Urban Transit Networks',
      'Transportation Funding',
      'Mobility as a Service'
  ],
  'Globalization': [
      'International Trade',
      'Cultural Exchange',
      'Global Economy',
      'Global Governance',
      'Migration',
      'Global Communication',
      'Multinational Corporations',
      'Global Supply Chains',
      'Economic Integration',
      'Global Labor Markets',
      'Global Environmental Issues',
      'Cultural Homogenization',
      'Global Health',
      'Digital Globalization',
      'Global Inequality',
      'Global Education',
      'Global Security',
      'International Law',
      'Globalization Critiques',
      'Global Networks',
      'Transnationalism',
      'Global Tourism',
      'Global Media',
      'Global Citizenship',
      'Financial Globalization',
      'Technological Globalization',
      'Global Urbanization',
      'Global Cultural Heritage',
      'Global Ethics',
      'Global Crises Response'
  ],
  'Family': [
      'Parenting Styles',
      'Family Dynamics',
      'Work-Life Balance',
      'Child Development',
      'Family Communication',
      'Blended Families',
      'Elder Care',
      'Family Traditions',
      'Adoption',
      'Divorce and Separation',
      'Family Counseling',
      'Sibling Relationships',
      'Grandparenting',
      'Foster Care',
      'Family Health',
      'Single Parenting',
      'Family Planning',
      'Family Law',
      'Intergenerational Relationships',
      'Family Education',
      'Family Rituals',
      'Coping with Loss',
      'Family Support Systems',
      'Cultural Family Practices',
      'Domestic Violence Prevention',
      'Family Therapy',
      'Stepfamilies',
      'Family Finances',
      'Workplace Family Policies',
      'Family Recreation'
  ],
  'Aging': [
      'Healthy Aging',
      'Retirement Planning',
      'Aging Population',
      'Elderly Care',
      'Age-Related Diseases',
      'Loneliness in Aging',
      'Geriatric Medicine',
      'Active Aging',
      'Intergenerational Relationships',
      'Ageism',
      'Lifelong Learning',
      'End-of-Life Care',
      'Pension Systems',
      'Senior Housing',
      'Cognitive Decline',
      'Social Engagement',
      'Elder Abuse Prevention',
      'Assistive Technologies',
      'Caregiver Support',
      'Nutrition for Aging',
      'Physical Activity in Aging',
      'Mental Health in Aging',
      'Policy on Aging',
      'Retirement Communities',
      'Volunteerism in Aging',
      'Financial Security',
      'Health Care Access',
      'Dementia Care',
      'Legal Issues in Aging',
      'Advanced Care Planning'
  ],
  'Law': [
      'Criminal Law',
      'Civil Law',
      'International Law',
      'Human Rights Law',
      'Corporate Law',
      'Intellectual Property Law',
      'Environmental Law',
      'Family Law',
      'Constitutional Law',
      'Labor Law',
      'Legal Ethics',
      'Contract Law',
      'Tax Law',
      'Immigration Law',
      'Cyber Law',
      'Legal Aid',
      'Alternative Dispute Resolution',
      'Legal Education',
      'Judicial System',
      'Forensic Science',
      'Legal Reform',
      'Property Law',
      'Consumer Protection Law',
      'Health Law',
      'Media Law',
      'Elder Law',
      'Sports Law',
      'Entertainment Law',
      'Maritime Law',
      'Antitrust Law'
  ],
  'Economics': [
      'Microeconomics',
      'Macroeconomics',
      'Economic Policy',
      'International Economics',
      'Behavioral Economics',
      'Development Economics',
      'Econometrics',
      'Public Finance',
      'Monetary Policy',
      'Fiscal Policy',
      'Labor Economics',
      'Health Economics',
      'Environmental Economics',
      'Game Theory',
      'Economic Inequality',
      'Economic Growth',
      'Economic Systems',
      'Financial Markets',
      'Industrial Organization',
      'Urban Economics',
      'Agricultural Economics',
      'Resource Economics',
      'Economics of Education',
      'Transport Economics',
      'Welfare Economics',
      'Economic History',
      'Institutional Economics',
      'Experimental Economics',
      'Digital Economy',
      'Economics of Innovation'
  ],
  'Language': [
      'Linguistics',
      'Language Learning',
      'Translation',
      'Language Preservation',
      'Bilingualism',
      'Language Education',
      'Sociolinguistics',
      'Language and Culture',
      'Language Policy',
      'Sign Languages',
      'Language Technology',
      'Language Acquisition',
      'Phonetics',
      'Syntax',
      'Semantics',
      'Pragmatics',
      'Language Testing',
      'Language Disorders',
      'Historical Linguistics',
      'Computational Linguistics',
      'Dialectology',
      'Language Documentation',
      'Second Language Acquisition',
      'Multilingualism',
      'Corpus Linguistics',
      'Language Revitalization',
      'Applied Linguistics',
      'Language Ideologies',
      'Psycholinguistics',
      'Language Typology'
  ],
  'Philosophy': [
      'Metaphysics',
      'Epistemology',
      'Ethics',
      'Logic',
      'Aesthetics',
      'Philosophy of Mind',
      'Political Philosophy',
      'Philosophy of Science',
      'Existentialism',
      'Phenomenology',
      'Analytic Philosophy',
      'Continental Philosophy',
      'Eastern Philosophy',
      'Philosophy of Language',
      'Philosophy of Religion',
      'Philosophy of Law',
      'Philosophy of History',
      'Social Philosophy',
      'Philosophy of Education',
      'Philosophy of Technology',
      'Moral Philosophy',
      'Philosophy of Mathematics',
      'Environmental Philosophy',
      'Feminist Philosophy',
      'Philosophy of Art',
      'Philosophy of Culture',
      'Hermeneutics',
      'Critical Theory',
      'Pragmatism',
      'Stoicism'
  ],
  'Medicine': [
      'Anatomy',
      'Physiology',
      'Pathology',
      'Pharmacology',
      'Surgery',
      'Pediatrics',
      'Psychiatry',
      'Oncology',
      'Cardiology',
      'Neurology',
      'Dermatology',
      'Emergency Medicine',
      'Preventive Medicine',
      'Radiology',
      'Medical Ethics',
      'General Practice',
      'Endocrinology',
      'Gastroenterology',
      'Immunology',
      'Nephrology',
      'Obstetrics and Gynecology',
      'Orthopedics',
      'Ophthalmology',
      'Otolaryngology',
      'Rheumatology',
      'Public Health',
      'Medical Research',
      'Telemedicine',
      'Genetic Medicine',
      'Transplantation'
  ],
  'Agriculture': [
      'Sustainable Farming',
      'Crop Production',
      'Soil Science',
      'Agricultural Technology',
      'Organic Farming',
      'Agribusiness',
      'Animal Husbandry',
      'Irrigation',
      'Plant Genetics',
      'Agricultural Policy',
      'Agroecology',
      'Food Security',
      'Agricultural Economics',
      'Pest Management',
      'Agricultural Education',
      'Precision Agriculture',
      'Agroforestry',
      'Fisheries and Aquaculture',
      'Rural Development',
      'Agricultural Engineering',
      'Seed Technology',
      'Agricultural Marketing',
      'Post-Harvest Technology',
      'Agricultural Extension',
      'Climate-Smart Agriculture',
      'Agricultural Biodiversity',
      'Farm Management',
      'Sustainable Livestock',
      'Hydroponics',
      'Urban Agriculture'
  ],
  'Sustainability': [
      'Renewable Energy',
      'Sustainable Development',
      'Resource Conservation',
      'Green Building',
      'Sustainable Agriculture',
      'Waste Reduction',
      'Sustainable Transportation',
      'Corporate Sustainability',
      'Sustainable Consumption',
      'Environmental Policy',
      'Climate Action',
      'Ecosystem Services',
      'Sustainable Design',
      'Sustainability Education',
      'Life Cycle Assessment',
      'Circular Economy',
      'Sustainable Finance',
      'Sustainable Tourism',
      'Sustainability Reporting',
      'Carbon Neutrality',
      'Energy Efficiency',
      'Sustainable Water Management',
      'Biodiversity Protection',
      'Sustainable Materials',
      'Sustainability Metrics',
      'Social Sustainability',
      'Sustainable Supply Chains',
      'Sustainable Cities',
      'Ethical Consumerism',
      'Sustainable Innovation'
  ],
  'Peace': [
      'Conflict Resolution',
      'Disarmament',
      'Peacekeeping',
      'Diplomacy',
      'Nonviolence',
      'Peace Education',
      'Humanitarian Aid',
      'International Relations',
      'Peacebuilding',
      'Mediation',
      'Negotiation',
      'Transitional Justice',
      'Intercultural Dialogue',
      'Reconciliation',
      'Peace Psychology',
      'Peace Studies',
      'Conflict Prevention',
      'Peace Movements',
      'Arms Control',
      'Restorative Justice',
      'Global Security',
      'Peace Research',
      'Community Peacebuilding',
      'Cultural Peace',
      'Ethnic Conflict Resolution',
      'Peace Economics',
      'Peace Journalism',
      'Peace Leadership',
      'Post-Conflict Reconstruction',
      'Human Security'
  ],
  'DisasterManagement': [
      'Risk Assessment',
      'Emergency Preparedness',
      'Disaster Response',
      'Recovery Planning',
      'Mitigation Strategies',
      'Crisis Communication',
      'Humanitarian Assistance',
      'Disaster Resilience',
      'Community Engagement',
      'Disaster Policy',
      'Early Warning Systems',
      'First Responders',
      'Public Health Emergencies',
      'Natural Disasters',
      'Technological Hazards',
      'Disaster Education',
      'Volunteer Coordination',
      'Disaster Logistics',
      'Shelter Management',
      'Disaster Psychology',
      'Incident Command Systems',
      'Damage Assessment',
      'Business Continuity Planning',
      'Disaster Risk Reduction',
      'Search and Rescue',
      'Post-Disaster Recovery',
      'Disaster Insurance',
      'Climate Change Adaptation',
      'Disaster Simulation',
      'International Disaster Cooperation'
  ],
  'Infrastructure': [
      'Urban Planning',
      'Transportation Systems',
      'Water Supply',
      'Waste Management',
      'Energy Infrastructure',
      'Communication Networks',
      'Public Facilities',
      'Infrastructure Financing',
      'Infrastructure Maintenance',
      'Smart Cities',
      'Infrastructure Policy',
      'Sustainable Infrastructure',
      'Infrastructure Security',
      'Housing Development',
      'Infrastructure Innovation',
      'Bridges and Tunnels',
      'Road Networks',
      'Railway Systems',
      'Port and Harbor Facilities',
      'Airport Infrastructure',
      'Utility Services',
      'Digital Infrastructure',
      'Infrastructure Planning',
      'Public-Private Partnerships',
      'Infrastructure Resilience',
      'Green Infrastructure',
      'Rural Infrastructure',
      'Infrastructure Regulation',
      'Infrastructure Projects',
      'Community Infrastructure'
  ],
  'Energy': [
      'Renewable Energy',
      'Fossil Fuels',
      'Energy Efficiency',
      'Nuclear Energy',
      'Energy Policy',
      'Energy Storage',
      'Grid Management',
      'Energy Markets',
      'Alternative Energy',
      'Energy Conservation',
      'Hydroelectric Power',
      'Solar Energy',
      'Wind Energy',
      'Bioenergy',
      'Energy Transition',
      'Geothermal Energy',
      'Energy Security',
      'Carbon Capture',
      'Energy Economics',
      'Distributed Generation',
      'Smart Grids',
      'Energy Technology',
      'Energy Access',
      'Energy Education',
      'Ocean Energy',
      'Energy Auditing',
      'Clean Energy Innovation',
      'Energy Forecasting',
      'Energy Ethics',
      'Energy Infrastructure'
  ],
  'ArtificialIntelligence': [
      'Machine Learning',
      'Deep Learning',
      'Natural Language Processing',
      'Computer Vision',
      'AI Ethics',
      'Robotics',
      'AI in Healthcare',
      'AI in Finance',
      'AI Policy',
      'Autonomous Systems',
      'AI Safety',
      'AI and Employment',
      'AI Research',
      'Human-AI Interaction',
      'Explainable AI',
      'Reinforcement Learning',
      'AI Algorithms',
      'AI Applications',
      'AI in Education',
      'Cognitive Computing',
      'AI and Privacy',
      'AI Hardware',
      'AI Regulation',
      'Neural Networks',
      'AI in Agriculture',
      'AI and Creativity',
      'AI Governance',
      'AI Bias',
      'AI in Transportation',
      'Edge AI'
  ],
  'Privacy': [
      'Data Protection',
      'Online Privacy',
      'Privacy Laws',
      'Surveillance',
      'Encryption',
      'Privacy Policies',
      'Privacy Rights',
      'Identity Theft Prevention',
      'Privacy in Social Media',
      'Consent Management',
      'Privacy by Design',
      'Anonymity',
      'Privacy in Healthcare',
      'Cybersecurity',
      'Privacy Advocacy',
      'Data Breaches',
      'Digital Footprint',
      'Privacy Education',
      'Internet of Things Privacy',
      'Biometric Data Privacy',
      'Employee Privacy',
      'Consumer Privacy',
      'Location Data Privacy',
      'Cloud Privacy',
      'Children\'s Online Privacy',
      'Privacy Impact Assessments',
      'Privacy Compliance',
      'Global Privacy Regulations',
      'Ethics of Privacy',
      'Privacy in Artificial Intelligence'
  ],
  'Security': [
      'Cybersecurity',
      'National Security',
      'Information Security',
      'Physical Security',
      'Security Policies',
      'Risk Management',
      'Security Technologies',
      'Encryption',
      'Security Awareness',
      'Network Security',
      'Security Compliance',
      'Emergency Response',
      'Threat Analysis',
      'Security Audits',
      'Homeland Security',
      'Security Training',
      'Security Management',
      'Data Security',
      'Security Architecture',
      'Security Standards',
      'Identity and Access Management',
      'Security Analytics',
      'Application Security',
      'Cloud Security',
      'Security Incident Response',
      'Operational Security',
      'Fraud Prevention',
      'Mobile Security',
      'Security Certifications',
      'Security Governance'
  ],
  'SpaceExploration': [
      'Space Missions',
      'Planetary Science',
      'Astronautics',
      'Space Technology',
      'Commercial Spaceflight',
      'Space Colonization',
      'Astrobiology',
      'Space Policy',
      'International Space Station',
      'Space Telescopes',
      'Mars Exploration',
      'Lunar Missions',
      'Space Tourism',
      'Exoplanet Research',
      'Rocket Propulsion',
      'Space Mining',
      'Space Law',
      'Human Spaceflight',
      'Space Debris Management',
      'Satellite Technology',
      'Deep Space Exploration',
      'Space Robotics',
      'Microgravity Research',
      'Space Agencies',
      'Space Communication',
      'Interstellar Travel',
      'Space Medicine',
      'Reusable Rockets',
      'Space Education',
      'Astrophysics'
  ],
  'WaterResources': [
      'Water Conservation',
      'Water Quality',
      'Water Management',
      'Hydrology',
      'Irrigation Systems',
      'Drinking Water Supply',
      'Wastewater Treatment',
      'Flood Control',
      'Water Policy',
      'Desalination',
      'Aquatic Ecosystems',
      'Water Scarcity',
      'Transboundary Water Issues',
      'Hydroelectric Power',
      'Rainwater Harvesting',
      'Groundwater Management',
      'Water Recycling',
      'Watershed Management',
      'Water Security',
      'Urban Water Systems',
      'Water Pollution',
      'Water Infrastructure',
      'Integrated Water Resources Management',
      'Water Governance',
      'Ecosystem Restoration',
      'Water Education',
      'Climate Change Impact on Water',
      'Sustainable Water Use',
      'Agricultural Water Use',
      'Water Rights'
  ],
  'UrbanDevelopment': [
      'City Planning',
      'Sustainable Cities',
      'Urban Design',
      'Housing Policy',
      'Transportation Planning',
      'Urban Economics',
      'Smart Cities',
      'Urban Regeneration',
      'Public Spaces',
      'Community Development',
      'Urban Sustainability',
      'Gentrification',
      'Affordable Housing',
      'Urban Governance',
      'Green Infrastructure',
      'Urbanization Challenges',
      'Urban Poverty',
      'Urban Resilience',
      'Urban Policy',
      'Urban Mobility',
      'Urban Ecology',
      'Mixed-Use Development',
      'Urban Crime Prevention',
      'Urban Health',
      'Cultural Heritage Preservation',
      'Urban Data Analytics',
      'Urban Agriculture',
      'Urban Education',
      'Urban Energy Systems',
      'Urban Climate Adaptation'
  ],
  'CulturalDiversity': [
      'Multiculturalism',
      'Cultural Heritage',
      'Intercultural Communication',
      'Diversity and Inclusion',
      'Cultural Identity',
      'Cultural Exchange',
      'Ethnic Studies',
      'Cultural Competence',
      'Language Diversity',
      'Religious Diversity',
      'Cultural Festivals',
      'Indigenous Cultures',
      'Cultural Preservation',
      'Diaspora Communities',
      'Cultural Education',
      'Cross-Cultural Relations',
      'Cultural Sensitivity',
      'Global Cultures',
      'Cultural Rights',
      'Cultural Awareness Training',
      'Anti-Discrimination Efforts',
      'Cultural Policy',
      'Interfaith Dialogue',
      'Cultural Anthropology',
      'Cultural Adaptation',
      'Cultural Conflict Resolution',
      'Diversity in Media',
      'Cultural Tourism',
      'Cultural Innovation',
      'Diversity in the Workplace'
  ],
  'MentalHealth': [
      'Stress Management',
      'Depression',
      'Anxiety Disorders',
      'Mental Health Awareness',
      'Psychotherapy',
      'Mindfulness',
      'Substance Abuse',
      'Mental Health Policy',
      'Child and Adolescent Mental Health',
      'Workplace Mental Health',
      'Suicide Prevention',
      'Mental Health Stigma',
      'Community Mental Health',
      'Mental Health Services',
      'Self-Care Practices',
      'Eating Disorders',
      'Post-Traumatic Stress Disorder',
      'Cognitive Behavioral Therapy',
      'Emotional Well-being',
      'Mental Health in Schools',
      'Sleep Disorders',
      'Family Mental Health',
      'Psychiatric Research',
      'Holistic Mental Health',
      'Mental Health Technology',
      'Mental Health Advocacy',
      'Neurodiversity',
      'Mental Health and Aging',
      'Crisis Intervention',
      'Resilience Building'
  ],
  'SocialJustice': [
      'Equality',
      'Equity',
      'Human Rights',
      'Discrimination',
      'Social Inequality',
      'Activism',
      'Civil Rights',
      'Economic Justice',
      'Gender Justice',
      'Racial Justice',
      'Social Movements',
      'Justice Reform',
      'Environmental Justice',
      'Restorative Justice',
      'Social Policy',
      'Poverty Alleviation',
      'Community Organizing',
      'Disability Rights',
      'LGBTQ+ Advocacy',
      'Immigrant Rights',
      'Labor Rights',
      'Education Equity',
      'Healthcare Access',
      'Fair Housing',
      'Criminal Justice Reform',
      'Food Justice',
      'Voting Rights',
      'Social Inclusion',
      'Advocacy Training',
      'Intersectionality'
  ],
  'Photography': [
    'Landscape Photography',
    'Portrait Photography',
    'Wildlife Photography',
    'Street Photography',
    'Macro Photography',
    'Astrophotography',
    'Wedding Photography',
    'Fashion Photography',
    'Sports Photography',
    'Photojournalism',
    'Black and White Photography',
    'Drone Photography',
    'Underwater Photography',
    'Architectural Photography',
    'Food Photography',
    'Event Photography',
    'Travel Photography',
    'Fine Art Photography',
    'Digital Editing',
    'Photography Equipment',
    'Lighting Techniques',
    'Composition',
    'Photography Workshops',
    'Photography Contests',
    'Stock Photography',
    'Mobile Photography',
    'Film Photography',
    'Photography History',
    'Photography Ethics',
    'Photography Business'
],
'Dance': [
    'Ballet',
    'Contemporary Dance',
    'Hip Hop Dance',
    'Ballroom Dancing',
    'Latin Dance',
    'Jazz Dance',
    'Tap Dance',
    'Folk Dance',
    'Modern Dance',
    'Breakdancing',
    'Salsa',
    'Tango',
    'Swing Dance',
    'Cultural Dances',
    'Dance Choreography',
    'Dance Competitions',
    'Dance Education',
    'Dance Fitness',
    'Dance Therapy',
    'Dance History',
    'Dance Companies',
    'Dance Festivals',
    'Dance Costumes',
    'Dance Techniques',
    'Improvisation in Dance',
    'Dance Notation',
    'Dance and Technology',
    'Dance Careers',
    'Dance Auditions',
    'Dance Criticism'
],
'Psychology': [
    'Cognitive Psychology',
    'Developmental Psychology',
    'Clinical Psychology',
    'Social Psychology',
    'Abnormal Psychology',
    'Personality Psychology',
    'Neuropsychology',
    'Industrial-Organizational Psychology',
    'Educational Psychology',
    'Forensic Psychology',
    'Psychological Research',
    'Behavioral Psychology',
    'Positive Psychology',
    'Psychotherapy',
    'Psychological Testing',
    'Psychological Disorders',
    'Psychology of Learning',
    'Emotion and Motivation',
    'Psychology of Perception',
    'Health Psychology',
    'Experimental Psychology',
    'Counseling Psychology',
    'Cross-Cultural Psychology',
    'Psychology of Aging',
    'Child Psychology',
    'Psychology Journals',
    'Psychological Theories',
    'Psychology Careers',
    'Psychology Ethics',
    'Applied Psychology'
],
'Sociology': [
    'Social Theory',
    'Cultural Sociology',
    'Social Stratification',
    'Race and Ethnicity',
    'Gender Studies',
    'Urban Sociology',
    'Rural Sociology',
    'Family Sociology',
    'Sociology of Education',
    'Medical Sociology',
    'Criminology',
    'Sociology of Religion',
    'Sociology of Work',
    'Political Sociology',
    'Economic Sociology',
    'Environmental Sociology',
    'Sociological Research Methods',
    'Social Change',
    'Demography',
    'Social Movements',
    'Sociology of Media',
    'Sociology of Law',
    'Sociology of Aging',
    'Global Sociology',
    'Sociology of Organizations',
    'Sociology of Deviance',
    'Sociology Journals',
    'Sociology Conferences',
    'Applied Sociology',
    'Sociological Imagination'
],
'Anthropology': [
    'Cultural Anthropology',
    'Biological Anthropology',
    'Archaeology',
    'Linguistic Anthropology',
    'Anthropological Theory',
    'Ethnography',
    'Anthropology of Religion',
    'Medical Anthropology',
    'Anthropology of Gender',
    'Visual Anthropology',
    'Economic Anthropology',
    'Environmental Anthropology',
    'Forensic Anthropology',
    'Urban Anthropology',
    'Anthropology of Development',
    'Anthropology of Art',
    'Anthropology Education',
    'Anthropology Fieldwork',
    'Anthropology Journals',
    'Anthropology Conferences',
    'Anthropology Careers',
    'Anthropology Ethics',
    'Anthropology and Globalization',
    'Applied Anthropology',
    'Anthropology of Kinship',
    'Anthropology of Migration',
    'Anthropology of Food',
    'Anthropology of the Body',
    'Symbolic Anthropology',
    'Anthropology Museums'
],
'Engineering': [
    'Mechanical Engineering',
    'Electrical Engineering',
    'Civil Engineering',
    'Chemical Engineering',
    'Computer Engineering',
    'Aerospace Engineering',
    'Biomedical Engineering',
    'Environmental Engineering',
    'Industrial Engineering',
    'Materials Engineering',
    'Nuclear Engineering',
    'Software Engineering',
    'Structural Engineering',
    'Robotics Engineering',
    'Automotive Engineering',
    'Engineering Design',
    'Engineering Ethics',
    'Engineering Education',
    'Engineering Management',
    'Engineering Research',
    'Nanotechnology Engineering',
    'Renewable Energy Engineering',
    'Marine Engineering',
    'Petroleum Engineering',
    'Systems Engineering',
    'Telecommunications Engineering',
    'Manufacturing Engineering',
    'Engineering Technology',
    'Geotechnical Engineering',
    'Engineering Projects'
],
'Business': [
    'Business Management',
    'Entrepreneurship',
    'Business Strategy',
    'Human Resources',
    'Operations Management',
    'Project Management',
    'Supply Chain Management',
    'Business Ethics',
    'Business Law',
    'International Business',
    'E-commerce',
    'Business Analytics',
    'Business Communication',
    'Business Finance',
    'Marketing',
    'Sales',
    'Business Development',
    'Business Innovation',
    'Organizational Behavior',
    'Change Management',
    'Leadership',
    'Business Planning',
    'Small Business',
    'Corporate Social Responsibility',
    'Business Intelligence',
    'Risk Management',
    'Customer Relationship Management',
    'Business Process Improvement',
    'Franchising',
    'Business Networking'
],
'Marketing': [
    'Digital Marketing',
    'Brand Management',
    'Market Research',
    'Consumer Behavior',
    'Content Marketing',
    'Social Media Marketing',
    'Advertising',
    'Public Relations',
    'Email Marketing',
    'Search Engine Optimization',
    'Product Marketing',
    'Marketing Strategy',
    'Affiliate Marketing',
    'Influencer Marketing',
    'Mobile Marketing',
    'Marketing Analytics',
    'Event Marketing',
    'Direct Marketing',
    'B2B Marketing',
    'B2C Marketing',
    'Guerrilla Marketing',
    'Marketing Communications',
    'Marketing Automation',
    'Viral Marketing',
    'Marketing Ethics',
    'Experiential Marketing',
    'Neuromarketing',
    'Retail Marketing',
    'Marketing Education',
    'Customer Engagement'
],
'Finance': [
    'Corporate Finance',
    'Investment Banking',
    'Financial Markets',
    'Risk Management',
    'Financial Analysis',
    'Portfolio Management',
    'Financial Planning',
    'Wealth Management',
    'Financial Modeling',
    'Derivatives',
    'Fixed Income',
    'Equity Markets',
    'Foreign Exchange',
    'Hedge Funds',
    'Private Equity',
    'Financial Regulations',
    'Financial Technology (FinTech)',
    'Insurance',
    'Retirement Planning',
    'Behavioral Finance',
    'International Finance',
    'Public Finance',
    'Financial Reporting',
    'Capital Budgeting',
    'Financial Economics',
    'Microfinance',
    'Credit Analysis',
    'Financial Risk',
    'Financial Literacy',
    'Valuation'
],
'Military': [
    'Military History',
    'Military Strategy',
    'Defense Policy',
    'Military Technology',
    'Armed Forces',
    'Military Operations',
    'Military Training',
    'Peacekeeping Missions',
    'Veterans Affairs',
    'Military Ethics',
    'Cyber Warfare',
    'Intelligence Gathering',
    'Military Logistics',
    'Naval Warfare',
    'Air Warfare',
    'Land Warfare',
    'Nuclear Deterrence',
    'Military Leadership',
    'Military Education',
    'Military Medicine',
    'Asymmetric Warfare',
    'Counterterrorism',
    'Military Communications',
    'Special Forces',
    'Military Engineering',
    'Military Law',
    'Civil-Military Relations',
    'Defense Industry',
    'Military Innovation',
    'Military Alliances'
],
'Weather': [
    'Weather Forecasting',
    'Climate Science',
    'Meteorology',
    'Severe Weather',
    'Weather Patterns',
    'Climate Change',
    'Atmospheric Physics',
    'Oceanography',
    'Weather Instruments',
    'Weather Satellites',
    'Hydrology',
    'Weather Modification',
    'Weather Hazards',
    'Seasonal Weather',
    'Weather Data Analysis',
    'Weather Education',
    'Weather and Agriculture',
    'Aviation Weather',
    'Marine Weather',
    'Weather Apps',
    'Historical Weather',
    'Weather Photography',
    'Weather and Health',
    'Weather Phenomena',
    'Weather Research',
    'Weather and Climate Policy',
    'Weather Preparedness',
    'Weather Broadcasting',
    'Weather Terminology',
    'Weather Impact on Society'
],
'Pets': [
    'Pet Care',
    'Dog Training',
    'Cat Behavior',
    'Pet Nutrition',
    'Exotic Pets',
    'Pet Adoption',
    'Veterinary Medicine',
    'Pet Grooming',
    'Pet Health',
    'Pet Insurance',
    'Pet Psychology',
    'Pet Breeds',
    'Pet Behavior Problems',
    'Pet Accessories',
    'Pet Safety',
    'Pet Vaccinations',
    'Pet Travel',
    'Animal Welfare',
    'Pet Therapy',
    'Pet Obedience',
    'Pet Rescue',
    'Pet-Friendly Spaces',
    'Pet Loss and Grieving',
    'Pet Legislation',
    'Pet Shows',
    'Pet Communication',
    'Pet Socialization',
    'Pet Training Techniques',
    'Pet Industry Trends',
    'Pet Blogs and Communities'
],
'Comics': [
    'Superheroes',
    'Manga',
    'Graphic Novels',
    'Comic Book Art',
    'Comic Book Writing',
    'Independent Comics',
    'Comic Conventions',
    'Webcomics',
    'Comic Book Collecting',
    'Comic Book History',
    'Comic Book Publishing',
    'Comic Book Characters',
    'Comic Book Movies',
    'Comic Book Reviews',
    'Comic Strip Syndication',
    'Comic Book Culture',
    'Comic Book Genres',
    'Comic Book Shops',
    'Comic Book Creators',
    'Comic Book Industry',
    'Comic Book Adaptations',
    'Comic Book Memorabilia',
    'Comic Book Education',
    'Comic Book Lettering',
    'Comic Book Inking',
    'Digital Comics',
    'Comic Book Anthologies',
    'Comic Book Podcasts',
    'Comic Book Awards',
    'Comic Book Fandom'
],
'Anime': [
    'Anime Series',
    'Anime Movies',
    'Anime Genres',
    'Anime Characters',
    'Anime Studios',
    'Anime Conventions',
    'Anime Music',
    'Anime Art',
    'Anime Merchandise',
    'Anime Cosplay',
    'Anime Reviews',
    'Anime Streaming',
    'Anime Culture',
    'Anime Adaptations',
    'Anime Directors',
    'Anime Voice Actors',
    'Anime History',
    'Anime Communities',
    'Anime Fan Art',
    'Anime Games',
    'Anime News',
    'Anime Podcasts',
    'Anime Magazines',
    'Anime Clubs',
    'Anime Forums',
    'Anime Influences',
    'Anime Industry',
    'Anime Education',
    'Anime Soundtracks',
    'Anime Philosophy'
],
'Automotive': [
    'Car Reviews',
    'Automotive Technology',
    'Electric Vehicles',
    'Automotive Design',
    'Classic Cars',
    'Automotive Maintenance',
    'Motorsports',
    'Automotive Industry',
    'Automotive Safety',
    'Automotive Engineering',
    'Automotive News',
    'Car Buying',
    'Automotive Accessories',
    'Off-Road Vehicles',
    'Automotive Events',
    'Car Modifications',
    'Hybrid Vehicles',
    'Automotive History',
    'Luxury Cars',
    'Automotive Marketing',
    'Automotive Laws',
    'Car Insurance',
    'Automotive Education',
    'Automotive Manufacturing',
    'Automotive Careers',
    'Automotive Art',
    'Automotive Testing',
    'Automotive Emissions',
    'Automotive Trends',
    'Autonomous Vehicles'
],
'Hiking': [
    'Trail Navigation',
    'Hiking Gear',
    'Backpacking',
    'Mountain Hiking',
    'Day Hiking',
    'Long-Distance Trails',
    'Hiking Safety',
    'Hiking Nutrition',
    'Hiking Etiquette',
    'Hiking with Pets',
    'Winter Hiking',
    'Hiking Clubs',
    'Hiking Trails',
    'Hiking Photography',
    'Hiking Fitness',
    'Hiking Destinations',
    'Leave No Trace Principles',
    'Hiking Apps',
    'Hiking for Beginners',
    'Solo Hiking',
    'Group Hiking',
    'Hiking Blogs',
    'Hiking and Wildlife',
    'Hiking Maps',
    'Hiking Stories',
    'Hiking Challenges',
    'Hiking First Aid',
    'Hiking Workshops',
    'Hiking Culture',
    'Thru-Hiking'
],
'DIY': [
    'Home Improvement',
    'DIY Crafts',
    'DIY Electronics',
    'DIY Furniture',
    'DIY Decor',
    'DIY Gardening',
    'DIY Repairs',
    'DIY Projects',
    'DIY Tools',
    'DIY Workshops',
    'DIY Tutorials',
    'DIY Woodworking',
    'DIY Metalworking',
    'DIY Upcycling',
    'DIY Sewing',
    'DIY Gifts',
    'DIY Beauty Products',
    'DIY Cleaning Products',
    'DIY Automation',
    'DIY Vehicles',
    'DIY Kids Projects',
    'DIY Outdoor Projects',
    'DIY Home Automation',
    'DIY Costumes',
    'DIY Art',
    'DIY Home Security',
    'DIY Lighting',
    'DIY Plumbing',
    'DIY Painting Techniques',
    'DIY Blogs'
],
'Crafts': [
    'Knitting',
    'Crocheting',
    'Scrapbooking',
    'Quilting',
    'Paper Crafts',
    'Jewelry Making',
    'Glass Art',
    'Ceramics',
    'Calligraphy',
    'Embroidery',
    'Leather Crafting',
    'Pottery',
    'Weaving',
    'Macrame',
    'Candle Making',
    'Soap Making',
    'Origami',
    'Beadwork',
    'Felting',
    'Stained Glass',
    'Cross-Stitch',
    'Model Building',
    'Craft Fairs',
    'Craft Supplies',
    'Craft Workshops',
    'Upcycling Crafts',
    'DIY Crafts',
    'Craft Blogs',
    'Craft Techniques',
    'Craft Communities'
],
'Fitness': [
    'Cardiovascular Training',
    'Strength Training',
    'Yoga',
    'Pilates',
    'Fitness Nutrition',
    'Personal Training',
    'Fitness Classes',
    'Home Workouts',
    'Fitness Technology',
    'Fitness Motivation',
    'Fitness Challenges',
    'Flexibility Training',
    'Fitness Programs',
    'Fitness Apps',
    'Group Fitness',
    'Functional Fitness',
    'High-Intensity Interval Training',
    'Bodyweight Exercises',
    'Fitness for Seniors',
    'Sports Performance',
    'Fitness Equipment',
    'Fitness Trends',
    'Fitness Coaching',
    'Fitness Events',
    'Fitness Competitions',
    'Mind-Body Fitness',
    'Fitness Recovery',
    'Fitness Safety',
    'Fitness Communities',
    'Fitness Blogs'
],

'Comedy': [
  'Stand-Up Comedy',
  'Comedy Writing',
  'Improv Comedy',
  'Sketch Comedy',
  'Comedy Shows',
  'Comedy Podcasts',
  'Comedy Films',
  'Comedy Clubs',
  'Satire',
  'Parody',
  'Comedy Festivals',
  'Comedy History',
  'Comedy Troupes',
  'Online Comedy',
  'Dark Comedy',
  'Physical Comedy',
  'Comedy Acting',
  'Comedy Specials',
  'Comedy Awards',
  'Comedy Education',
  'Comedy Reviews',
  'Comedy Timing',
  'Comedy Genres',
  'Comedy Directing',
  'Comedy Workshops',
  'Comedy Scripts',
  'Political Comedy',
  'Comedy Networks',
  'Comedy Influencers',
  'Comedy Writing Techniques'
],
'Gardening': [
  'Organic Gardening',
  'Urban Gardening',
  'Hydroponics',
  'Permaculture',
  'Vegetable Gardening',
  'Flower Gardening',
  'Indoor Plants',
  'Garden Design',
  'Gardening Tools',
  'Soil Management',
  'Composting',
  'Pest Control',
  'Gardening Techniques',
  'Seasonal Gardening',
  'Garden Landscaping',
  'Watering Systems',
  'Plant Propagation',
  'Herb Gardening',
  'Garden Sustainability',
  'Garden Workshops',
  'Garden Maintenance',
  'Garden Photography',
  'Community Gardens',
  'Garden Therapy',
  'Gardening Blogs',
  'Garden Art',
  'Fruit Trees',
  'Garden Events',
  'Botanical Gardens',
  'Gardening for Wildlife'
],
'Podcasts': [
  'Podcast Production',
  'Podcast Hosting',
  'True Crime Podcasts',
  'Educational Podcasts',
  'Comedy Podcasts',
  'Technology Podcasts',
  'Interview Podcasts',
  'Podcast Marketing',
  'Podcast Monetization',
  'Podcast Equipment',
  'Podcast Editing',
  'Podcast Networks',
  'Storytelling Podcasts',
  'Business Podcasts',
  'Health and Wellness Podcasts',
  'Podcast Analytics',
  'Podcast SEO',
  'Podcast Communities',
  'Podcast Live Shows',
  'Podcast Sponsorships',
  'Podcast Distribution',
  'Podcast Scriptwriting',
  'Podcast Cover Art',
  'Niche Podcasts',
  'Podcast Legal Issues',
  'Podcast Transcription',
  'Podcast Directories',
  'Podcast Trends',
  'Podcast Audience Engagement',
  'Podcast Awards'
], 
'Memes': [
  'Internet Memes',
  'Meme Culture',
  'Meme History',
  'Meme Creation',
  'Viral Memes',
  'Meme Formats',
  'Dank Memes',
  'Meme Generators',
  'Meme Trends',
  'Meme Communities',
  'Meme Humor',
  'Meme Marketing',
  'Reaction Memes',
  'Meme Templates',
  'Meme Evolution',
  'Meme Ethics',
  'Meme Analysis',
  'Political Memes',
  'Meme Subcultures',
  'Meme Sharing Platforms',
  'Meme Influencers',
  'Meme Merchandise',
  'Meme Aesthetics',
  'Meme Compilations',
  'Meme Contests',
  'Meme Adaptations',
  'Meme Language',
  'Meme Research',
  'Meme Law and Intellectual Property',
  'Future of Memes'
],
'History': [ 
  'Ancient History', 
  'Medieval History', 
  'Modern History', 
  'Military History', 
  'Economic History', 
  'Social History', 
  'Cultural History', 
  'Political History', 
  'Historiography', 
  'History of Science', 
  'History of Technology', 
  'History of Art', 
  'History of Religion', 
  'Regional Histories', 
  'Biographical History', 
  'Womens History', 
  'Intellectual History', 
  'Environmental History', 
  'History of Medicine', 
  'History of Education', 
  'Legal History', 
  'Urban History', 
  'History of Slavery', 
  'History of Colonization', 
  'History of Trade', 
  'Diplomatic History', 
  'History of Revolutions', 
  'History of Migration', 
  'Oral History', 
  'Public History', 
  'Digital History', 
  'History in Literature', 
  'Historical Archaeology', 
  'History of Philosophy', 
  'History of Sports', 
  'History of Music', 
  'Postcolonial History', 
  'History of Immigration', 
  'Transnational History', 
  'History of Transportation', 
  'History of Communication', 
  'History of Fashion',  
  'History of Food', 
  'History of Education', 
  'History of Labor', 
  'History of Mental Health', 
  'Comparative History', 
  'History of Popular Culture' 
],
    'Open': [
      'Random', 
    ],
    'Ai': [ 
      'General', 
    ],
    'Official': [
      'Official', 
    ]
  };
  
  module.exports = domainsByModel;
  