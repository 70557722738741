import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { styled } from '@mui/material/styles';
import {
  Button,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import { loginUser, signupUser } from "../../reducers/userReducer";
import { notify } from "../../reducers/notificationReducer";
import getErrorMsg from "../../utils/getErrorMsg";
import "./AuthTwo.css";

const validationSchemaSignup = yup.object({
  username: yup
    .string()
    .required("Required")
    .max(20, "Must be at most 20 characters")
    .min(3, "Must be at least 3 characters")
    .matches(
      /^[a-zA-Z0-9-_]*$/,
      "Only alphanumeric characters allowed, no spaces/symbols"
    ),
  password: yup
    .string()
    .required("Required")
    .min(10, "Must be at least 10 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  totpToken: yup.string().required("Required"),
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Must be a valid email address"
    ),
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#f5f5f5',
    color: 'black',
  },
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  color: 'black',
}));

const CustomDialogContentText = styled(DialogContentText)(({ theme }) => ({
  color: 'black',
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  color: 'black',
}));

const validationSchemaLogin = yup.object({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
  totpToken: yup.string().required("Required"),
});

const AuthTwo = () => {
  const dispatch = useDispatch();
  const [authType, setAuthType] = useState("login");
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [secret, setSecret] = useState("");
  const [isLoadingQRCode, setIsLoadingQRCode] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [usernameChecked, setUsernameChecked] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
  const [formikValues, setFormikValues] = useState({});
  const [formikActions, setFormikActions] = useState({});

  useEffect(() => {
    const generateQrCode = async () => {
      setIsLoadingQRCode(true);
      try {
        const response = await axios.post("/generate-totp");
        setQrCodeUrl(response.data.qrCodeUrl);
        setSecret(response.data.secret);
      } catch (err) {
        setError(getErrorMsg(err));
      } finally {
        setIsLoadingQRCode(false);
      }
    };

    if (authType === "signup" && isUsernameAvailable) {
      generateQrCode();
    }
  }, [authType, isUsernameAvailable]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const checkUsernameAvailability = async (username) => {
    const normalizedUsername = username.toLowerCase();
    setUsernameChecked(false);
    try {
      const response = await axios.post("/check-username", { username: normalizedUsername });
      setIsUsernameAvailable(response.data.isAvailable);
      if (response.data.isAvailable && authType === "signup") {
        const qrResponse = await axios.post("/generate-totp", { username });
        setQrCodeUrl(qrResponse.data.qrCodeUrl);
        setSecret(qrResponse.data.secret);
      }
    } catch (err) {
      setError(getErrorMsg(err));
    } finally {
      setUsernameChecked(true);
    }
  };

  const checkEmailAvailability = async (email) => {
    const normalizedEmail = email.toLowerCase();
    setEmailChecked(false);
    try {
      const response = await axios.post("/check-email", { email: normalizedEmail });
      setIsEmailAvailable(response.data.isAvailable);
    } catch (err) {
      setError(getErrorMsg(err));
    } finally {
      setEmailChecked(true);
    }
  };
  

  const debouncedCheckUsernameAvailability = debounce(checkUsernameAvailability, 500);
  const debouncedCheckEmailAvailability = debounce(checkEmailAvailability, 500);

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await dispatch(loginUser(values));
      dispatch(notify(`Welcome back ${values.username}!`, "success"));
      window.location.href = "/";
    } catch (err) {
      setSubmitting(false);
      setError(getErrorMsg(err));
    }
  };

  const handleSignup = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const signupData = {
        username: values.username,
        password: values.password,
        email: values.email,
        secret: secret, // Include the generated secret here
      };
      await dispatch(signupUser(signupData));
      dispatch(notify("Sign up successful!", "success"));
      window.location.href = "/";
    } catch (err) {
      setSubmitting(false);
      setError(getErrorMsg(err));
    }
  };

  const handleSubmit = (values, actions) => {
    if (authType === "signup") {
      validationSchemaSignup
        .validate(values, { abortEarly: false })
        .then(() => {
          setOpenAgreementDialog(true);
          setFormikValues(values);
          setFormikActions(actions);
          actions.setSubmitting(false);
        })
        .catch((err) => {
          setValidationErrors(err.errors);
          setOpenDialog(true);
          actions.setSubmitting(false);
        });
    } else {
      handleLogin(values, actions);
    }
  };

  const handleAgreement = (agree) => {
    setOpenAgreementDialog(false);
    if (agree) {
      handleSignup(formikValues, formikActions);
    }
  };

  const copyToClipboard = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(secret);
        dispatch(notify("Setup key copied to clipboard!", "success"));
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = secret;
        textArea.style.position = "fixed"; // Avoid scrolling to bottom
        textArea.style.opacity = "0"; // Hide element
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        dispatch(notify("Setup key copied to clipboard!", "success"));
      }
    } catch (err) {
      dispatch(notify("Failed to copy setup key. Please try again.", "error"));
    }
  };
  
  

  const isAuthenticated = !!localStorage.getItem("readifyUserKey");

  if (isAuthenticated) {
    window.location.href = "/";
    return null;
  }

  return (
    <div className="pageContainer1">
      <div className="authWrapper1">
        <Formik
          validateOnChange={true}
          initialValues={
            authType === "login"
              ? { username: "", password: "", totpToken: "" }
              : {
                  username: "",
                  password: "",
                  email: "",
                  totpToken: "",
                }
          }
          onSubmit={handleSubmit}
          validationSchema={
            authType === "login"
              ? validationSchemaLogin
              : validationSchemaSignup
          }
        >
          {({ isSubmitting, values, setFieldValue, errors, validateForm }) => (
            <Form className="form">
              <Typography variant="h5" color="secondary" className="formTitle">
                {authType === "login" ? "Login" : "Sign Up"}
              </Typography>
              <div className="input" style={{ position: "relative" }}>
                <PersonIcon className="inputIcon" color="primary" />
                <Field
                  name="username"
                  type="text"
                  placeholder="Enter Alias"
                  label="username"
                  required
                  fullWidth
                  className="black-text"
                  onChange={(e) => {
                    setFieldValue("username", e.target.value);
                    if (authType === "signup") {
                      debouncedCheckUsernameAvailability(e.target.value);
                    }
                  }}
                />
                {authType === "signup" && usernameChecked && (
                  <div style={{ position: "absolute", top: "7.69px", left: "87%" }}>
                    {isUsernameAvailable ? (
                      <CheckCircleIcon style={{ color: 'green' }} />
                    ) : (
                      <CancelIcon style={{ color: 'red' }} />
                    )}
                  </div>
                )}
              </div>
              {authType === "signup" && (
                <>
                  <div className="input" style={{ position: "relative" }}>
                    <LockIcon className="inputIcon" color="primary" />
                    <Field
                      name="password"
                      type={showPass ? "text" : "password"}
                      placeholder="Enter password"
                      label="Password"
                      required
                      fullWidth
                      className="black-text"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPass((prevState) => !prevState)}>
                              {showPass ? (
                                <VisibilityOffIcon color="primary" />
                              ) : (
                                <VisibilityIcon color="primary" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: "0", left: "96.69%" }}
                      onClick={() => setOpenPasswordDialog(true)}
                    >
                      <HelpOutlineIcon 
  color="primary" 
  sx={{ 
    boxShadow: 'none', 
    border: 'none' 
  }} 
/>
                    </IconButton>
                  {errors.password && values.password && (
                    <div style={{ position: "absolute", top: "7.69px", left: "87%" }}>
                      <CancelIcon style={{ color: 'red' }} />
                    </div>
                  )}
                  {!errors.password && values.password && (
                    <div style={{ position: "absolute", top: "7.69px", left: "87%" }}>
                      <CheckCircleIcon style={{ color: 'green' }} />
                    </div>
                  )}
                  </div>
                  <div className="input" style={{ position: "relative" }}>
                    <LockIcon className="inputIcon" color="primary" />
                    <Field
                      name="confirmPassword"
                      type={showPass ? "text" : "password"}
                      placeholder="Confirm password"
                      label="Confirm Password"
                      required
                      fullWidth
                      className="black-text"
                      onChange={(e) => {
                        setFieldValue("confirmPassword", e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPass((prevState) => !prevState)}>
                              {showPass ? (
                                <VisibilityOffIcon color="primary" />
                              ) : (
                                <VisibilityIcon color="primary" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.confirmPassword && values.confirmPassword && (
                      <div style={{ position: "absolute", top: "7.69px", left: "87%" }}>
                        <CancelIcon style={{ color: 'red' }} />
                      </div>
                    )}
                    {!errors.confirmPassword && values.confirmPassword && (
                      <div style={{ position: "absolute", top: "7.69px", left: "87%" }}>
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </div>
                    )}
                  </div>
                  <div className="input" style={{ position: "relative" }}>
                    <EmailIcon className="inputIcon" color="primary" />
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter Email Address"
                      label="Email Address"
                      required
                      fullWidth
                      className="black-text"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                        debouncedCheckEmailAvailability(e.target.value);
                      }}
                    />
                    {emailChecked && (
                      <div style={{ position: "absolute", top: "7.69px", left: "87%" }}>
                        {isEmailAvailable ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <CancelIcon style={{ color: 'red' }} />
                        )}
                      </div>
                    )}
                  </div>
                  {isUsernameAvailable && (
                    <>
                      <Typography variant="h6">Google Authenticator</Typography>
                      <Button
                        onClick={() => window.open("/qr-code-instructions", "_blank")}
                        color="primary"
                        variant="contained"
                        className="instructionsButton"
                      >
                        Instructions
                      </Button>
                      {isLoadingQRCode ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <img src={qrCodeUrl} alt="QR Code" />
                          <Button
                            onClick={copyToClipboard}
                            color="primary"
                            variant="contained"
                            startIcon={<FileCopyIcon />}
                          >
                            Copy Setup Key
                          </Button>
                          <div className="input">
                            <VpnKeyIcon className="inputIcon" color="primary" />
                            <Field
                              name="totpToken"
                              type="text"
                              placeholder="Enter 6 Digit Code"
                              label="TOTP Token"
                              required
                              fullWidth
                              value={values.totpToken}
                              onChange={(e) => setFieldValue("totpToken", e.target.value)}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {authType === "login" && (
                <>
                  <div className="input">
                    <LockIcon className="inputIcon" color="primary" />
                    <Field
                      name="password"
                      type={showPass ? "text" : "password"}
                      placeholder="Enter Password"
                      label="Password"
                      required
                      className="black-text"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPass((prevState) => !prevState)}>
                              {showPass ? (
                                <VisibilityOffIcon color="primary" />
                              ) : (
                                <VisibilityIcon color="primary" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="input">
                    <VpnKeyIcon className="inputIcon" color="primary" />
                    <Field
                      name="totpToken"
                      type="text"
                      placeholder="Enter 6 Digit Code"
                      label="TOTP Token"
                      required
                      fullWidth
                      value={values.totpToken}
                      onChange={(e) => setFieldValue("totpToken", e.target.value)}
                    />
                  </div>
                </>
              )}
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                startIcon={
                  authType === "login" ? <ExitToAppIcon /> : <PersonAddIcon />
                }
                className="submitButton"
                disabled={isSubmitting}
              >
                {authType === "login"
                  ? isSubmitting
                    ? "Logging In"
                    : "Login"
                  : isSubmitting
                  ? "Signing Up"
                  : "Sign Up"}
              </Button>
              <Button
                onClick={() =>
                  authType === "login"
                    ? setAuthType("signup")
                    : setAuthType("login")
                }
                fullWidth
                size="large"
                color="primary"
                variant="outlined"
                startIcon={
                  authType === "login" ? <PersonAddIcon /> : <ExitToAppIcon />
                }
                disabled={isSubmitting}
                className="toggleButton"
              >
                {authType === "login" ? "Sign Up" : "Login"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <CustomDialog
      open={openPasswordDialog}
      onClose={() => setOpenPasswordDialog(false)}
      aria-labelledby="password-dialog-title"
    >
      <CustomDialogTitle id="password-dialog-title">Signup Requirements</CustomDialogTitle>
      <DialogContent>
        <CustomDialogContentText>
          <strong>Username</strong>
          <ul>
            <li>Must be at most 20 characters</li>
            <li>Must be at least 3 characters</li>
            <li>Only alphanumeric characters allowed, no spaces/symbols</li>
          </ul>
          <strong>Password</strong>
          <ul>
            <li>Must be at least 10 characters</li>
            <li>Must contain at least one uppercase letter</li>
            <li>Must contain at least one lowercase letter</li>
            <li>Must contain at least one number</li>
            <li>Must contain at least one special character</li>
          </ul>
          <strong>Confirm Password</strong>
          <ul>
            <li>Must match the password</li>
          </ul>
          <strong>Email</strong>
          <ul>
            <li>Must be a valid email address</li>
            <li>Will be only used for recovery & support.</li>
          </ul>
          <strong>Google Authenticator</strong>
          <ul>
            <li>Required</li>
            <li>Will be used each time you Login.</li>
          </ul>
        </CustomDialogContentText>
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={() => setOpenPasswordDialog(false)} sx={{ color: 'black' }}>
          Close
        </Button>
      </CustomDialogActions>
    </CustomDialog>
    <CustomDialog
      open={openAgreementDialog}
      onClose={() => setOpenAgreementDialog(false)}
      aria-labelledby="agreement-dialog-title"
    >
      <CustomDialogTitle id="agreement-dialog-title">Terms and Conditions</CustomDialogTitle>
      <DialogContent>
        <CustomDialogContentText>
          By signing up, you agree to our <a href="/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/guidelines.html" target="_blank" rel="noopener noreferrer">Guidelines</a>.
        </CustomDialogContentText>
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={() => handleAgreement(false)} sx={{ color: 'black' }}>
          No
        </Button>
        <Button onClick={() => handleAgreement(true)} sx={{ color: 'black' }}>
          Yes
        </Button>
      </CustomDialogActions>
    </CustomDialog>
    </div>
  );
};

export default AuthTwo;
