import React, { useState, useEffect } from 'react';
import {
  TextareaAutosize, FormControl, InputLabel, Select, MenuItem,
  IconButton, TextField, InputAdornment, Dialog, DialogContent,
  DialogActions, Button, Checkbox, FormControlLabel, Typography
} from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { GiTreeBranch } from "react-icons/gi";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Autocomplete } from '@material-ui/lab';
import { handleInputChange } from './formhandler';
import HelpInfoDialog from './AIHelpInfoDialog';
import GetSeeds from './GetSeeds';
import domainsByModel from '../explorer/domainsByModel'; // Adjust the import path as needed
import axios from 'axios';

const aiModels = [ 
  { name: 'Willow', alias: 'Willow', accepts: ['text'], cost: 10 },
  { name: 'Oak', alias: 'Oak', accepts: ['text', 'web', 'image'], cost: 20 },
  // Add Cypress and Maple if they are still needed
  // { name: 'Cypress', alias: 'Cypress', accepts: ['...'], cost: ... },
  // { name: 'Maple', alias: 'Maple', accepts: ['...'], cost: ... },
];

function AIForm({ formData, setFormData, classes }) {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [getSeedsDialogOpen, setGetSeedsDialogOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState('');
  const [showDomainFields, setShowDomainFields] = useState(false);
  const [availableSeeds, setAvailableSeeds] = useState(0);
  const [modelCost, setModelCost] = useState(0);
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  useEffect(() => {
    const fetchUserSeeds = async () => {
      try {
        const response = await axios.get(`/api/user/seeds?username=${loggedInUser}`);
        setAvailableSeeds(response.data.seeds);
      } catch (error) {
        console.error('Error fetching seeds:', error);
      }
    };

    if (selectedModel) {
      fetchUserSeeds();
      const model = aiModels.find((model) => model.name === selectedModel);
      if (model) {
        setModelCost(model.cost);
      } else {
        setModelCost(0); // or handle as appropriate
      }
    }
  }, [selectedModel, loggedInUser]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newKey = e.target.value.trim();
      if (newKey && (!formData.keys || !formData.keys.includes(newKey))) {
        const updatedKeys = [...(formData.keys || []), newKey];
        if (updatedKeys.length <= 7) {
          setFormData({ ...formData, keys: updatedKeys });
        } else {
          alert('You can only add up to 7 branches.');
        }
      }
      e.target.value = '';
    }
  };

  const removeKey = (index) => {
    const updatedKeys = formData.keys.filter((_, i) => i !== index);
    setFormData({ ...formData, keys: updatedKeys });
  };

  const handleHelpDialogOpen = () => {
    setHelpDialogOpen(true);
  };

  const handleHelpDialogClose = () => {
    setHelpDialogOpen(false);
  };

  const handleGetSeedsDialogOpen = () => {
    setGetSeedsDialogOpen(true);
  };

  const handleGetSeedsDialogClose = () => {
    setGetSeedsDialogOpen(false);
  };

  const handleModelChange = (e) => {
    const { value } = e.target;
    setSelectedModel(value);
    const selectedAiModel = aiModels.find((model) => model.name === value);
    setFormData({
      ...formData,
      aiModel: value,
      aiPromptText: '',
      aiPromptImage: '',
      useWebSearch: false,
      keys: [],
      domain: '',
      domainModel: '', // Renamed from 'model' to 'domainModel' to avoid confusion
      seedsUsed: selectedAiModel ? selectedAiModel.cost : 0,
    });
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>AI Model</InputLabel> 
        <Select
          value={formData.aiModel}
          onChange={handleModelChange}
          label="AI Model" 
          name="aiModel"
        >
          {aiModels.map((model) => (
            <MenuItem key={model.name} value={model.name}>
              {model.alias}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ textAlign: 'center' }}>
        <Typography variant="body2" className={classes.seedsInfo}>
          {availableSeeds} of {modelCost} Seeds Available
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGetSeedsDialogOpen}
          className={classes.getSeedsButton}
          style={{ textTransform: 'none', fontSize: '0.875rem', marginTop: '8px', marginBottom: '8px', width: 'fit-content' }}
        >
          Get Seeds
        </Button>
      </div>

      {formData.aiModel === 'Willow' && (
        <TextareaAutosize
          className={classes.descriptionField}
          minRows={3}
          maxRows={10}
          placeholder="What text do you want Willow to Generate for you?"
          name="aiPromptText"
          value={formData.aiPromptText}
          onChange={(e) => handleInputChange(e, formData, setFormData)}
          maxLength={6969}
          required
        />
      )}

      {formData.aiModel === 'Oak' && (
        <>
          <TextareaAutosize
            className={classes.descriptionField}
            minRows={3}
            maxRows={10}
            placeholder="What text do you want Oak to Generate for you?"
            name="aiPromptText"
            value={formData.aiPromptText}
            onChange={(e) => handleInputChange(e, formData, setFormData)}
            maxLength={6969}
            required
          />
          <TextareaAutosize
            className={classes.descriptionField}
            minRows={3}
            maxRows={10}
            placeholder="Describe the image you want Oak to Generate"
            name="aiPromptImage"
            value={formData.aiPromptImage}
            onChange={(e) => handleInputChange(e, formData, setFormData)}
            maxLength={6969}
            required
          />
        </>
      )}

      {/* Removed Cypress and Maple conditionals if not needed */}
      {/* If needed, ensure they are included in aiModels */}

      <TextField
        className={classes.keysField}
        placeholder="Add Branch Words/Phrases"
        onKeyPress={handleKeyPress}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <GiTreeBranch />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleHelpDialogOpen}>
                <HelpOutlineIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.keysPreview}>
        {(formData.keys || []).map((key, index) => (
          <span key={index} className={classes.keyTag}>
            {key}
            <IconButton size="small" onClick={() => removeKey(index)}>
              &times;
            </IconButton>
          </span>
        ))}
      </div>

      <FormControlLabel
        control={
          <Checkbox
            checked={showDomainFields}
            onChange={(e) => setShowDomainFields(e.target.checked)}
            name="showDomainFields"
            color="default"
            style={{ color: 'black' }}
          />
        }
        label="Add Field"
      />

      {showDomainFields && (
        <>
          <Autocomplete
            className={classes.dropdown}
            options={Object.keys(domainsByModel)}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setSelectedModel(newValue);
              setFormData({ ...formData, domain: '', domainModel: newValue }); // Updated 'model' to 'domainModel'
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Field"
                variant="outlined"
                required
              />
            )}
          />
          {selectedModel && domainsByModel[selectedModel] && (
            <Autocomplete
              className={classes.dropdown}
              options={domainsByModel[selectedModel]}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => handleInputChange({ target: { name: 'domain', value: newValue }}, formData, setFormData)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="outlined"
                  required
                />
              )}
            />
          )}
        </>
      )}

      <Dialog open={helpDialogOpen} onClose={handleHelpDialogClose}>
        <DialogContent>
          <HelpInfoDialog />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={getSeedsDialogOpen} onClose={handleGetSeedsDialogClose}>
        <DialogContent>
          <GetSeeds />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGetSeedsDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AIForm;
