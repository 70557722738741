import React, { useState, useEffect } from 'react';
import './boot.css';

const bootMessages = [
    "Initiating Programmer's Daily Routine...",
    "Brewing Morning Coffee...",
    "Updating Local Development Environment...",
    "Pulling Latest Code from Repository...",
    "Scanning Emails and Team Messages...",
    "Reviewing Today's Task List...",
    "Setting Up Virtual Work Environment...",
    "Launching Code Editor and Tools...",
    "Loading Project Files and Dependencies...",
    "Connecting to Remote Servers...",
    "Running Initial Code Tests...",
    "Entering Focus Mode for Productive Coding...",
    "Hoping Campbell Will Like It",
    "I Hope So",
    // ... other messages
];

const RetroBootScreen = () => {
    const [displayedMessages, setDisplayedMessages] = useState([]);

    useEffect(() => {
        let messageIndex = 0;
        const interval = setInterval(() => {
            setDisplayedMessages(prevMessages => [...prevMessages, bootMessages[messageIndex]]);
            messageIndex++;
            if (messageIndex >= bootMessages.length) clearInterval(interval);
        }, 1000); // Adjust time for each message display

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="retro-screen">
            {displayedMessages.map((message, index) => (
                <div key={index} className="boot-text">{message}</div>
            ))}
        </div>
    );
};

export default RetroBootScreen;