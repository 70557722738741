import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog, DialogContent, CircularProgress
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EcologySubmit from '../add/addEntry';
import EcologyCardSelect from '../cardselect/cardSelect';
import EntriesGrid from './entriesGrid';
import SearchAndSort from './searchandsort';
import useScrollToTop from '../scrolltotop';
import { useStyles } from './explorerStyles';
import { fetchEntries, recordUserInteraction, handleEntryAction } from './explorerapiHelper';
import addIcon from '../../../../assets/add.png';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

const Explorer = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [entries, setEntries] = useState([]);
  const [activeDomains, setActiveDomains] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [domainsDialogOpen, setDomainsDialogOpen] = useState(false);
  const [valuesDialogOpen, setValuesDialogOpen] = useState(false);
  const [savedDialogOpen, setSavedDialogOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const { checkScrollTop, scrollToTop } = useScrollToTop();
  const [usernames, setUsernames] = useState([]);
  const [keys, setKeys] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeParameter, setTimeParameter] = useState('');
  const [searchEntries, setSearchEntries] = useState('');
  const [followed, setFollowed] = useState(false);
  // New states for the SavedDialog categories
  const [branches, setBranches] = useState([]);
  const [fields, setFields] = useState([]);
  const [categories, setCategories] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [originNames, setOriginNames] = useState([]);
  const [selectedMediaTypes, setSelectedMediaTypes] = useState([]);
  const [selectedItemsByType, setSelectedItemsByType] = useState({
    branches: [],
    fields: [],
    categories: [],
    origins: [],
    originNames: [],  
  });

  const handleSelectFromSaved = (category, newSelectedItems) => {
    setSelectedItemsByType((prevSelectedItemsByType) => ({
      ...prevSelectedItemsByType,
      [category]: newSelectedItems,
    }));

    // Update specific states based on the category
    switch (category) {
      case 'branches':
        setBranches(newSelectedItems);
        break;
      case 'fields':
        setFields(newSelectedItems);
        break;
      case 'categories':
        setCategories(newSelectedItems);
        break;
      case 'origins':
        setOrigins(newSelectedItems);
        break;
      case 'originNames':
        setOriginNames(newSelectedItems);
        break;
      default:
        break;
    }

    // Re-fetch entries with the updated selections
    updateEntries();
  };
  
  // Inside your component
const updateEntries = () => {
  setEntries([]);
  setPage(0);
  setHasMore(true);

  fetchEntries(
    0,
    activeDomains,
    searchTerm,
    sortOrder,
    selectedDomains,
    setEntries,
    setHasMore,
    usernames,
    keys,
    timeParameter,
    searchEntries,
    selectedItemsByType.branches,
    selectedItemsByType.fields,
    selectedItemsByType.categories,
    selectedItemsByType.origins,
    selectedItemsByType.originNames,
    followed,
    selectedMediaTypes  // Now 'mediaTypes' is passed correctly
  );
};

  useEffect(() => {
    setEntries([]);
    setPage(0);
    setHasMore(true);
  }, [
    activeDomains,
    searchTerm,
    sortOrder,
    selectedDomains,
    usernames,
    keys,
    timeParameter,
    searchEntries,
    branches,
    fields,
    categories,
    origins,
    originNames,
    followed,
    selectedMediaTypes  // Include selectedMediaTypes in dependencies
  ]);

  useEffect(() => {
    const loadEntries = async () => {
      setLoading(true);
      await fetchEntries(
        page,
        activeDomains,
        searchTerm,
        sortOrder,
        selectedDomains,
        setEntries,
        setHasMore,
        usernames,
        keys,
        timeParameter,
        searchEntries,
        branches,
        fields,
        categories,
        origins,
        originNames,
        followed,
        selectedMediaTypes
      );
      setLoading(false);
    };
    loadEntries();
  }, [
    activeDomains,
    searchTerm,
    sortOrder,
    selectedDomains,
    page,
    usernames,
    keys,
    timeParameter,
    searchEntries,
    branches,
    fields,
    categories,
    origins,
    originNames,
    followed,
    selectedMediaTypes 
  ]);

  const throttleScroll = useCallback(
    _.throttle(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      setShowScrollToTop(scrollTop > 300); // Show "Top" button after scrolling down 300px
    }, 200),
    []
  ); // Adjust the delay (200ms) to your preference

  useEffect(() => {
    window.addEventListener('scroll', throttleScroll);

    return () => {
      window.removeEventListener('scroll', throttleScroll);
    };
  }, [throttleScroll]);

  const fetchMoreData = useCallback(() => {
    if (loading || !hasMore) return;
  
    setLoading(true);
    fetchEntries(
      page + 1,
      activeDomains,
      searchTerm,
      sortOrder,
      selectedDomains,
      setEntries,
      setHasMore,
      usernames,
      keys,
      timeParameter,
      searchEntries,
      branches,
      fields,
      categories,
      origins,
      originNames,
      followed,
      selectedMediaTypes
    ).finally(() => {
      setLoading(false);
      setPage((prevPage) => prevPage + 1);
    });
  }, [
    loading,
    hasMore,
    page,
    activeDomains,
    searchTerm,
    sortOrder,
    selectedDomains,
    usernames,
    keys,
    timeParameter,
    searchEntries,
    branches,
    fields,
    categories,
    origins,
    originNames,
    followed,
    selectedMediaTypes 
  ]);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleCardClick = (entry) => {
    const tag = window.location.pathname.split('/').pop();
    setSelectedEntry(entry);
    recordUserInteraction('entrySelection', {
      selectedEntryId: entry._id,
      domain: entry.domain,
      tag: tag,
    });
    handleEntryAction(entry._id, entry.domain, tag);
  };

  const handleDeleteKey = (keyToDelete) => {
    setKeys(keys.filter((key) => key !== keyToDelete));
    setUsernames(usernames.filter((username) => username !== keyToDelete));
  };

  return (
    <div className={classes.container}>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <img src={addIcon} alt="Add" onClick={toggleForm} className={classes.addIcon} />
      </div>
      <SearchAndSort
        isMobile={isMobile}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setActiveDomains={setActiveDomains}
        setSelectedDomains={setSelectedDomains}
        selectedMediaTypes={selectedMediaTypes}
        setSelectedMediaTypes={setSelectedMediaTypes}
        setSearchTerm={setSearchTerm}
        setDomainsDialogOpen={setDomainsDialogOpen}
        setValuesDialogOpen={setValuesDialogOpen}
        activeDomains={activeDomains}
        selectedDomains={selectedDomains}
        domainsDialogOpen={domainsDialogOpen}
        valuesDialogOpen={valuesDialogOpen}
        usernames={usernames}
        setUsernames={setUsernames}
        keys={keys}
        setKeys={setKeys}
        handleDeleteKey={handleDeleteKey}
        searchEntries={searchEntries}
        setSearchEntries={setSearchEntries}
        timeParameter={timeParameter}
        setTimeParameter={setTimeParameter}
        handleSelectFromSaved={handleSelectFromSaved}
        setBranches={setBranches}          // Pass the setBranches function
        setFields={setFields}              // Pass the setFields function
        setCategories={setCategories}      // Pass the setCategories function
        setOrigins={setOrigins}            // Pass the setOrigins function
        setOriginNames={setOriginNames}    // Pass the setOriginNames function
        setSelectedItemsByTypeInDialog={setSelectedItemsByType}      
        followed={followed}                // Pass followed state
        setFollowed={setFollowed}          // Pass setFollowed function
      />
      <InfiniteScroll
        dataLength={entries.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div
            className={classes.loader}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}
          >
            <CircularProgress style={{ color: 'white' }} />
          </div>
        }
        scrollThreshold={0.69}
        style={{ overflow: 'unset' }} // Prevents the scroll container from restricting the page scroll
      >
        <EntriesGrid entries={entries} isMobile={isMobile} classes={classes} handleCardClick={handleCardClick} />
      </InfiniteScroll>
      <Dialog
        open={isFormOpen}
        onClose={handleCloseForm}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <DialogContent>
          <EcologySubmit />
        </DialogContent>
      </Dialog>
      {selectedEntry && (
        <EcologyCardSelect isOpen={true} onClose={() => setSelectedEntry(null)} post={selectedEntry} />
      )}
      {showScrollToTop && (
        <button onClick={scrollToTop} className={classes.scrollToTopButton}>
          Top
        </button>
      )}
    </div>
  );
};

export default Explorer;
