// src/api/pathways.js

import axios from 'axios';

/**
 * Fetch pathways with pagination and filters.
 * @param {number} page - Current page number.
 * @param {object} filters - Filter parameters.
 * @returns {Promise<object>} - Response data containing pathways.
 */
export const fetchPathways = async (page, filters) => {
  const params = {
    page,
    limit: 10,
    ...filters,
  };
  const response = await axios.get('/api/pathways', { params });
  return response.data;
};

/**
 * Create a new pathway.
 * @param {FormData | object} pathwayData - Data for the new pathway.
 * @param {boolean} isFormData - Indicates if pathwayData is FormData.
 * @returns {Promise<object>} - Response data of the created pathway.
 */
export const createPathway = async (pathwayData, isFormData = false) => {
  const config = isFormData
    ? { headers: { 'Content-Type': 'multipart/form-data' } }
    : { headers: { 'Content-Type': 'application/json' } };
  const response = await axios.post('/api/pathways', pathwayData, config);
  return response.data;
};

/**
 * Update an existing pathway.
 * @param {string} pathwayId - ID of the pathway to update.
 * @param {FormData | object} pathwayData - Updated pathway data.
 * @returns {Promise<object>} - Response data of the updated pathway.
 */
export const updatePathway = async (pathwayId, formData) => {
  try {
    const response = await axios.put(`/api/pathways/${pathwayId}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Error updating pathway:', error);
    throw error;
  }
};

/**
 * Add support to a pathway.
 * @param {string} pathwayId - ID of the pathway.
 * @param {object} supportData - Data for the support.
 * @returns {Promise<object>} - Response data after adding support.
 */
export const addSupport = async (pathwayId, supportData) => {
  const response = await axios.post(`/api/pathways/${pathwayId}/support`, supportData);
  return response.data;
};
