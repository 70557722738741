// Person.js

import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Dialog,
  DialogContent,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getCircularAvatar } from '../../utils/cloudinaryTransform';
import storageService from '../../utils/localStorage';
import { fetchUser } from '../../reducers/userPageReducer';
import AddedActiveShapePieChart from './persexplorer/biology/AddedRadar';
import KeptActiveShapePieChart from './persexplorer/biology/KeptRadar';
import { DomainContext } from './DomainContext';
import axios from 'axios';
import { notify } from '../../reducers/notificationReducer';
import UserSettingsMenu from './settings';
import PersonStats from './stats';
import DomainToExplorer from './newexplorer/domaintoexplorer';
import AddAvatar from './funcs/AddAvatar';

const useStyles = makeStyles((theme) => ({
  personContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    margin: '0',
    paddingTop: '42px', // Added buffer from the top
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    width: '169px',
    height: '169px',
    background: 'transparent',
    borderRadius: '50%',
    boxShadow: '0 0 10px turquoise',
    animation: '$glow 2s infinite alternate',
    cursor: 'pointer',
  },
  '@keyframes glow': {
    '0%': {
      boxShadow: '0 0 10px transparent',
    },
    '100%': {
      boxShadow: '0 0 10px turquoise',
    },
  },
  usernameBackground: {
    backgroundColor: '#9C9C6E',
    padding: theme.spacing(1),
    borderRadius: '4px',
    display: 'inline-block',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    marginTop: theme.spacing(2),
    marginBottom: '3%',
  },
  usernameText: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.5rem',
    letterSpacing: '1px',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  closeButton: {
    backgroundColor: '#9C9C6E',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.5rem',
    letterSpacing: '1px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#8B8B5E',
    },
  },
  personHeader: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '-17px',
  },
  personHeaderButton: {
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  chartsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginLeft: '0%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  activeChartsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    margin: '3px 0',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  activeChart: {
    flex: 1,
    '&:first-child': {
      marginRight: '10px',
    },
    '&:last-child': {
      marginLeft: '10px',
    },
  },
  mobileContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  mobileChartsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    '& > *': {
      marginBottom: theme.spacing(2),
      width: '90%',
    },
  },
  menu: {
    marginTop: '45px',
  },
}));

const Person = ({ username, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userPage);
  const loggedUser = storageService.loadUser();
  const userDetails = userInfo && userInfo.userDetails;
  const { avatar, username: userName } = userDetails || {};
  const [selectedDomain, setSelectedDomain] = useState('');
  const [activeExplorer, setActiveExplorer] = useState('');
  const [requestExists, setRequestExists] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openOtherDialog, setOpenOtherDialog] = useState(false);
  const [showStatsDirectly, setShowStatsDirectly] = useState(false);
  const [openAddAvatar, setOpenAddAvatar] = useState(false);

  const handleCloseOtherDialog = () => {
    setOpenOtherDialog(false);
  };

  useEffect(() => {
    dispatch(fetchUser(username));
  }, [dispatch, username]);

  useEffect(() => {
    const checkRequestExists = async () => {
      try {
        const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
        if (!readifyUserKey) {
          setRequestExists(false);
          return;
        }

        const response = await axios.get('/api/check-request-exists', {
          params: {
            requestor: readifyUserKey.username,
            receiver: username,
          },
        });

        setRequestExists(response.data.exists);
      } catch (error) {
        console.error('Error checking if request exists:', error);
        setRequestExists(false);
      }
    };

    checkRequestExists();
  }, [username]);

  const handleRequestConversation = async () => {
    const checkRequestExists = async () => {
      try {
        const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
        if (!readifyUserKey) {
          dispatch(notify('Please log in to send a conversation request.', 'error'));
          return;
        }

        const response = await axios.get('/api/check-request-exists', {
          params: {
            requestor: readifyUserKey.username,
            receiver: username,
          },
        });

        setRequestExists(response.data.exists);
      } catch (error) {
        console.error('Error checking if request exists:', error);
        setRequestExists(false);
      }
    };

    try {
      const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
      if (!readifyUserKey) {
        dispatch(notify('Please log in to send a conversation request.', 'error'));
        return;
      }

      const conversationRequest = {
        requestor: readifyUserKey.username,
        receiver: username,
      };

      await axios.post('/api/create-request', conversationRequest);

      await checkRequestExists();

      dispatch(notify('Conversation request sent successfully.', 'success'));
    } catch (error) {
      console.error('Error sending conversation request:', error);
      dispatch(notify('Error sending conversation request. Please try again later.', 'error'));
    }
  };

  const handleAvatarClick = (event) => {
    if (loggedUser && username === loggedUser.username) {
      // If the logged-in user is viewing their own profile, open the menu
      setAnchorEl(event.currentTarget);
    } else {
      // If viewing someone else's profile, show stats
      setShowStatsDirectly(true);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddProfileImage = () => {
    setOpenAddAvatar(true);
    handleCloseMenu();
  };

  const handleShowStats = () => {
    setShowStatsDirectly(true);
    handleCloseMenu();
  };

  const handleCloseAddAvatar = () => {
    setOpenAddAvatar(false);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.90)',
        },
      }}
      fullScreen
      fullWidth
      maxWidth={false}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogContent
        style={{
          padding: 0,
          overflowY: 'auto',
          width: '100vw',
          height: '100vh',
        }}
      >
        <DomainContext.Provider
          value={{ selectedDomain, setSelectedDomain, activeExplorer, setActiveExplorer }}
        >
          <div className={classes.personContainer}>
            <div className={classes.avatarContainer}>
              <Avatar
                alt={userName}
                src={avatar && avatar.exists ? getCircularAvatar(avatar.imageLink) : undefined}
                variant="circular"
                className={classes.avatar}
                onClick={handleAvatarClick}
              >
                {!avatar || !avatar.exists ? userName : ''}
              </Avatar>
              {/* Menu for own profile */}
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleAddProfileImage}>Add Profile Image</MenuItem>
                <MenuItem onClick={handleShowStats}>Show Stats</MenuItem>
              </Menu>
              {/* Add Avatar Dialog */}
              <Dialog
                open={openAddAvatar}
                onClose={handleCloseAddAvatar}
                aria-labelledby="add-avatar-dialog-title"
              >
                <DialogContent>
                  <AddAvatar closeModal={handleCloseAddAvatar} />
                </DialogContent>
              </Dialog>
            </div>
            <div className={classes.usernameBackground}>
              <span className={classes.usernameText}>{userName}</span>
            </div>
            <div className={classes.closeButtonContainer}>
              <Button className={classes.closeButton} onClick={onClose}>
                Close
              </Button>
            </div>
            <div className={classes.chartsContainer}>
              <DomainToExplorer username={username}/>
              <div className={classes.activeChartsContainer}>
                {/* Existing charts or additional components */}
              </div>
            </div>
            <div className={classes.mobileContainer}>
              <div className={classes.mobileChartsContainer}>
                {/* Existing mobile charts or additional components */}
              </div>
            </div>
            {showStatsDirectly && <PersonStats username={username} />}
          </div>
        </DomainContext.Provider>
      </DialogContent>
    </Dialog>
  );
};

export default Person;
