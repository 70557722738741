import React, { useState } from 'react';
import { Typography, List, ListItem, ListItemText, makeStyles, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: '#E8F0F2', // light tiffany blue
    borderRadius: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    color: '#4F6D7A', // complementary color to tiffany blue
  },
  list: {
    backgroundColor: '#F3F7F0', // off white with a hint of sage green
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItemTextPrimary: {
    fontWeight: 'bold',
    color: '#4F6D7A', // complementary color to tiffany blue
  },
  listItemTextSecondary: {
    color: '#8E9A9A', // natural looking secondary text color
  },
  link: {
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

function HelpInfoDialog() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Help Information
      </Typography>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
<ListItemText
    primary="2. Branches"
    secondary={
      <>
        <div>Choose a personal branch name or phrase based on what the entry means to you or the key information it holds.</div>
        <div>Type one and then enter to include each for the entry.</div>
        <div>This helps others find it if you share it with them.</div>
        <div>Maximum of 7 branches per entry.</div>
      </>
    }
    classes={{
      primary: classes.listItemTextPrimary,
      secondary: classes.listItemTextSecondary,
    }}
  />
</ListItem>
<ListItem className={classes.listItem}>
  <ListItemText
    primary="2. Gatherers"
    secondary={
      <>
        <div>Willow:</div>
        <ul>
          <li>Willow is your go-to for everyday stuff—like chatting, writing quick notes, or grabbing a simple code example.</li>
          <li>It’s perfect for those quick questions and can help you brainstorm ideas.</li>
          <li>If you need some basic HTML, Markdown, or JSON, Willow's got it.</li>
        </ul>
        <div>Oak:</div>
        <ul>
          <li>Oak steps in when things get a bit trickier.</li>
          <li>It’s great for handling more complex questions and can even search the web if you need.</li>
          <li>With Oak, you can create images, detailed reports, or even build charts and structured data tables.</li>
          <li>Think of it as a knowledgeable buddy who can give you deep insights and help with just about anything.</li>
        </ul>
      </>
    }
    classes={{
      primary: classes.listItemTextPrimary,
      secondary: classes.listItemTextSecondary,
    }}
  />
</ListItem>

<ListItem className={classes.listItem}>
        <ListItemText
  primary="3. Add Field"
  secondary={
    <>
      <div>Select from the available options in the drop-down.</div>
      <div>
        If you have ideas for new Fields, please visit Polls in the Compass Menu</div>
    </>
  }
  classes={{
    primary: classes.listItemTextPrimary,
    secondary: classes.listItemTextSecondary,
  }}
/>
</ListItem>
<ListItem className={classes.listItem}>
  <ListItemText
    primary="4. Category"
    secondary={
      <>
        <div>Select from the available options in the drop-down.</div>
      <div>
        If you have ideas for new Fields, please visit Polls in the Compass Menu</div>
    </>
  }
    classes={{
      primary: classes.listItemTextPrimary,
      secondary: classes.listItemTextSecondary,
    }}
  />
</ListItem>
      </List>
      <Button variant="outlined" color="primary" className={classes.button} onClick={handleClickOpen} style={{ textTransform: 'none' }}>
  Suggestions
</Button>
      <Dialog open={open} onClose={handleClose}>
  <DialogContent>
    <List>
      <ListItem>
        <ListItemText
          primary="Be Clear and Specific"
          secondary="The more precise you are the better Willow or Oak can understand what you're asking for and provide a meaningful response. Instead of asking for a general summary, try to pinpoint exactly what information you need."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Ask Direct Questions"
          secondary="Direct questions often lead to the most accurate answers. For example, asking 'How does quantum computing impact cryptography?' is better than 'Tell me about quantum computing.'"
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Provide Context"
          secondary="Giving some background can help Willow or Oak tailor their responses to your specific situation. For instance, if you're asking about marketing strategies, mention the industry or target audience."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Use Examples"
          secondary="Examples can clarify what you're asking for and guide the AI in generating more relevant responses. For example, 'Can you write a blog post like this one?' followed by a sample link or text."
        />
      </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HelpInfoDialog;
