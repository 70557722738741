import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import generateBase64Encode from '../utils/genBase64Encode';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import AddImage from '../assets/art/AddImage.png';
const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    minHeight: '100%', // Ensure it at least covers the full viewport height
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent', 
    alignItems: 'center', 
    border: 'none',
    outline: 'none',
  },
  form: {
    width: 'calc(69vw)',
    maxWidth: '100%',
    margin: 'auto',
    backgroundColor: "#c5c9cc",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  textField: {
    width: '90%',
    marginBottom: theme.spacing(3),
    backgroundColor: "#ffffff",
    '& .MuiInputLabel-root': {
        color: 'black', // Ensure label color is black by default
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main, // Change color when focused
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.dark,
      },
      '& input': {
        color: theme.palette.text.primary,
      },
    },
  },
  descriptionField: {
    width: '90%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '&:focus': {
      borderColor: theme.palette.secondary.main,
    },
    resize: 'vertical',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    '&::placeholder': {
      color: theme.palette.text.disabled,
    },
  },
  submitButton: {
    width: '90%',
    padding: theme.spacing(2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    backgroundColor: "#57c0bf",
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  imageInputLabel: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  linkField: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  linkInput: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    borderColor: theme.palette.primary.light,
  },
  linkRemoveButton: {
    minWidth: 'auto',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
    '& .MuiButton-label': {
      fontSize: '0.8rem',
    },
  },
}));

const sourceTypes = [
  'Institution',
  'Company',
  'Book',
  'Movie',
  'Personal',
  'Social Media',
  'Television',
  'Newspaper',
];

const domains = [
  'Classical ',
  'Gothic ',
  'Renaissance ',
  'Baroque ',
  'Neoclassical ',
  'Romantic ',
  'Victorian ',
  'Modern ',
  'Contemporary ',
  'Futurist ',
  'Brutalist ',
  'Postmodern ',
  'Deconstructivism',
  'Eco-Friendly ',
  'Sustainable ',
  'Green Building',
  'High-Tech ',
  'Minimalist ',
  'Expressionist ',
  'Art Nouveau ',
  'Art Deco ',
  'Bauhaus ',
  'Regional ',
  'Vernacular ',
  'Other Architecture '
];

function EcologyAdd() {
  const classes = useStyles();
  const [imagePreview, setImagePreview] = useState(null);
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
  const [adding, setAdding] = useState(false);
  const [formData, setFormData] = useState({
    topic: '',
    description: '',
    mediaData: '',
    mediaType: '', // 'image' or 'video'
    links: [''],
    sourceType: '',
    domain: '',
    additionalField: '',
    username: loggedInUser,
  });

  const handleMediaUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }
  
    const fileType = file.type.split('/')[0];
  
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData(prevFormData => ({
        ...prevFormData,
        mediaData: reader.result,
        mediaType: fileType.includes('image') ? 'image' : 'video', // More robust check
      }));
  
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [numLinks, setNumLinks] = useState(0); // State variable for number of links
  const renderDomainField = () => (
    <TextField
      className={classes.textField}
      label="Field"
      
  name="domain"
  variant="outlined"
  select
  required
  SelectProps={{
    native: true,
    displayEmpty: true, // Add this to display the label when no item is selected
  }}
  value={formData.domain}
  onChange={handleInputChange}
  InputLabelProps={{
    shrink: true, // Add this to ensure the label is always visible
  }}
>
  <option aria-label="domain" value="" /> 
  {domains.map((option) => (
    <option key={option} value={option}>
      {option}
    </option>
  ))}
</TextField>
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLinkChange = (index, value) => {
    const links = [...formData.links];
    links[index] = value;
    setFormData({
      ...formData,
      links,
    });
  };

  const addLinkField = () => {
    if (numLinks < 5) {
      setNumLinks(numLinks + 1); // Increment the number of links to display
    }
  };
  
  const removeLinkField = (index) => {
    const links = [...formData.links];
    links.splice(index, 1);
    setFormData({
      ...formData,
      links,
    });
    setNumLinks(numLinks - 1); // Decrement the number of links
  };

  
const handleSubmit = async (e) => {
    e.preventDefault();
    setAdding(true);
    try {
      const formDataToSend = {
        sourceType: formData.sourceType,
        description: formData.description,
        username: formData.username,
        mediaData: formData.mediaData,
        mediaType: formData.mediaType, // Ensure this is included
        links: formData.links,
        additionalField: formData.additionalField,
        domain: formData.domain,
      };

    // Send the form data as JSON in the POST request
    const response = await fetch('/api/add-architecture', {
      method: 'POST',
      body: JSON.stringify(formDataToSend), // Send the form data as JSON
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
        // Handle successful submission, e.g., resetting form state, providing user feedback
        setFormData({
          topic: '',
          description: '',
          mediaData: '',
          mediaType: '', // Reset these as well
          links: [''],
          sourceType: '',
          domain: '',
          additionalField: '',
          username: loggedInUser,
        });
        window.location.reload(); // Reset image preview
      } else {
        console.error('Submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  // Function to render additional text boxes based on source type
  const renderAdditionalFields = () => {
    if (
      formData.sourceType === 'Company' ||
      formData.sourceType === 'Movie' ||
      formData.sourceType === 'Television' ||
      formData.sourceType === 'Newspaper' ||
      formData.sourceType === 'Social Media' ||
      formData.sourceType === 'Institution' ||
      formData.sourceType === 'Book'
    ) {
      return (
        <TextField
          className={classes.textField}
          label="Origin Name"
          name="additionalField" // Use 'additionalField' as the name
          variant="outlined"
          maxLength={333} 
          required
          value={formData.additionalField}
          onChange={handleInputChange}
        />
      );
    }
    // Return null if source type is not selected or "Personal" is selected
    return null;
  };

  return (
    <div className={classes.formContainer}>
      <form className={`${classes.form} custom-width` } onSubmit={handleSubmit}>
      <TextareaAutosize
  className={classes.descriptionField}
  minRows={3}
  maxRows={10}
  placeholder="Thoughts"
  name="description"
  value={formData.description}
  onChange={handleInputChange}
  maxLength={6969} // Added maxLength attribute
  required
/>
<label htmlFor="media-upload" style={{ cursor: 'pointer' }}>
  <input
    accept="image/*,video/*"
    id="media-upload"
    type="file"
    style={{ display: 'none' }}
    onChange={handleMediaUpload}
  />
  <img
    src={AddImage}
    alt="Upload"
    style={{ maxWidth: '100px', maxHeight: '100px' }}
  />
</label>
{formData.mediaData && formData.mediaType === 'image' && (
  <img
    src={formData.mediaData}
    alt="Uploaded Preview"
    style={{ maxWidth: '100%', maxHeight: '200px' }}
  />
)}
{formData.mediaData && formData.mediaType === 'video' && (
  <video controls style={{ maxWidth: '100%', maxHeight: '200px' }}>
    <source src={formData.mediaData} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
)}
        {Array.from({ length: numLinks }).map((_, index) => (
          <div key={index} className={classes.linkContainer}>
            <div className={classes.linkField}>
              <TextField
                className={`${classes.textField} ${classes.linkInput}`}
                label="Link"
                variant="outlined"
                value={formData.links[index] || ''}
                onChange={(e) => handleLinkChange(index, e.target.value)}
              />
              <Button
                variant="outlined"
                color="secondary"
                className={classes.linkRemoveButton}
                onClick={() => removeLinkField(index)}
              >
              </Button>
            </div>
          </div>
        ))}
        {numLinks < 5 && (
          <Button
            className={classes.submitButton}
            variant="outlined"
            color="primary"
            onClick={addLinkField}
          >
            Add Evidence
          </Button>
        )}
        <br></br>
        <TextField
          className={classes.textField}
          label="Origin"
          name="sourceType"
          variant="outlined"
          required
          select
          SelectProps={{
            native: true,
            displayEmpty: true, // Add this to display the label when no item is selected
          }}
          InputLabelProps={{
    shrink: true, // Add this to ensure the label is always visible
  }}
          value={formData.sourceType}
          onChange={handleInputChange}
        >
          <option aria-label="domain" value="" /> 
          {sourceTypes.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
        {renderAdditionalFields()}{renderDomainField()}
          {adding ? ( // Conditional rendering for CircularProgress when adding is true
            <CircularProgress />
          ) : (
            <Button
              className={classes.submitButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          Present
            </Button>
          )}
        </form>
      </div>
  );
}

export default EcologyAdd;