import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import styled from 'styled-components';
import { FaRegImage, FaUser } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const apiBaseUrl = 'http://localhost:3005/api/chat';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;
`;

const Header = styled.div`
  padding: 1rem;
  display: flex;
  width: 69vw;
  flex-direction: column;
  align-items: center;
  background-color: #f0ead6;
`;

const RoomImage = styled.img`
  max-width: 23%;
  border-radius: 8px;
`;

const RoomTitle = styled.h2`
  margin: 0.5rem 0;
  font-size: 1.5rem;
`;

const UserCount = styled.p`
  margin: 0;
  font-size: 1rem;
  color: sagegreen;
`;

const ChatArea = styled.div`
  flex-grow: 1;
  padding: 1rem;
  width: 69vw;
  background-color: #fff;
  color: #2f4f4f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const MessagesContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f0ead6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  position: relative;
`;

const Message = styled.div`
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: fit-content;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Georgia', serif;
  font-size: 1rem;
`;

const InputContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px 0 0 8px;
`;

const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const FavoriteButton = styled.button`
  padding: 0.5rem;
  background-color: #556b2f;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #6b8e23;
  }
`;

const NewMessageIndicator = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6b8e23;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  display: ${({ show }) => (show ? 'block' : 'none')};

  &:hover {
    background-color: #556b2f;
  }
`;

const MobileChatRoom = ({ roomId, name, imageData, description }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const socketRef = useRef();
  const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
  const user = readifyUserKey ? readifyUserKey.username : null;

  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showNewMessageIndicator, setShowNewMessageIndicator] = useState(false);

  useEffect(() => {
    socketRef.current = io('http://localhost:3005', {
      transports: ['websocket', 'polling'],
      upgrade: false,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });

    socketRef.current.emit('joinRoom', roomId);

    const handleMessageReceive = (message) => {
      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (msg) =>
            msg.timestamp === message.timestamp &&
            msg.message === message.message &&
            msg.user === message.user
        );

        if (!isDuplicate) {
          return [...prevMessages, message];
        }

        return prevMessages;
      });
    };

    const handleUpdateUserCount = ({ count }) => {
      setUserCount(count);
    };

    socketRef.current.on('receiveMessage', handleMessageReceive);
    socketRef.current.on('updateUserCount', handleUpdateUserCount);

    socketRef.current.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
    });

    socketRef.current.on('connect_error', (error) => {
      console.error('Connection Error:', error);
    });

    return () => {
      socketRef.current.emit('leaveRoom', roomId);
      socketRef.current.off('receiveMessage', handleMessageReceive);
      socketRef.current.off('updateUserCount', handleUpdateUserCount);
      socketRef.current.disconnect();
    };
  }, [roomId]);

  useEffect(() => {
    if (roomId) {
      axios
        .get(`${apiBaseUrl}/${roomId}`)
        .then((response) => setMessages(response.data.messages))
        .catch((error) => console.error(error));

      axios
        .get(`${apiBaseUrl}/favorites/${roomId}`, { params: { username: user } })
        .then((response) => setIsFavorite(response.data.isFavorite))
        .catch((error) => console.error(error));
    }
  }, [roomId, user]);

  useEffect(() => {
    const container = messagesContainerRef.current;

    const handleScroll = () => {
      if (container) {
        const isAtBottom =
          container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
        setIsAtBottom(isAtBottom);
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isAtBottom && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
      setShowNewMessageIndicator(false);
    } else if (!isAtBottom) {
      setShowNewMessageIndicator(true);
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    setShowNewMessageIndicator(false);
  };

  const handleMediaUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }

    const fileType = file.type.split('/')[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setMedia(file);
      setMediaType(fileType.includes('image') ? 'image' : 'video');
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const sendMessage = () => {
    if (newMessage.trim() || media) {
      const messageData = {
        user: user,
        message: newMessage,
        roomId: roomId,
      };

      if (media) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Media = reader.result;

          messageData.mediaData = base64Media;
          messageData.mediaType = mediaType;

          axios
            .post(`${apiBaseUrl}/${roomId}/message`, messageData, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              // Message sent successfully
            })
            .catch((error) => console.error(error));
        };
        reader.readAsDataURL(media);
      } else {
        axios
          .post(`${apiBaseUrl}/${roomId}/message`, messageData, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            // Message sent successfully
          })
          .catch((error) => console.error(error));
      }

      setNewMessage('');
      setMedia(null);
      setMediaType('');
      setImagePreview(null);
    }
  };

  const handleKeyPress = (e) => {
    const mobileEnterKeys = ['Enter', 'Go', 'Done', 'Next', 'Send'];

    if (mobileEnterKeys.includes(e.key)) {
      sendMessage();
    }
  };

  const openDialog = (image) => {
    setDialogImage(image);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogImage('');
  };

  const addToFavorites = () => {
    axios
      .post(`${apiBaseUrl}/${roomId}/add-to-favorites`, { username: user })
      .then((response) => {
        setIsFavorite(true);
        console.log(response.data.message);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Container>
      <Header>
        {imageData?.imageLink && (
          <RoomImage src={imageData.imageLink} alt={name} />
        )}
        <RoomTitle>{name}</RoomTitle>
        <UserCount>{userCount} People In Conversation</UserCount>
        {!isFavorite && (
          <FavoriteButton onClick={addToFavorites}>Add to Favorites</FavoriteButton>
        )}
      </Header>
      <ChatArea>
        <MessagesContainer ref={messagesContainerRef}>
          {messages.map((msg, index) => (
            <Message key={index}>
              <strong>{msg.user}</strong>: {msg.message}
              {msg.mediaData &&
                msg.mediaData.mediaType === 'image' &&
                msg.mediaData.mediaLink && (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={msg.mediaData.mediaLink}
                      alt="Media"
                      style={{
                        maxWidth: '100%',
                        marginTop: '1rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => openDialog(msg.mediaData.mediaLink)}
                    />
                  </div>
                )}
              {msg.mediaData &&
                msg.mediaData.mediaType === 'video' &&
                msg.mediaData.mediaLink && (
                  <div style={{ textAlign: 'center' }}>
                    <video
                      controls
                      style={{ maxWidth: '100%', marginTop: '1rem' }}
                    >
                      <source src={msg.mediaData.mediaLink} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
            </Message>
          ))}
          <div ref={messagesEndRef} />
          <NewMessageIndicator
            show={showNewMessageIndicator}
            onClick={scrollToBottom}
          >
            New Messages
          </NewMessageIndicator>
        </MessagesContainer>
        <InputContainer>
          <Input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message"
          />
          <FileInputLabel>
            <FaRegImage />
            <input
              type="file"
              accept="image/*,video/*"
              onChange={handleMediaUpload}
              style={{ display: 'none' }}
            />
          </FileInputLabel>
        </InputContainer>
        {imagePreview && (
          <div>
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: '100px', marginTop: '1rem' }}
            />
          </div>
        )}
      </ChatArea>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            maxWidth: 'none',
            width: '80%',
            height: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent style={{ padding: 0 }}>
          <img
            src={dialogImage}
            alt="Full-size"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </DialogContent>
        <DialogActions>
          <FavoriteButton onClick={closeDialog} color="primary">
            Close
          </FavoriteButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MobileChatRoom;
