import { createMuiTheme } from '@material-ui/core/styles';

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#004d40', // Teal shade matching the images
    },
    secondary: {
      main: '#00363a', // Dark blue shade matching the images
    },
    background: {
      default: '#e0f2f1', // Light background shade for balance
      paper: '#b2dfdb', // Soft teal for paper elements
    },
    text: {
      primary: '#000000', // Keeping text mostly black for readability
      secondary: '#ffffff', // White for contrast on darker backgrounds
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        wordBreak: 'break-word',
      },
    },
    // Add any other component overrides if needed
  },
});

export default customTheme;