import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { keyframes, css } from 'styled-components';
import FlipMove from 'react-flip-move';
import ChatRoom from './ChatRoom';
import { Drawer, Dialog } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import conversationsIcon from '../../assets/conversation.png';
import OptionsButton from './Options';
import { motion, AnimatePresence } from 'framer-motion';


const breathingGlow = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
`;

const RoomListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
  scrollbar-width: none;     /* Hide scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none;          /* Hide scrollbar in Chrome, Safari, and Opera */
  }
`;


const MotionRoomItem = styled(motion.div)`
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #004d40;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  justify-content: center;
  width: fit-content;

  &:hover {
    background-color: #fff;
    color: #004d40;
  }

  img {
    margin-right: 1rem;
    border-radius: 4px;
    width: 42px;
    height: 42px;
    object-fit: cover;
  }
`;



const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 17%;
  height: ${({ expanded }) => (expanded ? '69vh' : '60px')};
  transition: height 0.3s ease-in-out;
  background-color: none;
  z-index: 1300;
`;

const ExpandButton = styled.div`
  position: absolute;
  top: -42px;
  left: 50%;
  z-index: 1400;
  background-color: transparent;
  border-radius: 50%;
  padding: 8px;
  transform: translateX(-50%);
  overflow: visible;
`;

const CompassImage = styled.img`
  width: 69px;
  height: 69px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 50%;
  ${({ active }) =>
    active &&
    css`
      transform: rotate(360deg);
      animation: ${breathingGlow} 2s ease-in-out infinite;
    `}
`;

const RoomList = styled.div`
  height: 100%;
  padding: 1rem;
  color: white;
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const RoomItem = styled.div`
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #004d40;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  justify-content: center;
  width: fit-content;

  &:hover {
    background-color: #fff;
    color: #004d40;
  }

  img {
    margin-right: 1rem;
    border-radius: 4px;
    width: 42px;
    height: 42px;
    object-fit: cover;
  }
`;
const SectionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const SectionHeader = styled.h3`
  text-align: center;
  flex-shrink: 0;
  margin: 0;
`;

const PasswordInputModal = styled(Dialog)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PasswordInput = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #004d40;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #fff;
      color: #004d40;
    }
  }
`;

const OptionsButtonContainer = styled.div`
  position: absolute;
  top: 23px;
  right: 69px;
  transform: translateY(-50%);
  z-index: 1500;
  visibility: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const FlipMoveContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ChatRoomsList = () => {
  const [drawerExpanded, setDrawerExpanded] = useState(true);
  const [chatRooms, setChatRooms] = useState([]);
  const [favoriteRooms, setFavoriteRooms] = useState([]);
  const [friends, setFriends] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [roomUserCounts, setRoomUserCounts] = useState({});
  const [roomsWithPasswords, setRoomsWithPasswords] = useState({});
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [roomToJoin, setRoomToJoin] = useState(null);
  const [filterResults, setFilterResults] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [newRoomName, setNewRoomName] = useState('');
  const [password, setPassword] = useState('');
  const [description, setDescription] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
  const [favoriteRoomsLastMessageTimestamps, setFavoriteRoomsLastMessageTimestamps] = useState({});
  const [friendsLastMessageTimestamps, setFriendsLastMessageTimestamps] = useState({});

  useEffect(() => {
    const fetchRoomsAndUserCounts = async () => {
      try {
        const response = await axios.get('/api/chat');
        setChatRooms(response.data);
  
        const mostConnectedResponse = await axios.get('/api/chat/filter/most-connected');
        const mostConnectedRooms = mostConnectedResponse.data.reduce((acc, room) => {
          acc[room.roomId] = room.userCount;
          return acc;
        }, {});
        setRoomUserCounts(mostConnectedRooms);
  
        const passwordCheckPromises = response.data.map(room =>
          axios.get(`/api/chat/${room.roomId}/has-password`)
        );
  
        const passwordCheckResults = await Promise.all(passwordCheckPromises);
        const roomsWithPasswordsData = passwordCheckResults.reduce((acc, result, index) => {
          acc[response.data[index].roomId] = result.data.hasPassword;
          return acc;
        }, {});
  
        setRoomsWithPasswords(roomsWithPasswordsData);
  
        const favoriteRoomsResponse = await axios.get('/api/chat/person/getFavorites', {
          params: { username: loggedInUser },
        });
        setFavoriteRooms(favoriteRoomsResponse.data);
  
        const friendsResponse = await axios.get('/api/friends', {
          params: { username: loggedInUser },
        });
        setFriends(friendsResponse.data.friends);
  
        // Fetch last message timestamps for favorite rooms
        const favoriteRoomIds = favoriteRoomsResponse.data.map((room) => room.roomId);
        const favoriteRoomsMessagesPromises = favoriteRoomIds.map((roomId) =>
          axios.get(`/api/chat/${roomId}/last-message`)
        );
        const favoriteRoomsMessages = await Promise.all(favoriteRoomsMessagesPromises);
        const favoriteRoomsTimestamps = {};
        favoriteRoomsMessages.forEach((response, index) => {
          favoriteRoomsTimestamps[favoriteRoomIds[index]] = new Date(response.data.timestamp).getTime() || 0;
        });
        setFavoriteRoomsLastMessageTimestamps(favoriteRoomsTimestamps);

        // Fetch last message timestamps for friends
        const friendsMessagesPromises = friendsResponse.data.friends.map((friendUsername) =>
          axios.post('/api/chat/private/last-message', {
            user1: loggedInUser,
            user2: friendUsername,
          })
        );
        const friendsMessages = await Promise.all(friendsMessagesPromises);
        const friendsTimestamps = {};
        friendsMessages.forEach((response, index) => {
          friendsTimestamps[friendsResponse.data.friends[index]] = new Date(response.data.timestamp).getTime() || 0;
        });
        setFriendsLastMessageTimestamps(friendsTimestamps);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRoomsAndUserCounts();
  
    const intervalId = setInterval(fetchRoomsAndUserCounts, 30000);
  
    return () => clearInterval(intervalId);
  }, []);
  

  const handleRoomClick = (room) => {
    if (roomsWithPasswords[room.roomId]) {
      axios
        .get(`/api/chat/${room.roomId}/is-authenticated`, { params: { username: loggedInUser } })
        .then((response) => {
          if (response.data.isAuthenticated) {
            setSelectedRoom({
              roomId: room.roomId,
              imageData: room.imageData,
              name: room.name,
            });
          } else {
            setRoomToJoin(room);
            setPasswordModalOpen(true);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedRoom({ roomId: room.roomId, imageData: room.imageData, name: room.name });
    }
  };

  const handleFriendClick = async (friendUsername) => {
    try {
      const response = await axios.post('/api/chat/private', {
        user1: loggedInUser,
        user2: friendUsername,
      });
      const privateRoom = response.data.room;
      setSelectedRoom({
        roomId: privateRoom.roomId,
        imageData: privateRoom.imageData,
        name: privateRoom.name,
      });
    } catch (error) {
      console.error('Error opening chat with friend:', error);
    }
  };

  // Inside the ChatRoomsList component
// Before the return statement
const sortedChatRooms = [...chatRooms].sort((a, b) => {
  const countA = roomUserCounts[a.roomId] || 0;
  const countB = roomUserCounts[b.roomId] || 0;
  return countB - countA; // Sort in descending order
});

// Sort favorite rooms by last message timestamp in descending order
const sortedFavoriteRooms = [...favoriteRooms].sort((a, b) => {
  const timestampA = favoriteRoomsLastMessageTimestamps[a.roomId] || 0;
  const timestampB = favoriteRoomsLastMessageTimestamps[b.roomId] || 0;
  return timestampB - timestampA; // Sort from most recent to least recent
});

// Sort friends by last message timestamp in descending order
const sortedFriends = [...friends].sort((a, b) => {
  const timestampA = friendsLastMessageTimestamps[a] || 0;
  const timestampB = friendsLastMessageTimestamps[b] || 0;
  return timestampB - timestampA;
});

  

  const handlePasswordSubmit = () => {
    if (passwordInput) {
      axios
        .post(`/api/chat/${roomToJoin.roomId}/verify-password`, {
          password: passwordInput,
          username: loggedInUser,
        })
        .then((response) => {
          if (response.data.success) {
            setSelectedRoom({
              roomId: roomToJoin.roomId,
              imageData: roomToJoin.imageData,
              name: roomToJoin.name,
            });
            setPasswordModalOpen(false);
            setPasswordInput('');
          } else {
            alert('Incorrect password. Please try again.');
          }
        })
        .catch((error) => console.error('Error in handlePasswordSubmit:', error));
    }
  };

  const createRoom = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/chat/create', {
        roomName: newRoomName,
        password,
        description,
        image: imagePreview,
        createdBy: loggedInUser,
      });
  
      if (response.status === 201 && response.data) {
        const newRoom = response.data; // The newly created chat room
  
        // Step 1: Add the new room to the user's favorites
        await axios.post(`/api/chat/${newRoom.roomId}/add-to-favorites`, {
          username: loggedInUser,
        });
  
        // Step 2: Clear the form fields
        setNewRoomName('');
        setPassword('');
        setDescription('');
        setImagePreview('');
  
        // Update the chatRooms state
        const updatedChatRooms = [...chatRooms, newRoom];
        setChatRooms(updatedChatRooms);
  
        // Step 3: Open the chat dialog for the created room
        setSelectedRoom({
          roomId: newRoom.roomId,
          imageData: newRoom.imageData,
          name: newRoom.name,
          description: newRoom.description,
        });
  
        // Step 4: Notify the user
        alert('Conversation created successfully and added to your favorites!');
      }
    } catch (error) {
      console.error('Error creating room:', error);
    }
  };
  

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchMostConnectedRooms = async () => {
    try {
      const response = await axios.get('/api/chat/filter/most-connected');
      setFilterResults(response.data);
    } catch (error) {
      console.error('Error fetching most connected rooms:', error);
    }
  };

  const fetchLeastConnectedRooms = async () => {
    try {
      const response = await axios.get('/api/chat/filter/least-connected');
      setFilterResults(response.data);
    } catch (error) {
      console.error('Error fetching least connected rooms:', error);
    }
  };

  const fetchMostMessagesRooms = async () => {
    try {
      const response = await axios.get('/api/chat/filter/most-messages');
      setFilterResults(response.data);
    } catch (error) {
      console.error('Error fetching rooms with most messages:', error);
    }
  };

  const searchRoomsByName = async () => {
    try {
      const response = await axios.get(`/api/chat/search?name=${searchName}`);
      setFilterResults(response.data);
    } catch (error) {
      console.error('Error searching rooms by name:', error);
    }
  };

  return (
    <Container expanded={drawerExpanded}>
      <ExpandButton onClick={() => setDrawerExpanded(!drawerExpanded)}>
        <CompassImage src={conversationsIcon} alt="Open Chat" active={drawerExpanded} />
      </ExpandButton>
      <OptionsButtonContainer expanded={drawerExpanded}>
        <OptionsButton
          createRoom={createRoom}
          handleImageUpload={handleImageUpload}
          imagePreview={imagePreview}
          newRoomName={newRoomName}
          setNewRoomName={setNewRoomName}
          password={password}
          setPassword={setPassword}
          description={description}
          setDescription={setDescription}
          searchName={searchName}
          setSearchName={setSearchName}
          searchRoomsByName={searchRoomsByName}
          fetchMostConnectedRooms={fetchMostConnectedRooms}
          fetchLeastConnectedRooms={fetchLeastConnectedRooms}
          fetchMostMessagesRooms={fetchMostMessagesRooms}
          filterResults={filterResults}
          roomUserCounts={roomUserCounts}
          setSelectedRoom={setSelectedRoom}
          roomsWithPasswords={roomsWithPasswords}
          loggedInUser={loggedInUser}
        />
      </OptionsButtonContainer>
      <RoomList expanded={drawerExpanded}>
        {/* Favorites Section */}
        <SectionContainer>
        <SectionHeader style={{ marginTop: '2vh' }}>Favorites</SectionHeader>
          <RoomListContainer>
            <AnimatePresence>
              {sortedFavoriteRooms.map((room) => (
                <MotionRoomItem
                  key={room.roomId}
                  onClick={() => handleRoomClick(room)}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  layout
                >
                  {room.imageData?.imageLink && (
                    <img src={room.imageData.imageLink} alt={room.name} />
                  )}
                  {room.name}
                  <PersonIcon style={{ marginLeft: 'auto', marginRight: '0.5rem' }} />
                  {roomUserCounts[room.roomId] || 0}
                  {roomsWithPasswords[room.roomId] && (
                    <LockIcon style={{ marginLeft: '0.5rem' }} />
                  )}
                </MotionRoomItem>
              ))}
            </AnimatePresence>
          </RoomListContainer>
        </SectionContainer>
  
        {/* Friends Section */}
        <SectionContainer>
          <SectionHeader>Friends</SectionHeader>
          <RoomListContainer>
            <AnimatePresence>
              {sortedFriends.map((friendUsername) => (
                <MotionRoomItem
                  key={friendUsername}
                  onClick={() => handleFriendClick(friendUsername)}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  layout
                >
                  {friendUsername}
                </MotionRoomItem>
              ))}
            </AnimatePresence>
          </RoomListContainer>
        </SectionContainer>
  
        {/* Most Active Conversations Section */}
        <SectionContainer>
          <SectionHeader>Most Active Conversations</SectionHeader>
          <RoomListContainer>
            <AnimatePresence>
              {sortedChatRooms.map((room) => (
                <MotionRoomItem
                  key={room.roomId}
                  onClick={() => handleRoomClick(room)}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  layout
                >
                  {room.imageData?.imageLink && (
                    <img src={room.imageData.imageLink} alt={room.name} />
                  )}
                  {room.name}
                  <PersonIcon style={{ marginLeft: 'auto', marginRight: '0.5rem' }} />
                  {roomUserCounts[room.roomId] || 0}
                  {roomsWithPasswords[room.roomId] && (
                    <LockIcon style={{ marginLeft: '0.5rem' }} />
                  )}
                </MotionRoomItem>
              ))}
            </AnimatePresence>
          </RoomListContainer>
        </SectionContainer>
      </RoomList>
      <PasswordInputModal
        open={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
      >
        <PasswordInput>
          <h2>What's the Password?</h2>
          <input
            type="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
          />
          <button onClick={handlePasswordSubmit}>Submit</button>
        </PasswordInput>
      </PasswordInputModal>
      {selectedRoom && (
        <Dialog
          open={Boolean(selectedRoom)}
          onClose={() => setSelectedRoom(null)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              width: '75vw',
              height: '75vh',
            },
          }}
        >
          <ChatRoom
            roomId={selectedRoom.roomId}
            imageData={selectedRoom.imageData}
            name={selectedRoom.name}
            description={selectedRoom.description}
          />
        </Dialog>
      )}
    </Container>
  );  
};

export default ChatRoomsList;
