import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Card, CardContent, Typography, Box } from '@mui/material';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend);

const PoolStats = ({ poolId }) => {
  const [memberCount, setMemberCount] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [entryTagsData, setEntryTagsData] = useState(null);
  const [entryDomainsData, setEntryDomainsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/pools/${poolId}/stats`);
        const { members, entryTags, entryDomains, totalEntries } = response.data;

        setMemberCount(members.length);
        setTotalEntries(totalEntries);

        const entryTagsCount = entryTags.reduce((acc, tag) => {
          acc[tag] = (acc[tag] || 0) + 1;
          return acc;
        }, {});

        const entryDomainsCount = entryDomains.reduce((acc, domain) => {
          acc[domain] = (acc[domain] || 0) + 1;
          return acc;
        }, {});

        setEntryTagsData({
          labels: Object.keys(entryTagsCount),
          datasets: [
            {
              data: Object.values(entryTagsCount).map(count => (count / entryTags.length) * 100),
              backgroundColor: Object.keys(entryTagsCount).map(
                (_, index) => `hsl(${(index * 360) / Object.keys(entryTagsCount).length}, 70%, 60%)`
              ),
            },
          ],
        });

        setEntryDomainsData({
          labels: Object.keys(entryDomainsCount),
          datasets: [
            {
              data: Object.values(entryDomainsCount).map(count => (count / entryDomains.length) * 100),
              backgroundColor: Object.keys(entryDomainsCount).map(
                (_, index) => `hsl(${(index * 360) / Object.keys(entryDomainsCount).length}, 70%, 60%)`
              ),
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching pool stats:', error);
      }
    };

    fetchData();
  }, [poolId]);

  return (
    <Card>
      <CardContent>
        <Box textAlign="center">
          <Typography variant="h6">Members: {memberCount}</Typography>
          <Typography variant="h6">Entries: {totalEntries}</Typography>
          <Typography variant="h6">Fields</Typography>
          {entryTagsData ? (
            <Pie
              data={entryTagsData}
              options={{
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => `${context.label}: ${context.raw.toFixed(2)}% (${context.raw})`
                    }
                  }
                }
              }}
            />
          ) : (
            <Typography>Loading...</Typography>
          )}
          <Typography variant="h6">Categories</Typography>
          {entryDomainsData ? (
            <Pie
              data={entryDomainsData}
              options={{
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => `${context.label}: ${context.raw.toFixed(2)}% (${context.raw})`
                    }
                  }
                }
              }}
            />
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PoolStats;
