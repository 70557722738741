import { useState, useEffect } from 'react';

const useScrollToTop = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const checkScrollTop = () => {
    if (!showScrollToTop && window.pageYOffset > 2000) {
      setShowScrollToTop(true);
    } else if (showScrollToTop && window.pageYOffset <= 2000) {
      setShowScrollToTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return { showScrollToTop, checkScrollTop, scrollToTop };
};

export default useScrollToTop;
