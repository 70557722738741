import React, { useState, useEffect } from 'react';
import { useStyles } from './submitStyles';
import { Button, CircularProgress } from '@material-ui/core';
import OpenForm from './OpenForm';
import DetailedForm from './DetailedForm';
import AIForm from './AIForm';
import EcologyCardSelect from '../cardselect/cardSelect';
import {
  handleInputChange,
  handleLinkChange,
  addLinkField,
  removeLinkField,
  handleMediaUpload,
} from './formhandler';
import generateBase64Encode from '../../../../utils/genBase64Encode';
import axios from 'axios';

function EcologyAdd() {
  const classes = useStyles();
  const [formType, setFormType] = useState('Open');
  const [imagePreview, setImagePreview] = useState(null);
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
  const [adding, setAdding] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    mediaData: [],
    mediaType: '',
    links: [''],
    sourceType: '',
    sourceTypeName: '',
    domain: '',
    model: '',
    keys: '',
    username: loggedInUser,
  });
  const [aiFormData, setAiFormData] = useState({
    username: loggedInUser,
    aiModel: '',
    aiPromptText: '',
    aiPromptImage: '',
    useWebSearch: false,
    keys: [],
    domain: '',
    model: '',
  });
  const [numLinks, setNumLinks] = useState(0);
  const [newEntry, setNewEntry] = useState(null);

  const handleOpenSubmit = async (e) => {
    e.preventDefault();
    setAdding(true);
    try {
      const mediaDataWithBase64 = await Promise.all(formData.mediaData.map(async (media) => {
        if (!media.base64) {
          const base64 = await generateBase64Encode(media.file);
          return {
            ...media,
            base64,
          };
        }
        return media;
      }));

      const formDataToSend = {
        ...formData,
        mediaData: mediaDataWithBase64,
        domain: 'Random', // Hardcoded domain value for Open form
        model: 'Open', // Hardcoded model value for Open form
      };

      const response = await fetch('/api/add-entry', {
        method: 'POST',
        body: JSON.stringify(formDataToSend),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const savedEntry = await response.json();
        setNewEntry(savedEntry);
        setFormData({
          description: '', 
          mediaData: [],
          mediaType: '',
          links: [''],
          sourceType: '',
          sourceTypeName: '',
          domain: '',
          model: '',
          username: loggedInUser,
        });
      } else {
        console.error('Submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setAdding(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAdding(true);
    try {
      const mediaDataWithBase64 = await Promise.all(formData.mediaData.map(async (media) => {
        if (!media.base64) {
          const base64 = await generateBase64Encode(media.file);
          return {
            ...media,
            base64,
          };
        }
        return media;
      }));

      const formDataToSend = {
        ...formData,
        mediaData: mediaDataWithBase64,
        sourceTypeName: formData.sourceTypeName,
      };

      const response = await fetch('/api/add-entry', {
        method: 'POST',
        body: JSON.stringify(formDataToSend),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const savedEntry = await response.json();
        setNewEntry(savedEntry);
        setFormData({
          description: '',
          mediaData: [],
          mediaType: '',
          links: [''],
          sourceType: '',
          sourceTypeName: '',
          domain: '',
          username: loggedInUser,
        });
      } else {
        console.error('Submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setAdding(false);
    }
  };

  const handleAISubmit = async (e) => {
    e.preventDefault();
    setAdding(true);
    try {
      let formDataToSend = { ...aiFormData, username: loggedInUser };
      
      if (!aiFormData.model || !aiFormData.domain) {
        formDataToSend = {
          ...formDataToSend,
          model: 'Ai',
          domain: 'General',
        };
      }

      const response = await axios.post('/api/submit-ai-form', formDataToSend);

      if (response.status === 200) {
        const savedEntry = await response.data;
        setNewEntry(savedEntry);
        setAiFormData({
          username: loggedInUser,
          aiModel: '',
          aiPromptText: '',
          aiPromptImage: '',
          useWebSearch: false,
          keys: [],
          model: '',
          domain: '',
        });
      } else {
        alert('AI Submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('AI Submission failed. Please try again.');
    } finally {
      setAdding(false);
    }
  };

  const renderForm = () => {
    switch (formType) {
      case 'Open':
        return <OpenForm formData={formData} setFormData={setFormData} classes={classes} setImagePreview={setImagePreview} />;
      case 'Detailed':
        return <DetailedForm formData={formData} setFormData={setFormData} classes={classes} setImagePreview={setImagePreview} />;
      case 'AI':
        return <AIForm formData={aiFormData} setFormData={setAiFormData} classes={classes} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.toggleButton}
          variant="contained"
          color="primary"
          onClick={() => setFormType('Open')}
        >
          Open
        </Button>
        <Button
          className={classes.toggleButton}
          variant="contained"
          color="primary"
          onClick={() => setFormType('Detailed')}
        >
          Detailed
        </Button>
        <Button
          className={classes.toggleButton}
          variant="contained"
          color="primary"
          onClick={() => setFormType('AI')}
        >
          Ai
        </Button>
      </div>
      {formType && (
        <form className={`${classes.form} custom-width`} onSubmit={formType === 'AI' ? handleAISubmit : formType === 'Open' ? handleOpenSubmit : handleSubmit}>
          {renderForm()}
          {adding ? (
            <CircularProgress />
          ) : (
            <Button
              className={classes.submitButton}
              variant="contained"
              color="primary"
              type="submit"
            >
              Place
            </Button>
          )}
        </form>
      )}
      {newEntry && (
        <EcologyCardSelect
          isOpen={!!newEntry}
          onClose={() => setNewEntry(null)}
          post={newEntry}
        />
      )}
    </div>
  );
}

export default EcologyAdd;
