// Updated src/components/Pathways/PathwayExplorer.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  CircularProgress,
  Button,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddPathwayForm from './AddPathwayDialog'; // Ensure the import path is correct
import PathwayGrid from './PathwayGrid';
import FilterBar from './Filters';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
    width: '69vw',
    marginLeft: '3vw',
    marginRight: 'auto',
    '@media (max-width:600px)': {
      width: '100%',
      marginLeft: '-4.42vw',
    },
  },
  loader: {
    textAlign: 'center',
    padding: '20px',
    color: 'white' // Replaced theme.spacing(2.5) with '20px'
  },
}));

const PathwayExplorer = () => { 
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [pathways, setPathways] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    createdBy: '',
  });
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPathways = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios.get('/api/pathways', {
        params: {
          page,
          limit: 10,
          search: filters.search,
          status: filters.status,
          createdBy: filters.createdBy,
        },
      });
      setPathways((prev) => {
        if (page === 1) {
          return res.data.pathways;
        } else {
          return [...prev, ...res.data.pathways];
        }
      });
      setHasMore(page < res.data.pages);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [page, filters]);

  useEffect(() => {
    fetchPathways();
  }, [page, filters]);

  useEffect(() => {
    setPathways([]);
    setPage(1);
    setHasMore(true);
  }, [filters]);

  const handleAddPathway = (newPathway) => {
    setPathways((prev) => [newPathway, ...prev]);
  };

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        onClick={() => setIsAddDialogOpen(true)}
        sx={{
          margin: '0 auto 3px',
          backgroundColor: '#004d40',
          color: '#ffffff',
          borderRadius: '25px',
          padding: '8px 24px',
          display: 'block',
          textTransform: 'none', // Ensures no text transformation
          '&:hover': {
            backgroundColor: '#ffffff', // Inverted colors on hover
            color: '#004d40',
          },
        }}
      >
        Add Pathway
      </Button>
      <FilterBar filters={filters} setFilters={setFilters} />
      <InfiniteScroll
        dataLength={pathways.length}
        next={() => setPage((prevPage) => prevPage + 1)}
        hasMore={hasMore}
        loader={ 
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        }
      >
        <PathwayGrid pathways={pathways} />
      </InfiniteScroll>
      
      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            background: 'transparent',
            boxShadow: 'none',
            overflow: 'visible', // To ensure the form is fully visible
          }, 
        }}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.90)' },
        }}
      >
        <AddPathwayForm 
          onAdd={handleAddPathway} 
          onClose={() => setIsAddDialogOpen(false)} 
        />
      </Dialog>
    </div>
  );
};

export default PathwayExplorer;
