import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core/';
import { useMainPaperStyles } from './styles/muiStyles';
import AuthTwo from './components/auth/auth2';
import PrivacyPolicy from './components/privacyguide/privacy';
import UserGuidelines from './components/privacyguide/guidelines';
import Campbell from './components/Campbell/Campbell';
import BigBar from './components/big/bigBar';
import Front from './components/big/front/front';
import Person from './components/Person/Person';
import TotpSetup from './components/auth/totp-instructions';
import Pools from './pools/pools';
import Insights from './components/analytics/insights';
import FeedbackContentRequest from './components/feedbackrequests/all';
import ChatRoomsList from './components/lounge/ChatRoomsList';
import Explorer from './components/Explorers2/utils/explorer/explorer';
import TermsOfService from './components/privacyguide/ToS';
import MainLayout from './components/Explorers2/utils/first';
import OfficialPoolExplorer from './officialpools/PoolExplorer';
import OfficialPools from './officialpools/pools';

const Routes = () => {
  const classes = useMainPaperStyles();

  // Function to check if the user has readifyUserKey in local storage
  const isUserAuthenticated = () => {
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    return readifyUserKey !== null;
  };

  return (
    <Switch>
      {/* Public Routes */}
      <Route exact path="/qr-code-instructions" component={TotpSetup} />
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/guidelines" component={UserGuidelines} />
      <Route exact path="/ToS" component={TermsOfService} />
      <Route exact path="/official" component={OfficialPools} />
      {/* Authenticated Routes */}
      {isUserAuthenticated() && (
        <>
          <Route exact path="/" component={MainLayout} />
          <Route exact path="/Campbell" component={Campbell} />
        </>
      )}

      {/* Public Routes for Unauthenticated Users */}
      {!isUserAuthenticated() && (
        <>
          <Route exact path="/start" component={AuthTwo} />
          {/* Redirect all other routes to /start */}
          <Route path="*">
            <Redirect to="/start" />
          </Route>
        </>
      )}
    </Switch>
  );
};

export default Routes;
