// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-container1 {
    width: 42px; /* Set the width and height to match the avatar size */
    height: 42px;
    position: relative; /* Needed for positioning the pseudo-element */
    display: inline-flex; /* Use inline-flex to center the content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
  
  .avatar-container1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid transparent; /* Adjust the border size as needed */
    border-radius: 50%; /* Make it a circle */
    z-index: -1; /* Place it behind the avatar */
  }
  
  @keyframes breathingGold1 {
    0% {
      border-color: gold;
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      border-color: gold;
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      border-color: gold;
      transform: scale(1);
      opacity: 0.7;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Chat/forCampbell/Cccss.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,sDAAsD;IACnE,YAAY;IACZ,kBAAkB,EAAE,8CAA8C;IAClE,oBAAoB,EAAE,0CAA0C;IAChE,mBAAmB,EAAE,sBAAsB;IAC3C,uBAAuB,EAAE,wBAAwB;EACnD;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,6BAA6B,EAAE,qCAAqC;IACpE,kBAAkB,EAAE,qBAAqB;IACzC,WAAW,EAAE,+BAA+B;EAC9C;;EAEA;IACE;MACE,kBAAkB;MAClB,mBAAmB;MACnB,YAAY;IACd;IACA;MACE,kBAAkB;MAClB,qBAAqB;MACrB,UAAU;IACZ;IACA;MACE,kBAAkB;MAClB,mBAAmB;MACnB,YAAY;IACd;EACF","sourcesContent":[".avatar-container1 {\n    width: 42px; /* Set the width and height to match the avatar size */\n    height: 42px;\n    position: relative; /* Needed for positioning the pseudo-element */\n    display: inline-flex; /* Use inline-flex to center the content */\n    align-items: center; /* Center vertically */\n    justify-content: center; /* Center horizontally */\n  }\n  \n  .avatar-container1::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    border: 4px solid transparent; /* Adjust the border size as needed */\n    border-radius: 50%; /* Make it a circle */\n    z-index: -1; /* Place it behind the avatar */\n  }\n  \n  @keyframes breathingGold1 {\n    0% {\n      border-color: gold;\n      transform: scale(1);\n      opacity: 0.7;\n    }\n    50% {\n      border-color: gold;\n      transform: scale(1.1);\n      opacity: 1;\n    }\n    100% {\n      border-color: gold;\n      transform: scale(1);\n      opacity: 0.7;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
