// UserSettingsMenu.js

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Box,
} from '@material-ui/core';
import {
  DataUsage, 
  Delete,
  Image,
  QrCode,
  Info,
  Person,
} from '@mui/icons-material';
import AddAvatar from './funcs/AddAvatar';

const dialogs = [
  { name: 'subscriptionInformation', title: 'Subscription Information', icon: <DataUsage /> },
  { name: 'requestMyData', title: 'Request My Data', icon: <Info /> },
  { name: 'deleteMyData', title: 'Delete My Data', icon: <Delete /> },
  { name: 'addPersonalImage', title: 'Add Personal Image', icon: <Image /> },
  { name: 'resetQRCode', title: 'Reset QR Code', icon: <QrCode /> },
  { name: 'personalInformation', title: 'Personal Information', icon: <Person /> },
];

const UserSettingsMenu = () => {
  const [openDialog, setOpenDialog] = useState(null);

  const handleOpenDialog = (dialogName) => {
    setOpenDialog(dialogName);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Settings
      </Typography>
      <List>
        {dialogs.map((dialog) => (
          <React.Fragment key={dialog.name}>
            <ListItem button onClick={() => handleOpenDialog(dialog.name)}>
              <ListItemIcon>{dialog.icon}</ListItemIcon>
              <ListItemText primary={dialog.title} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {dialogs.map((dialog) => (
        <DialogComponent
          key={dialog.name}
          dialog={dialog}
          open={openDialog === dialog.name}
          onClose={handleCloseDialog}
        />
      ))}
    </Box>
  );
};

const DialogComponent = ({ dialog, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialog.title}</DialogTitle>
      <DialogContent>
        {dialog.name === 'addPersonalImage' ? (
          <AddAvatar closeModal={onClose} />
        ) : (
          <Typography variant="body1" gutterBottom>
            Placeholder content for {dialog.title}.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSettingsMenu;
