import React, { useState, useEffect } from 'react';
import generateBase64Encode from '../../../utils/genBase64Encode';
import AlertMessage from '../../AlertMessage';
import getErrorMsg from '../../../utils/getErrorMsg';
import DeleteDialog from '../../DeleteDialog';
import axios from 'axios';
import {
  Button,
  useMediaQuery,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useAvatarFormStyles } from '../../../styles/muiStyles';
import { useTheme } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import FaceIcon from '@material-ui/icons/Face';

const AddAvatar = ({ closeModal }) => {
  const classes = useAvatarFormStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [avatarInput, setAvatarInput] = useState('');
  const [preview, setPreview] = useState('');
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authToken, setAuthToken] = useState('');
  const [avatarExists, setAvatarExists] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState(null);

  useEffect(() => {
    const userValues = JSON.parse(localStorage.getItem('readifyUserKey'));
    if (userValues?.token) {
      setAuthToken(userValues.token);
      if (userValues?.avatar) {
        setAvatarExists(true);
        setCurrentAvatar(userValues.avatar);
      }
    }
  }, []);

  const setConfig = (token) => {
    return {
      headers: { 'x-auth-token': token },
    };
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    try {
      const base64 = await generateBase64Encode(file);
      setAvatarInput(base64);
      setPreview(URL.createObjectURL(file));
    } catch (err) {
      setError('Failed to encode the file.');
    }
  };

  const clearFileSelection = () => {
    setAvatarInput('');
    setFileName('');
    setPreview('');
  };

  const handleAvatarUpload = async () => {
    if (avatarInput === '') {
      return setError('Select an image file first.');
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        `/api/users/avatar`,
        { avatarImage: avatarInput },
        setConfig(authToken)
      );
      const uploadedAvatar = response.data;
      setIsLoading(false);

      // Update the local storage
      const userValues = JSON.parse(localStorage.getItem('readifyUserKey'));
      userValues.avatar = uploadedAvatar;
      localStorage.setItem('readifyUserKey', JSON.stringify(userValues));

      setAvatarExists(true);
      setCurrentAvatar(uploadedAvatar);
      setAvatarInput('');
      setFileName('');
      setPreview('');
      closeModal();
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      setError(getErrorMsg(err));
    }
  };

  const handleRemoveAvatar = async () => {
    try {
      await axios.delete(`/api/users/avatar`, setConfig(authToken));

      // Update the local storage
      const userValues = JSON.parse(localStorage.getItem('readifyUserKey'));
      delete userValues.avatar;
      localStorage.setItem('readifyUserKey', JSON.stringify(userValues));

      setAvatarExists(false);
      setCurrentAvatar(null);
    } catch (err) {
      setError(getErrorMsg(err));
    }
  };

  return (
    <div>
      {avatarExists && currentAvatar && (
        <div>
          <div className={classes.imagePreview}>
            <img
              alt={'user-avatar'}
              src={currentAvatar.imageLink}
              width={150}
            />
          </div>
          <div className={classes.currentAvatar}>
            <Typography
              variant="h6"
              color="secondary"
              className={classes.currentAvatarText}
            >
              Current Image
            </Typography>
            <DeleteDialog type="avatar" handleDelete={handleRemoveAvatar} />
          </div>
        </div>
      )}
      <div className={classes.imageBtnsWrapper}>
        <input
          type="file"
          id="image-upload"
          accept="image/*"
          hidden
          onChange={handleFileInputChange}
        />
        <Button
          component="label"
          htmlFor="image-upload"
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={avatarInput ? <CheckCircleIcon /> : <PublishIcon />}
          className={classes.selectBtn}
        >
          {avatarInput
            ? `${isMobile ? '' : 'Selected '}"${fileName}"`
            : `Add New Image`}
        </Button> 
        {avatarInput && (
          <IconButton
            onClick={clearFileSelection}
            color="secondary"
            size={isMobile ? 'small' : 'medium'}
            className={classes.clearSelectionBtn}
          >
            <CancelIcon />
          </IconButton>
        )}
      </div>
      {preview && (
        <div className={classes.imagePreview}>
          <img alt={fileName} src={preview} width={isMobile ? 250 : 350} />
        </div>
      )}
      <Button
        size={isMobile ? 'medium' : 'large'}
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        fullWidth
        onClick={handleAvatarUpload}
        disabled={isLoading}
      >
        {avatarExists
          ? isLoading
            ? 'Updating'
            : 'Save'
          : isLoading
          ? 'Adding'
          : 'Add Picture'}
      </Button>
      <AlertMessage
        error={error}
        severity="error"
        clearError={() => setError(null)}
      />
    </div>
  );
};

export default AddAvatar;
