// SentimentsBox.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Button, makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: '300px', // Adjust as needed
    overflowY: 'auto',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    boxSizing: 'border-box',
  },
  mobileContainer: {
    maxWidth: '90vw', // Set max width for mobile view
  },
  sentimentItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center everything inside the sentiment item
    textAlign: 'center', // Center text inside the sentiment item
  },
  sentimentHeader: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center items inside the sentiment header
  },
  username: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
  },
  sentimentText: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    fontSize: '1rem',
    fontStyle: 'italic', // Optional: italicize sentiment for emphasis
  },
  createdDate: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    padding: theme.spacing(2),
  },
  viewButton: {
    marginTop: theme.spacing(1),
    textTransform: 'none', // Remove text transformation
  },
}));

const SentimentsBox = ({ username, onViewEntry }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sentiments, setSentiments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSentiments = async () => {
      try {
        const response = await axios.get(`/api/sentiments/user/${username}`);
        setSentiments(response.data.sentiments);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch sentiments.');
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchSentiments();
    }
  }, [username]);

  if (loading) {
    return (
      <Box className={classes.loading}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.error}>
        <Typography variant="body1">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box className={`${classes.container} ${isMobile ? classes.mobileContainer : ''}`}>
      {sentiments.length === 0 ? (
        <Typography variant="body1">No sentiments available.</Typography>
      ) : (
        sentiments.map((sentiment) => (
          <Box key={sentiment.sentimentId} className={classes.sentimentItem}>
            <Box className={classes.sentimentHeader}>         
              <Typography variant="caption" className={classes.createdDate} style={{ color: '#000' }}>
                {new Date(sentiment.createdDate).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'short',
                  year: '2-digit',
                })}
              </Typography>
              <Typography variant="body2" className={classes.username}>
                {sentiment.username}
              </Typography>
              <Typography variant="subtitle1" className={classes.sentimentText}>
                {sentiment.sentiment}
              </Typography>     
            </Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.viewButton}
              onClick={() => onViewEntry(sentiment.postId)}
            >
              View Entry
            </Button>
          </Box>
        ))
      )}
    </Box>
  );
};

export default SentimentsBox;
