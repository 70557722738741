import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: '100vw',
    height: '80vh',
    margin: 'auto',
    backgroundColor: '#fff', // Soft greenish-grey
  },
  mobileBigDialog: {
    width: '100%',
    margin: '0',
  },
  descriptionText: { 
    color: '#004d40',
    fontSize: '1.2rem'
  },
  media: {
    maxHeight: '79vh',
    height: '69vh',
    width: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  glowingBorder: {
    border: '2px solid white',
    animation: `$breathingBorder 2s infinite`,
  },
  breathingBorder: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderRadius: 'inherit',
      border: '2px solid white',
      animation: `$breathingAnimation 3s ease-in-out infinite`,
    },
  },
  '@keyframes breathingAnimation': {
    '0%': { boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)' },
    '50%': { boxShadow: '0 0 20px rgba(255, 255, 255, 1)' },
    '100%': { boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)' },
  },
  mobileMedia: {
    maxHeight: '79vh',
    height: '69vh',
    width: '100%',
    cursor: 'pointer',
  },
  glowingBorder: {
    border: '7px solid #B8BCA0', // Soft olive green
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap', 
    marginTop: '29px',
    gap: '16px',
  },
  feedbackButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '3px',
    marginTop: '16px',
    transition: 'transform 0.5s ease',
    '@media (max-width: 600px)': {
      flexWrap: 'nowrap',
    },
  },  
  feedbackButtonContainerShifted: {
    transform: 'translateY(20px)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    margin: '0 auto', // Center the container
    '&:hover': {
      animation: 'loadThenJump 0.6s ease-in-out',},
  },
  
  '@keyframes loadThenJump': {
    '0%': { transform: 'translateY(20px)', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' },
    '50%': { transform: 'translateY(30px)', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' },
    '100%': { transform: 'translateY(10px)', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)' },
  },
  
  button: {
    margin: '8px',
    backgroundColor: '#D2E1DD', // Soft greenish-grey
    color: '#FFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#A8B9A3', // Muted sage
    },
  },
  feedbackCount: {
    animation: '$fadeIn 0.5s ease-in-out 0.3s',
    position: 'absolute',
    top: '-50px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    transform: 'scale(1.2)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.3)',
    },
  },
  feedbackBadge: {
    backgroundColor: '#2D6A4F', // Rich green
    color: '#FFF',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transform: 'scale(1.1)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'scale(0.8)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  },
}));