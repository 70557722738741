// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.retro-screen {
    background-color: black;
    color: green;
    font-family: 'Courier New', monospace;
    padding: 20px;
    max-height: fit-content;
}

.boot-text {
    white-space: pre;
}

@keyframes blink {
    50% { border-color: transparent; }
}

.blinking-cursor {
    border-right: 2px solid green;
    animation: blink 1s step-start infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/Chat/boot.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,qCAAqC;IACrC,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,MAAM,yBAAyB,EAAE;AACrC;;AAEA;IACI,6BAA6B;IAC7B,uCAAuC;AAC3C","sourcesContent":[".retro-screen {\n    background-color: black;\n    color: green;\n    font-family: 'Courier New', monospace;\n    padding: 20px;\n    max-height: fit-content;\n}\n\n.boot-text {\n    white-space: pre;\n}\n\n@keyframes blink {\n    50% { border-color: transparent; }\n}\n\n.blinking-cursor {\n    border-right: 2px solid green;\n    animation: blink 1s step-start infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
