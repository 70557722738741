import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
    color: '#333',
  },
  content: {
    backgroundColor: 'white',
    padding: '40px 30px',
    borderRadius: 10,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    maxWidth: 1000,
    width: '100%',
    textAlign: 'left',
    margin: '30px auto',
    lineHeight: 1.8,
    fontSize: '1rem',
  },
  title: {
    fontSize: '3em',
    marginBottom: 40,
    fontWeight: 800,
    color: '#222',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: 25,
    textAlign: 'justify',
  },
  sectionTitle: {
    fontSize: '2em',
    marginTop: 50,
    marginBottom: 25,
    fontWeight: 700,
    color: '#444',
  },
  subSectionTitle: {
    fontSize: '1.6em',
    marginTop: 30,
    marginBottom: 20,
    fontWeight: 600,
    color: '#555',
  },
  list: {
    margin: '15px 0',
    paddingLeft: '25px',
  },
  listItem: {
    marginBottom: 15,
  },
  link: {
    color: '#1a0dab',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h1 className={classes.title}>Librious.com Privacy Policy</h1>
        
        <p className={classes.paragraph}>
          <strong>Effective Date:</strong> November 11 2024
        </p>
        
        <p className={classes.paragraph}>
          At Librious LLC ("Librious," "we," "us," or "our"), we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, <a href="https://librious.com" className={classes.link}>librious.com</a> (the "Site"), and use our services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
        </p>

        <div className={classes.sectionTitle}>1. Definitions</div>
        <p className={classes.paragraph}>
          For the purposes of this Privacy Policy:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>"Personal Data"</strong> refers to any information relating to an identified or identifiable natural person.
          </li>
          <li className={classes.listItem}>
            <strong>"Processing"</strong> means any operation or set of operations performed on Personal Data, whether or not by automated means.
          </li>
          <li className={classes.listItem}>
            <strong>"Data Subject"</strong> means the individual to whom Personal Data relates.
          </li>
          <li className={classes.listItem}>
            <strong>"Service"</strong> refers to the services provided by Librious LLC through the Site.
          </li>
        </ul>

        <div className={classes.sectionTitle}>2. Information We Collect</div>
        <p className={classes.paragraph}>
          We may collect and process the following types of information about you:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Personal Identification Information:</strong> Email address, phone number, name, and other contact details.
          </li>
          <li className={classes.listItem}>
            <strong>Demographic Information:</strong> Preferences, interests, and other demographic data.
          </li>
          <li className={classes.listItem}>
            <strong>Technical Data:</strong> IP address, browser type and version, time zone settings, browser plug-in types and versions, operating system, and platform.
          </li>
          <li className={classes.listItem}>
            <strong>Usage Data:</strong> Information about how you use our Site, products, and services.
          </li>
          <li className={classes.listItem}>
            <strong>Marketing and Communications Data:</strong> Your preferences in receiving marketing from us and your communication preferences.
          </li>
          <li className={classes.listItem}>
            <strong>Transactional Data:</strong> Details about payments to and from you and other details of products and services you have purchased from us.
          </li>
          <li className={classes.listItem}>
            <strong>User Content:</strong> Content you upload, submit, or otherwise provide to us.
          </li>
        </ul>

        <div className={classes.sectionTitle}>3. How We Collect Your Information</div>
        <p className={classes.paragraph}>
          We collect information about you in the following ways:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Direct Interactions:</strong> You may provide information by filling in forms on our Site, subscribing to services, or otherwise communicating with us.
          </li>
          <li className={classes.listItem}>
            <strong>Automated Technologies:</strong> As you interact with our Site, we may automatically collect Technical Data about your equipment, browsing actions, and patterns. We may also collect Personal Data using cookies, server logs, and other similar technologies.
          </li>
          <li className={classes.listItem}>
            <strong>Third Parties or Publicly Available Sources:</strong> We may receive information about you from various third parties and public sources.
          </li>
        </ul>

        <div className={classes.sectionTitle}>4. Legal Bases for Processing Your Information</div>
        <p className={classes.paragraph}>
          We process your Personal Data based on the following legal grounds:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Consent:</strong> You have given clear consent for us to process your Personal Data for a specific purpose.
          </li>
          <li className={classes.listItem}>
            <strong>Performance of a Contract:</strong> Processing is necessary for a contract we have with you or because you have asked us to take specific steps before entering into a contract.
          </li>
          <li className={classes.listItem}>
            <strong>Legal Obligation:</strong> Processing is necessary for us to comply with the law.
          </li>
          <li className={classes.listItem}>
            <strong>Legitimate Interests:</strong> Processing is necessary for our legitimate interests or the legitimate interests of a third party, unless there is a good reason to protect your personal data which overrides those legitimate interests.
          </li>
        </ul>

        <div className={classes.sectionTitle}>5. How We Use Your Information</div>
        <p className={classes.paragraph}>
          We use the information we collect in the following ways:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            To provide, operate, and maintain our Site and services.
          </li>
          <li className={classes.listItem}>
            To improve, personalize, and expand our Site and services.
          </li>
          <li className={classes.listItem}>
            To understand and analyze how you use our Site and services.
          </li>
          <li className={classes.listItem}>
            To develop new products, services, features, and functionality.
          </li>
          <li className={classes.listItem}>
            To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Site, and for marketing and promotional purposes.
          </li>
          <li className={classes.listItem}>
            To process your transactions and manage your orders.
          </li>
          <li className={classes.listItem}>
            To send you emails.
          </li>
          <li className={classes.listItem}>
            To find and prevent fraud.
          </li>
          <li className={classes.listItem}>
            To comply with legal obligations and resolve any disputes that we may have.
          </li>
        </ul>

        <div className={classes.sectionTitle}>6. Disclosure of Your Information</div>
        <p className={classes.paragraph}>
          We may share your Personal Data with:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Third-Party Service Providers:</strong> Service providers who perform services for us or on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
          </li>
          <li className={classes.listItem}>
            <strong>Business Transfers:</strong> In connection with a merger, acquisition, or sale of all or a portion of our assets, your Personal Data may be transferred.
          </li>
          <li className={classes.listItem}>
            <strong>Affiliates:</strong> Our affiliates may share your information with us.
          </li>
          <li className={classes.listItem}>
            <strong>Legal Requirements:</strong> We may disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
          </li>
          <li className={classes.listItem}>
            <strong>Protecting Rights:</strong> To protect the rights, property, or safety of Librious, our users, or others.
          </li>
        </ul>

        <div className={classes.sectionTitle}>7. International Data Transfers</div>
        <p className={classes.paragraph}>
          Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
        </p>
        <p className={classes.paragraph}>
          If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.
        </p>
        <p className={classes.paragraph}>
          Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
        </p>
        <p className={classes.paragraph}>
          Librious will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
        </p>

        <div className={classes.sectionTitle}>8. Data Retention</div>
        <p className={classes.paragraph}>
          We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
        </p>
        <p className={classes.paragraph}>
          When we have no ongoing legitimate business need to process your Personal Data, we will either delete or anonymize it, or, if this is not possible (for example, because your Personal Data has been stored in backup archives), then we will securely store your Personal Data and isolate it from any further processing until deletion is possible.
        </p>

        <div className={classes.sectionTitle}>9. Children's Privacy</div>
        <p className={classes.paragraph}>
          Our Services are not intended for use by children under the age of 13. We do not knowingly collect Personal Data from children under 13. If we become aware that we have collected Personal Data from a child under the age of 13 without verification of parental consent, we will take steps to remove that information from our servers.
        </p>

        <div className={classes.sectionTitle}>10. Cookies and Tracking Technologies</div>
        <p className={classes.paragraph}>
          We use cookies and similar tracking technologies to track the activity on our Site and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.
        </p>
        <p className={classes.paragraph}>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.
        </p>
        <p className={classes.paragraph}>
          Additionally, we may use web beacons, pixels, and other tracking technologies to collect information about your interaction with our Site and services.
        </p>

        <div className={classes.sectionTitle}>11. Your Rights Regarding Your Information</div>
        <p className={classes.paragraph}>
          Depending on your location and applicable law, you may have the following rights regarding your Personal Data:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Right to Access:</strong> You can request access to the Personal Data we hold about you.
          </li>
          <li className={classes.listItem}>
            <strong>Right to Rectification:</strong> You can request that we correct any inaccurate or incomplete Personal Data we hold about you.
          </li>
          <li className={classes.listItem}>
            <strong>Right to Erasure:</strong> You can request that we delete your Personal Data, subject to certain conditions.
          </li>
          <li className={classes.listItem}>
            <strong>Right to Restrict Processing:</strong> You can request that we restrict the processing of your Personal Data under certain conditions.
          </li>
          <li className={classes.listItem}>
            <strong>Right to Data Portability:</strong> You can request a copy of your Personal Data in a structured, machine-readable format.
          </li>
          <li className={classes.listItem}>
            <strong>Right to Object:</strong> You can object to the processing of your Personal Data in certain circumstances.
          </li>
          <li className={classes.listItem}>
            <strong>Right to Withdraw Consent:</strong> If we are processing your Personal Data based on your consent, you can withdraw that consent at any time.
          </li>
        </ul>
        <p className={classes.paragraph}>
          To exercise any of these rights, please contact us using the contact details provided in the "Contact Us" section below. We will respond to your request within a reasonable timeframe and in accordance with applicable laws.
        </p>

        <div className={classes.sectionTitle}>12. How to Exercise Your Rights</div>
        <p className={classes.paragraph}>
          You can exercise your rights by contacting us at:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Email:</strong> <a href="mailto:support@librious.com" className={classes.link}>support@librious.com</a>
          </li>
        </ul>
        <p className={classes.paragraph}>
          Please include sufficient information to help us understand and respond to your request.
        </p>

        <div className={classes.sectionTitle}>13. Third-Party Links</div>
        <p className={classes.paragraph}>
          Our Site may contain links to third-party websites and services that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
        </p>
        <p className={classes.paragraph}>
          We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </p>

        <div className={classes.sectionTitle}>14. Changes to This Privacy Policy</div>
        <p className={classes.paragraph}>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.
        </p>
        <p className={classes.paragraph}>
          When we update our Privacy Policy, we will revise the "Effective Date" at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
        </p>

        <div className={classes.sectionTitle}>15. Data Breach Notification</div>
        <p className={classes.paragraph}>
          In the event of a data breach that affects your Personal Data, we will notify you and the appropriate authorities as required by law. We will also take necessary measures to mitigate the breach and prevent future occurrences.
        </p>

        <div className={classes.sectionTitle}>16. California Privacy Rights (CCPA)</div>
        <p className={classes.paragraph}>
          If you are a resident of California, you have specific rights under the California Consumer Privacy Act (CCPA). These include the right to know what Personal Data is being collected, the right to request deletion of Personal Data, and the right to opt-out of the sale of your Personal Data.
        </p>
        <p className={classes.paragraph}>
          To exercise your CCPA rights, please contact us at <a href="mailto:support@librious.com" className={classes.link}>support@librious.com</a>.
        </p>

        <div className={classes.sectionTitle}>17. International Users</div>
        <p className={classes.paragraph}>
          Our Services are intended for users located within the United States. If you are accessing the Site from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated.
        </p>
        <p className={classes.paragraph}>
          By using our Site and providing your information, you consent to the transfer, storage, and processing of your information in the United States.
        </p>

        <div className={classes.sectionTitle}>18. Contact Us</div>
        <p className={classes.paragraph}>
          If you have any questions about this Privacy Policy, please contact us:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Email:</strong> <a href="mailto:support@librious.com" className={classes.link}>support@librious.com</a>
          </li>
        </ul>

        <p className={classes.paragraph}>
          Thank you for trusting Librious LLC with your personal information. We are committed to safeguarding your data and providing you with a secure and transparent experience.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
