import React, { useState } from 'react';
import {
  Button, Chip, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, IconButton, Collapse, Typography
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { GiTreeBranch } from "react-icons/gi";
import { FiBox, FiBookOpen } from "react-icons/fi";
import { FaPeopleRoof } from "react-icons/fa6";
import { IoPeopleCircleOutline } from "react-icons/io5";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import domainsByModel from './domainsByModel';
import SavedDialog from './SavedDialog'; // Import the SavedDialog component
import styled from 'styled-components';
// In your imports at the top of the SearchAndSort component
import PhotoIcon from '@mui/icons-material/Photo';
import VideocamIcon from '@mui/icons-material/Videocam';
import DescriptionIcon from '@mui/icons-material/Description';

const InputContainer = styled.div`
  display: flex; 
  justify-content: center;
  gap: 10px;
  margin: 5px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const InputWrapper = styled.div`
  flex: 1;
  max-width: 45%;
  margin-right: 0px;
  position: relative;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
    align-self: flex-start;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;

  &.people-icon {
    left: 61%;

    @media (max-width: 768px) {
      left: 52%;
      margin-top: 10px;
    }
  }

  &.branches-icon {
    left: 20%;

    @media (max-width: 768px) {
      left: 36%;
      margin-top: 10px;
    }
  }

  &.entry-icon {
    left: -20%;

    @media (max-width: 768px) {
      left: 52%;
      margin-top: 10px;
    }
  }
`;

const InputField = styled.input`
  width: 40%;
  padding: 10px 10px 10px 40px;
  font-size: 16px;
  display: block;
  color: #fff;
  background-color: #004d40;
  border: none;
  text-transform: none;
  margin-left: 0;
  margin-right: 0;

  &.people-input {
    margin-left: 57%;

    @media (max-width: 768px) {
      margin-left: 47%;
      width: 42%;
      margin-bottom: -20px; /* Add margin between inputs */
    }
  }

  &.branches-input {
    margin-left: 15%;

    @media (max-width: 768px) {
      margin-left: 31%;
      width: 69%;
      margin-bottom: -20px; /* Add margin between inputs */
    }
  }

  &.entry-input {
    margin-left: -25%;

    @media (max-width: 768px) {
      margin-left: 47%;
      width: 42%;
      margin-bottom: -20px; /* Add margin between inputs */
    }
  }
`;


const SearchAndSort = ({
  isMobile, sortOrder, setSortOrder, setActiveDomains, setSelectedDomains,
  setSearchTerm, setDomainsDialogOpen, setValuesDialogOpen, activeDomains = [],
  selectedDomains, domainsDialogOpen, valuesDialogOpen, usernames, setUsernames,
  keys, setKeys, handleDeleteKey, searchEntries, setSearchEntries, timeParameter, setTimeParameter,
  handleSelectFromSaved,
  setBranches, // Add these lines
  setFields,   // Add these lines
  setCategories, // Add these lines
  setOrigins,  // Add these lines
  setOriginNames,
  setSelectedItemsByTypeInDialog,   // Add these lines
  setFollowed,  // Add this line
  followed,  // Add this line
}) => {
  // Add this state to manage selected media types
const [selectedMediaTypes, setSelectedMediaTypes] = useState([]);
  const [keyInput, setKeyInput] = useState('');
  const [usernameInput, setUsernameInput] = useState('');
  const [searchEntriesInput, setSearchEntriesInput] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [savedDialogOpen, setSavedDialogOpen] = useState(false); // State to handle Saved dialog open/close
  const [followedSelected, setFollowedSelected] = useState(followed);
  const [selectedItemsByType, setSelectedItemsByType] = useState({
    branches: [],
    fields: [],
    categories: [],
    origins: [],
    originNames: [],
  });

  const handleClearAll = () => {
    setActiveDomains([]);
    setSelectedDomains([]);
    setSearchTerm('');
    setSortOrder('');
    setSearchEntries('');
    setTimeParameter('');
    setKeys([]);
    setUsernames([]);
    setBranches([]);
    setFields([]);
    setCategories([]);
    setOrigins([]);
    setOriginNames([]);
    setSearchEntriesInput('');
    setFollowed(false); // Reset Followed button state
    setFollowedSelected(false); // Reset Followed button colors
    const resetSelection = {
      branches: [],
      fields: [],
      categories: [],
      origins: [],
      originNames: [],
    };
    setSelectedItemsByType(resetSelection); // Reset local state
    setSelectedItemsByTypeInDialog(resetSelection); // Notify SavedDialog to reset
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && keyInput.trim() !== '') {
      setKeys([...keys, keyInput.trim()]);
      setKeyInput('');
    }
  };

  // Add this handler function to toggle media type selection
const handleMediaTypeClick = (mediaType) => {
  setSelectedMediaTypes(prevSelected => {
    if (prevSelected.includes(mediaType)) {
      return prevSelected.filter(type => type !== mediaType);
    } else {
      return [...prevSelected, mediaType];
    }
  });
};

  const handleUsernameKeyDown = (event) => {
    if (event.key === 'Enter' && usernameInput.trim() !== '') {
      setUsernames([...usernames, usernameInput.trim()]);
      setUsernameInput('');
    }
  };

  const handleSearchEntriesKeyDown = (event) => {
    if (event.key === 'Enter' && searchEntriesInput.trim() !== '') {
      setSearchEntries(searchEntriesInput.trim());
      setSearchEntriesInput('');
    }
  };

  const handleDomainClick = (domain) => {
    setActiveDomains(prevDomains => 
      prevDomains.includes(domain) 
      ? prevDomains.filter(d => d !== domain)
      : [...prevDomains, domain]
    );
  };

  const handleFollowedClick = () => {
    const newFollowedState = !followedSelected;
    setFollowedSelected(newFollowedState);
    setFollowed(newFollowedState); // Update the parent state
  };


  const handleValueClick = (value) => {
    setSelectedDomains(prevSelected => 
      prevSelected.includes(value) 
      ? prevSelected.filter(d => d !== value)
      : [...prevSelected, value]
    );
  };

  return (
    <div style={{ textAlign: "center", marginTop: "3px" }}>
      <input
        type="text"
        placeholder="What do you Seek?"
        onKeyDown={(e) => e.key === 'Enter' && setSearchTerm(e.target.value.toLowerCase())}
        style={{
          width: "300px",
          padding: "10px",
          fontSize: "16px",
          margin: "3px auto",
          display: "block",
          color: "#fff",
          backgroundColor: "#004d40",
          border: "none",
          textTransform: "none"
        }}
      />
      <Button
        onClick={() => setShowAdvanced(!showAdvanced)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '3px auto',
          backgroundColor: "#004d40",
          color: "white",
          textTransform: "none"
        }}
      >
        <SearchIcon />
        {showAdvanced ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '3px' }}>
  <IconButton
    onClick={() => handleMediaTypeClick('image')}
    style={{
      color: selectedMediaTypes.includes('image') ? '#004d40' : '#fff',
      backgroundColor: selectedMediaTypes.includes('image') ? '#fff' : '#004d40',
      margin: '0 5px',
    }}
  >
    <PhotoIcon />
  </IconButton>
  <IconButton
    onClick={() => handleMediaTypeClick('video')}
    style={{
      color: selectedMediaTypes.includes('video') ? '#004d40' : '#fff',
      backgroundColor: selectedMediaTypes.includes('video') ? '#fff' : '#004d40',
      margin: '0 5px',
    }}
  >
    <VideocamIcon />
  </IconButton>
  <IconButton
    onClick={() => handleMediaTypeClick('document')}
    style={{
      color: selectedMediaTypes.includes('document') ? '#004d40' : '#fff',
      backgroundColor: selectedMediaTypes.includes('document') ? '#fff' : '#004d40',
      margin: '0 5px',
    }}
  >
    <DescriptionIcon />
  </IconButton>
</div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDomainsDialogOpen(true)}
            style={{ margin: "3px", backgroundColor: "#004d40", textTransform: "none" }}
          >
            Fields
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setValuesDialogOpen(true)}
            disabled={activeDomains.length === 0}
            style={{ margin: "3px", backgroundColor: "#004d40", textTransform: "none" }}
          >
            Categories
          </Button>
        </div>
        <InputContainer>
    {/* People Input */}
    <InputWrapper>
      <Icon as={FaPeopleRoof} className="people-icon" />
      <InputField
        value={usernameInput}
        onChange={(e) => setUsernameInput(e.target.value)}
        onKeyDown={handleUsernameKeyDown}
        className="people-input"
        placeholder="People"
      />
    </InputWrapper>

    {/* Branches Input */}
    <InputWrapper>
      <Icon as={GiTreeBranch} className="branches-icon" />
      <InputField
        value={keyInput}
        onChange={(e) => setKeyInput(e.target.value)}
        onKeyDown={handleKeyDown}
        className="branches-input"
        placeholder="Branches"
      />
    </InputWrapper>

    {/* Entry Ids Input 
    <InputWrapper>
      <Icon as={FiBookOpen} className="entry-icon" />
      <InputField
        value={searchEntriesInput}
        onChange={(e) => setSearchEntriesInput(e.target.value)}
        onKeyDown={handleSearchEntriesKeyDown}
        className="entry-input"
        placeholder="Entry Ids"
      />
    </InputWrapper>*/}
  </InputContainer>
        <div style={{ textAlign: "center", margin: "3px 0" }}>
          <Button
            onClick={() => setSortOrder("most")}
            style={{
              marginLeft: "5.69px",
              margin: "3.69px 4.69px",
              backgroundColor: sortOrder === "most" ? "#fff" : "#004d40",
              color: sortOrder === "most" ? "#004d40" : "white",
              zIndex: 1100,
              textTransform: "none"
            }}
          >
            Most Interacted
          </Button>
          <Button
            onClick={() => setSortOrder("least")}
            style={{
              marginLeft: "5.69px",
              margin: "3.69px 4.69px",
              backgroundColor: sortOrder === "least" ? "#fff" : "#004d40",
              color: sortOrder === "least" ? "#004d40" : "white",
              zIndex: 1100,
              textTransform: "none"
            }}
          >
            Least Interacted
          </Button>
          <div style={{ textAlign: "center", margin: "3px 0" }}>
            <Button
              onClick={() => setTimeParameter("24h")}
              style={{
                marginLeft: "5.69px", 
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "24h" ? "#fff" : "#004d40",
                color: timeParameter === "24h" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last Day
            </Button>
            <Button
              onClick={() => setTimeParameter("week")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "week" ? "#fff" : "#004d40",
                color: timeParameter === "week" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last Week
            </Button>
            <Button
              onClick={() => setTimeParameter("month")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "month" ? "#fff" : "#004d40",
                color: timeParameter === "month" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last Month
            </Button>
            <Button
              onClick={() => setTimeParameter("6months")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "6months" ? "#fff" : "#004d40",
                color: timeParameter === "6months" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              Last 6 Months
            </Button>
            <Button
              onClick={() => setTimeParameter("all")}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: timeParameter === "all" ? "#fff" : "#004d40",
                color: timeParameter === "all" ? "#004d40" : "white",
                zIndex: 1100,
                textTransform: "none"
              }}
            >
              All Time
            </Button>
          </div>
          <Button
  onClick={handleFollowedClick}
  style={{
    marginLeft: "5.69px",
    margin: "3.69px 4.69px",
    backgroundColor: followedSelected ? "#fff" : "#004d40",
    color: followedSelected ? "#004d40" : "white",
    zIndex: 1100,
    textTransform: "none"
  }}
>
  Followed
</Button>
          <Button
          onClick={() => setSavedDialogOpen(true)}
          style={{
            marginLeft: "5.69px",
            margin: "3.69px 4.69px",
            backgroundColor: "#004d40",
            color: "white",
            zIndex: 1100,
            textTransform: "none"
          }}
        >
          Saved
        </Button>
          <Button
            onClick={handleClearAll}
            style={{
              marginLeft: "5.69px",
              margin: "3.69px 4.69px",
              backgroundColor: "#004d40",
              color: "white",
              zIndex: 1100,
              textTransform: "none"
            }}
          >
            Clear All
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', margin: '3px' }}>
          {keys.map((key) => (
            <Chip
              key={key}
              label={key}
              onDelete={() => handleDeleteKey(key)}
              style={{ margin: "5px", backgroundColor: "#fff", color: "#004d40", textTransform: "none" }}
            />
          ))}
          {usernames.map((username) => (
            <Chip
              key={username}
              label={username}
              onDelete={() => handleDeleteKey(username)}
              style={{ margin: "5px", backgroundColor: "#fff", color: "#004d40", textTransform: "none" }}
            />
          ))}
          {searchEntries && (
            <Chip
              label={searchEntries}
              onDelete={() => setSearchEntries('')}
              style={{ margin: "5px", backgroundColor: "#fff", color: "#004d40", textTransform: "none" }}
            />
          )}
        </div>
      </Collapse>
      <Dialog
        open={domainsDialogOpen}
        onClose={() => setDomainsDialogOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: isMobile
            ? { width: '90vw', height: 'auto', backgroundColor: 'transparent' }
            : { width: '50vw', height: '50vh', backgroundColor: 'transparent' },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: 'transparent', color: '#fff' }}>
          <IconButton
            aria-label="close"
            onClick={() => setDomainsDialogOpen(false)}
            style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: 'transparent' }}>
  <List
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      gap: '5px',
      justifyContent: 'center'
    }}
  >
    {Object.keys(domainsByModel).map((domain) => (
      <ListItem
        button
        onClick={() => handleDomainClick(domain)}
        key={domain}
        selected={activeDomains.includes(domain)}
        style={{
          flex: '0 0 auto',
          boxSizing: 'border-box',
          padding: '10px',
          backgroundColor: activeDomains.includes(domain) ? '#fff' : '#004d40',
          color: activeDomains.includes(domain) ? '#004d40' : '#fff',
          textAlign: 'center',
          width: 'fit-content',
          borderRadius: '10px'
        }}
      >
        <ListItemText primary={domain.replace(/([a-z])([A-Z])/g, '$1 $2')} />
      </ListItem>
    ))}
  </List>
</DialogContent>

      </Dialog>
      <Dialog
        open={valuesDialogOpen}
        onClose={() => setValuesDialogOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: isMobile
            ? { width: '90vw', height: 'auto', backgroundColor: 'transparent' }
            : { width: '50vw', height: '50vh', backgroundColor: 'transparent' },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: 'transparent', color: '#fff' }}>
          <IconButton
            aria-label="close"
            onClick={() => setValuesDialogOpen(false)}
            style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: 'transparent' }}>
          <List style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
            {activeDomains.map((domain) => (
              <div key={domain} style={{ width: '100%' }}>
                <Typography variant="h6" style={{ textAlign: 'center', margin: '10px 0', color: '#fff' }}>
  {domain.replace(/([a-z])([A-Z])/g, '$1 $2')}
</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                  {domainsByModel[domain]?.map((value) => (
                    <ListItem 
                      button 
                      onClick={() => handleValueClick(value)} 
                      key={value}
                      selected={selectedDomains.includes(value)}
                      style={{ padding: '10px', backgroundColor: selectedDomains.includes(value) ? '#fff' : '#004d40', color: selectedDomains.includes(value) ? '#004d40' : '#fff', textAlign: 'center', width: 'fit-content', borderRadius: '10px' }}
                    >
                      <ListItemText primary={value} />
                    </ListItem>
                  ))}
                </div>
              </div>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <SavedDialog
        open={savedDialogOpen}
        onClose={() => setSavedDialogOpen(false)}
        isMobile={isMobile}
        handleSelect={handleSelectFromSaved}
        resetSelection={selectedItemsByType}
      />
    </div>
  );
};

export default SearchAndSort;
