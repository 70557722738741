import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  CircularProgress, Typography, Grid, Card, CardContent
} from '@mui/material';
import { styled } from '@mui/system';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StyledDialogContent = styled(DialogContent)`
  background: transparent;
`;

const StyledCard = styled(Card)`
  background-color: #f9f9f9;
`;

const PersonStats = ({ username }) => {
  const [open, setOpen] = useState(true);
  const [totalViews, setTotalViews] = useState(0);
  const [feedbackValues, setFeedbackValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/user-total-views/${username}`);
        setTotalViews(response.data.totalViews);
        setFeedbackValues(response.data.feedbackValues);
      } catch (err) {
        setError('Failed to fetch total views');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username]);

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const feedbackData = {
    labels: ['Nice', 'Interesting', 'Not Sure', 'Whistles'],
    datasets: [
      {
        label: 'Feedback Values',
        data: [
          feedbackValues.nice,
          feedbackValues.interesting,
          feedbackValues.notSure,
          feedbackValues.whistle
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)'
        ],
        borderWidth: 1
      }
    ]
  };

  const feedbackOptions = {
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth PaperProps={{
    style: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  }}>
        <StyledDialogContent>
          <DialogContentText>
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" align="center">
                        Views
                      </Typography>
                      <Typography variant="h3" align="center" color="primary">
                        {totalViews}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" align="center">
                        Feedback
                      </Typography>
                      <Bar data={feedbackData} options={feedbackOptions} />
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            )}
          </DialogContentText>
        </StyledDialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PersonStats;
