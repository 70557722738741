// src/components/PoolCreationForm.js

import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import generateBase64Encode from '../utils/genBase64Encode';
import useStyles from './stylin'; // Adjust the path as necessary

const PoolCreationForm = ({ fetchPools }) => {
    const classes = useStyles();
    const [poolName, setPoolName] = useState('');
    const [description, setDescription] = useState('');
    const [businessInfo, setBusinessInfo] = useState({
        businessName: '',
        contactEmail: '',
        contactPhone: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
        },
        officialEntity: true, // Default to true as per the model
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [password, setPassword] = useState('');
    const [imageError, setImageError] = useState(false);

    const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
    const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const base64Data = await generateBase64Encode(file);
            setImageData(base64Data);
            setImagePreview(URL.createObjectURL(file));
            setImageError(false);
        }
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        // Optionally reset form fields
        resetForm();
    };

    const resetForm = () => {
        setPoolName('');
        setDescription('');
        setBusinessInfo({
            businessName: '',
            website: '',
            contactEmail: '',
            contactPhone: '',
            address: {
                street: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
            },
            officialEntity: true,
        });
        setImageData(null);
        setImagePreview(null);
        setPassword('');
        setImageError(false);
    };

    const handleBusinessInfoChange = (e) => {
        const { name, value } = e.target;
        if (['street', 'city', 'state', 'zipCode', 'country'].includes(name)) {
            setBusinessInfo((prev) => ({
                ...prev,
                address: {
                    ...prev.address,
                    [name]: value,
                },
            }));
        } else {
            setBusinessInfo((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleOfficialEntityChange = (e) => {
        setBusinessInfo((prev) => ({
            ...prev,
            officialEntity: e.target.checked,
        }));
    };

    const createPool = async (e) => {
        e.preventDefault();
        if (!imageData) {
            setImageError(true);
            return;
        }
        try {
            const response = await fetch('/api/add-official-pools', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: poolName,
                    description,
                    creator: loggedInUser,
                    imageData,
                    password,
                    businessInfo, // Include detailed businessInfo
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Pool created:', data);
                resetForm();
                fetchPools();
                handleDialogClose();
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to create pool');
            }
        } catch (error) {
            console.error('Error creating pool:', error);
        }
    };

    return (
        <div className={classes.formContainer}>
            <Button 
                variant="contained" 
                className={classes.toggleButton} 
                onClick={handleDialogOpen}
                style={{
                    backgroundColor: openDialog ? "#fff" : "#004d40",
                    color: openDialog ? "#004d40" : "#fff",
                }}
            >
                Add Pool
            </Button>
            <Dialog 
                open={openDialog} 
                onClose={handleDialogClose} 
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackdrop,
                    },
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                    Add Pool
                </DialogTitle>
                <form onSubmit={createPool} className={classes.form}>
                    <DialogContent className={classes.scrollableContainer}>
                        {/* Pool Name */}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="poolName" // Ensure name matches state key if needed
                            label="Pool Name"
                            type="text"
                            fullWidth
                            value={poolName}
                            onChange={(e) => setPoolName(e.target.value)}
                            required
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />

                        {/* Password */}
                        <TextField
                            margin="dense"
                            id="password"
                            label="Password (Optional)"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />

                        {/* Description */}
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />

                        {/* Business Information Section */}
                        <Typography variant="h6" style={{ marginTop: '16px', marginBottom: '8px' }}>
                            Business Information
                        </Typography>

                        {/* Business Name */}
                        <TextField
                            margin="dense"
                            id="businessName"
                            name="businessName" // Added name attribute
                            label="Business Name"
                            type="text"
                            fullWidth
                            value={businessInfo.businessName}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="website"
                            name="website" // Added name attribute
                            label="website"
                            type="text"
                            fullWidth
                            value={businessInfo.website}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />
                        {/* Contact Email */}
                        <TextField
                            margin="dense"
                            id="contactEmail"
                            name="contactEmail" // Added name attribute
                            label="Contact Email"
                            type="email"
                            fullWidth
                            value={businessInfo.contactEmail}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />

                        {/* Contact Phone */}
                        <TextField
                            margin="dense"
                            id="contactPhone"
                            name="contactPhone" // Added name attribute
                            label="Contact Phone"
                            type="tel"
                            fullWidth
                            value={businessInfo.contactPhone}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />

                        {/* Address Fields */}
                        <Typography variant="subtitle1" style={{ marginTop: '16px', marginBottom: '8px' }}>
                            Address
                        </Typography>
                        <TextField
                            margin="dense"
                            id="street"
                            label="Street"
                            type="text"
                            fullWidth
                            name="street"
                            value={businessInfo.address.street}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="city"
                            label="City"
                            type="text"
                            fullWidth
                            name="city"
                            value={businessInfo.address.city}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="state"
                            label="State"
                            type="text"
                            fullWidth
                            name="state"
                            value={businessInfo.address.state}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="zipCode"
                            label="Zip Code"
                            type="text"
                            fullWidth
                            name="zipCode"
                            value={businessInfo.address.zipCode}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="country"
                            label="Country"
                            type="text"
                            fullWidth
                            name="country"
                            value={businessInfo.address.country}
                            onChange={handleBusinessInfoChange}
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel,
                            }}
                        />

                        {/* Official Entity Checkbox */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={businessInfo.officialEntity}
                                    onChange={handleOfficialEntityChange}
                                    name="officialEntity"
                                    color="primary"
                                />
                            }
                            label="Managed by an Official Entity"
                            className={classes.formControl}
                        />

                        {/* Image Upload */}
                        <div className={classes.uploadContainer}>
                            <input
                                accept="image/*"
                                id="image-upload"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                            <label htmlFor="image-upload" className={classes.uploadLabel}>
                                <IconButton color="primary" component="span">
                                    <AddPhotoAlternateIcon style={{ color: '#004d40' }} />
                                </IconButton>
                                <Typography variant="body1">Upload Image</Typography>
                            </label>
                        </div>
                        {imagePreview && (
                            <div className={classes.previewContainer}>
                                <div className={classes.previewImageContainer}>
                                    <img
                                        src={imagePreview}
                                        alt="Uploaded Preview"
                                        className={classes.previewImage}
                                    />
                                    <IconButton
                                        size="small"
                                        className={classes.removeButton}
                                        onClick={() => {
                                            setImageData(null);
                                            setImagePreview(null);
                                        }}
                                    >
                                        &times;
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        {imageError && (
                            <Typography color="error" variant="body2">
                                Image is required to create a pool.
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions className={classes.buttonContainer}>
                        <Button 
                            onClick={handleDialogClose} 
                            className={classes.cancelButton}
                            style={{
                                backgroundColor: openDialog ? "#FF1744" : "#fff",
                                color: openDialog ? "#fff" : "#FF1744",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type="submit" 
                            className={classes.submitButton}
                            style={{
                                backgroundColor: openDialog ? "#004d40" : "#fff",
                                color: openDialog ? "#fff" : "#004d40",
                            }}
                        >
                            Create
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );

};

export default PoolCreationForm;
