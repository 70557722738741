import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Modal,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Explorer from '../Person/newexplorer/explorer'; // Adjust the import path as needed

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Arial, sans-serif',
    width: '100%',
    padding: theme.spacing(4),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '100vw',
      marginLeft: '-3.69vw'
    },
  },
  timeframeButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '10%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#2f4f4f',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#004d40',
    },
  },
  selectedButton: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    color: '#2f4f4f',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  graphsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '20px',
    background: 'transparent',
  },
  graphCard: {
    width: '300px',
    margin: '20px',
    padding: '10px',
    background: '#ffffff',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      boxShadow: '0 6px 12px 0 rgba(0, 77, 64, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: theme.spacing(1, 0),
    },
  },
  cardHeader: {
    backgroundColor: '#2f4f4f',
    color: '#ffffff',
    padding: theme.spacing(1),
    borderRadius: '4px 4px 0 0',
  },
  cardContent: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  modalPaper: {
    position: 'absolute',
    background: '#F0F0F0',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      height: '96.69vh',
      top: 0,
      left: 0,
      transform: 'none',
      borderRadius: 0,
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    overflow: 'auto',
    height: 'calc(100% - 100px)',[theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 104px)', // Adjusted for mobile header and tabs height
    },
  },
  tableContainer: {
    maxHeight: '60vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '50vh',
    },
  },
  table: {
    minWidth: 650,
  },
  dialogPaperMobile: {
    height: '100vh',
    width: '99vw',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '100vh',
    },
  },
  dialogContent: {
    padding: 0,
    color: 'white',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  spinner: {
    color: '#2f4f4f',
  },
}));

const subjects = [
  'Environment', 'Computers', 'Physics', 'Chemistry', 'Biology', 'Astronomy', 'Mathematics',
  'Painting', 'Sculpting', 'Literature', 'Music', 'Theatre', 'Architecture', 'Cinema',
  'DigitalArt', 'Nature', 'Fashion', 'Beauty', 'Travel', 'Countries', 'Parenting', 'Sports',
  'Health', 'Cooking', 'PersonalFinance', 'Stocks', 'Crypto', 'Entertainment', 'Technology',
  'Gaming', 'Politics', 'CurrentEvents', 'Religion', 'Home', 'Volunteering', 'College',
  'Career', 'Relationships', 'Open', 'Gathered', 'Education', 'HumanRights', 'Ethics',
  'Innovation', 'Communication', 'Transportation', 'Globalization', 'Family', 'Aging', 'Law',
  'Economics', 'Language', 'Philosophy', 'Medicine', 'Agriculture', 'Sustainability', 'Peace',
  'DisasterManagement', 'Infrastructure', 'Energy', 'ArtificialIntelligence', 'Privacy',
  'Security', 'SpaceExploration', 'WaterResources', 'UrbanDevelopment', 'CulturalDiversity',
  'MentalHealth', 'SocialJustice', 'Photography', 'Dance', 'History', 'Psychology', 'Sociology',
  'Anthropology', 'Engineering', 'Business', 'Marketing', 'Finance', 'Military', 'Weather',
  'Pets', 'Comics', 'Anime', 'Automotive', 'Hiking', 'DIY', 'Crafts', 'Fitness', 'Comedy',
  'Gardening', 'Podcasts', 'Memes', 'Official'
];

const Trends = () => {
  const [timeframe, setTimeframe] = useState('24h');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [trendsData, setTrendsData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [entryDialog, setEntryDialog] = useState({ isOpen: false, entryId: null });
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const fetchTrends = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/get-trends', {
          params: {
            timeframe, 
          },
        });

        setTrendsData(response.data);
      } catch (error) {
        console.error('Error fetching trends:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrends();
  }, [timeframe]);

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
  };

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedSubject(null);
    setSelectedTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewEntry = (entryId) => {
    setEntryDialog({ isOpen: true, entryId });
  };

  const handleCloseEntryDialog = () => {
    setEntryDialog({ isOpen: false, entryId: null });
  };

  const renderChart = (subject) => {
    if (!trendsData[subject] || trendsData[subject].length === 0) return null;

    const labels = trendsData[subject].map((entry, index) => `Entry ${index + 1}`);
    const totalFeedback = trendsData[subject].map(entry => entry.totalFeedback);

    const data = {
      labels,
      datasets: [
        {
          label: 'Feedback',
          data: totalFeedback,
          backgroundColor: 'rgba(47, 79, 79, 0.8)',
          borderColor: '#000000',
          borderWidth: 1,
          fill: true,
        },
      ],
    };

    return (
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: { display: true, title: { display: true, text: '' } },
            y: { display: true, title: { display: true, text: '' }, beginAtZero: true },
          },
          plugins: {
            legend: { position: 'top' },
            tooltip: { enabled: true },
          },
        }}
      />
    );
  };

  const renderTabContent = () => {
    if (selectedTab === 0) {
      return renderChart(selectedSubject);
    } else if (selectedTab === 1) {
      return (
        <div className={classes.tableContainer}>
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><b>Entry</b></TableCell>
                <TableCell><b>Person</b></TableCell>
                <TableCell><b>Origin</b></TableCell>
                <TableCell><b>Origin Name</b></TableCell>
                <TableCell><b>Category</b></TableCell>
                <TableCell><b>Branches</b></TableCell>
                <TableCell><b>Feedback</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trendsData[selectedSubject]?.map((entry, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewEntry(entry.uniqueEntries[0]._id)}
                      style={{ textTransform: 'none' }}
                    >
                      View Entry
                    </Button>
                  </TableCell>
                  <TableCell>{entry.username}</TableCell>
                  <TableCell>{entry.sourceType}</TableCell>
                  <TableCell>{entry.sourceTypeName}</TableCell>
                  <TableCell>{entry.domain}</TableCell>
                  <TableCell>{entry.keys?.join(', ') || ''}</TableCell>
                  <TableCell>{entry.totalFeedback}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      );
    } else if (selectedTab === 2) {
      return (
        <div className={classes.tableContainer}>
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><b>Entry</b></TableCell>
                <TableCell><b>Nice Feedback</b></TableCell>
                <TableCell><b>Interesting Feedback</b></TableCell>
                <TableCell><b>Not Sure Feedback</b></TableCell>
                <TableCell><b>Whistle Feedback</b></TableCell>
                <TableCell><b>Total Feedback</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trendsData[selectedSubject]?.map((entry, index) => (
                <TableRow key={index}>
                  <TableCell>Entry {index + 1}</TableCell>
                  <TableCell>{entry.niceFeedback}</TableCell>
                  <TableCell>{entry.interestingFeedback}</TableCell>
                  <TableCell>{entry.notSureFeedback}</TableCell>
                  <TableCell>{entry.whistleFeedback}</TableCell>
                  <TableCell>{entry.totalFeedback}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      {/* Timeframe Buttons */}
      <div className={classes.timeframeButtons}>
        <Button
          className={timeframe === '24h' ? classes.selectedButton : classes.button}
          onClick={() => handleTimeframeChange('24h')}
        >
          Last 24 Hours
        </Button>
        <Button
          className={timeframe === '1w' ? classes.selectedButton : classes.button}
          onClick={() => handleTimeframeChange('1w')}
        >
          Last Week
        </Button>
        <Button
          className={timeframe === '1m' ? classes.selectedButton : classes.button}
          onClick={() => handleTimeframeChange('1m')}
        >
          Last Month
        </Button>
        <Button
          className={timeframe === '6m' ? classes.selectedButton : classes.button}
          onClick={() => handleTimeframeChange('6m')}
        >
          Last Six Months
        </Button>
        <Button
          className={timeframe === 'all' ? classes.selectedButton : classes.button}
          onClick={() => handleTimeframeChange('all')}
        >
          All
        </Button>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinner} />
        </div>
      )}

      {/* Graphs Container */}
      {!loading && (
        <div className={classes.graphsContainer}>
          {subjects.filter(subject => trendsData[subject] && trendsData[subject].length > 0).map(subject => (
            <Card
              key={subject}
              className={classes.graphCard}
              onClick={() => handleSubjectClick(subject)}
            >
              <div className={classes.cardHeader}>
              <Typography variant="h6">
  {subject.replace(/([a-z])([A-Z])/g, '$1 $2')}
</Typography>
              </div>
              <CardContent className={classes.cardContent}> 
                {renderChart(subject)}
              </CardContent>
            </Card>
          ))}
        </div>
      )}

      {/* Modal for Detailed View */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="subject-modal-title"
        aria-describedby="subject-modal-description"
      >
        <Paper className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <Typography variant="h5" id="subject-modal-title">
              {selectedSubject}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Chart" style={{ textTransform: 'none', color: 'black' }} />
            <Tab label="Details" style={{ textTransform: 'none', color: 'black' }} />
          </Tabs>
          <div className={classes.modalContent}>
            {renderTabContent()}
          </div>
        </Paper>
      </Modal>

      {/* Dialog for Entry Viewer */}
      <Dialog
        open={entryDialog.isOpen}
        onClose={handleCloseEntryDialog}
        fullScreen
        classes={{ paper: classes.dialogPaperMobile }}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
          onClick: handleCloseEntryDialog,
        }}
      >
        <DialogContent
          className={classes.dialogContent}
          onClick={(e) => e.stopPropagation()}
        >
          <Explorer ids={entryDialog.entryId} onClose={handleCloseEntryDialog} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Trends;
