import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import Explorer from '../components/Explorers2/utils/explorer/poolexplorer';
import PoolStats from './PoolStats';
import axios from 'axios';

const PoolCardSelect = ({ name, _id, open, onClose }) => {
  const [statsOpen, setStatsOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [poolInfo, setPoolInfo] = useState({});
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [isInPool, setIsInPool] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const colors = {
    darkSlateGray: '#2f4f4f',
    darkOliveGreen: '#556b2f',
    white: '#ffffff',
    darkTeal: '#004d40',
    tiffanyBlue: '#0abab5',
  };

  useEffect(() => {
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    if (readifyUserKey) {
      const { username } = JSON.parse(readifyUserKey);
      setUsername(username);
    }
  }, []);

  useEffect(() => {
    const checkUserInPool = async () => {
      try {
        const response = await axios.post('/api/check-user-in-pool', { poolId: _id, username });
        if (response.data.isInPool) {
          setIsInPool(true);
        } else {
          setIsInPool(false);
        }
      } catch (error) {
        console.error('Error checking if user is in pool:', error);
      }
    };

    if (username) {
      checkUserInPool();
    }
  }, [username, _id]);

  const handleStatsClick = () => {
    setStatsOpen(true);
  };

  const handleStatsClose = () => {
    setStatsOpen(false);
  };

  const handleInfoClick = async () => {
    try {
      const response = await axios.get(`/api/pool-info/${_id}`);
      const data = response.data.poolInfo;
      
      const formattedPoolInfo = {
        ...data,
        firstEntryDate: new Date(data.firstEntryDate),
        mostRecentEntryDate: new Date(data.mostRecentEntryDate),
      };

      setPoolInfo(formattedPoolInfo);
      setInfoOpen(true);
    } catch (error) {
      console.error('Error fetching pool info:', error);
    }
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleJoinPool = async () => {
    try {
      const response = await axios.post('/api/join-pool', { poolId: _id, username });
      if (response.data.success) {
        setMessage(response.data.message || 'Joined pool successfully');
        onClose(); // Close the dialog after successful join
      } else {
        setMessage(response.data.message || 'Failed to join the pool');
      }
    } catch (error) {
      console.error('Error joining pool:', error);
      setMessage('An error occurred while joining the pool.');
    }
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose} 
        fullScreen={fullScreen} 
        maxWidth="lg"
        fullWidth={!fullScreen} 
        PaperProps={{
          style: {
            width: fullScreen ? '100%' : '100%',
            height: fullScreen ? '100%' : '100%',
            backgroundColor: 'rgba(245, 245, 245, 0.84)',
          }
        }}
      >
        <DialogTitle
          sx={{ 
            textAlign: 'center', 
            color: colors.tiffanyBlue,
            backgroundColor: colors.darkSlateGray,
            borderBottom: `1px solid ${colors.tiffanyBlue}`,
          }}
        >
          {name}
        </DialogTitle>
        
        <DialogActions
          sx={{
            justifyContent: 'center',
            padding: 2,
            backgroundColor: colors.darkSlateGray,
            borderTop: `1px solid ${colors.tiffanyBlue}`,
          }}
        >
          <Button 
            onClick={handleStatsClick} 
            sx={{ 
              color: colors.white, 
              fontSize: '1rem', 
              '&:hover': {
                color: colors.tiffanyBlue,
              },
              textTransform: 'none',
            }}
          >
            Stats
          </Button>
          <Button 
            onClick={handleInfoClick} 
            sx={{ 
              color: colors.white, 
              fontSize: '1rem', 
              '&:hover': {
                color: colors.tiffanyBlue,
              },
              textTransform: 'none',
            }}
          >
            Info
          </Button>
          {!isInPool && (
            <Button 
              onClick={handleJoinPool} 
              sx={{ 
                color: colors.white, 
                fontSize: '1rem', 
                '&:hover': {
                  color: colors.tiffanyBlue,
                },
                textTransform: 'none',
              }}
            >
              Join Pool
            </Button>
          )}
          <Button 
            onClick={onClose} 
            sx={{ 
              color: colors.white, 
              fontSize: '1rem', 
              '&:hover': {
                color: colors.tiffanyBlue,
              },
              textTransform: 'none',
            }}
          >
            Close
          </Button>
        </DialogActions> 
        <DialogContent> 
          {message && <Box textAlign="center" my={2} style={{ color: 'black' }}>{message}</Box>}
          <Explorer poolId={_id} />
        </DialogContent>
      </Dialog>

      <Dialog 
  open={statsOpen} 
  onClose={handleStatsClose} 
  fullWidth 
  maxWidth="lg" // Change to "sm", "md", "lg", "xl", or "false" for custom sizing
  PaperProps={{
    sx: {
      backgroundColor: colors.darkSlateGray,
      color: colors.white,
      borderRadius: 2,
      width: '80%', // Custom width
      height: '80%', // Custom height
      maxWidth: 'none', // Disable default max-width constraint
    },
  }}
>
  <DialogTitle
    sx={{ 
      textAlign: 'center', 
      color: colors.tiffanyBlue,
      borderBottom: `1px solid ${colors.tiffanyBlue}`,
      fontSize: '2rem', // Larger font size for the title
    }}
  >
    Stats
  </DialogTitle>
  <DialogContent
    sx={{
      padding: 3, // Increase padding for a spacious look
    }}
  >
    <PoolStats poolId={_id} />
  </DialogContent>
  <DialogActions
    sx={{
      justifyContent: 'center',
      padding: 3, // Increase padding for spacing
      borderTop: `1px solid ${colors.tiffanyBlue}`,
    }}
  >
    <Button 
      onClick={handleStatsClose} 
      sx={{ 
        backgroundColor: colors.tiffanyBlue, 
        color: colors.white, 
        fontSize: '1.25rem', // Larger font size for the button
        padding: '0.75rem 1.5rem', // Adjust padding for a bigger button
        '&:hover': {
          backgroundColor: colors.darkTeal,
        },
        textTransform: 'none',
      }}
    >
      Close
    </Button>
  </DialogActions>
</Dialog>
      <Dialog 
        open={infoOpen} 
        onClose={handleInfoClose} 
        PaperProps={{
          sx: {
            backgroundColor: colors.darkSlateGray,
            color: colors.white,
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle 
          sx={{ 
            textAlign: 'center', 
            color: colors.tiffanyBlue, 
            borderBottom: `1px solid ${colors.tiffanyBlue}` 
          }}
        >
          Pool Information
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ color: colors.white }}>
              Creator: <span style={{ color: colors.tiffanyBlue }}>{poolInfo.creator}</span>
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: colors.white }}>
              Description: <span style={{ color: colors.tiffanyBlue }}>{poolInfo.description}</span>
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: colors.white }}>
              First Entry Date: <span style={{ color: colors.tiffanyBlue }}>{poolInfo.firstEntryDate ? poolInfo.firstEntryDate.toLocaleString() : 'N/A'}</span>
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: colors.white }}>
              Most Recent Entry Date: <span style={{ color: colors.tiffanyBlue }}>{poolInfo.mostRecentEntryDate ? poolInfo.mostRecentEntryDate.toLocaleString() : 'N/A'}</span>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions 
          sx={{ 
            justifyContent: 'center', 
            padding: 2, 
            borderTop: `1px solid ${colors.tiffanyBlue}` 
          }}
        >
          <Button 
            onClick={handleInfoClose} 
            sx={{ 
              backgroundColor: colors.tiffanyBlue, 
              color: colors.white, 
              fontSize: '1rem', 
              '&:hover': {
                backgroundColor: colors.darkTeal,
              },
              textTransform: 'none',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PoolCardSelect;
