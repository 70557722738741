import React, { useState, useEffect } from 'react';
import { TextField, Button, makeStyles } from '@material-ui/core';
import PoolCreationForm from './PoolCreationForm';
import PoolCard from './PoolCard';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    'html, body': {
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
  },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        color: "#fff",
        textTransform: "none",
        overflow: 'hidden',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    searchField: {
        margin: theme.spacing(2, 0),
        width: '100%',
        maxWidth: 600,
        color: "#fff",
        backgroundColor: "#004d40",
        border: "none",
        textTransform: "none",
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300,
            margin: '0 auto',
        },
    },    
    poolList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        justifyContent: 'center',
        overflow: 'hidden',
    },
    selectedPoolDetails: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: "#fff",
        overflow: 'hidden',
    },
    poolTitle: {
        color: "#fff",
        fontWeight: 'bold',
        overflow: 'hidden',
    },
    poolDescription: {
        color: "#fff",
        overflow: 'hidden',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        color: "#fff",
        textTransform: "none",
        overflow: 'hidden',
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: "#004d40",
        color: "white",
        textTransform: "none",
        overflow: 'hidden',
    },
    resetButton: {
        margin: theme.spacing(1),
        backgroundColor: "#FF1744",
        color: "white",
        textTransform: "none",
        overflow: 'hidden',
    }
}));

const Pools = () => {
    const classes = useStyles();
    const [pools, setPools] = useState([]);
    const [filteredPools, setFilteredPools] = useState([]);
    const [selectedPool, setSelectedPool] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [myPoolsSelected, setMyPoolsSelected] = useState(false);
    const [joinedPoolsSelected, setJoinedPoolsSelected] = useState(false);
    const isMobile = window.innerWidth <= 600;
    const fetchPools = async () => {
        try {
            const response = await fetch('/api/get-pools');
            if (response.ok) {
                const data = await response.json();
                setPools(data); 
                setFilteredPools(data);
            } else {
                throw new Error('Failed to fetch pools');
            }
        } catch (error) {
            console.error('Error fetching pools:', error);
        }
    };

    const fetchMyPools = async () => {
        const userKey = JSON.parse(localStorage.getItem('readifyUserKey'));
        const username = userKey.username;
        try {
            const response = await fetch(`/api/get-my-pools?username=${username}`);
            if (response.ok) {
                const data = await response.json();
                setPools(data);
                setFilteredPools(data);
                setMyPoolsSelected(true);
                setJoinedPoolsSelected(false);
            } else {
                throw new Error('Failed to fetch my pools');
            }
        } catch (error) {
            console.error('Error fetching my pools:', error);
        }
    };

    const fetchJoinedPools = async () => {
        const userKey = JSON.parse(localStorage.getItem('readifyUserKey'));
        const username = userKey.username;
        try {
            const response = await fetch(`/api/get-joined-pools?username=${username}`);
            if (response.ok) {
                const data = await response.json();
                setPools(data);
                setFilteredPools(data);
                setJoinedPoolsSelected(true);
                setMyPoolsSelected(false);
            } else {
                throw new Error('Failed to fetch joined pools');
            }
        } catch (error) {
            console.error('Error fetching joined pools:', error);
        }
    };

    const resetPools = () => {
        fetchPools();
        setSearchTerm('');
        setMyPoolsSelected(false);
        setJoinedPoolsSelected(false);
    };

    useEffect(() => {
        fetchPools();
    }, []);

    useEffect(() => {
        setFilteredPools(
            pools.filter(pool =>
                pool.normalizedName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                pool.description.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, pools]);

    return (
        <div className={classes.container}>
            <PoolCreationForm fetchPools={fetchPools} />
            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={fetchMyPools}
                    style={{
                        backgroundColor: myPoolsSelected ? "#fff" : "#004d40",
                        color: myPoolsSelected ? "#004d40" : "white",
                    }}
                >
                    My Pools
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={fetchJoinedPools}
                    style={{
                        backgroundColor: joinedPoolsSelected ? "#fff" : "#004d40",
                        color: joinedPoolsSelected ? "#004d40" : "white",
                    }}
                >
                    Joined Pools
                </Button>
                <Button
                    variant="contained"
                    className={classes.resetButton}
                    onClick={resetPools}
                >
                    Reset
                </Button>
            </div>
            <TextField
                className={classes.searchField}
                margin="dense"
                id="search"
                placeholder="Find Pools By Name"
                type="text"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && setSearchTerm(e.target.value.toLowerCase())}
                style={{
                    width: "100%",
                    maxWidth: isMobile ? 300 : 600,
                    padding: "10px 20px",
                    fontSize: "16px",
                    margin: "3px auto",
                    color: "#fff",
                    backgroundColor: "#004d40",
                    borderRadius: "25px",
                    border: "none",
                    textTransform: "none",
                    display: "block",
                }}                
                InputProps={{
                    disableUnderline: true, // To match the input style
                    style: {
                        color: '#fff', // Text color
                    },
                }}
                InputLabelProps={{
                    style: {
                        color: '#fff', // Placeholder color
                    },
                }}
            />
            <div className={classes.poolList}>
                {filteredPools.map(pool => (
                    <PoolCard key={pool._id} pool={pool} onClick={() => setSelectedPool(pool)} />
                ))}
            </div>
            {selectedPool && (
                <div className={classes.selectedPoolDetails}>
                    <h2 className={classes.poolTitle}>{selectedPool.name}</h2>
                    <p className={classes.poolDescription}>{selectedPool.description}</p>
                </div>
            )}
        </div>
    );
};

export default Pools;
