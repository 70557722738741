import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TbMessage2 } from "react-icons/tb";
import MarkdownIt from 'markdown-it';
import FeedbackButton from './FeedbackButton';
import AuthorInfo from './authorInfo';
import SourceInfo from './SourceInfo';
import KeepEntryDialog from './KeepEntry';
import SentimentManager from './SentimentManager';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  fetchAuthorAvatar,
  handleImageClick,
  handleKeepForSelf,
  handleKeepForPool,
  handleToggleFeedbackPopover,
  handleFeedback,
  handleSentimentChange
} from './cardselecthelpers';
import { useStyles } from './cardselectStyles';

const md = new MarkdownIt();
const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
const videoExtensions = ['mp4', 'mov', 'avi', 'flv', 'wmv'];
const documentExtensions = ['pdf', 'odt', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

const isVideo = (url) => {
  if (!url) return false;
  const extension = url.split('.').pop().toLowerCase();
  return videoExtensions.includes(extension);
};

const isDocument = (url) => {
  if (!url) return false;
  const extension = url.split('.').pop().toLowerCase();
  return documentExtensions.includes(extension);
};

const isHtml = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

const MediaSection = ({ post, currentMediaIndex, setCurrentMediaIndex }) => {
  const handlePreviousMedia = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex - 1 + post.mediaData.length) % post.mediaData.length);
  };

  const handleNextMedia = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % post.mediaData.length);
  };

  return (
    <Box>
      {post.mediaData && post.mediaData.length > 0 && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            {isVideo(post.mediaData[currentMediaIndex].mediaLink) ? (
              <video
                autoPlay
                controls
                muted
                loop
                playsInline
                controlsList="nodownload"
                style={{ maxWidth: '100%', maxHeight: '69vh', objectFit: 'contain' }}
                onContextMenu={(e) => e.preventDefault()}
                onTouchStart={(e) => e.preventDefault()}
                onTouchEnd={(e) => e.stopPropagation()}
              >
                <source src={post.mediaData[currentMediaIndex].mediaLink} type="video/mp4" />
              </video>
            ) : isDocument(post.mediaData[currentMediaIndex].mediaLink) ? (
              <iframe
                src={`https://docs.google.com/gview?url=${post.mediaData[currentMediaIndex].mediaLink}&embedded=true`}
                width="100%"
                height="690px"
                style={{ border: 'none' }}
                sandbox="allow-same-origin allow-scripts"
              />
            ) : (
              <CardMedia
                style={{
                  border: 'none',
                  maxWidth: '100%',
                  width: 'fit-content',
                  maxHeight: '69vh',
                  objectFit: 'contain',
                  margin: 'auto',
                  animation: 'breathingAnimation 4.2s infinite',
                }}
                component="img"
                src={post.mediaData[currentMediaIndex].mediaLink}
                alt=""
                onClick={() => handleImageClick(post.mediaData[currentMediaIndex].mediaLink)}
                onContextMenu={(e) => e.preventDefault()}
                onTouchStart={(e) => e.preventDefault()}
                onTouchEnd={(e) => e.stopPropagation()}
              />
            )}
          </div>
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <IconButton onClick={handlePreviousMedia} style={{ color: 'white' }}>
              <ArrowBackIosIcon />
            </IconButton>
            {post.mediaData.map((media, index) => (
              <div key={index} onClick={() => setCurrentMediaIndex(index)} style={{ cursor: 'pointer' }}>
                {isVideo(media.mediaLink) ? (
                  <video
                    src={media.mediaLink}
                    style={{
                      width: '69px',
                      height: '69px',
                      margin: '0 5px',
                      border: currentMediaIndex === index ? '2px solid #0ABAB5' : 'none',
                      maxWidth: '69px',
                      maxHeight: '69px',
                    }}
                  />
                ) : isDocument(media.mediaLink) ? (
                  <div
                                style={{
                                  width: '69px',
                                  height: '69px',
                                  backgroundColor: '#f0f0f0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                  border: '1px solid #ddd',
                                }}
                              >
                                <InsertDriveFileIcon style={{ fontSize: '2rem', color: '#e53935' }} />
                              </div>
                ) : (
                  <img
                    src={media.mediaLink}
                    alt=""
                    style={{
                      width: '69px',
                      height: '69px',
                      margin: '0 5px',
                      border: currentMediaIndex === index ? '2px solid #0ABAB5' : 'none',
                      maxWidth: '69px',
                      maxHeight: '69px',
                    }}
                  />
                )}
              </div>
            ))}
            <IconButton onClick={handleNextMedia} style={{ color: 'white' }}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </div>
      )}
    </Box>
  );
};

const FeedbackButtons = ({ post, feedbackData, selectedFeedback, isSubmittingFeedback, handleFeedbackWithAnimation, loggedInUser, classes }) => {
  const [isFeedbackShifted, setIsFeedbackShifted] = useState(false);

  return (
    <Box className={`${classes.feedbackButtonContainer} ${isFeedbackShifted ? classes.feedbackButtonContainerShifted : ''}`}>
      {['nice', 'interesting', 'notSure', 'whistle'].map((type) => (
        <FeedbackButton
          key={type}
          type={type}
          count={feedbackData[type]}
          isSelected={selectedFeedback === type}
          onClick={() => handleFeedbackWithAnimation(post._id, loggedInUser, type, setIsFeedbackShifted)}
          isSubmittingFeedback={isSubmittingFeedback}
        />
      ))}
    </Box>
  );
};

const ActionButtons = ({ setShowAuthorInfo, setShowSourceInfo, setIsKeepEntryDialogOpen, setShowSentimentManager, onClose, classes }) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '5px',
    }}
  >
    <Button
      onClick={() => setShowSourceInfo(true)}
      style={{
        backgroundColor: '#004d40',
        color: '#FFFFFF',
        padding: '5px 10px',
        borderRadius: '4px',
        fontWeight: 'bold',
        textTransform: 'none',
        width: 'auto',
      }}
    >
      About
    </Button>
    <Button
      onClick={onClose}
      style={{
        backgroundColor: '#004d40',
        color: '#FFFFFF',
        padding: '5px 10px',
        borderRadius: '4px',
        fontWeight: 'bold',
        textTransform: 'none',
        width: 'auto',
      }}
    >
      Close
    </Button>
  </Box>
);

const Description = ({ post, classes }) => {
  const containerRef = useRef(null);
  const [isCentered, setIsCentered] = useState(false);
  const isMobile = useMediaQuery('(max-width:1328px)');
  
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const parentWidth = containerRef.current.parentElement.offsetWidth;
      if (containerWidth / parentWidth < 0.4) {
        setIsCentered(true);
      } else {
        setIsCentered(false);
      }
    }
  }, [post.description]);

  return (
    <Typography
  variant="body1"
  style={{
    marginTop: '16px',
    backgroundColor: '#fff',
    width: isMobile ? '80vw' : 'fit-content',
    margin: '16px auto', // Always center horizontally
    padding: '16px',
    borderRadius: '8px',
  }}
  ref={containerRef}
>
  {isHtml(post.description) ? (
    <div className={classes.descriptionText} dangerouslySetInnerHTML={{ __html: post.description }} />
  ) : (
    <div className={classes.descriptionText} dangerouslySetInnerHTML={{ __html: md.render(post.description) }} />
  )}
</Typography>
  );
};

const EcologyCardSelect = ({ isOpen, onClose, post }) => {
  const classes = useStyles();
  const [showAuthorInfo, setShowAuthorInfo] = useState(false);
  const [authorInfo, setAuthorInfo] = useState({ username: post.username, avatar: null });
  const [sourceInfo, setSourceInfo] = useState({
    sourceType: post.sourceType,
    sourceTypeName: post.sourceTypeName,
    domain: post.domain,
    createdAt: post.createdAt,
    promptmodel: post.promptmodel,
    prompttext: post.prompttext,
    imageprompttext: post.imageprompttext,
    keys: post.keys,
    links: post.links,
    modelName: post.modelName,
  });
  const [showSourceInfo, setShowSourceInfo] = useState(false);
  const [showFeedbackPopover, setShowFeedbackPopover] = useState(false);
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackCountsVisible, setFeedbackCountsVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showSentimentManager, setShowSentimentManager] = useState(false);
  const [sentiments, setSentiments] = useState([]);
  const [newSentiment, setNewSentiment] = useState('');
  const svgInputRef = useRef(null);
  const [isSentimentDialogOpen, setIsSentimentDialogOpen] = useState(false);
  const [sentimentAlreadySubmitted, setSentimentAlreadySubmitted] = useState(false);
  const [isKeepEntryDialogOpen, setIsKeepEntryDialogOpen] = useState(false);
  const [userPools, setUserPools] = useState([]);
  const [isAlreadyInPool, setIsAlreadyInPool] = useState(false);
  const isMobile = useMediaQuery('(max-width:1328px)');
  const [feedbackData, setFeedbackData] = useState({
    nice: 0,
    interesting: 0,
    notSure: 0,
    whistle: 0,
  });

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  useEffect(() => {
    fetchAuthorAvatar(post.username, setAuthorInfo);
  }, [post.username]);

  useEffect(() => {
    handleToggleFeedbackPopover(post._id, loggedInUser, setFeedbackCountsVisible, setFeedbackData, setSelectedFeedback);
  }, [post._id, loggedInUser]);

  const handleFeedbackWithAnimation = async (entryId, username, feedbackType, setIsFeedbackShifted) => {
    if (selectedFeedback === feedbackType) {
      setSelectedFeedback(null);
      return;
    }

    setIsSubmittingFeedback(true);

    try {
      const response = await fetch(`/api/feeling/${entryId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          feedbackType,
          username,
        }),
      });

      if (response.ok) {
        const updatedFeedback = await response.json();
        setFeedbackData(updatedFeedback.feedback);
        setSelectedFeedback(feedbackType);
        setFeedbackCountsVisible(true);
        setTimeout(() => {
          setIsFeedbackShifted(false);
        }, 500);
      } else {
        console.error('Failed to send feedback');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      disableEnforceFocus
      disableRestoreFocus
      PaperProps={{
        style: {
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none',
          margin: 0,
          zIndex: 9999, // optional, to ensure it stays on top
        },      
        classes: { root: isMobile ? classes.mobileBigDialog : classes.dialogPaper }
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.96)',
          boxShadow: 'none',
          border: 'none'
        }
      }}
      maxWidth={false}
    >
      <DialogContent className={classes.content}>
        <MediaSection post={post} classes={classes} currentMediaIndex={currentMediaIndex} setCurrentMediaIndex={setCurrentMediaIndex} />
        {feedbackCountsVisible && (
          <Box className={classes.feedbackCount}>
            {['nice', 'interesting', 'notSure', 'whistle'].map((type) => (
              <div key={type} className={classes.feedbackBadge}>
                {feedbackData[type]}
              </div>
            ))}
          </Box>
        )}
        <ActionButtons
          setShowAuthorInfo={setShowAuthorInfo}
          setShowSourceInfo={setShowSourceInfo}
          setIsKeepEntryDialogOpen={setIsKeepEntryDialogOpen}
          setShowSentimentManager={setShowSentimentManager}
          onClose={onClose}
          classes={classes}
          disableEnforceFocus
          disableRestoreFocus
        />
        <Description post={post} classes={classes} style={{ backgroundColor: '#f5f5f5' }} />
      </DialogContent>

      {showAuthorInfo && (
        <AuthorInfo
          open={showAuthorInfo}
          onClose={() => setShowAuthorInfo(false)}
          authorInfo={authorInfo}
          disableEnforceFocus
          disableRestoreFocus
        />
      )}
      {showSourceInfo && (
        <SourceInfo
          open={showSourceInfo}
          onClose={() => setShowSourceInfo(false)}
          sourceInfo={sourceInfo}
          disableEnforceFocus
          disableRestoreFocus
        />
      )}
      {isKeepEntryDialogOpen && (
        <KeepEntryDialog
          open={isKeepEntryDialogOpen}
          onClose={() => setIsKeepEntryDialogOpen(false)}
          entryId={post._id}
          username={loggedInUser}
          domain={post.domain}
          onSaveForPool={(poolName) => handleKeepForPool(poolName, post._id, loggedInUser, post.domain)}
          userPools={userPools}
          isAlreadyInPool={isAlreadyInPool}
          disableEnforceFocus
          disableRestoreFocus
        />
      )}
      {showSentimentManager && (
        <SentimentManager
          open={showSentimentManager}
          onClose={() => setShowSentimentManager(false)}
          sentiments={sentiments}
          newSentiment={newSentiment}
          onSentimentChange={(newSentiment) => handleSentimentChange(newSentiment, post._id, loggedInUser, setSentiments, setSentimentAlreadySubmitted)}
          sentimentAlreadySubmitted={sentimentAlreadySubmitted}
          svgInputRef={svgInputRef}
          isSentimentDialogOpen={isSentimentDialogOpen}
          postId={post._id}
          loggedInUser={loggedInUser}
          disableEnforceFocus
          disableRestoreFocus
        />
      )}
    </Dialog>
  );
};

export default EcologyCardSelect;
