import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Button, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getCircularAvatar } from '../utils/cloudinaryTransform';
import storage from '../utils/localStorage';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  avatar: (props) => ({
    width: props.isMobile ? "40px" : "69px",
    height: props.isMobile ? "40px" : "69px",
    marginRight: theme.spacing(1),
    background: "transparent",
    borderRadius: '50%',
    animation: '$glow 2s infinite alternate',
  }),
  '@keyframes glow': {
    '0%': {
      boxShadow: '0 0 10px transparent',
    },
    '100%': {
      boxShadow: '0 0 10px turquoise',
    },
  }
}));

const handleLogoutClick = async (history, handleLogout, setIsLoggedIn) => {
  const isLoggedIn = storage.loadUser();
  if (isLoggedIn) {
    try {
      storage.logoutUser();
      handleLogout();
      setIsLoggedIn(null);
      window.location.reload();
      history.push('/start');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }
};

const DesktopUserMenu = ({ user, handleLogout, isMobile }) => {
  const classes = useStyles({ isMobile });
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(storage.loadUser() || user);
  const [avatar, setAvatar] = useState(null);
  const timerRef = useRef(null);
  const localKey = JSON.parse(localStorage.getItem('readifyUserKey') || '{}');

  useEffect(() => {
    const fetchAvatar = async () => {
      if (isLoggedIn) {
        try {
          const response = await axios.get(`/api/users/get-avatar/${isLoggedIn.username}`);
          setAvatar(response.data.avatar);
        } catch (error) {
          console.error('Error fetching avatar:', error);
        }
      }
    };

    fetchAvatar();
  }, [isLoggedIn]);

  useEffect(() => {
    const validateSession = async () => {
      const username = localKey.username;
      const sessionId = localKey.sessionId;
  
      if (username && sessionId) {
        try {
          const response = await axios.post('/validate-persons-session', { username, sessionId });
          if (response.data.sessionId !== sessionId) {
            handleLogoutClick(history, handleLogout, setIsLoggedIn);
          }
        } catch (error) {
          console.error('Error validating session:', error);
          handleLogoutClick(history, handleLogout, setIsLoggedIn);
        }
      } else {
        console.log('No username or sessionId found in local storage');
      }
    };

    const interval = setInterval(validateSession, 300000);
    return () => clearInterval(interval);
  }, [history, handleLogout, setIsLoggedIn, localKey]);

  const handleMouseDown = () => {
    timerRef.current = setTimeout(() => handleLogoutClick(history, handleLogout, setIsLoggedIn), 3333);
  };

  const handleMouseUp = () => {
    clearTimeout(timerRef.current);
  };

  const handleTouchStart = () => {
    timerRef.current = setTimeout(() => handleLogoutClick(history, handleLogout, setIsLoggedIn), 3333);
  };

  const handleTouchEnd = () => {
    clearTimeout(timerRef.current);
  };

  return (
    <div>
      {isLoggedIn ? (
        <Button
          style={{ boxShadow: 'none' }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {avatar ? (
            <Avatar
              alt={isLoggedIn.username}
              src={getCircularAvatar(avatar.imageLink)}
              variant="rounded"
              className={classes.avatar}
              style={{ borderRadius: '50%' }}
            />
          ) : (
            <Avatar variant="rounded" className={classes.avatar} style={{ backgroundColor: '#0ABAB5', color: 'white' }}>
              {isLoggedIn.username[0]}
            </Avatar>
          )}
        </Button>
      ) : null}
    </div>
  );
};

export { handleLogoutClick };
export default DesktopUserMenu;
