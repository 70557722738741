// src/components/Pathways/PathwaySupport.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Tabs,
  Tab,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { AddPhotoAlternate, Delete } from '@mui/icons-material';
import MediaViewer from './MediaViewer';

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  mediaItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
});

const PathwaySupport = ({ open, onClose, pathwayId, stepId }) => {
  const classes = useStyles();
  const [mediaFiles, setMediaFiles] = useState([]);
  const [text, setText] = useState('');
  const [supports, setSupports] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [mediaViewer, setMediaViewer] = useState({ open: false, mediaList: [], initialIndex: 0 });
  const [loading, setLoading] = useState(false);

  const loggedInUser = JSON.parse(localStorage.getItem('readifyUserKey'))?.username || 'anonymous';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (tabIndex === 1) {
      fetchSupports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  const fetchSupports = async () => {
    try {
      const res = await axios.get(`/api/pathways/${pathwayId}/supports/${stepId}`);
      setSupports(res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    } catch (err) {
      console.error(err);
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleAddMedia = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    try {
      const mediaData = await Promise.all(
        Array.from(files).map(async (file) => {
          const base64 = await readFileAsDataURL(file);
          let mediaType = 'document';
          if (file.type.startsWith('image/')) {
            mediaType = 'image';
          } else if (file.type.startsWith('video/')) {
            mediaType = 'video';
          }
          return {
            base64: base64,
            mediaType,
            name: file.name,
          };
        })
      );

      setMediaFiles((prev) => [...prev, ...mediaData]);
    } catch (error) {
      console.error('Error handling media upload:', error);
    } finally {
      event.target.value = null;
    }
  };

  const handleRemoveMedia = (index) => {
    const newMediaFiles = [...mediaFiles];
    newMediaFiles.splice(index, 1);
    setMediaFiles(newMediaFiles);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const media = mediaFiles.map((file) => ({
        base64: file.base64,
        mediaType: file.mediaType,
        name: file.name,
      }));

      await axios.post(`/api/pathways/${pathwayId}/support`, {
        stepId,
        media,
        text,
        username: loggedInUser,
      });
      setMediaFiles([]);
      setText('');
      setTabIndex(1);
      fetchSupports();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleMediaClick = (mediaList, index) => {
    setMediaViewer({ open: true, mediaList, initialIndex: index });
  };

  const closeMediaViewer = () => {
    setMediaViewer({ open: false, mediaList: [], initialIndex: 0 });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogContent>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ marginBottom: '20px', color: '#004d40' }}
            TabIndicatorProps={{ style: { backgroundColor: '#0ABAB5' } }}
          >
            <Tab label="Add Support" sx={{ textTransform: 'none', color: '#004d40' }} />
            <Tab label="View Support" sx={{ textTransform: 'none', color: '#004d40' }} />
          </Tabs>
          {tabIndex === 0 && (
            <form className={classes.form}>
              <input
                accept="image/*,video/*,application/pdf"
                style={{ display: 'none' }}
                id="support-media-upload"
                type="file"
                multiple
                onChange={handleAddMedia}
              />
              <label htmlFor="support-media-upload">
              <Button
  variant="contained"
  component="span"
  startIcon={<AddPhotoAlternate />}
  sx={{
    textTransform: 'none',
    backgroundColor: '#004d40',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    mx: 'auto'
  }}
>
  Add Media
</Button>
              </label>
              {mediaFiles.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    overflowX: 'auto',
                    padding: '10px 0',
                    gap: '10px',
                  }}
                >
                  {mediaFiles.map((media, index) => (
                    <div
                      key={index}
                      className={classes.mediaItem}
                      style={{
                        position: 'relative',
                        minWidth: '100px',
                        minHeight: '100px',
                        maxWidth: '100px',
                        maxHeight: '100px',
                        flex: '0 0 auto',
                        border: `1px solid #556b2f`,
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                      }}
                    >
                      {media.mediaType === 'image' && (
                        <img
                          src={media.base64}
                          alt="Media"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '8px',
                          }}
                        />
                      )}
                      {media.mediaType === 'video' && (
                        <video
                          src={media.base64}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '8px',
                          }}
                          controls
                        />
                      )}
                      {media.mediaType === 'document' && (
                        <a
                          href={media.base64}
                          download={media.name}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#0ABAB5',
                            borderRadius: '8px',
                            textDecoration: 'none',
                            color: '#004d40',
                            fontSize: '0.8rem',
                            textAlign: 'center',
                            padding: '5px',
                          }}
                        >
                          {media.name}
                        </a>
                      )}
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveMedia(index)}
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: '#ffffff',
                          padding: '2px',
                          color: '#004d40',
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}

              <TextField
                label="Support Text"
                variant="outlined"
                value={text}
                onChange={(e) => setText(e.target.value)}
                multiline
                rows={4}
                fullWidth
                sx={{ textTransform: 'none' }}
              />
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ textTransform: 'none', backgroundColor: '#004d40', color: '#ffffff' }}
                >
                  Place Support
                </Button>
              )}
            </form>
          )}
          {tabIndex === 1 && (
            <Box>
              {supports.length === 0 ? (
                <Typography>No supports available.</Typography>
              ) : (
                supports.map((support) => (
                  <Box
                    key={support._id}
                    sx={{
                      border: '1px solid #556b2f',
                      borderRadius: '8px',
                      padding: '10px',
                      marginBottom: '10px',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    <Typography variant="body1" sx={{ marginBottom: '10px', color: '#004d40' }}>
                      {support.text}
                    </Typography>
                    {support.mediaData && support.mediaData.length > 0 && (
                      <div
                        style={{
                          display: 'flex',
                          overflowX: 'auto',
                          padding: '10px 0',
                          gap: '10px',
                        }}
                      >
                        {support.mediaData.map((media, idx) => (
                          <div
                            key={idx}
                            className={classes.mediaItem}
                            style={{
                              position: 'relative',
                              minWidth: '100px',
                              minHeight: '100px',
                              maxWidth: '100px',
                              maxHeight: '100px',
                              flex: '0 0 auto',
                              border: `1px solid #556b2f`,
                              borderRadius: '8px',
                              backgroundColor: '#ffffff',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              const mediaList = support.mediaData.map((m) => ({
                                mediaLink: m.mediaLink,
                                mediaType: m.mediaType,
                              }));
                              handleMediaClick(mediaList, idx);
                            }}
                          >
                            {media.mediaType === 'image' && (
                              <img
                                src={media.mediaLink}
                                alt="Media"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  borderRadius: '8px',
                                }}
                              />
                            )}
                            {media.mediaType === 'video' && (
                              <video
                                src={media.mediaLink}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  borderRadius: '8px',
                                }}
                                muted
                                loop
                              />
                            )}
                            {media.mediaType === 'document' && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#0ABAB5',
                                  borderRadius: '8px',
                                  color: '#004d40',
                                  fontSize: '0.8rem',
                                  textAlign: 'center',
                                  padding: '5px',
                                }}
                              >
                                {media.name}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Box>
                ))
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <MediaViewer
        open={mediaViewer.open}
        onClose={closeMediaViewer}
        mediaList={mediaViewer.mediaList}
        initialIndex={mediaViewer.initialIndex}
      />
    </>
  );
};

export default PathwaySupport;
