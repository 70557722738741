import React, { useState, useEffect, useCallback  } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EcologySubmit from './EcologySubmit'; // Import your SubmissionForm component
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, DialogTitle, Select } from '@mui/material'; // Import Material-UI components
import SelectPoolEntry from './SelectPoolEntry';
import { CardMedia, Paper } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddEntry from '../assets/humanity/AddEntry.png';
const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '::-webkit-scrollbar': { 
      display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
    },
    'html, body': {
      '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
      'scrollbar-width': 'none', // Hide scrollbar for Firefox
    },
  },
  cardContainer: {   
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: theme.spacing(2),
    gap: theme.spacing(2),
    marginTop: '5.69vh' // Add gap between cards for spacing
  },
  card: {
    textAlign: 'center',
    maxWidth: '42%', // Limit the content width within the card
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: '#fefadc',
    borderRadius: '10px', // Add this line to round the corners
    transform: 'perspective(1000px) rotateY(0deg) rotateX(0deg)', // Apply 3D transformation
    transition: 'transform 0.5s', // Add transition for smooth animation
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Ensure card content stacks vertically
    height: '42%', // Allow the card to expand based on content
  },
  thumbnailWrapper: {
    flex: '1', // Allow the thumbnail to expand within the card
    position: 'center', // Change to 'relative' to maintain image positioning
    overflow: 'hidden',
    borderRadius: '4px',
    '&:hover': {
      '& $thumbnailOverlay': {
        opacity: 1,
      },
    },
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  thumbnailOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '69%',
    height: '69%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
  },
  cardHovered: {
    transform: 'perspective(1000px) translateZ(69px) ',
  },
  feedbackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  feedbackButton: {
    background: 'none !important',
    border: 'none',
    cursor: 'pointer',
  },
  feedbackCount: {
    marginLeft: '0.69px',
    marginRight: '-0.69px',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  feedbackButton: {
    background: 'none !important',
    border: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    margin: '0',
    fontSize: '14px',
  },
  flippedCard: {
    transform: 'rotateY(180deg)',
  },
  // Add additional styles here
}));

const domains = [
];

const OfficialPoolExplorer = ({ poolId }) => {
  const classes = useStyles();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [entries, setEntries] = useState([]);
  const [activeDomain, setActiveDomain] = useState('All Categories');
  const [feedbackCounts, setFeedbackCounts] = useState({});
  const [selectedFeedbackType, setSelectedFeedbackType] = useState({});
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const isMobile = useMediaQuery('(max-width:600px)');
  const [showFilters, setShowFilters] = useState(false);
  const [domainsDialogOpen, setDomainsDialogOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const checkScrollTop = () => {
    if (!showScrollToTop && window.pageYOffset > 2000) {
      setShowScrollToTop(true);
    } else if (showScrollToTop && window.pageYOffset <= 2000) {
      setShowScrollToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScrollToTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const recordUserInteraction = async (type, detail) => {
    const payload = {
      username: loggedInUser,
      type,
      detail,
      timestamp: new Date().toISOString(),
    };

    try {
      const response = await fetch('/api/user-interactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to record interaction');
      }
    } catch (error) {
      console.error('Error recording interaction:', error);
    }
  };

  const handleEntryAction = useCallback(async (entryId, domain, tag) => {
    const payload = {
      loggedInUser,
      entryId,
      tag,
      domain,
    };

    try {
      const response = await fetch('/api/entry-actions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to send simplified entry action');
      }
    } catch (error) {
      console.error('Error sending simplified entry action:', error);
    }
  }, []);

  useEffect(() => {
    setEntries([]);
    setPage(0);
  }, [activeDomain, searchTerm, sortOrder, selectedDomains]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !isSubmittingFeedback && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isSubmittingFeedback, hasMore, activeDomain, searchTerm, sortOrder, selectedDomains]);

  const handleDomainsDialogToggle = () => {
    setDomainsDialogOpen(!domainsDialogOpen);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    recordUserInteraction('searchTermChange', { searchTerm: event.target.value });
  };

  const handleFeedback = async (entryId, feedbackType) => {
    if (selectedFeedbackType[entryId] === feedbackType) {
      return;
    }

    setIsSubmittingFeedback(true);

    try {
      const response = await fetch(`/api/feedback/${entryId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedbackType }),
      });

      if (response.ok) {
        const updatedEntry = await response.json();

        setSelectedFeedbackType((prevSelected) => ({
          ...prevSelected,
          [entryId]: feedbackType,
        }));
      } else {
        console.error('Failed to send feedback');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  const handleDomainClick = (domain) => {
    setActiveDomain(domain);
    recordUserInteraction('domainSelection', { selectedDomain: domain });
  };

  useEffect(() => {
    const fetchEntries = async () => {
      if (page === 0) {
        setEntries([]);
      }

      const queryParams = new URLSearchParams();
      if (activeDomain !== 'All Categories') queryParams.append('domain', activeDomain);
      if (searchTerm) queryParams.append('search', searchTerm);
      queryParams.append('sort', sortOrder);
      selectedDomains.forEach(domain => queryParams.append('selectedDomains', domain));
      queryParams.append('page', page);
      if (poolId) queryParams.append('poolId', poolId);

      try {
        const response = await fetch(`/api/get-official-pool-entries?${queryParams.toString()}`);
        if (response.ok) {
          const { entries: newEntries, hasMore: hasNextPage } = await response.json();
          if (page === 0) {
            setEntries(newEntries);
          } else {
            setEntries(prevEntries => [...prevEntries, ...newEntries]);
          }
          setHasMore(hasNextPage);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchEntries();
  }, [activeDomain, searchTerm, sortOrder, selectedDomains, page, poolId]);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleCardHover = (event) => {
    event.currentTarget.classList.toggle(classes.cardHovered);
  };

  const handleCardClick = (entry) => {
    setSelectedEntry(entry);
    recordUserInteraction('entrySelection', {
      selectedEntryId: entry._id,
      domain: entry.domain,
      tag: "Politics"
    });
    handleEntryAction(entry._id, entry.domain, "Politics");
  };

  const isDomainSelected = (domain) => {
    return selectedDomains.includes(domain);
  };

  const toggleDomainSelection = (domain) => {
    setSelectedDomains((prevSelected) => {
      const isDomainSelected = prevSelected.includes(domain);
      const updatedDomains = isDomainSelected
        ? prevSelected.filter((d) => d !== domain)
        : [...prevSelected, domain];

      recordUserInteraction('domainToggle', {
        toggledDomain: domain,
        isDomainSelected: !isDomainSelected,
      });

      return updatedDomains;
    });
  };

  const getTotalFeedback = (feedback) => {
    return feedback.nice + feedback.interesting + feedback.notSure + feedback.whistle;
  };

  const sortByMostInteracted = (entries) => {
    return [...entries].sort((a, b) => {
      const totalFeedbackA = getTotalFeedback(a.feedback);
      const totalFeedbackB = getTotalFeedback(b.feedback);
      return totalFeedbackB - totalFeedbackA;
    });
  };

  const sortByLeastInteracted = (entries) => {
    return [...entries].sort((a, b) => {
      const totalFeedbackA = getTotalFeedback(a.feedback);
      const totalFeedbackB = getTotalFeedback(b.feedback);
      return totalFeedbackA - totalFeedbackB;
    });
  };

  const sortByDateNewest = (entries) => {
    return [...entries].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };

  const sortByDateOldest = (entries) => {
    return [...entries].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  };

  const getFilteredAndSortedEntries = (entries) => {
    const domainAndSearchFiltered = entries.filter((entry) => {
      const matchesDomain = selectedDomains.length === 0 || 
                            selectedDomains.includes('All Categories') ||
                            selectedDomains.includes(entry.domain);

      const matchesSearchTerm = entry.description?.toLowerCase().includes(searchTerm);

      return matchesDomain && matchesSearchTerm;
    });

    switch (sortOrder) {
      case 'most':
        return sortByMostInteracted(domainAndSearchFiltered);
      case 'least':
        return sortByLeastInteracted(domainAndSearchFiltered);
      case 'newest':
        return sortByDateNewest(domainAndSearchFiltered);
      case 'oldest':
        return sortByDateOldest(domainAndSearchFiltered);
      default:
        return domainAndSearchFiltered;
    }
  };

  const filteredAndSortedEntries = getFilteredAndSortedEntries(entries);

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <input
          type="text"
          placeholder="What do you seek?"
          onKeyDown={(e) => e.key === 'Enter' && handleSearchChange(e)}
          style={{
            width: "300px",
            padding: "10px",
            fontSize: "16px",
            margin: "10px auto",
            display: "block",
            color: "#57c0bf",
          }}
        />
      </div>
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <button
          onClick={() => setSortOrder("most")}
          variant="outlined"
          style={{
            marginLeft: "5.69px",
            margin: "3.69px 4.69px",
            backgroundColor: sortOrder === "most" ? "#57c0bf" : "#fefadc",
            color: sortOrder === "most" ? "white" : "black",
            zIndex: 1100,
          }}
        >
          Most Interacted
        </button>
        <button
          onClick={() => setSortOrder("least")}
          variant="outlined"
          style={{
            marginLeft: "5.69px",
            margin: "3.69px 4.69px",
            backgroundColor: sortOrder === "least" ? "#57c0bf" : "#fefadc",
            color: sortOrder === "least" ? "white" : "black",
            zIndex: 1100,
          }}
        >
          Least Interacted
        </button>
        <button
          onClick={() => setSortOrder("newest")}
          variant="outlined"
          style={{
            marginLeft: "5.69px",
            margin: "3.69px 4.69px",
            backgroundColor: sortOrder === "newest" ? "#57c0bf" : "#fefadc",
            color: sortOrder === "newest" ? "white" : "black",
            zIndex: 1100,
          }}
        >
          Newest
        </button>
        <button
          onClick={() => setSortOrder("oldest")}
          variant="outlined"
          style={{
            marginLeft: "5.69px",
            margin: "3.69px 4.69px",
            backgroundColor: sortOrder === "oldest" ? "#57c0bf" : "#fefadc",
            color: sortOrder === "oldest" ? "white" : "black",
            zIndex: 1100,
          }}
        >
          Oldest
        </button>
        <button
          onClick={() => {
            setActiveDomain('All Categories');
            setSelectedDomains([]);
            setSearchTerm('');
            setSortOrder('none');
          }}
          style={{
            marginLeft: "5.69px",
            margin: "3.69px 4.69px",
            backgroundColor: "#fefadc",
            zIndex: 1100,
          }}
        >
          Clear All
        </button>
      </div>
      {isMobile && (
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <button onClick={handleDomainsDialogToggle} variant="contained" style={{
            backgroundColor: "#fefadc",
            color: "#000",
          }}>
            Fields
          </button>
        </div>
      )}
      <Dialog open={domainsDialogOpen} onClose={handleDomainsDialogToggle} 
        PaperProps={{
          style: { backgroundColor: "transparent !important", border: "none", boxShadow: "none" }
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
      >
        <DialogContent>
          {[...domains].map((domain) => (
            <button
              key={domain}
              onClick={() => toggleDomainSelection(domain)}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 4.69px",
                backgroundColor: isDomainSelected(domain) ? "#57c0bf" : "#fefadc",
                color: isDomainSelected(domain) ? "white" : "black",
              }}
            >
              {domain}
            </button>
          ))}
        </DialogContent>
      </Dialog>
      {!isMobile && (
        <div style={{ zIndex: -1, marginLeft: '3.69vw', marginRight: '3.69vw' }}>
          {[...domains].map((domain) => (
            <button
              key={domain}
              onClick={() => toggleDomainSelection(domain)}
              style={{
                marginLeft: "5.69px",
                margin: "3.69px 2.69px",
                backgroundColor: isDomainSelected(domain) ? "#57c0bf" : "#fefadc",
                color: isDomainSelected(domain) ? "white" : "black",
              }}
            >
              {domain}
            </button>
          ))}
        </div>
      )}
      <div className={`${classes.cardContainer} gridContainer`} style={{ overflowY: "hidden" }}>
        {filteredAndSortedEntries.map((entry) => (
          <div
            className={`${classes.card} ${entry.mediaData?.mediaLink ? "" : classes.cardWithoutImage}`}
            style={{
              overflowY: "hidden",
              width: isMobile ? '90%' : 'fit-content',
              height: isMobile ? 'fit-content' : '42.69%',
              margin: isMobile ? '10px auto' : '10px',
              maxWidth: isMobile ? 'none' : '42.69%',
            }}
            key={entry._id}
            onMouseEnter={handleCardHover}
            onMouseLeave={handleCardHover}
            onClick={() => handleCardClick(entry)}
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="card-front" style={{ position: 'relative' }}>
              {entry.mediaData?.mediaLink && (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: '100%',
                      width: '100%',
                      zIndex: 1,
                    }}
                    onTouchStart={(e) => { e.preventDefault(); e.stopPropagation(); }}
                  />
                  <img
                    className={`${classes.thumbnail} nonInteractive`}
                    src={entry.mediaData.mediaLink}
                    alt={""}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                  <video
                    className="nonInteractive"
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{ maxWidth: '100%', maxHeight: '100%', display: 'none' }}
                    onCanPlay={(e) => {
                      e.target.style.display = '';
                      e.target.previousSibling.style.display = 'none';
                    }}
                  >
                    <source src={entry.mediaData.mediaLink} type="video/mp4" />
                  </video>
                </>
              )}
              {!entry.mediaData?.mediaLink && entry.description && (
                <p className={classes.descriptionText} style={{ whiteSpace: "pre-wrap" }}>
                  {entry.description.slice(0, 333)}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      <Dialog open={isFormOpen} onClose={handleCloseForm}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          }
        }}
      >
        <DialogContent>
          <EcologySubmit />
        </DialogContent>
      </Dialog>
      {selectedEntry && (
        <SelectPoolEntry
          isOpen={true}
          onClose={() => setSelectedEntry(null)}
          post={selectedEntry}
        />
      )}
      {showScrollToTop && (
        <button
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: isMobile ? '5.69%' : '5.69%',
            right: isMobile ? '46.69%' : '49.69%',
            transform: 'translateX(50%)',
            zIndex: 1000,
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: "#fefadc",
          }}
        >
          Top
        </button>
      )}
    </div>
  );
};

export default OfficialPoolExplorer;