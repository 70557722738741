import React, { useState } from 'react';
import { TextField, Button, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const TotpSetup = () => {
  const [step, setStep] = useState(1);
  const [manualSetup, setManualSetup] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const [osType, setOsType] = useState('');
  const [setupKey, setSetupKey] = useState('');

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleManualSetup = () => {
    setManualSetup(true);
  };

  const installInstructions = {
    android: (
      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">
        Please install Google Authenticator from the Google Play Store.
      </a>
    ),
    apple: (
      <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
        Please install Google Authenticator from the Apple App Store.
      </a>
    ),
  };

  const scanQrInstructions = (
    <>
      <p>1. Open the Google Authenticator app on your {deviceType} device.</p>
      <p>2. Tap the '+' icon and select 'Scan a QR code'.</p>
      <p>3. Point your camera at the QR code displayed in the browser.</p>
      <p>4. Once the QR code is scanned, your device will automatically add the 6 Digit Code Entry.</p>
    </>
  );

  const manualSetupInstructions = (
    <>
      <p>1. Open the Google Authenticator app on your {deviceType} device.</p>
      <p>2. Tap the '+' icon and select 'Enter a setup key'.</p>
      <p>3. Enter "Librious - [Your Alias]" for Account Name.</p>
      <p>4. Tap the "Copy setup key" button provided on the Librious sign up menu.</p>
      <p>4. Paste the Setup Key in "Your Key" on the Google Authenticator "Enter Account Details."</p>
      <p>5. Ensure 'Time-based' is selected and tap 'Add'.</p>
    </>
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="95vh">
      <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" p={4} borderRadius={8} boxShadow={3} width="fit-content">
        {step === 1 && (
          <Box>
            <Typography variant="h6">Which Phone Do You Have?</Typography>
            <FormControl component="fieldset">
              <RadioGroup value={deviceType} onChange={(e) => setDeviceType(e.target.value)}>
                <FormControlLabel value="android" control={<Radio />} label="Android" />
                <FormControlLabel value="apple" control={<Radio />} label="Apple" />
              </RadioGroup>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleNext} disabled={!deviceType}>
              Next
            </Button>
          </Box>
        )}
        {step === 2 && (
          <Box>
            <Typography variant="h6">Install Google Authenticator</Typography>
            <Typography variant="body1">{installInstructions[deviceType]}</Typography>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </Box>
        )}
        {step === 3 && (
          <Box>
            <Typography variant="h6">Set up Google Authenticator</Typography>
            <Typography variant="body1">
              {manualSetup ? manualSetupInstructions : scanQrInstructions}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
            {!manualSetup && (
              <Button variant="text" color="secondary" onClick={handleManualSetup}>
                Can't scan
              </Button>
            )}
          </Box>
        )}
        {step === 4 && (
          <Box>
            <Typography variant="h6">Enter the Code</Typography>
            <Typography variant="body1">
              Enter the 6 digit code generated by Google Authenticator for "Librious - [Your Alias]".
            </Typography>
            <TextField
              label="Enter Code"
              variant="outlined"
              value={setupKey}
              onChange={(e) => setSetupKey(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleNext}>
              Verify
            </Button>
          </Box>
        )}
        {step === 5 && (
          <Box>
            <Typography variant="h6">Verification Complete</Typography>
            <Typography variant="body1">Your setup is complete. Place the 6 digit number into the "Enter 6 Digit Code" field on the Librious sign up menu. Then Select Sign Up.</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TotpSetup;
