export const recordUserInteraction = async (type, detail) => {
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  const payload = {
    username: loggedInUser,
    type,
    detail,
    timestamp: new Date().toISOString(),
  };

  try {
    const response = await fetch('/api/user-interactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
 
    if (!response.ok) {
      throw new Error('Failed to record interaction');
    }
  } catch (error) {
    console.error('Error recording interaction:', error);
  }
};

export const fetchDomainEntries = async (
  ids,
  setEntries,
  setHasMore,
) => {
  const queryParams = new URLSearchParams();

  if (ids) {
    if (Array.isArray(ids)) {
      queryParams.append('ids', ids.join(','));
    } else {
      queryParams.append('id', ids);
    }
  }

  const apiEndpoint = `/api/get-domain-entries?${queryParams.toString()}`;

  try {
    const response = await fetch(apiEndpoint);
    if (response.ok) {
      const data = await response.json();
      if (Array.isArray(ids)) {
        const { entries } = data;
        setEntries(entries);
      } else {
        const { entry } = data;
        setEntries([entry]);
      }
      setHasMore(false);
    } else {
      console.error('Failed to fetch domain entries');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};


export const handleEntryAction = async (entryId, domain, tag) => {
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  const payload = {
    loggedInUser,
    entryId,
    tag,
    domain,
  };

  try {
    const response = await fetch('/api/entry-actions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to send simplified entry action');
    }
  } catch (error) {
    console.error('Error sending simplified entry action:', error);
  }
};

let currentFetchEntriesRequest = 0;

export const fetchEntries = async (
  page, 
  activeDomains, 
  searchTerm,  
  sortOrder, 
  selectedDomains, 
  setEntries, 
  setHasMore, 
  usernames, 
  keys, 
  timeParameter, 
  searchEntries, 
  branches, 
  fields, 
  categories,  
  origins, 
  originNames,
  followed,
  mediaTypes  // 'mediaTypes' is now the last parameter
) => {
  const requestId = ++currentFetchEntriesRequest;

  // Retrieve loggedInUser from localStorage
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  const queryParams = new URLSearchParams();

  // Include loggedInUser as PersonLoggedIn in the query params
  if (loggedInUser) {
    queryParams.append('PersonLoggedIn', loggedInUser);
  }

  // Append 'mediaTypes' to query parameters
  if (mediaTypes && mediaTypes.length > 0) {
    mediaTypes.forEach(type => queryParams.append('mediaTypes[]', type));
  }

  // Handle multiple active domains
  if (activeDomains && activeDomains.length > 0) {
    activeDomains.forEach(domain => queryParams.append('domain', domain));
  }

  if (searchTerm) queryParams.append('search', searchTerm);

  if (usernames && usernames.length > 0) {
    usernames.forEach(username => queryParams.append('usernames', username));
  }

  if (keys && keys.length > 0) {
    keys.forEach(key => queryParams.append('keys', key));
  }

  if (timeParameter) queryParams.append('time', timeParameter);

  if (searchEntries) queryParams.append('searchEntries', searchEntries);

  if (selectedDomains && selectedDomains.length > 0) {
    selectedDomains.forEach(domain => queryParams.append('selectedDomains', domain));
  }

  // Add branches, fields, categories, origins, and originNames to the query params
  if (branches && branches.length > 0) {
    branches.forEach(branch => queryParams.append('branches', branch));
  }

  if (fields && fields.length > 0) {
    fields.forEach(field => queryParams.append('fields', field));
  }

  if (categories && categories.length > 0) {
    categories.forEach(category => queryParams.append('categories', category));
  }

  if (origins && origins.length > 0) {
    origins.forEach(origin => queryParams.append('origins', origin));
  }

  if (originNames && originNames.length > 0) {
    originNames.forEach(originName => queryParams.append('originNames', originName));
  }

  // Add followed to the query params
  if (followed) {
    queryParams.append('followed', 'true');
  }

  queryParams.append('sort', sortOrder);
  queryParams.append('page', page);

  const apiEndpoint = `/api/get-entries?${queryParams.toString()}`;

  try {
    const response = await fetch(apiEndpoint);
    if (response.ok && requestId === currentFetchEntriesRequest) {
      const { entries: newEntries, hasMore: hasNextPage } = await response.json();
      setEntries(prevEntries => page === 0 ? newEntries : [...prevEntries, ...newEntries]);
      setHasMore(hasNextPage);
    } else if (!response.ok) {
      console.error('Failed to fetch data');
    }
  } catch (error) {
    if (requestId === currentFetchEntriesRequest) {
      console.error('Error:', error);
    }
  }
};


export const fetchPoolEntries = async (
  page, 
  activeDomains, 
  searchTerm,  
  sortOrder, 
  selectedDomains, 
  setEntries, 
  setHasMore, 
  usernames, 
  keys, 
  timeParameter, 
  searchEntries, 
  branches, 
  fields, 
  categories, 
  origins, 
  originNames,
  followed,
  poolId,
  mediaTypes   // Add poolId as a parameter
) => {
  const requestId = ++currentFetchEntriesRequest;

  // Retrieve loggedInUser from localStorage
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  const queryParams = new URLSearchParams();

  // Include loggedInUser as PersonLoggedIn in the query params
  if (loggedInUser) {
    queryParams.append('PersonLoggedIn', loggedInUser);
  }

  // Append 'mediaTypes' to query parameters
  if (mediaTypes && mediaTypes.length > 0) {
    mediaTypes.forEach(type => queryParams.append('mediaTypes[]', type));
  }


  // Handle multiple active domains
  if (activeDomains && activeDomains.length > 0) {
    activeDomains.forEach(domain => queryParams.append('domain', domain));
  }
  
  if (searchTerm) queryParams.append('search', searchTerm);
  
  if (usernames && usernames.length > 0) {
    usernames.forEach(username => queryParams.append('usernames', username));
  }
  
  if (keys && keys.length > 0) {
    keys.forEach(key => queryParams.append('keys', key));
  }
  
  if (timeParameter) queryParams.append('time', timeParameter);
  
  if (searchEntries) queryParams.append('searchEntries', searchEntries);
  
  if (selectedDomains && selectedDomains.length > 0) {
    selectedDomains.forEach(domain => queryParams.append('selectedDomains', domain));
  }
  
  // Add branches, fields, categories, origins, and originNames to the query params
  if (branches && branches.length > 0) {
    branches.forEach(branch => queryParams.append('branches', branch));
  }
  
  if (fields && fields.length > 0) {
    fields.forEach(field => queryParams.append('fields', field));
  }
  
  if (categories && categories.length > 0) {
    categories.forEach(category => queryParams.append('categories', category));
  }
  
  if (origins && origins.length > 0) {
    origins.forEach(origin => queryParams.append('origins', origin));
  }
  
  if (originNames && originNames.length > 0) {
    originNames.forEach(originName => queryParams.append('originNames', originName));
  }
  
  // Add followed to the query params
  if (followed) {
    queryParams.append('followed', 'true');
  }
  
  // Add poolId to the query params
  if (poolId) {
    queryParams.append('poolId', poolId);
  }

  queryParams.append('sort', sortOrder);
  queryParams.append('page', page);

  const apiEndpoint = `/api/get-pool-entries?${queryParams.toString()}`;
 
  try {
    const response = await fetch(apiEndpoint);
    if (response.ok && requestId === currentFetchEntriesRequest) {
      const { entries: newEntries, hasMore: hasNextPage } = await response.json();
      setEntries(prevEntries => page === 0 ? newEntries : [...prevEntries, ...newEntries]);
      setHasMore(hasNextPage);
    } else if (!response.ok) {
      console.error('Failed to fetch data');
    }
  } catch (error) {
    if (requestId === currentFetchEntriesRequest) {
      console.error('Error:', error);
    }
  }
};

export const fetchOfficialPoolEntries = async (
  page, 
  activeDomains, 
  searchTerm,  
  sortOrder, 
  selectedDomains, 
  setEntries, 
  setHasMore, 
  usernames, 
  keys, 
  timeParameter, 
  searchEntries, 
  branches, 
  fields, 
  categories, 
  origins, 
  originNames,
  followed,
  poolId  // Add poolId as a parameter
) => {
  const requestId = ++currentFetchEntriesRequest;

  // Retrieve loggedInUser from localStorage
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  const queryParams = new URLSearchParams();

  // Include loggedInUser as PersonLoggedIn in the query params
  if (loggedInUser) {
    queryParams.append('PersonLoggedIn', loggedInUser);
  }

  // Handle multiple active domains
  if (activeDomains && activeDomains.length > 0) {
    activeDomains.forEach(domain => queryParams.append('domain', domain));
  }
  
  if (searchTerm) queryParams.append('search', searchTerm);
  
  if (usernames && usernames.length > 0) {
    usernames.forEach(username => queryParams.append('usernames', username));
  }
  
  if (keys && keys.length > 0) {
    keys.forEach(key => queryParams.append('keys', key));
  }
  
  if (timeParameter) queryParams.append('time', timeParameter);
  
  if (searchEntries) queryParams.append('searchEntries', searchEntries);
  
  if (selectedDomains && selectedDomains.length > 0) {
    selectedDomains.forEach(domain => queryParams.append('selectedDomains', domain));
  }
  
  // Add branches, fields, categories, origins, and originNames to the query params
  if (branches && branches.length > 0) {
    branches.forEach(branch => queryParams.append('branches', branch));
  }
  
  if (fields && fields.length > 0) {
    fields.forEach(field => queryParams.append('fields', field));
  }
  
  if (categories && categories.length > 0) {
    categories.forEach(category => queryParams.append('categories', category));
  }
  
  if (origins && origins.length > 0) {
    origins.forEach(origin => queryParams.append('origins', origin));
  }
  
  if (originNames && originNames.length > 0) {
    originNames.forEach(originName => queryParams.append('originNames', originName));
  }
  
  // Add followed to the query params
  if (followed) {
    queryParams.append('followed', 'true');
  }
  
  // Add poolId to the query params
  if (poolId) {
    queryParams.append('poolId', poolId);
  }

  queryParams.append('sort', sortOrder);
  queryParams.append('page', page);

  const apiEndpoint = `/api/get-official-pool-entries?${queryParams.toString()}`;
 
  try {
    const response = await fetch(apiEndpoint);
    if (response.ok && requestId === currentFetchEntriesRequest) {
      const { entries: newEntries, hasMore: hasNextPage } = await response.json();
      setEntries(prevEntries => page === 0 ? newEntries : [...prevEntries, ...newEntries]);
      setHasMore(hasNextPage);
    } else if (!response.ok) {
      console.error('Failed to fetch data');
    }
  } catch (error) {
    if (requestId === currentFetchEntriesRequest) {
      console.error('Error:', error);
    }
  }
};



