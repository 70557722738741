import React, { useState, useEffect, useRef } from 'react';
import "./chat.css";
import ChatPopup from './forCampbell/CcPopup';
import { Link } from "react-router-dom";
import RetroBootScreen from './boot';
import RequestorAvatar from './requestorAvatar';
const Chat = () => {
  // State to manage messages
  const [messages, setMessages] = useState([]);
  // State to manage user input
  const [newMessage, setNewMessage] = useState("");
  // State to manage the selected receiver
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  // State to store the list of users fetched from the backend
  const [users, setUsers] = useState([]);
  const [openChats, setOpenChats] = useState([]);
  const [chatRequests, setChatRequests] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [showChatRequests, setShowChatRequests] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
  const [acceptedChatRequests, setAcceptedChatRequests] = useState([]);
  //const [chatPopupUser, setChatPopupUser] = useState(null);
  const [openChatPopups, setOpenChatPopups] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const activeChatRef = useRef(null);
  const [sortedMentorChatPopups, setSortedMentorChatPopups] = useState([]);
const [sortedNonMentorChatPopups, setSortedNonMentorChatPopups] = useState([]);
const messagesContainerRef = useRef(null);


const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  //thanks

  const handlePopupClick = (requestor) => {
    setActiveChat(requestor);
    // ... other logic to open chat ...
  };

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeChatRef.current && !activeChatRef.current.contains(event.target)) {
        setActiveChat(null); // Close active chat
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
      if (messagesContainerRef.current) {
        const { scrollHeight, clientHeight } = messagesContainerRef.current;
        messagesContainerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    }, [messages]); // Dependency on messages to update scroll on new messages
   

  const renderActiveChat = () => {
    const selectedChat = messages.find(chat => chat.receiver === activeChat);
    
     
    // Check if selectedChat is valid and has content
    if (!selectedChat) {
      return <RetroBootScreen/>
    }
  
    // Continue with existing rendering logic if selectedChat is valid
    return (
      <>
        <div className="chat-messages-container" ref={messagesContainerRef}>
          {selectedChat.content.map((message, index) => (
            <div key={index} style={{ textAlign: "left" }}>
              <p>
                <span style={{ color: "#00F100" }}>
                  {message.sender}
                  <span style={{ color: "white" }}>:</span>
                <span style={{ color: "#0000FF" }}>~</span>
                <span style={{ color: "white" }}>$</span></span>

$
<span style={{ color: "white" }}>{message.content}</span></p>
            </div>
          ))}
        </div>
        <div className={`chat-input`}>
          <div className="chat-input-area">
          <textarea
  type="text"
  placeholder="Press Enter After Typing Message <3"
  value={newMessage}
  onChange={(e) => setNewMessage(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents a new line
      sendMessageToChat();
    }
  }}
/>
          </div>
        </div>
      </>
    );
  };

  

  useEffect(() => {
    // Check screen size and update isSmallScreen state
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767); // Adjust the screen width as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchChatData = () => {
    // Fetch chat requests (unchanged)
    fetch(`/api/chat-requests/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => {
        setChatRequests(data);
      })
      .catch((error) => {
        console.error("Error fetching chat requests:", error);
      });

    // Fetch accepted chat requests (unchanged)
    fetch(`/api/chat-requests/accepted/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => {
        setAcceptedChatRequests(data);
      })
      .catch((error) => {
        console.error("Error fetching accepted chat requests:", error);
      });
  };

  // Update the timer to periodically fetch data, including messages
  useEffect(() => {
    let intervalId; // Declare intervalId variable outside of the if block

    if (isMenuOpen) {
      intervalId = setInterval(fetchChatData, 1000); // Fetch data every 5 seconds
    }

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [isMenuOpen]);

  // Set up a timer to periodically fetch chat messages for open chats
  useEffect(() => {
    const messageUpdateInterval = setInterval(() => {
      if (isMenuOpen) {
        openChatPopups.forEach((requestor) => {
          fetch(`/api/messages/${loggedInUser}/${requestor}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.success && Array.isArray(data.message)) {
                setMessages((prevMessages) => {
                  const existingChatIndex = prevMessages.findIndex(chat => chat.receiver === requestor);
                  if (existingChatIndex !== -1) {
                    const existingChat = prevMessages[existingChatIndex];
                    const newMessages = data.message.filter(msg => {
                      const lastMessage = existingChat.content[existingChat.content.length - 1];
                      return lastMessage && new Date(msg.timestamp) > new Date(lastMessage.timestamp);
                    });
  
                    const updatedChat = { ...existingChat, content: [...existingChat.content, ...newMessages] };
                    return [
                      ...prevMessages.slice(0, existingChatIndex),
                      updatedChat,
                      ...prevMessages.slice(existingChatIndex + 1)
                    ];
                  } else {
                    return [...prevMessages, { receiver: requestor, content: data.message }];
                  }
                });
              }
            })
            .catch((error) => {
              console.error("Error fetching chat messages:", error);
            });
        });
      }
    }, 5000); // Fetch messages every 5 seconds
  
    return () => clearInterval(messageUpdateInterval);
  }, [isMenuOpen, openChatPopups, loggedInUser]);

  useEffect(() => {
    // Fetch chat requests for the logged-in user
    fetch(`/api/chat-requests/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => { 
        setChatRequests(data); // Assuming the response contains pending chat requests
      })
      .catch((error) => {
        console.error("Error fetching chat requests:", error);
      });
  }, [loggedInUser]);

  const acceptChatRequest = (requestor) => {
    // Define the sender (loggedInUser) and receiver (requestor)
    const sender = loggedInUser;

    // Make an API call to accept the chat request with both sender and receiver
    fetch(`/api/chat-requests/accept/${requestor}/${sender}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Handle success
          console.log(requestor, "and", loggedInUser, "chat request accepted");

          // Update the acceptedChatRequests state
          setAcceptedChatRequests([...acceptedChatRequests, requestor]);

          // Optionally, you can also open the chat popup here if needed
          openChatPopup(requestor);
        } else {
          // Handle error
          console.error("Error accepting chat request:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error accepting chat request:", error);
      });
  };

  useEffect(() => {
    fetch(`/api/chat-requests/accepted/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => {
        setAcceptedChatRequests(data); // Assuming the response contains accepted chat requests
      })
      .catch((error) => {
        console.error("Error fetching accepted chat requests:", error);
      });
  }, [loggedInUser]);

  useEffect(() => {
    // Check if any chat request has been accepted
    acceptedChatRequests.forEach((acceptedRequest) => {
      // Automatically open the chat popup for each accepted request
      openChatPopup(acceptedRequest.requestor);
    });
  }, [acceptedChatRequests, chatRequests]);

  const declineChatRequest = (requestor) => {
    // Define the sender (loggedInUser) and receiver (requestor)
    const sender = loggedInUser;

    // Make an API call to accept the chat request with both sender and receiver
    fetch(`/api/chat-requests/decline/${requestor}/${sender}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Handle success
          console.log(requestor, "and", loggedInUser, "chat request accepted");

          // You can optionally update the state of chatRequests here if needed
        } else {
          // Handle error
          console.error("Error accepting chat request:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error accepting chat request:", error);
      });
  };

  const toggleChatRequests = () => {
    setShowChatRequests(!showChatRequests);
  };

  // Function to handle receiver selection

  useEffect(() => {
    // Check if any chat request has been accepted
    acceptedChatRequests.forEach((acceptedRequest) => {
      // Automatically open the chat popup for each accepted request
      openChatPopup(acceptedRequest.requestor);
    });
  }, [acceptedChatRequests, openChatPopups]);

  // Function to open a chat popup for a specific requestor
  const openChatPopup = (requestor) => {
    if (!openChatPopups.includes(requestor)) {
      // Create a new array with the added requestor
      const updatedOpenChatPopups = [...openChatPopups, requestor];
      setOpenChatPopups(updatedOpenChatPopups);
    }
  };

  const sendMessageToChat = () => {
    if (newMessage.trim() === "") {
      return; // Prevent sending empty messages
    }
  
    const receiver = activeChat; // or selectedChat.receiver based on your setup
    if (!receiver) {
      console.error("No receiver selected.");
      return;
    }
  
    const message = {
      sender: loggedInUser,
      receiver: receiver,
      content: newMessage,
      timestamp: new Date().toISOString(),
    };
  
    fetch(`/api/messages/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Update the chat state with the new message
          setMessages(prevMessages => {
            // Update the messages state with the new message for the correct chat
            return prevMessages.map(chat => 
              chat.receiver === receiver ? {...chat, content: [...chat.content, message]} : chat
            );
          });
          setNewMessage(""); // Clear the input field
        } else {
          // Handle error
          console.error("Error sending message:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  // Function to expand the chat popup for a specific requestor
  const expandChatPopup = (requestor) => {
    // Toggle chat popup
    if (openChatPopups.includes(requestor)) {
      setOpenChatPopups(openChatPopups.filter((item) => item !== requestor));
    } else {
      setOpenChatPopups([...openChatPopups, requestor]);
    }

    // Make an API call to check messages and fetch chat history for the selected chat
    fetch(`/api/messages/${loggedInUser}/${requestor}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.message)) {
            // Check if message is an array
            if (data.message.length === 0) {
              // If no messages found, create a new chat entry
              createNewChatEntry(loggedInUser, requestor);
            } else {
              // Update the selectedChat state with the fetched messages
              setSelectedChat({
                receiver: requestor,
                content: data.message,
              });
            }
          } else {
            // Handle the case where data.message is not an array (error or unexpected response)
            console.error("Invalid response data:", data.message);
          }
        } else {
          // Handle error or display a message indicating no chat history
          console.error("Error fetching chat history:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching chat history:", error);
      });
  };

  // Function to create a new chat entry
  const createNewChatEntry = (loggedInUser, requestor) => {
    // Use requestor here
    const newChatEntry = {
      sender: loggedInUser,
      receiver: requestor, // Use requestor here
      content: [],
    };

    // Make an API call to create a new chat entry
    fetch("/api/messages/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newChatEntry),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // New chat entry created successfully, update the selectedChat state
          setSelectedChat({
            receiver: requestor, // Use requestor here
            content: [],
          });
        } else {
          // Handle error
          console.error("Error creating new chat entry:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error creating new chat entry:", error);
      });
  };

  const getLatestMessageTimestamp = (chatReceiver) => {
    const chat = messages.find(c => c.receiver === chatReceiver);
    if (chat && chat.content.length > 0) {
      return new Date(chat.content[chat.content.length - 1].timestamp).getTime();
    }
    return 0;
  };


  const sortChatPopups = (chatPopups, mentorStatus) => {
    return chatPopups
      .filter(request => request.mentor === mentorStatus)
      .sort((a, b) => {
        return getLatestMessageTimestamp(b.requestor) - getLatestMessageTimestamp(a.requestor);
      });
  };

  useEffect(() => {
    const getLatestMessageTimestamp = (chatReceiver) => {
      const chat = messages.find(c => c.receiver === chatReceiver);
      if (chat && chat.content.length > 0) {
        return new Date(chat.content[chat.content.length - 1].timestamp).getTime();
      }
      return 0;
    };
  
    setSortedMentorChatPopups(sortChatPopups(acceptedChatRequests, true));
    setSortedNonMentorChatPopups(sortChatPopups(acceptedChatRequests, false));
  }, [acceptedChatRequests, messages]);
  
  const mentorChatPopups = acceptedChatRequests.filter(
    (acceptedRequest) => acceptedRequest.mentor === true
  );

  const nonMentorChatPopups = acceptedChatRequests.filter(
    (acceptedRequest) => acceptedRequest.mentor === false
  );

  const mentorRequests = chatRequests.filter(request => request.mentor);
  const nonMentorRequests = chatRequests.filter(request => !request.mentor);

  const mentorChatsSection = (
    <div className="mentor-chat-container">
      {sortedMentorChatPopups.map((acceptedRequest) => (
        <ChatPopup
          key={acceptedRequest.requestor}
          acceptedRequest={acceptedRequest}
          expandChatPopup={expandChatPopup}
          selectedChat={selectedChat}
          sendMessageToChat={sendMessageToChat}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          isSmallScreen={isSmallScreen}
          handlePopupClick={handlePopupClick}
        />
      ))}
    </div>
  );
  
  // Section for Non-Mentor Chats
  const nonMentorChatsSection = (
    <div className="non-mentor-chat-container">
      {sortedNonMentorChatPopups.map((acceptedRequest) => (
        <ChatPopup
          key={acceptedRequest.requestor}
          acceptedRequest={acceptedRequest}
          expandChatPopup={expandChatPopup}
          selectedChat={selectedChat}
          sendMessageToChat={sendMessageToChat}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          isSmallScreen={isSmallScreen}
          handlePopupClick={handlePopupClick}
        />
      ))}
    </div>
  );

  return (
    <div className="chat-container">
      {/* Conversations button and menu */}
      <div className="fixed-conversations">
        <button
          onClick={toggleMenu}
          style={{
            background: "linear-gradient(135deg, #ff6b6b, #6b63ff)",
            borderRadius: "15px",
            padding: "2px",
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
            display: "flex",
            position: "fixed",
            bottom: 7,
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Center horizontally
            color: "black",
            fontSize: "24px",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "2px",
            zIndex: 3,
          }}
        >
          Conversations
        </button>
        {isMenuOpen && (
          <div>
            {/* Full-width menu */}
            <div>
    {/* Mentor Requests Container */}
    <div className="chat-requests-container mentor">
  <h3 className="retro-text">Mentorship</h3>
  <div className="scrollable-requests">
    {chatRequests.filter(request => request.mentor).map(chatRequest => (
      <div key={chatRequest.requestor} className="chat-request">
        <div className="avatar-container">
  <RequestorAvatar requestor={chatRequest.requestor} />
</div>
        <Link to={`/u/${chatRequest.requestor}`}>
          <p>{chatRequest.requestor}</p>
        </Link>
        {chatRequest.selectedDisciplines && (
          <div className="chat-request-text">
            {/* Map over selectedDisciplines and render each discipline */}
            {chatRequest.selectedDisciplines.map((discipline) => (
              <p key={discipline}>{discipline}</p>
            ))}
          </div>
        )}
        {chatRequest.requestText && (
          <div className="chat-request-text">
            <p>{chatRequest.requestText}</p>
          </div>
        )}
        <div className="chat-request-options">
          <button onClick={() => acceptChatRequest(chatRequest.requestor)}>Accept</button>
          <button onClick={() => declineChatRequest(chatRequest.requestor)}>Decline</button>
        </div>
      </div>
    ))}
  </div>
</div>

    {/* Non-Mentor Requests Container */}
    <div className="chat-requests-container non-mentor">
    <h3 className="retro-text">Friendship</h3>
      <div className="scrollable-requests">
        {chatRequests.filter(request => !request.mentor).map(chatRequest => (
          <div key={chatRequest.requestor} className="chat-request">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
  <RequestorAvatar requestor={chatRequest.requestor} />
</div>
            <Link to={`/u/${chatRequest.requestor}`}>
              <p>{chatRequest.requestor}</p>
            </Link>
            {chatRequest.disciplines && (
              <div className="chat-request-text">
                <p>{chatRequest.disciplines}</p>
              </div>
            )}
            {chatRequest.requestText && (
              <div className="chat-request-text">
                <p>{chatRequest.requestText}</p>
              </div>
            )}
            <div className="chat-request-options">
              <button onClick={() => acceptChatRequest(chatRequest.requestor)}>Accept</button>
              <button onClick={() => declineChatRequest(chatRequest.requestor)}>Decline</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}
            <div className="chat-container">
  {/* ... other components ... */}

  <div className="chat-popups-container">
    <div className="non-mentor-chat-popups-container">
      {nonMentorChatsSection}
    </div>
    <div className="mentor-chat-popups-container">
      {mentorChatsSection}
    </div>
  </div>

  {/* Render active chat messages and input */}
  {activeChat && (
    <div className="active-chat-container" ref={activeChatRef}>
    {renderActiveChat()}
  </div>
  )}
</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
