import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  CircularProgress
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EcologySubmit from '../../Explorers2/utils/add/addEntry';
import EcologyCardSelect from '../../Explorers2/utils/cardselect/cardSelect';
import EntriesGrid from '../../Explorers2/utils/explorer/entriesGrid';
import SearchAndSort from '../../Explorers2/utils/explorer/searchandsort';
import useScrollToTop from '../../Explorers2/utils/scrolltotop';
import { useStyles } from '../../Explorers2/utils/explorer/explorerStyles';
import { fetchEntries, fetchDomainEntries, recordUserInteraction, handleEntryAction } from '../../Explorers2/utils/explorer/explorerapiHelper';
import addIcon from '../../../assets/add.png';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';


const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

const Explorer = ({ ids, onClose }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [entries, setEntries] = useState([]);
  const [activeDomains, setActiveDomains] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [domainsDialogOpen, setDomainsDialogOpen] = useState(false);
  const [valuesDialogOpen, setValuesDialogOpen] = useState(false);
  const [savedDialogOpen, setSavedDialogOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const { checkScrollTop, scrollToTop } = useScrollToTop();
  const [usernames, setUsernames] = useState([]);
  const [keys, setKeys] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeParameter, setTimeParameter] = useState('');
  const [followed, setFollowed] = useState(false);
  // New states for the SavedDialog categories
  const [branches, setBranches] = useState([]);
  const [fields, setFields] = useState([]);
  const [categories, setCategories] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [originNames, setOriginNames] = useState([]);
  const [selectedItemsByType, setSelectedItemsByType] = useState({
    branches: [],
    fields: [],
    categories: [],
    origins: [],
    originNames: [],  
  });

  const handleSelectFromSaved = (category, newSelectedItems) => {
    setSelectedItemsByType((prevSelectedItemsByType) => ({
      ...prevSelectedItemsByType,
      [category]: newSelectedItems,
    }));

    // Update specific states based on the category
    switch (category) {
      case 'branches':
        setBranches(newSelectedItems);
        break;
      case 'fields':
        setFields(newSelectedItems);
        break;
      case 'categories':
        setCategories(newSelectedItems);
        break;
      case 'origins':
        setOrigins(newSelectedItems);
        break;
      case 'originNames':
        setOriginNames(newSelectedItems);
        break;
      default:
        break;
    }

    // Re-fetch entries with the updated selections
    updateEntries();
  };
  
  const updateEntries = () => {
    setEntries([]);
    setPage(0);
    setHasMore(true);

    fetchEntries(
      0,
      activeDomains,
      searchTerm,
      sortOrder,
      selectedDomains,
      setEntries,
      setHasMore,
      usernames,
      keys,
      timeParameter,
      [],
      branches,
      fields,
      categories,
      origins,
      originNames,
      followed,
    );
  };

  useEffect(() => {
    setEntries([]);
    setPage(0);
    setHasMore(true);
  }, [
    activeDomains,
    searchTerm,
    sortOrder,
    selectedDomains,
    usernames,
    keys,
    timeParameter,
    branches,
    fields,
    categories,
    origins,
    originNames,
    followed,
    ids 
  ]);

  useEffect(() => {
    const loadEntries = async () => {
      setLoading(true);
      if (ids && ids.length > 0) {
        await fetchDomainEntries(
          ids,
          setEntries,
          setHasMore,
        );
      } else {
        await fetchEntries(
          page,
          activeDomains,
          searchTerm,
          sortOrder,
          selectedDomains,
          setEntries,
          setHasMore,
          usernames,
          keys,
          timeParameter,
          [],
          branches,
          fields,
          categories,
          origins,
          originNames,
          followed
        );
      }
      setLoading(false);
    };
    loadEntries();
  }, [
    ids,
    activeDomains,
    searchTerm,
    sortOrder,
    selectedDomains,
    page,
    usernames,
    keys,
    timeParameter,
    branches,
    fields,
    categories,
    origins,
    originNames,
    followed,
  ]);

  const throttleScroll = useCallback(
    _.throttle(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      setShowScrollToTop(scrollTop > 300); // Show "Top" button after scrolling down 300px
    }, 200),
    []
  ); // Adjust the delay (200ms) to your preference

  useEffect(() => {
    window.addEventListener('scroll', throttleScroll);

    return () => {
      window.removeEventListener('scroll', throttleScroll);
    };
  }, [throttleScroll]);

  const fetchMoreData = useCallback(() => {
    if (loading || !hasMore) return;
    if (ids && ids.length > 0) return; // Do not fetch more data when ids are provided

    setLoading(true);
    fetchEntries(
      page + 1,
      activeDomains,
      searchTerm,
      sortOrder,
      selectedDomains,
      setEntries,
      setHasMore,
      usernames,
      keys,
      timeParameter,
      [],
      branches,
      fields,
      categories,
      origins,
      originNames,
      followed
    ).finally(() => {
      setLoading(false);
      setPage((prevPage) => prevPage + 1);
    });
  }, [
    loading,
    hasMore,
    page,
    activeDomains,
    searchTerm,
    sortOrder,
    selectedDomains,
    usernames,
    keys,
    timeParameter,
    branches,
    fields,
    categories,
    origins,
    originNames,
    followed,
    ids
  ]);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleCardClick = (entry) => {
    const tag = window.location.pathname.split('/').pop();
    setSelectedEntry(entry);
    recordUserInteraction('entrySelection', {
      selectedEntryId: entry._id,
      domain: entry.domain,
      tag: tag,
    });
    handleEntryAction(entry._id, entry.domain, tag);
  };

  const handleDeleteKey = (keyToDelete) => {
    setKeys(keys.filter((key) => key !== keyToDelete));
    setUsernames(usernames.filter((username) => username !== keyToDelete));
  };

  return (
    <div className={classes.container}>
      <InfiniteScroll
        dataLength={entries.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div
            className={classes.loader}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}
          >
            <CircularProgress style={{ color: 'white' }} />
          </div>
        }
        scrollThreshold={0.69}
        style={{ overflow: 'unset' }} // Prevents the scroll container from restricting the page scroll
      >
        <EntriesGrid entries={entries} isMobile={isMobile} classes={classes} handleCardClick={handleCardClick} />
      </InfiniteScroll>
      <Dialog
        open={isFormOpen}
        onClose={handleCloseForm}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.96)',
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <DialogContent>
          <EcologySubmit />
        </DialogContent>
      </Dialog>
      {selectedEntry && (
        <EcologyCardSelect isOpen={true} onClose={() => setSelectedEntry(null)} post={selectedEntry} />
      )}
      {showScrollToTop && (
        <button onClick={scrollToTop} className={classes.scrollToTopButton}>
          Top
        </button>
      )}
      {/* Fixed Close Button */}
      <button
        onClick={onClose}
        style={{
          position: 'fixed',
          bottom: '4.2vh',  // Adjust bottom to keep it visible
          right: '0.42vw',
          backgroundColor: '#004d40',
          border: 'none',
          borderRadius: '50%',
          width: '42.69px',
          height: '42.69px',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          zIndex: 1000, // Ensure the button is on top of other elements
        }}
        aria-label="Close Explorer"
      >
        Close
      </button>
    </div>
  );
};

export default Explorer;
