import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import missingIMG from '../../../../assets/yep.png'; // Replace with actual path
import { handleKeepForSelf } from './cardselecthelpers'; // Import the helper function

const KeepEntryDialog = ({ open, onClose, entryId, username, domain, onSaveForPool, isAlreadyInPool }) => {
  const [selectedPool, setSelectedPool] = useState(null);
  const [userPools, setUserPools] = useState([]);
  const [isKeepEntryDialogOpen, setIsKeepEntryDialogOpen] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(null); // 'add' | 'self' | null
  const [successMessage, setSuccessMessage] = useState('');
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

  const openKeepEntryDialog = async () => {
    setIsKeepEntryDialogOpen(true);
    try {
      // Fetch both user pools and official pools concurrently
      const [userPoolsResponse, officialPoolsResponse] = await Promise.all([
        fetch(`/api/get-pools?userId=${loggedInUser}`),
        fetch(`/api/get-official-pools?userId=${loggedInUser}`)
      ]);

      let userPoolsData = [];
      let officialPoolsData = [];

      // Process user pools response
      if (userPoolsResponse.ok) {
        userPoolsData = await userPoolsResponse.json();
        userPoolsData = userPoolsData.map(pool => ({ ...pool, isOfficial: false }));
      } else {
        console.error('Failed to fetch user pools');
      }

      // Process official pools response
      if (officialPoolsResponse.ok) {
        officialPoolsData = await officialPoolsResponse.json();
        officialPoolsData = officialPoolsData.map(pool => ({ ...pool, isOfficial: true }));
      } else {
        console.error('Failed to fetch official pools');
      }

      // Combine pools, ensuring no duplicates
      const combinedPoolsMap = new Map();

      userPoolsData.forEach(pool => {
        combinedPoolsMap.set(pool._id, pool);
      });

      officialPoolsData.forEach(pool => {
        combinedPoolsMap.set(pool._id, pool);
      });

      const combinedPools = Array.from(combinedPoolsMap.values());

      setUserPools(combinedPools);
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };

  useEffect(() => {
    if (open) {
      openKeepEntryDialog();
    }
  }, [open]);

  const handleSaveForSelf = async () => {
    setActionInProgress('self');
    setSuccessMessage('');
    try {
      await handleKeepForSelf(entryId, username, domain);
      setSuccessMessage("Successfully Added");
    } catch (error) {
      console.error('Error keeping entry for self:', error);
      setSuccessMessage("An error occurred. Please try again.");
    } finally {
      setActionInProgress(null);
    }
  };

  const handleSaveForPool = async () => {
    if (isAlreadyInPool) {
      setSuccessMessage("Already in Pool");
      return;
    }
    if (!selectedPool) {
      setSuccessMessage("Please select a pool");
      return;
    }
    setActionInProgress('add');
    setSuccessMessage('');
    try {
      await onSaveForPool(selectedPool.name);
      setSuccessMessage("Successfully Added");
    } catch (error) {
      console.error('Error saving to pool:', error);
      setSuccessMessage("An error occurred. Please try again.");
    } finally {
      setActionInProgress(null);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{
      style: {
        backgroundColor: '#c5c9cc',
      },
    }}>
      <DialogContent>
        <Typography 
          variant="h6" 
          sx={{ 
            textAlign: 'center', 
            margin: '6.9px 0 10px', 
            wordBreak: 'break-word' 
          }}
        >
          Pools
        </Typography>
        {userPools.length > 0 ? (
          userPools.map((pool) => (
            <Box
              key={pool._id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '6.9px',
                marginBottom: '6.9px',
                border: `2px solid ${selectedPool && selectedPool._id === pool._id ? '#57c0bf' : 'transparent'}`,
                borderRadius: '6.9px',
                cursor: 'pointer',
                '&:hover': {
                  borderColor: '#57c0bf',
                },
              }}
              onClick={() => setSelectedPool(pool)}
            >
              <CardMedia
                component="img"
                sx={{ width: 50, height: 50, borderRadius: '50%' }}
                image={pool.imageData.imageLink || missingIMG}
                alt="Missing image"
              />
              <Typography variant="subtitle1" sx={{ marginLeft: '10px', wordBreak: 'break-word' }}>
                {pool.name} {pool.isOfficial && "(Official)"} 
              </Typography>
            </Box>
          ))
        ) : (
          <Typography>No pools available.</Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
        <Box sx={{ position: 'relative', width: '100%', marginBottom: '8px' }}>
          {actionInProgress === 'add' ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                size={60}
                sx={{
                  color: '#004d40',
                }}
              />
            </Box>
          ) : (
            <button
              style={{
                width: '100%',
                padding: '16px 20px',
                backgroundColor: isAlreadyInPool ? '#a5d6a7' : '#004d40',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: isAlreadyInPool || actionInProgress ? 'not-allowed' : 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
              onClick={handleSaveForPool}
              disabled={isAlreadyInPool || actionInProgress}
            >
              Add to Pool
            </button>
          )}
        </Box>
        <Box sx={{ position: 'relative', width: '100%', marginBottom: '8px' }}>
          {actionInProgress === 'self' ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                size={60}
                sx={{
                  color: '#004d40',
                }}
              />
            </Box>
          ) : (
            <button
              style={{
                width: '100%',
                padding: '16px 20px',
                backgroundColor: '#004d40',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: actionInProgress ? 'not-allowed' : 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
              onClick={handleSaveForSelf}
              disabled={actionInProgress}
            >
              Keep for Self
            </button>
          )}
        </Box>
        {successMessage && (
          <Typography 
            variant="body1" 
            sx={{ 
              color: successMessage === "Successfully Added" ? 'green' : 'red',
              marginTop: '8px',
              textAlign: 'center',
            }}
          >
            {successMessage}
          </Typography>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default KeepEntryDialog;
