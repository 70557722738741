// src/components/Pathways/PathwayDetail.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Button,
  Chip,
  useMediaQuery,
  useTheme,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import EditPathwayDialog from './EditPathwayDialog';
import PathwaySupport from './PathwaySupport';
import { getPathwayId } from './getPathwayId';
import MediaViewer from './MediaViewer';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { ArcherContainer, ArcherElement } from 'react-archer';

const PathwayDetail = ({ pathwayId, onClose }) => {
  const [pathway, setPathway] = useState(null);
  const [expandedMainSteps, setExpandedMainSteps] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [supportDialog, setSupportDialog] = useState({ open: false, stepId: null });
  const [mediaViewer, setMediaViewer] = useState({ open: false, mediaList: [], initialIndex: 0 });
  const loggedInUser = JSON.parse(localStorage.getItem("readifyUserKey"))?.username;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchPathway = async () => {
      try {
        const res = await axios.get(`/api/pathways/${getPathwayId({ pathwayId })}`);
        setPathway(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPathway();
  }, [pathwayId]);

  const handleUpdatePathway = (updatedPathway) => {
    setPathway(updatedPathway);
  };

  const handleStatusChange = async (mainStepId, newStatus) => {
    try {
      const res = await axios.put(`/api/pathways/${getPathwayId(pathway)}/status`, {
        username: loggedInUser,
        mainStepId: getPathwayId({ pathwayId: mainStepId }),
        newStatus,
      });
      setPathway(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleExpandMainStep = (mainStepId) => {
    const id = getPathwayId({ pathwayId: mainStepId });
    setExpandedMainSteps((prev) =>
      prev.includes(id) ? prev.filter((mid) => mid !== id) : [...prev, id]
    );
  };

  const handleMediaClick = (mediaList, index) => {
    setMediaViewer({ open: true, mediaList, initialIndex: index });
  };

  const closeMediaViewer = () => {
    setMediaViewer({ open: false, mediaList: [], initialIndex: 0 });
  };

  // Helper functions to detect media types
  const isVideo = (mediaType) => mediaType === 'video';
  const isImage = (mediaType) => mediaType === 'image';
  const isDocument = (mediaType) => mediaType === 'document';

  if (!pathway)
    return (
      <Typography variant="h5" sx={{ textAlign: 'center', color: '#2f4f4f', marginBottom: '20px' }}>
        Loading...
      </Typography>
    );

  const renderSubsteps = (midstep) => {
    return midstep.substeps.map((substep, subIndex) => {
      const substepId = getPathwayId(substep);

      return (
        <div key={substepId} style={{ margin: '10px', position: 'relative' }}>
          <ArcherElement id={`substep-${substepId}`}>
            <Card
              sx={{
                width: isMobile ? '90%' : '200px',
                backgroundColor: '#ffffff',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              <CardContent sx={{ padding: '10px' }}>
                {/* Substep Description */}
                <Typography
                  variant="body2"
                  sx={{
                    color: '#2f4f4f',
                    fontSize: isMobile ? '0.8rem' : '0.875rem',
                  }}
                >
                  {substep.description}
                </Typography>

                {/* Substep Resources */}
                {substep.resources && substep.resources.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: '10px',
                      marginTop: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    {substep.resources.map((resource, idx) => (
                      <div
                        key={idx}
                        style={{
                          position: 'relative',
                          margin: '5px',
                          width: isMobile ? '60px' : '80px',
                          height: isMobile ? '60px' : '80px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const mediaList = substep.resources.map((r) => ({
                            mediaLink: r.mediaLink,
                            mediaType: r.mediaType,
                          }));
                          handleMediaClick(mediaList, idx);
                        }}
                      >
                        {isImage(resource.mediaType) && (
                          <img
                            src={resource.mediaLink}
                            alt="Substep Media"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                          />
                        )}
                        {isVideo(resource.mediaType) && (
                          <video
                            src={resource.mediaLink}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                            muted
                            loop
                            autoPlay
                          />
                        )}
                        {isDocument(resource.mediaType) && (
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              backgroundColor: '#f0f0f0',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                          >
                            <InsertDriveFileIcon style={{ fontSize: '2rem', color: '#e53935' }} />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </CardContent>
            </Card>
          </ArcherElement>
        </div>
      );
    });
  };

  const renderMidsteps = (mainStep) => {
    return mainStep.midsteps.map((midstep, midIndex) => {
      const midstepId = getPathwayId(midstep);

      const relations = [];

      if (midstep.substeps && midstep.substeps.length > 0) {
        relations.push(
          ...midstep.substeps.map((substep) => ({
            targetId: `substep-${getPathwayId(substep)}`,
            targetAnchor: 'top',
            sourceAnchor: 'bottom',
            style: { strokeColor: '#32CD32', strokeWidth: 2 },
          }))
        );
      }

      return (
        <div key={midstepId} style={{ margin: '10px', position: 'relative' }}>
          <ArcherElement id={`midstep-${midstepId}`} relations={relations}>
            <Card
              sx={{
                width: isMobile ? '90%' : '220px',
                backgroundColor: '#ffffff',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              <CardContent sx={{ padding: '10px' }}>
                {/* Midstep Description */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: '#2f4f4f',
                    fontWeight: 'bold',
                    fontSize: isMobile ? '0.9rem' : '1rem',
                  }}
                >
                  {midstep.description}
                </Typography>

                {/* Midstep Resources */}
                {midstep.resources && midstep.resources.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: '10px',
                      marginTop: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    {midstep.resources.map((resource, idx) => (
                      <div
                        key={idx}
                        style={{
                          position: 'relative',
                          margin: '5px',
                          width: isMobile ? '60px' : '80px',
                          height: isMobile ? '60px' : '80px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const mediaList = midstep.resources.map((r) => ({
                            mediaLink: r.mediaLink,
                            mediaType: r.mediaType,
                          }));
                          handleMediaClick(mediaList, idx);
                        }}
                      >
                        {isImage(resource.mediaType) && (
                          <img
                            src={resource.mediaLink}
                            alt="Midstep Media"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                          />
                        )}
                        {isVideo(resource.mediaType) && (
                          <video
                            src={resource.mediaLink}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                            muted
                            loop
                            autoPlay
                          />
                        )}
                        {isDocument(resource.mediaType) && (
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              backgroundColor: '#f0f0f0',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                          >
                            <InsertDriveFileIcon style={{ fontSize: '2rem', color: '#e53935' }} />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </CardContent>
            </Card>
          </ArcherElement>

          {midstep.substeps && midstep.substeps.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <ArcherContainer strokeColor="#32CD32">
                {renderSubsteps(midstep)}
              </ArcherContainer>
            </div>
          )}
        </div>
      );
    });
  };

  const renderMainSteps = () => {
    return pathway.mainSteps.map((mainStep, index) => {
      const mainStepId = getPathwayId(mainStep);
      const isExpanded = expandedMainSteps.includes(mainStepId);
      const nextMainStep = pathway.mainSteps[index + 1];
      const hasNextMainStep = !!nextMainStep;
      const nextMainStepId = hasNextMainStep ? getPathwayId(nextMainStep) : null;

      const relations = [];

      if (hasNextMainStep) {
        relations.push({
          targetId: `main-step-${nextMainStepId}`,
          targetAnchor: isMobile ? 'top' : 'top', // Adjusted
          sourceAnchor: isMobile ? 'bottom' : 'bottom', // Adjusted
          style: { strokeColor: '#00BFFF', strokeWidth: 2 },
        });
      }

      if (isExpanded && mainStep.midsteps && mainStep.midsteps.length > 0) {
        relations.push(
          ...mainStep.midsteps.map((midstep) => ({
            targetId: `midstep-${getPathwayId(midstep)}`,
            targetAnchor: 'top',
            sourceAnchor: 'bottom',
            style: { strokeColor: '#FF69B4', strokeWidth: 2 },
          }))
        );
      }

      return (
        <div key={mainStepId} style={{ margin: '10px', position: 'relative' }}>
          <ArcherElement id={`main-step-${mainStepId}`} relations={relations}>
            <Card
              sx={{
                width: '100%',
                maxWidth: isMobile ? '90%' : '250px',
                backgroundColor: '#ffffff',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                padding: '10px',
                textAlign: 'center',
                cursor: 'pointer',
                flex: '1 1 auto', // Allow the card to grow and shrink
              }}
              onClick={() => toggleExpandMainStep(mainStepId)}
            >
              <CardContent sx={{ padding: '10px' }}>
                {/* Main Step Description */}
                <Typography
                  variant="h6"
                  sx={{
                    color: '#2f4f4f',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: isMobile ? '1rem' : '1.25rem',
                  }}
                >
                  {mainStep.description}
                </Typography>

                {/* Main Step Resources */}
                {mainStep.resources && mainStep.resources.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: '10px',
                      marginTop: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    {mainStep.resources.map((resource, idx) => (
                      <div
                        key={idx}
                        style={{
                          position: 'relative',
                          margin: '5px',
                          width: isMobile ? '60px' : '80px',
                          height: isMobile ? '60px' : '80px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const mediaList = mainStep.resources.map((r) => ({
                            mediaLink: r.mediaLink,
                            mediaType: r.mediaType,
                          }));
                          handleMediaClick(mediaList, idx);
                        }}
                      >
                        {isImage(resource.mediaType) && (
                          <img
                            src={resource.mediaLink}
                            alt="Main Step Media"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                          />
                        )}
                        {isVideo(resource.mediaType) && (
                          <video
                            src={resource.mediaLink}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                            muted
                            loop
                            autoPlay
                          />
                        )}
                        {isDocument(resource.mediaType) && (
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              backgroundColor: '#f0f0f0',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                            }}
                          >
                            <InsertDriveFileIcon style={{ fontSize: '2rem', color: '#e53935' }} />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {/* Main Step Status */}
                <Chip
                  label={mainStep.status}
                  sx={{
                    marginBottom: '10px',
                    color: '#ffffff',
                    backgroundColor:
                      mainStep.status === 'Completed' ? '#556b2f' : mainStep.status === 'In Progress' ? '#0abab5' : '#ff9800',
                  }}
                />
              </CardContent>
            </Card>
          </ArcherElement>

          {/* Expanded Content */}
          {isExpanded && (
            <>
              {pathway.createdBy === loggedInUser && (
                <FormControl variant="outlined" sx={{ minWidth: 150, marginTop: '10px' }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={mainStep.status}
                    onChange={(e) => handleStatusChange(mainStepId, e.target.value)}
                    label="Status"
                  >
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Needs Help">Needs Help</MenuItem>
                  </Select>
                </FormControl>
              )}

              {pathway.createdBy !== loggedInUser && (
               <Button
               variant="contained"
               sx={{
                 backgroundColor: '#004d40',
                 color: '#ffffff',
                 '&:hover': {
                   backgroundColor: '#003d34',
                 },
                 marginTop: '10px',
                 textTransform: 'none',
                 alignSelf: 'center',
                 marginLeft: '42%',
               }}
               onClick={() => setSupportDialog({ open: true, stepId: mainStepId })}
             >
               Support
             </Button>                         
              )}

              {mainStep.midsteps && mainStep.midsteps.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <ArcherContainer strokeColor="#FF69B4">
                    {renderMidsteps(mainStep)}
                  </ArcherContainer>
                </div>
              )}
            </>
          )}
        </div>
      );
    });
  };

  return (
    <Box
      sx={{
        padding: isMobile ? 0 : '30px',
        backgroundColor: '#f0f2f5',
        minHeight: isMobile ? '100vh' : 'auto',
        width: isMobile ? '100vw' : 'auto',
        height: isMobile ? '100vh' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {/* Pathway Goal */}
      <Typography
        variant="h4"
        sx={{
          color: '#2f4f4f',
          marginBottom: '20px',
          textAlign: 'center',
          fontSize: isMobile ? '1.5rem' : '2.125rem',
        }}
      >
        {pathway.goal}
      </Typography>

      {/* Pathway Image */}
      {pathway.imageData && pathway.imageData.imageLink && (
        <img
          src={pathway.imageData.imageLink}
          alt={pathway.goal}
          style={{
            maxWidth: '100%',
            height: 'auto',
            borderRadius: '12px',
            marginBottom: isMobile ? '20px' : '30px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            cursor: 'pointer',
            border: '1px solid #ddd',
          }}
          onClick={() =>
            handleMediaClick(
              pathway.imageData.imageLink ? [{ mediaLink: pathway.imageData.imageLink, mediaType: 'image' }] : [],
              0
            )
          }
        />
      )}

      {/* Edit Button */}
      {pathway.createdBy === loggedInUser && (
  <Button
    variant="contained"
    sx={{
      backgroundColor: '#004d40',
      color: '#ffffff',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#003d34',
      },
      marginBottom: isMobile ? '20px' : '30px',
    }}
    onClick={() => setIsEditDialogOpen(true)}
  >
    Edit Pathway
  </Button>
)}

{/* Close Button */}
{onClose && (
  <Button
    variant="contained"
    sx={{
      backgroundColor: '#004d40',
      color: '#ffffff',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#003d34',
      },
      marginTop: isMobile ? '20px' : '30px',
    }}
    onClick={onClose}
  >
    Close
  </Button>
)}

      {/* Steps with Archer */}
      <ArcherContainer strokeColor="#00BFFF">
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            flexWrap: isMobile ? 'nowrap' : 'wrap', // Enable wrapping on desktop
            alignItems: isMobile ? 'center' : 'flex-start',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          {renderMainSteps()}
        </div>
      </ArcherContainer>

      {/* Edit Pathway Dialog */}
      <EditPathwayDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        pathway={pathway}
        onUpdate={handleUpdatePathway}
      />

      {/* Pathway Support Dialog */}
      <PathwaySupport
        open={supportDialog.open}
        onClose={() => setSupportDialog({ open: false, stepId: null })}
        pathwayId={getPathwayId(pathway)}
        stepId={supportDialog.stepId}
      />

      {/* Media Viewer */}
      <MediaViewer
        open={mediaViewer.open}
        onClose={closeMediaViewer}
        mediaList={mediaViewer.mediaList}
        initialIndex={mediaViewer.initialIndex}
      />
    </Box>
  );
};

export default PathwayDetail;
