// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* mediaTransition.css */
.media-transition-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .media-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .media-transition-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .media-transition-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 500ms;
  }
  



  
  `, "",{"version":3,"sources":["webpack://./src/components/Explorers2/utils/explorer/mediaTransition.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;IACxB,0CAA0C;EAC5C;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,4BAA4B;IAC5B,0CAA0C;EAC5C","sourcesContent":["/* mediaTransition.css */\n.media-transition-enter {\n    opacity: 0;\n    transform: translateX(100%);\n  }\n  .media-transition-enter-active {\n    opacity: 1;\n    transform: translateX(0);\n    transition: opacity 500ms, transform 500ms;\n  }\n  .media-transition-exit {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  .media-transition-exit-active {\n    opacity: 0;\n    transform: translateX(-100%);\n    transition: opacity 500ms, transform 500ms;\n  }\n  \n\n\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
