import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
    color: '#333',
  },
  content: {
    backgroundColor: 'white',
    padding: '40px 30px',
    borderRadius: 10,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    maxWidth: 1000,
    width: '100%',
    textAlign: 'left',
    margin: '30px auto',
    lineHeight: 1.8,
    fontSize: '1rem',
  },
  title: {
    fontSize: '3em',
    marginBottom: 40,
    fontWeight: 800,
    color: '#222',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: 25,
    textAlign: 'justify',
  },
  sectionTitle: {
    fontSize: '2em',
    marginTop: 50,
    marginBottom: 25,
    fontWeight: 700,
    color: '#444',
  },
  subSectionTitle: {
    fontSize: '1.6em',
    marginTop: 30,
    marginBottom: 20,
    fontWeight: 600,
    color: '#555',
  },
  list: {
    margin: '15px 0',
    paddingLeft: '25px',
  },
  listItem: {
    marginBottom: 15,
  },
  link: {
    color: '#1a0dab',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const UserGuidelines = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h1 className={classes.title}>Librious.com User Guidelines</h1>
        
        <p className={classes.paragraph}>
          <strong>Effective Date:</strong> November 11 2024
        </p>

        <p className={classes.paragraph}>
          Welcome to Librious.com ("Librious," "we," "us," or "our"). We are committed to providing a safe, respectful, and enjoyable environment for all our users. These User Guidelines ("Guidelines") outline the standards and expectations for behavior and content on our platform. By accessing and using Librious.com, you agree to adhere to these Guidelines. If you do not agree with these Guidelines, please do not use our services.
        </p>

        <div className={classes.sectionTitle}>1. Definitions</div>
        <p className={classes.paragraph}>
          For the purposes of these Guidelines:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>"User"</strong> refers to any individual or entity accessing or using Librious.com.
          </li>
          <li className={classes.listItem}>
            <strong>"Content"</strong> means any information, text, images, videos, or other materials uploaded, posted, or otherwise made available on Librious.com.
          </li>
          <li className={classes.listItem}>
            <strong>"Account"</strong> refers to the unique account created by a User to access and use Librious.com services.
          </li>
        </ul>

        <div className={classes.sectionTitle}>2. Acceptance of Guidelines</div>
        <p className={classes.paragraph}>
          By accessing and using Librious.com, you agree to comply with and be bound by these Guidelines. We reserve the right to modify these Guidelines at any time. Changes will be effective immediately upon posting. Continued use of our services constitutes acceptance of the revised Guidelines.
        </p>

        <div className={classes.sectionTitle}>3. User Account Management</div>
        <p className={classes.paragraph}>
          To access certain features of Librious.com, you may be required to create an Account. When creating an Account, you must provide accurate, current, and complete information. You are responsible for safeguarding your Account credentials and for all activities that occur under your Account. You agree to notify us immediately of any unauthorized use of your Account.
        </p>

        <div className={classes.sectionTitle}>4. Content Ownership and Licensing</div>
        <p className={classes.paragraph}>
          Users retain ownership of any Content they upload to Librious.com. However, by uploading Content, you grant Librious.com a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, modify, publish, distribute, and display your Content in connection with the Services. You represent and warrant that you have all necessary rights to grant this license and that your Content does not infringe upon the rights of any third party.
        </p>
        <p className={classes.paragraph}>
          All Content provided by Librious.com remains the legal property of Librious.com. Any unauthorized use, reproduction, or distribution of Librious.com Content is strictly prohibited.
        </p>

        <div className={classes.sectionTitle}>5. User Conduct</div>
        <p className={classes.paragraph}>
          Users are expected to behave respectfully and responsibly. Prohibited behaviors include, but are not limited to:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>Harassment, bullying, or abusive behavior towards other users.</li>
          <li className={classes.listItem}>Discrimination based on race, gender, sexual orientation, religion, nationality, or other protected characteristics.</li>
          <li className={classes.listItem}>Sharing or distributing private or sensitive information of others without consent.</li>
          <li className={classes.listItem}>Impersonating another individual or entity.</li>
          <li className={classes.listItem}>Engaging in or promoting illegal activities.</li>
          <li className={classes.listItem}>Spamming or flooding the platform with unsolicited messages.</li>
        </ul>

        <div className={classes.sectionTitle}>6. Prohibited Content</div>
        <p className={classes.paragraph}>
          Users are prohibited from uploading, posting, or sharing Content that:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>Contains malware, viruses, or other harmful code.</li>
          <li className={classes.listItem}>Promotes or depicts illegal activities, including but not limited to drug use, violence, or terrorism.</li>
          <li className={classes.listItem}>Includes hate speech, threats, or any content that encourages harm against individuals or groups.</li>
          <li className={classes.listItem}>Violates intellectual property rights, including copyrights and trademarks.</li>
          <li className={classes.listItem}>Contains explicit sexual content or graphic violence.</li>
          <li className={classes.listItem}>Invades the privacy of others or discloses private information without consent.</li>
          <li className={classes.listItem}>Misleading or deceptive content, including false claims or impersonation.</li>
        </ul>

        <div className={classes.sectionTitle}>7. Reporting Violations</div>
        <p className={classes.paragraph}>
          If you encounter Content or behavior that violates these Guidelines, please report it to us immediately. You can report violations by contacting us at <a href="mailto:support@librious.com" className={classes.link}>support@librious.com</a>. Provide as much detail as possible to help us investigate and address the issue promptly.
        </p>

        <div className={classes.sectionTitle}>8. Content Moderation</div>
        <p className={classes.paragraph}>
          Librious.com reserves the right to moderate, edit, or remove any Content that violates these Guidelines or is deemed inappropriate, without prior notice. We may also suspend or terminate the Accounts of users who repeatedly violate these Guidelines.
        </p>
        <p className={classes.paragraph}>
          We strive to maintain a positive environment for all users, and your cooperation is essential in achieving this goal.
        </p>

        <div className={classes.sectionTitle}>9. Intellectual Property</div>
        <p className={classes.paragraph}>
          Librious.com respects intellectual property rights. If you believe that your work has been copied in a way that constitutes copyright infringement, please contact us immediately with the relevant details. We will investigate and take appropriate action in accordance with applicable laws.
        </p>

        <div className={classes.sectionTitle}>10. Privacy Policy</div>
        <p className={classes.paragraph}>
          We value your privacy. Please review our <a href="/privacy" className={classes.link}>Privacy Policy</a> to understand how we collect, use, and protect your personal information.
        </p>

        <div className={classes.sectionTitle}>11. Limitation of Liability</div>
        <p className={classes.paragraph}>
          Librious.com is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services. This includes, but is not limited to, loss of data, loss of profits, or any other financial loss.
        </p>

        <div className={classes.sectionTitle}>12. Termination of Access</div>
        <p className={classes.paragraph}>
          We reserve the right to terminate or suspend your Account and access to Librious.com immediately, without prior notice or liability, for any reason, including if you breach these Guidelines. Upon termination, your right to use the Services will cease immediately, and all provisions of these Guidelines which by their nature should survive termination will remain in effect.
        </p>

        <div className={classes.sectionTitle}>13. Governing Law and Jurisdiction</div>
        <p className={classes.paragraph}>
          These Guidelines are governed by and construed in accordance with the laws of the State of California, USA, without regard to its conflict of law principles. Any disputes arising out of or relating to these Guidelines shall be resolved in the courts of [Your County], California.
        </p>

        <div className={classes.sectionTitle}>14. Dispute Resolution</div>
        <p className={classes.paragraph}>
          Any disputes arising out of or related to these Guidelines or the use of Librious.com shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in [Your County], California. Judgment on the arbitration award may be entered into any court having jurisdiction thereof.
        </p>

        <div className={classes.sectionTitle}>15. Changes to Guidelines</div>
        <p className={classes.paragraph}>
          We reserve the right to modify these Guidelines at any time. Changes will be effective immediately upon posting on Librious.com. Continued use of our services after such changes constitutes acceptance of the revised Guidelines.
        </p>

        <div className={classes.sectionTitle}>16. Miscellaneous</div>
        <p className={classes.paragraph}>
          These Guidelines constitute the entire agreement between you and Librious.com regarding your use of the Services and supersede all prior agreements, understandings, and representations. If any provision of these Guidelines is held to be invalid or unenforceable, the remaining provisions will continue in full force and effect.
        </p>
        <p className={classes.paragraph}>
          Our failure to enforce any right or provision of these Guidelines will not be considered a waiver of those rights. You may not assign or transfer these Guidelines without our prior written consent.
        </p>

        <div className={classes.sectionTitle}>17. Contact Information</div>
        <p className={classes.paragraph}>
          If you have any questions or need further clarification regarding these Guidelines, please contact us at:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>Email:</strong> <a href="mailto:support@librious.com" className={classes.link}>support@librious.com</a>
          </li>
        </ul>

        <p className={classes.paragraph}>
          Thank you for being a part of the Librious.com community. Your adherence to these Guidelines helps us maintain a respectful and dynamic environment for all users.
        </p>
      </div>
    </div>
  );
};

export default UserGuidelines;
