import React, { useState, useEffect } from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FeedbackList from './addfeedback';
import ContentRequestList from './addcontent';
import AnythingList from './addanything';

const useStyles = makeStyles({
  '@global': {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    'html, body': {
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
  },
});

const FeedbackContentRequest = () => {
  const classes = useStyles();
  const [view, setView] = useState(null);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    if (readifyUserKey) {
      const { username } = JSON.parse(readifyUserKey);
      setUsername(username);
    }
  }, []);

  return (
    <Container 
      sx={{ 
        backgroundColor: 'transparent', 
        padding: '2em', 
        borderRadius: '15px', 
        color: '#fff',
      }}
    >
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        height="90vh"
        textAlign="center"
      >
        <Typography 
          variant="h4" 
          gutterBottom
          sx={{
            backgroundColor: '#004d40', 
            padding: '0.5em 1em', 
            borderRadius: '8px',
            color: '#fff',
            fontWeight: 'bold',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            textTransform: 'none' // Make text not uppercase
          }}
        >
          Polls
        </Typography>
        <Box mb={2}>
          <Button 
            variant="contained" 
            onClick={() => setView('feedback')} 
            size="large"
            sx={{ 
              margin: 1, 
              backgroundColor: view === 'feedback' ? '#fff' : '#004d40',
              color: view === 'feedback' ? '#004d40' : '#fff',
              '&:hover': {
                backgroundColor: view === 'feedback' ? '#e0f7fa' : '#003f38'
              },
              fontWeight: 'bold',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              textTransform: 'none', // Make text not uppercase
            }}
          >
            Feedback
          </Button>
          <Button 
            variant="contained" 
            onClick={() => setView('content-requests')} 
            size="large"
            sx={{ 
              margin: 1, 
              backgroundColor: view === 'content-requests' ? '#fff' : '#004d40',
              color: view === 'content-requests' ? '#004d40' : '#fff',
              '&:hover': {
                backgroundColor: view === 'content-requests' ? '#e0f7fa' : '#003f38'
              },
              fontWeight: 'bold',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              textTransform: 'none', // Make text not uppercase
            }}
          >
            Content Requests
          </Button>
          <Button 
            variant="contained" 
            onClick={() => setView('anything')} 
            size="large"
            sx={{ 
              margin: 1, 
              backgroundColor: view === 'anything' ? '#fff' : '#004d40',
              color: view === 'anything' ? '#004d40' : '#fff',
              '&:hover': {
                backgroundColor: view === 'anything' ? '#e0f7fa' : '#003f38'
              },
              fontWeight: 'bold',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              textTransform: 'none', // Make text not uppercase
            }}
          >
            Anything
          </Button>
        </Box>
        <Box 
          sx={{
            maxHeight: '90vh',
            overflowY: 'auto',
            width: '100%',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',  // IE and Edge
            'scrollbar-width': 'none',  // Firefox
          }}
        >
          {view === 'feedback' && <FeedbackList username={username} />}
          {view === 'content-requests' && <ContentRequestList username={username} />}
          {view === 'anything' && <AnythingList username={username} />}
        </Box>
      </Box>
    </Container>
  );
};

export default FeedbackContentRequest;
