import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import styled from 'styled-components';
import { FaRegImage, FaUser } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MobileChatRoom from './MobileChatRoom'; // Import the MobileChatRoom component

const apiBaseUrl = 'http://localhost:3005/api/chat';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;
`;

const ChatArea = styled.div`
  flex-grow: 1;
  padding: 1vw;
  background-color: #fff;
  color: #2f4f4f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 6.9vw);
  overflow: hidden;
`;

const MessagesContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #f0ead6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  position: relative;
`;

const NewMessageIndicator = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6b8e23;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  display: ${({ show }) => (show ? 'block' : 'none')};

  &:hover {
    background-color: #556b2f;
  }
`;

const Message = styled.div`
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: fit-content;
  background-color: #fff;
  border-radius: 8px;
  color: #004d40;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Georgia', serif;
  font-size: 1.3rem;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 1rem;
  font-size: 1.3rem;
  color: #004d40;
  border: 1px solid #ccc;
  border-radius: 8px 0 0 8px;
`;

const Button = styled.button`
  padding: 1rem;
  background-color: #556b2f;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #6b8e23;
  }
`;

const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const FavoriteButton = styled(Button)`
  background-color: #9caf88;
  color: white;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #7a9562;
  }
`;

const Sidebar = styled.div`
  width: 100%;
  background: none;
  color: #004d40;
  padding: 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const RoomDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;
`;

const RoomTitle = styled.h2`
  margin: 0;
`;

const PeopleCount = styled.div`
  text-align: left;
  color: sagegreen;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  font-size: 24px;
`;

const FavoriteButtonContainer = styled.div`
  margin-left: auto;
`;

const ChatRoom = ({ roomId, name, imageData, description }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
  const [user, setUser] = useState(loggedInUser);
  const [userCount, setUserCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const socketRef = useRef();

  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showNewMessageIndicator, setShowNewMessageIndicator] = useState(false);

  const handleMessageReceive = (message) => {
    setMessages((prevMessages) => {
      const isDuplicate = prevMessages.some(
        (msg) =>
          msg.timestamp === message.timestamp &&
          msg.message === message.message &&
          msg.user === message.user
      );

      if (!isDuplicate) {
        return [...prevMessages, message];
      }

      return prevMessages;
    });
  };

  const handleUpdateUserCount = ({ count }) => {
    setUserCount(count);
  };

  useEffect(() => {
    // Initialize socket only once
    const socketURL = 'http://localhost:3005'; // Updated to match server port
    socketRef.current = io.connect(socketURL, {
      // Optional: Add query parameters if needed
      // query: { isMobile: isMobileDevice() }, // Define isMobileDevice() as needed
      transports: ['websocket'], // Force WebSocket transport to avoid issues with polling
      reconnectionAttempts: 5, // Optional: Set reconnection attempts
      timeout: 10000, // Optional: Set timeout
    });

    // Set up event listeners
    socketRef.current.on('connect', () => {
      console.log('Connected to Socket.IO server');
      socketRef.current.emit('joinRoom', roomId);
    });

    socketRef.current.on('receiveMessage', handleMessageReceive);
    socketRef.current.on('updateUserCount', handleUpdateUserCount);

    socketRef.current.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
    });

    socketRef.current.on('connect_error', (error) => {
      console.error('Connection Error:', error);
    });

    // Clean up on unmount
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);


  useEffect(() => {
    if (roomId) {
      axios
        .get(`${apiBaseUrl}/${roomId}`)
        .then((response) => setMessages(response.data.messages))
        .catch((error) => console.error(error));

      axios
        .get(`${apiBaseUrl}/favorites/${roomId}`, { params: { username: user } })
        .then((response) => setIsFavorite(response.data.isFavorite))
        .catch((error) => console.error(error));
    }
  }, [roomId]);

  useEffect(() => {
    if (roomId) {
      socketRef.current.emit('joinRoom', roomId);

      return () => {
        socketRef.current.emit('leaveRoom', roomId);
      };
    }
  }, [roomId]);

  useEffect(() => {
    const container = messagesContainerRef.current;

    const handleScroll = () => {
      if (container) {
        const isAtBottom =
          container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
        setIsAtBottom(isAtBottom);
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
      // Initialize isAtBottom when component mounts
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    // On component mount, scroll to bottom
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (isAtBottom && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
      setShowNewMessageIndicator(false);
    } else if (!isAtBottom) {
      setShowNewMessageIndicator(true);
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    setShowNewMessageIndicator(false);
  };

  const handleMediaUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }

    const fileType = file.type.split('/')[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setMedia(file);
      setMediaType(fileType.includes('image') ? 'image' : 'video');
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const sendMessage = () => {
    if (newMessage.trim() || media) {
      const messageData = {
        user: user,
        message: newMessage,
        roomId: roomId,
      };

      if (media) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Media = reader.result;

          messageData.mediaData = base64Media;
          messageData.mediaType = mediaType;

          axios
            .post(`${apiBaseUrl}/${roomId}/message`, messageData, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              // Removed socketRef.current.emit('sendMessage', newMessageData);
            })
            .catch((error) => console.error(error));
        };
        reader.readAsDataURL(media);
      } else {
        axios
          .post(`${apiBaseUrl}/${roomId}/message`, messageData, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            // Removed socketRef.current.emit('sendMessage', newMessageData);
          })
          .catch((error) => console.error(error));
      }

      setNewMessage('');
      setMedia(null);
      setMediaType('');
      setImagePreview(null);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const openDialog = (image) => {
    setDialogImage(image);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogImage('');
  };

  const addToFavorites = () => {
    axios
      .post(`${apiBaseUrl}/${roomId}/add-to-favorites`, { username: user })
      .then((response) => {
        setIsFavorite(true);
        console.log(response.data.message);
      })
      .catch((error) => console.error(error));
  };

  // Function to detect if the device is mobile
  const isMobile = () => {
    return window.matchMedia('(max-width: 767px)').matches;
  };

  return isMobile() ? (
    <MobileChatRoom
      roomId={roomId}
      name={name}
      imageData={imageData}
      description={description}
    />
  ) : (
    <Container>
      <Sidebar>
        <MediaContainer>
          {imageData?.imageLink && (
            <img
              src={imageData?.imageLink}
              alt={name}
              style={{ maxWidth: '100px', borderRadius: '8px' }}
            />
          )}
        </MediaContainer>
        <RoomDetails>
          <RoomTitle>{name}</RoomTitle>
        </RoomDetails>
        <PeopleCount>
          <FaUser style={{ marginRight: '0.5rem' }} />
          <p style={{ margin: 0 }}>{userCount}</p>
        </PeopleCount>
        <FavoriteButtonContainer>
          {!isFavorite && (
            <FavoriteButton onClick={addToFavorites}>
              Add to Favorites
            </FavoriteButton>
          )}
        </FavoriteButtonContainer>
      </Sidebar>
      <ChatArea>
        <MessagesContainer ref={messagesContainerRef}>
          {messages.map((msg, index) => (
            <Message key={index}>
              <strong>{msg.user}</strong>: {msg.message}
              {msg.mediaData &&
                msg.mediaData.mediaType === 'image' &&
                msg.mediaData.mediaLink && (
                  <div
                    style={{
                      display: 'inline-block',
                      textAlign: 'center',
                      marginTop: '1rem',
                    }}
                  >
                    <img
                      src={msg.mediaData.mediaLink}
                      alt="Media"
                      style={{
                        maxWidth: '20%',
                        height: 'auto',
                        cursor: 'pointer',
                        display: 'block',
                        margin: '0 auto',
                      }}
                      onClick={() => openDialog(msg.mediaData.mediaLink)}
                    />
                  </div>
                )}
              {msg.mediaData &&
                msg.mediaData.mediaType === 'video' &&
                msg.mediaData.mediaLink && (
                  <div style={{ textAlign: 'center' }}>
                    <video
                      controls
                      style={{ maxWidth: '100%', marginTop: '1rem' }}
                    >
                      <source
                        src={msg.mediaData.mediaLink}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
            </Message>
          ))}
          <div ref={messagesEndRef} />
          <NewMessageIndicator
            show={showNewMessageIndicator}
            onClick={scrollToBottom}
          >
            New Messages
          </NewMessageIndicator>
        </MessagesContainer>
        <InputContainer>
          <Input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Thoughts?"
          />
          <FileInputLabel>
            <FaRegImage />
            <input
              type="file"
              accept="image/*,video/*"
              onChange={handleMediaUpload}
              style={{ display: 'none' }}
            />
          </FileInputLabel>
        </InputContainer>
        {imagePreview && (
          <div>
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: '200px', marginTop: '1rem' }}
            />
          </div>
        )}
      </ChatArea>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            maxWidth: 'none',
            width: '80%',
            height: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent style={{ padding: 0 }}>
          <img
            src={dialogImage}
            alt="Full-size"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ChatRoom;
