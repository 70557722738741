import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

const handleFeedbackButtonContent = (feedbackType) => {
  switch (feedbackType) {
    case 'nice':
      return 'Nice';
    case 'interesting':
      return 'Interesting';
    case 'notSure':
      return (
        <div style={{ lineHeight: 1 }}>
          <div>Not</div>
          <div>Sure</div>
        </div>
      );
    case 'whistle':
      return '🚩';
    default:
      return null;
  }
};

const FeedbackButton = ({ type, count, isSelected, onClick, isSubmittingFeedback }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const buttonText = handleFeedbackButtonContent(type);
  const textLength = buttonText && typeof buttonText === 'string' ? buttonText.length : 0;

  const buttonSize = type === 'interesting'
    ? isMobile ? `${textLength * 9 + 12}px` : `${textLength * 9 + 12}px`
    : isMobile ? '42px' : '42px';

  const fontSize = isMobile ? '16px' : '16px';
  const flagFontSize = isMobile ? '16px' : '16px';

  return (
    <div
      style={{
        display: 'inline-block',
        margin: '3px',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Button
        variant="contained"
        color="secondary" 
        disabled={isSubmittingFeedback}
        onClick={onClick}
        style={{
          marginLeft: '1px',
          textTransform: 'none',
          fontSize: type === 'whistle' ? flagFontSize : fontSize,
          width: buttonSize,
          height: '42px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          backgroundColor: '#004d40',
          lineHeight: type === 'notSure' ? '1.1' : 'normal', // Adjust line height for "Not Sure"
        }}
      >
        {buttonText}
      </Button>
      {count > 0 && (
        <Typography
          variant="body1"
          style={{
            marginTop: '10%',
            background: '#004d40',
            fontSize: '17px',
            fontWeight: 'bold',
            width: '20%', 
            marginLeft: '45%',
            color: '#ffffff',
            textAlign: 'center',
            borderRadius: '12px',
            padding: '2px 0px',
          }}
        >
          {count}
        </Typography>
      )}
    </div>
  );
};

export default FeedbackButton;
