// Frontend: AddedActiveShapePieChart.js (Ensure 'type' is passed)
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { DomainContext } from '../../DomainContext'; // Update this path accordingly
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const AddedActiveShapePieChart = ({ username, onDomainClick }) => {
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const { setSelectedDomain, setActiveExplorer } = useContext(DomainContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/added-entries-graph/${username}`);
        const total = response.data.reduce((acc, cur) => acc + cur.count, 0);
        const percentData = response.data.map(d => ({
          ...d,
          percent: (d.count / total)
        }));
        setData(percentData);
      } catch (error) {
        console.error("Error fetching domain counts:", error);
      }
    };
  
    fetchData();
  }, [username]);

  const onClick = async (_, index) => {
    const domain = data[index]?.domain;
    try {
      const response = await axios.get(`/api/get-domain-ids`, {
        params: { username, domain, type: 'added' }
      });
      const { ids } = response.data;
      setSelectedDomain(domain);
      setActiveExplorer('added');
      if (onDomainClick) {
        onDomainClick(ids);
      }
    } catch (error) {
      console.error("Error fetching domain IDs:", error);
    }
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, fill, percent, payload } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={props.startAngle}
          endAngle={props.endAngle}
          fill={fill}
        />
        <circle cx={cx} cy={cy} r={innerRadius} fill="#f8f8f8" /> 
        <text x={cx} y={cy} dy={-13} textAnchor="middle" fill="#000">
          Added
        </text>
        <text x={cx} y={cy} dy={7} textAnchor="middle" fill={fill}> 
          {`${payload.domain}`}
        </text>
        <text x={cx} y={cy} dy={27} textAnchor="middle" fill="#333"> 
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={420.69}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={99.69}
          outerRadius={160.69}
          fill="#9C9C6E"
          dataKey="count"
          onMouseEnter={onPieEnter}
          onClick={onClick}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color || '#9C9C6E'} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default AddedActiveShapePieChart;
