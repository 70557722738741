import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Chart, PointElement, LineElement, BarElement, ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import {
  Line,
  Pie,
  Bar,
  Doughnut,
  Line as LineChart,
} from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Modal, 
  Paper,
  IconButton,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
Chart.register(PointElement, LineElement, BarElement, ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Arial, sans-serif',
    width: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '100vw',
      marginLeft: '-3.69vw'
    },
  },
  timeframeButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '10%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#2f4f4f',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#004d40',
    },
  },
  searchBar: {
    margin: theme.spacing(2),
    width: '242px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#2f4f4f',
    color: '#000000',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#004d40',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  loadingSpinner: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    color: '#ffffff',
  },
  graphsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '20px',
    background: 'transparent',
  },
  graphCard: {
    width: '300px',
    margin: '20px',
    padding: '10px',
    background: '#ffffff',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      boxShadow: '0 6px 12px 0 rgba(0, 77, 64, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '99%',
      margin: theme.spacing(1, 0),
    },
  },
  modalPaper: {
    position: 'absolute',
    background: '#F0F0F0',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      height: '96.69vh',
      top: 0,
      left: 0,
      transform: 'none',
      borderRadius: 0,
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    overflow: 'auto',
    height: 'calc(100% - 100px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 104px)',
    },
  },
  chartToggleButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
  },
  accordion: {
    width: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  accordionDetails: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  table: {
    minWidth: 650,
  },
  cardHeader: {
    backgroundColor: '#2f4f4f',
    color: '#ffffff',
    padding: theme.spacing(1),
    borderRadius: '4px 4px 0 0',
  },
  cardContent: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  overviewPaper: {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#2f4f4f',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  overviewChart: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(2),
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  filterInput: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const subjects = [
  'Environment',
  'Computers',
  'Physics',
  'Chemistry',
  'Biology',
  'Astronomy',
  'Mathematics',
  'Painting',
  'Sculpting',
  'Literature',
  'Music',
  'Theatre',
  'Architecture',
  'Cinema',
  'DigitalArt',
  'Nature',
  'Fashion',
  'Beauty',
  'Travel',
  'Countries',
  'Parenting',
  'Sports',
  'Health',
  'Cooking',
  'PersonalFinance',
  'Stocks',
  'Crypto',
  'Entertainment',
  'Technology',
  'Gaming',
  'Politics',
  'CurrentEvents',
  'Religion',
  'Home',
  'Volunteering',
  'College',
  'Career',
  'Relationships',
  'Open',
  'Gathered',
  'Education',
  'HumanRights',
  'Ethics',
  'Innovation',
  'Communication',
  'Transportation',
  'Globalization',
  'Family',
  'Aging',
  'Law',
  'Economics',
  'Language',
  'Philosophy',
  'Medicine',
  'Agriculture',
  'Sustainability',
  'Peace',
  'DisasterManagement',
  'Infrastructure',
  'Energy',
  'ArtificialIntelligence',
  'Privacy',
  'Security',
  'SpaceExploration',
  'WaterResources',
  'UrbanDevelopment',
  'CulturalDiversity',
  'MentalHealth',
  'SocialJustice',
  'Photography',
  'Dance',
  'History',
  'Psychology',
  'Sociology',
  'Anthropology',
  'Engineering',
  'Business',
  'Marketing',
  'Finance',
  'Military',
  'Weather',
  'Pets',
  'Comics',
  'Anime',
  'Automotive',
  'Hiking',
  'DIY',
  'Crafts',
  'Fitness',
  'Comedy',
  'Gardening',
  'Podcasts',
  'Memes',
  'Official',
];

const metrics = [
  { displayName: 'Entries', dataKey: 'totalEntries' },
  { displayName: 'Feedback', dataKey: 'totalFeedback' },
  { displayName: 'People', dataKey: 'totalUsers' },
  { displayName: 'Branches', dataKey: 'totalKeys' },
  { displayName: 'Links', dataKey: 'totalLinks' },
  { displayName: 'Ai Models', dataKey: 'totalPromptModels' },
  { displayName: 'Categories', dataKey: 'totalDomains' },
  { displayName: 'Source Types', dataKey: 'totalSourceTypes' },
  { displayName: 'Source Type Names', dataKey: 'totalSourceTypeNames' },
  { displayName: 'Media', dataKey: 'totalMedia' },
  { displayName: 'Ai Text Requests', dataKey: 'totalPromptTexts' },
  { displayName: 'Ai Image Requests', dataKey: 'totalImagePromptTexts' },
];

const feedbackMetrics = [
  { displayName: 'Nice', dataKey: 'niceFeedback' },
  { displayName: 'Interesting', dataKey: 'interestingFeedback' },
  { displayName: 'Not Sure', dataKey: 'notSureFeedback' },
  { displayName: 'Red Flags', dataKey: 'whistleFeedback' },
];

const Insights = () => {
  const [timeframe, setTimeframe] = useState('24h');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [chartType, setChartType] = useState('Bar');
  const [metricData, setMetricData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState(subjects);
  const [loading, setLoading] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState(
    metrics.map((metric) => metric.dataKey) 
  );
  const classes = useStyles();
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [filters, setFilters] = useState({});
  // Update filtered subjects based on search query
  useEffect(() => {
    const filtered = subjects.filter((subject) =>
      subject.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredSubjects(filtered);
  }, [searchQuery]);

  // Fetch data when timeframe changes
  useEffect(() => {
    const fetchAllMetrics = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/get-graphs', {
          params: {
            timeframe,
          },
        });
        setMetricData(response.data);
      } catch (error) {
        console.error('Error fetching all metrics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllMetrics();
  }, [timeframe]);

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
  };

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedSubject(null);
    setSelectedTab(0);
    setSelectedMetrics(metrics.map((metric) => metric.dataKey));
  };

  const handleChartTypeChange = (type) => {
    setChartType(type);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleMetricSelection = (event) => {
    const { name, checked } = event.target;
    setSelectedMetrics((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((metric) => metric !== name)
    );
  };

  const renderChart = (subject) => {
    if (!metricData[subject]) return null;
  
    const filteredMetrics = metrics.filter((metric) =>
      selectedMetrics.includes(metric.dataKey)
    );
  
    const data = {
      labels: filteredMetrics.map((metric) => metric.displayName),
      datasets: [
        {
          label: `${subject}`,
          data: filteredMetrics.map(
            (metric) => metricData[subject][metric.dataKey] || 0
          ),
          backgroundColor: 'rgba(47, 79, 79, 0.8)',
          borderColor: '#000000',
          fill: true,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false, // Allows the chart to fill the container height
      plugins: {
        legend: { position: 'top' },
        tooltip: { enabled: true },
      },
      interaction: { mode: 'nearest', axis: 'x', intersect: false },
      scales: {
        x: { title: { display: true, text: '' } },
        y: {
          title: { display: true, text: '' },
          beginAtZero: true,
        },
      },
    };
  
    const chartContainerStyle = {
      height: '60vh', // Sets a moderate height for most screen sizes
      maxHeight: '80vh', // Ensures it doesn't exceed 80% of the viewport height on larger screens
      width: '100%', // Makes it responsive to different screen widths
      overflow: 'hidden', // Prevents excess scrolling within the chart container
      '@media (max-width: 600px)': { // Mobile adjustments
        height: '50vh', // Reduces height on smaller screens
      },
      '@media (min-width: 1200px)': { // Large monitor adjustments
        height: '70vh', // Increases height on larger monitors
      },
    };
  
    switch (chartType) {
      case 'Line':
        return <div style={chartContainerStyle}><Line data={data} options={options} /></div>;
      case 'Bar':
        return <div style={chartContainerStyle}><Bar data={data} options={options} /></div>;
      case 'Pie':
        return <div style={chartContainerStyle}><Pie data={data} options={options} /></div>;
      case 'Doughnut':
        return <div style={chartContainerStyle}><Doughnut data={data} options={options} /></div>;
      default:
        return <div style={chartContainerStyle}><Bar data={data} options={options} /></div>;
    }
  };

  const renderTabContent = () => {
    if (!metricData[selectedSubject]) return null;

    if (selectedTab === 0) {
      // Overview Tab
      const overviewMetrics = [
        {
          displayName: 'Entries',
          value: metricData[selectedSubject].totalEntries || 0,
        },
        {
          displayName: 'Feedback',
          value: metricData[selectedSubject].totalFeedback || 0,
        },
        {
          displayName: 'People',
          value: metricData[selectedSubject].totalUsers || 0,
        },
      ];

      return (
        <Grid container spacing={2}>
          {overviewMetrics.map((metric, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Paper className={classes.overviewPaper} elevation={3}>
                <Typography variant="h6">{metric.displayName}</Typography>
                <Typography variant="h4">{metric.value}</Typography>
              </Paper>
            </Grid>
          ))}
          <Grid item xs={12}>
            <div className={classes.overviewChart}>
            <LineChart
  data={{
    labels: metrics.map((metric) => metric.displayName),
    datasets: [
      {
        label: '',
        data: metrics.map(
          (metric) => metricData[selectedSubject][metric.dataKey] || 0
        ),
        borderColor: '#000000',
        backgroundColor: 'rgba(47, 79, 79, 0.5)',
        fill: true,
      },
    ],
  }}
  options={{
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to adapt to container height
    scales: {
      x: { display: true, title: { display: true, text: '' } },
      y: {
        display: true,
        title: { display: true, text: '' },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: { display: false }, // Hides legend if not needed
      tooltip: { enabled: true },
    },
  }}
  style={{
    height: '60vh', // Sets the height for moderate screens
    maxHeight: '80vh', // Ensures it doesn’t exceed 80% of viewport on large screens
    width: '100%', // Full width for responsive layout
    '@media (max-width: 600px)': { // Adjustments for mobile
      height: '50vh',
    },
    '@media (min-width: 1200px)': { // Adjustments for larger monitors
      height: '70vh',
    },
  }}
/>
            </div>
          </Grid>
        </Grid>
      );
    } else if (selectedTab === 1) {
      // Charts Tab
      return (
        <div>
          {/* Chart Type Selection */}
          <div className={classes.chartToggleButtons}>
          <Button onClick={() => handleChartTypeChange('Bar')} style={{ textTransform: 'none' }}>
  Bar
</Button>
<Button onClick={() => handleChartTypeChange('Line')} style={{ textTransform: 'none' }}>
  Line
</Button>
<Button onClick={() => handleChartTypeChange('Pie')} style={{ textTransform: 'none' }}>
  Pie
</Button>
<Button onClick={() => handleChartTypeChange('Doughnut')} style={{ textTransform: 'none' }}>
  Doughnut
</Button>
          </div>
          {/* Metric Selection */}
          <div className={classes.filterContainer}>
            <FormGroup row>
              {metrics.map((metric) => (
                <FormControlLabel
                  key={metric.dataKey}
                  control={
                    <Checkbox
                      checked={selectedMetrics.includes(metric.dataKey)}
                      onChange={handleMetricSelection}
                      name={metric.dataKey}
                    />
                  }
                  label={metric.displayName}
                />
              ))}
            </FormGroup>
          </div>
          {renderChart(selectedSubject)}
        </div>
      );
    } else if (selectedTab === 2) {
      // Values Tab with Table
      const rows = metrics.map((metric) => ({
        metric: metric.displayName,
        value: metricData[selectedSubject][metric.dataKey] || 0,
      }));

      return (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Metric</b>
              </TableCell>
              <TableCell align="right">
                <b>Value</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.metric}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else if (selectedTab === 3) {
      // Details Tab with Tables
      const details = [
        {
          title: 'People',
          data: metricData[selectedSubject]?.users || [],
          columns: [{ label: 'Name', dataKey: 'name' }],
        },
        {
          title: 'Keys',
          data: metricData[selectedSubject]?.keys || [],
          columns: [{ label: 'Key', dataKey: 'key' }],
        },
        {
          title: 'Links',
          data: metricData[selectedSubject]?.links || [],
          columns: [{ label: 'Link', dataKey: 'link' }],
        },
        {
          title: 'Categories',
          data: metricData[selectedSubject]?.domains || [],
          columns: [{ label: 'Categories', dataKey: 'domain' }],
        },
        {
          title: 'Origin Names',
          data: metricData[selectedSubject]?.sourceTypeNames || [],
          columns: [{ label: 'Origin Names', dataKey: 'name' }],
        },
        {
          title: 'Origin',
          data: metricData[selectedSubject]?.sourceTypes || [],
          columns: [{ label: 'Origin', dataKey: 'type' }],
        },
        {
          title: 'Ai Models',
          data: metricData[selectedSubject]?.promptModels || [],
          columns: [{ label: 'Ai Model', dataKey: 'model' }],
        },
        {
          title: 'Ai Text Requests',
          data: metricData[selectedSubject]?.promptTexts || [],
          columns: [{ label: 'Ai Text Requests', dataKey: 'text' }],
        },
        {
          title: 'Ai Image Requests',
          data: metricData[selectedSubject]?.imagePromptTexts || [],
          columns: [{ label: 'Ai Image Requests', dataKey: 'text' }],
        },
      ];
    
      const handleFilterChange = (dataKey, value) => {
        setFilters((prev) => ({
          ...prev,
          [dataKey]: value,
        }));
      };
    
      const getFilteredData = () => {
        if (!selectedDetail) return [];
        return selectedDetail.data.filter((item) =>
          selectedDetail.columns.every((column) => {
            const filterValue = filters[column.dataKey];
            if (!filterValue) return true;
            return String(item[column.dataKey]).toLowerCase().includes(filterValue.toLowerCase());
          })
        );
      };
    
      return (
        <div>
          <div className={classes.buttonContainer}>
            {details.map((detail, index) => (
              <Button
                key={index}
                variant={selectedDetail === detail ? 'contained' : 'outlined'}
                color="primary"
                className={classes.button}
                onClick={() => {
                  setSelectedDetail(detail);
                  setFilters({});
                }}
              >
                {detail.title} ({detail.data.length})
              </Button>
            ))}
          </div>
    
          {selectedDetail && (
            <div className={classes.tableContainer}>
              <Typography variant="h6">
                {selectedDetail.title} ({selectedDetail.data.length})
              </Typography>
              {selectedDetail.columns.map((column) => (
                <TextField
                  key={column.dataKey}
                  label={`Filter ${column.label}`}
                  variant="outlined"
                  size="small"
                  className={classes.filterInput}
                  value={filters[column.dataKey] || ''}
                  onChange={(e) => handleFilterChange(column.dataKey, e.target.value)}
                />
              ))}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {selectedDetail.columns.map((column, idx) => (
                      <TableCell key={idx}>
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredData().map((item, idx) => (
                    <TableRow key={idx}>
                      {selectedDetail.columns.map((column, colIdx) => (
                        <TableCell key={colIdx}>
                          {item[column.dataKey] || item}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography variant="body2" style={{ marginTop: '0.5rem' }}>
                Total: {getFilteredData().length}
              </Typography>
            </div>
          )}
        </div>
      );
    } else if (selectedTab === 4) {
      // Feedback Tab
      const data = {
        labels: feedbackMetrics.map((metric) => metric.displayName),
        datasets: [
          {
            label: '',
            data: feedbackMetrics.map(
              (metric) => metricData[selectedSubject][metric.dataKey] || 0
            ),
            backgroundColor: ['#2f4f4f', '#556b2f', '#004d40', '#40e0d0'],
            hoverOffset: 4,
          },
        ],
      };

      return <Doughnut data={data} />;
    }
  };

  return (
    <div className={classes.root}>
      {/* Timeframe Buttons */}
      <div className={classes.timeframeButtons}>
        <Button className={classes.button} onClick={() => handleTimeframeChange('24h')}>
          Last 24 Hours
        </Button>
        <Button className={classes.button} onClick={() => handleTimeframeChange('1w')}>
          Last Week
        </Button>
        <Button className={classes.button} onClick={() => handleTimeframeChange('1m')}>
          Last Month
        </Button>
        <Button className={classes.button} onClick={() => handleTimeframeChange('6m')}>
          Last Six Months
        </Button>
        <Button className={classes.button} onClick={() => handleTimeframeChange('all')}>
          All
        </Button>
      </div>

      {/* Loading Spinner */}
      {loading && <CircularProgress className={classes.loadingSpinner} />}

      {/* Graphs Container */}
      <div className={classes.graphsContainer}>
        {!loading &&
          filteredSubjects.map((subject) => (
            <Card
              key={subject}
              className={classes.graphCard}
              onClick={() => handleSubjectClick(subject)}
            >
              <div className={classes.cardHeader}>
              <Typography variant="h6">
  {subject.replace(/([a-z])([A-Z])/g, '$1 $2')}
</Typography>
              </div>
              <CardContent className={classes.cardContent}>
                {metricData[subject] ? (
                  <div>
                    <LineChart
                      data={{
                        labels: ['Entries', 'Feedback', 'Users'],
                        datasets: [
                          {
                            label: subject,
                            data: [
                              metricData[subject].totalEntries || 0,
                              metricData[subject].totalFeedback || 0,
                              metricData[subject].totalUsers || 0,
                            ],
                            borderColor: '#000000',
                            backgroundColor: 'rgba(47, 79, 79, 0.5)',
                            fill: true,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        scales: {
                          x: { display: false },
                          y: { display: false, beginAtZero: true },
                        },
                        plugins: {
                          legend: { display: false },
                          tooltip: { enabled: false },
                        },
                      }}
                      height={100}
                    />
                    <Table size="small"> 
                      <TableBody>
                        <TableRow>
                          <TableCell>Entries</TableCell>
                          <TableCell align="right">
                            {metricData[subject].totalEntries || 0}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Feedback</TableCell>
                          <TableCell align="right">
                            {metricData[subject].totalFeedback || 0}
                          </TableCell>
                          </TableRow>
                        <TableRow>
                          <TableCell>People</TableCell>
                          <TableCell align="right">
                            {metricData[subject].totalUsers || 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <Typography variant="body2">No data available</Typography>
                )}
              </CardContent>
            </Card>
          ))}
      </div>

      {/* Modal for Detailed View */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Paper className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <Typography variant="h5">{selectedSubject}</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Overview" style={{ textTransform: 'none', color: 'black' }} />
<Tab label="Charts" style={{ textTransform: 'none', color: 'black' }} />
<Tab label="Values" style={{ textTransform: 'none', color: 'black' }} />
<Tab label="Details" style={{ textTransform: 'none', color: 'black' }} />
<Tab label="Feedback" style={{ textTransform: 'none', color: 'black' }} />
          </Tabs>
          <div className={classes.modalContent}>{renderTabContent()}</div>
        </Paper>
      </Modal>
    </div>
  );
};

export default Insights;
