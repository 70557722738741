// src/components/AdminPanel.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Define the color palette
const colors = {
  darkSlateGray: '#2f4f4f',
  oliveDrab: '#556b2f',
  white: '#ffffff',
  tealDark: '#004d40',
  tiffanyBlue: '#0abab5',
};

// Retrieve the user data from localStorage
const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [isOfficial, setIsOfficial] = useState(false);
  const [label, setLabel] = useState('');

  // Fetch all users when the component mounts, only if the logged-in user is "Love"
  useEffect(() => {
    if (loggedInUser === 'Love') {
      axios
        .get('/api/lovepanel', { withCredentials: true }) // Include credentials for session-based auth
        .then((response) => setUsers(response.data))
        .catch((error) => {
          console.error('Error fetching users:', error);
          alert('Failed to fetch users.');
        });
    }
  }, [loggedInUser]);

  // Handle selection of users
  const handleSelectUser = (username) => {
    setSelectedUsernames((prevSelected) =>
      prevSelected.includes(username)
        ? prevSelected.filter((u) => u !== username)
        : [...prevSelected, username]
    );
  };

  const handleSelectAll = () => {
    if (selectedUsernames.length === users.length) {
      setSelectedUsernames([]);
    } else {
      setSelectedUsernames(users.map((user) => user.username));
    }
  };

  // Handle setting isOfficial
  const handleSetOfficial = () => {
    if (selectedUsernames.length === 0) {
      alert('Please select at least one user.');
      return;
    }

    axios
      .post(
        `/api/lovepanel/set-official`,
        { usernames: selectedUsernames, isOfficial },
        { withCredentials: true }
      )
      .then((response) => {
        alert('User official status updated successfully.');
        // Update the local state to reflect changes
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            selectedUsernames.includes(user.username)
              ? { ...user, isOfficial: isOfficial }
              : user
          )
        );
        setSelectedUsernames([]);
        setIsOfficial(false);
      })
      .catch((error) => {
        console.error('Error updating official status:', error);
        alert('Failed to update user official status.');
      });
  };

  // Handle setting label
  const handleSetLabel = () => {
    if (selectedUsernames.length === 0) {
      alert('Please select at least one user.');
      return;
    }

    if (label.trim() === '') {
      alert('Please enter a label.');
      return;
    }

    axios
      .post(
        `/api/lovepanel/set-label`,
        { usernames: selectedUsernames, label },
        { withCredentials: true }
      )
      .then((response) => {
        alert('User labels updated successfully.');
        // Update the local state to reflect changes
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            selectedUsernames.includes(user.username)
              ? { ...user, label: label }
              : user
          )
        );
        setSelectedUsernames([]);
        setLabel('');
      })
      .catch((error) => {
        console.error('Error updating label:', error);
        alert('Failed to update user labels.');
      });
  };

  // Ensure only "Love" can access this component
  if (loggedInUser !== 'Love') {
    return (
      <div style={styles.container}>
        <p style={styles.accessDenied}>Access Denied. You do not have permission to view this page.</p>
      </div>
    );
  }

  // If the user data is missing
  if (loggedInUser === null) {
    return (
      <div style={styles.container}>
        <p style={styles.loginPrompt}>Please log in to access the Admin Panel.</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Admin Panel</h1>

      <section style={styles.section}>
        <h2 style={styles.subHeader}>Manage Users</h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>
                <input
                  type="checkbox"
                  checked={selectedUsernames.length === users.length && users.length > 0}
                  onChange={handleSelectAll}
                />
              </th>
              <th style={styles.th}>Username</th>
              <th style={styles.th}>Is Official</th>
              <th style={styles.th}>Label</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.username} style={styles.tr}>
                <td style={styles.td}>
                  <input
                    type="checkbox"
                    checked={selectedUsernames.includes(user.username)}
                    onChange={() => handleSelectUser(user.username)}
                  />
                </td>
                <td style={styles.td}>{user.username}</td>
                <td style={styles.td}>{user.isOfficial ? 'Yes' : 'No'}</td>
                <td style={styles.td}>{user.label || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subHeader}>Bulk Operations</h2>

        <div style={styles.operationContainer}>
          <div style={styles.operation}>
            <h3 style={styles.operationHeader}>Set Official Status</h3>
            <label style={styles.label}>
              Is Official:
              <input
                type="checkbox"
                checked={isOfficial}
                onChange={(e) => setIsOfficial(e.target.checked)}
                style={styles.checkbox}
              />
            </label>
            <button onClick={handleSetOfficial} style={styles.button}>
              Update Official Status
            </button>
          </div>

          <div style={styles.operation}>
            <h3 style={styles.operationHeader}>Set Label</h3>
            <input
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              placeholder="Enter label"
              style={styles.input}
            />
            <button onClick={handleSetLabel} style={styles.button}>
              Update Label
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

// CSS-in-JS styles
const styles = {
  container: {
    padding: '20px',
    backgroundColor: colors.darkSlateGray,
    minHeight: '100vh',
    color: colors.white,
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    textAlign: 'center',
    color: colors.tiffanyBlue,
    marginBottom: '40px',
  },
  subHeader: {
    color: colors.oliveDrab,
    marginBottom: '20px',
  },
  accessDenied: {
    color: colors.white,
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '50px',
  },
  loginPrompt: {
    color: colors.white,
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '50px',
  },
  section: {
    marginBottom: '40px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: colors.tealDark,
  },
  th: {
    padding: '12px',
    border: `1px solid ${colors.oliveDrab}`,
    textAlign: 'left',
    backgroundColor: colors.oliveDrab,
  },
  tr: {
    borderBottom: `1px solid ${colors.oliveDrab}`,
  },
  td: {
    padding: '12px',
    border: `1px solid ${colors.oliveDrab}`,
  },
  operationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '40px',
  },
  operation: {
    flex: '1',
    minWidth: '250px',
    backgroundColor: colors.darkSlateGray,
    padding: '20px',
    borderRadius: '8px',
    border: `1px solid ${colors.oliveDrab}`,
  },
  operationHeader: {
    color: colors.tiffanyBlue,
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '10px',
    color: colors.white,
  },
  checkbox: {
    marginLeft: '10px',
    transform: 'scale(1.2)',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: `1px solid ${colors.oliveDrab}`,
  },
  button: {
    padding: '10px 20px',
    backgroundColor: colors.tiffanyBlue,
    color: colors.darkSlateGray,
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
};

export default AdminPanel;
