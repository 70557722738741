import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
    color: '#333',
  },
  content: {
    backgroundColor: 'white',
    padding: '60px 30px',
    borderRadius: 10,
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.1)',
    maxWidth: 1000,
    width: '100%',
    textAlign: 'left',
    margin: '30px auto',
    lineHeight: 1.8,
    fontSize: '1rem',
  },
  title: {
    fontSize: '3em',
    marginBottom: 40,
    fontWeight: 800,
    color: '#222',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: 25,
    textAlign: 'justify',
  },
  sectionTitle: {
    fontSize: '2em',
    marginTop: 50,
    marginBottom: 25,
    fontWeight: 700,
    color: '#444',
  },
  subSectionTitle: {
    fontSize: '1.6em',
    marginTop: 30,
    marginBottom: 20,
    fontWeight: 600,
    color: '#555',
  },
  list: {
    margin: '15px 0',
    paddingLeft: '25px',
  },
  listItem: {
    marginBottom: 15,
  },
  link: {
    color: '#1a0dab',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const TermsOfService = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h1 className={classes.title}>Terms of Service</h1>
        <p className={classes.paragraph}>
          <strong>Effective Date:</strong> November 11 2024
        </p>
        <p className={classes.paragraph}>
          Welcome to Librious LLC ("Librious," "we," "us," or "our"). By accessing or using our website, applications, and services (collectively, the "Services"), you agree to comply with and be bound by these Terms of Service ("Terms"). These Terms govern your use of the Services and establish the legal relationship between you and Librious LLC. If you do not agree to these Terms, you must not use our Services.
        </p>

        <div className={classes.sectionTitle}>1. Definitions</div>
        <p className={classes.paragraph}>
          For the purposes of these Terms:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <strong>"Account"</strong> means a unique account created for You to access our Services.
          </li>
          <li className={classes.listItem}>
            <strong>"Content"</strong> means any information, text, graphics, or other materials uploaded, downloaded, or appearing on the Services.
          </li>
          <li className={classes.listItem}>
            <strong>"User"</strong> means any individual or entity accessing or using the Services.
          </li>
          <li className={classes.listItem}>
            <strong>"Services"</strong> refers to all offerings provided by Librious LLC, including but not limited to websites, applications, APIs, and associated tools.
          </li>
          <li className={classes.listItem}>
            <strong>"Personal Data"</strong> refers to any information relating to an identified or identifiable natural person, as defined by applicable data protection laws such as GDPR and CCPA.
          </li>
        </ul>

        <div className={classes.sectionTitle}>2. Eligibility</div>
        <p className={classes.paragraph}>
          You must be at least 13 years old to use our Services. By agreeing to these Terms, you represent and warrant that you have the right, authority, and capacity to enter into this agreement and to abide by all its terms.
        </p>

        <div className={classes.sectionTitle}>3. Account Registration</div>
        <p className={classes.paragraph}>
          To access certain features of the Services, you may be required to register for an Account. When you register, you must provide accurate, current, and complete information. You are responsible for safeguarding your Account credentials and for all activities that occur under your Account. You agree to notify us immediately of any unauthorized use of your Account.
        </p>

        <div className={classes.sectionTitle}>4. Privacy and Data Protection</div>
        <p className={classes.paragraph}>
          Our <a href="/privacy" className={classes.link}>Privacy Policy</a> describes how we handle your Personal Data. By using our Services, you consent to the collection, use, and disclosure of your Personal Data in accordance with the Privacy Policy.
        </p>

        <div className={classes.subSectionTitle}>4.1 GDPR Compliance</div>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            We process Personal Data in accordance with the General Data Protection Regulation (GDPR). This includes ensuring data is processed lawfully, transparently, and for specified purposes.
          </li>
          <li className={classes.listItem}>
            You have rights regarding your Personal Data, including access, rectification, erasure, and portability. To exercise these rights, please contact us at <a href="mailto:privacy@librious.com" className={classes.link}>privacy@librious.com</a>.
          </li>
          <li className={classes.listItem}>
            Data subjects have the right to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.
          </li>
        </ul>

        <div className={classes.subSectionTitle}>4.2 CCPA Compliance</div>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            California residents have the right to request disclosure of their Personal Data, deletion of their Personal Data, and to opt-out of the sale of their Personal Data.
          </li>
          <li className={classes.listItem}>
            We do not sell Personal Data. If you believe your rights under the CCPA have been violated, please contact us at <a href="mailto:privacy@librious.com" className={classes.link}>privacy@librious.com</a>.
          </li>
        </ul>

        <div className={classes.sectionTitle}>5. User Content</div>
        <p className={classes.paragraph}>
          You retain ownership of any Content you submit to the Services. By submitting Content, you grant Librious LLC a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, modify, publish, distribute, and display your Content in connection with the Services.
        </p>
        <p className={classes.paragraph}>
          You represent and warrant that you have all necessary rights to grant the above license and that your Content does not infringe or violate the rights of any third party.
        </p>

        <div className={classes.sectionTitle}>6. Prohibited Activities</div>
        <p className={classes.paragraph}>
          You agree not to engage in any of the following prohibited activities:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>Violation of any applicable laws or regulations.</li>
          <li className={classes.listItem}>Infringing upon the intellectual property rights of others.</li>
          <li className={classes.listItem}>Uploading or distributing viruses, malware, or other harmful software.</li>
          <li className={classes.listItem}>Engaging in fraudulent activities or misrepresenting your identity.</li>
          <li className={classes.listItem}>Interfering with the security or integrity of the Services.</li>
          <li className={classes.listItem}>Using the Services to transmit unsolicited communications or advertisements.</li>
        </ul>

        <div className={classes.sectionTitle}>7. Intellectual Property</div>
        <p className={classes.paragraph}>
          All intellectual property rights in the Services, including but not limited to copyrights, trademarks, patents, and trade secrets, are owned by or licensed to Librious LLC. You are granted a limited, non-exclusive, non-transferable, revocable license to access and use the Services for your personal or internal business purposes, subject to these Terms.
        </p>
        <p className={classes.paragraph}>
          You may not reproduce, modify, distribute, create derivative works of, publicly display, or otherwise exploit any part of the Services without our prior written consent.
        </p>

        <div className={classes.sectionTitle}>8. Payment Terms</div>
        <p className={classes.paragraph}>
          [Insert Payment Terms Here: Details about pricing, billing cycles, refunds, late payments, etc., if applicable.]
        </p>

        <div className={classes.sectionTitle}>9. Termination</div>
        <p className={classes.paragraph}>
          We reserve the right to terminate or suspend your Account and access to the Services immediately, without prior notice or liability, for any reason, including if you breach these Terms.
        </p>
        <p className={classes.paragraph}>
          Upon termination, your right to use the Services will immediately cease. All provisions of the Terms which by their nature should survive termination shall survive, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </p>

        <div className={classes.sectionTitle}>10. Indemnification</div>
        <p className={classes.paragraph}>
          You agree to indemnify, defend, and hold harmless Librious LLC and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Services, your violation of these Terms, or your infringement of any intellectual property or other rights of any person or entity.
        </p>

        <div className={classes.sectionTitle}>11. Disclaimers</div>
        <p className={classes.paragraph}>
          The Services are provided on an "AS IS" and "AS AVAILABLE" basis. Librious LLC disclaims all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </p>
        <p className={classes.paragraph}>
          We do not warrant that the Services will be uninterrupted, error-free, secure, or free from viruses or other harmful components. You are responsible for implementing appropriate safeguards to protect your devices and data.
        </p>

        <div className={classes.sectionTitle}>12. Limitation of Liability</div>
        <p className={classes.paragraph}>
          To the fullest extent permitted by applicable law, Librious LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, arising out of or related to your use of the Services, even if we have been advised of the possibility of such damages.
        </p>
        <p className={classes.paragraph}>
          In no event shall our total liability to you for all claims arising out of or relating to these Terms or your use of the Services exceed the amount you have paid us in the twelve (12) months preceding the claim.
        </p>

        <div className={classes.sectionTitle}>13. Governing Law and Jurisdiction</div>
        <p className={classes.paragraph}>
          These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles. You agree to submit to the personal jurisdiction of the state and federal courts located in [Your County], California.
        </p>

        <div className={classes.sectionTitle}>14. Dispute Resolution</div>
        <p className={classes.paragraph}>
          Any disputes arising out of or related to these Terms or the Services shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in [Your County], California. Judgment on the arbitration award may be entered into any court having jurisdiction thereof.
        </p>

        <div className={classes.sectionTitle}>15. Changes to Terms</div>
        <p className={classes.paragraph}>
          We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least thirty (30) days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use the Services after the revisions become effective, you agree to be bound by the revised Terms.
        </p>

        <div className={classes.sectionTitle}>16. Miscellaneous</div>
        <p className={classes.paragraph}>
          These Terms constitute the entire agreement between you and Librious LLC regarding the use of the Services and supersede all prior agreements, understandings, and representations. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will continue in full force and effect.
        </p>
        <p className={classes.paragraph}>
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. You may not assign or transfer these Terms without our prior written consent.
        </p>

        <div className={classes.sectionTitle}>17. Contact Us</div>
        <p className={classes.paragraph}>
          If you have any questions about these Terms, please contact us at <a href="mailto:support@librious.com" className={classes.link}>support@librious.com</a>.
        </p>

        <p className={classes.paragraph}>
          Thank you for choosing Librious LLC. We are committed to providing you with a secure and innovative platform.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
