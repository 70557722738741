// src/components/Pathways/PathwayGrid.js

import React from 'react';
import { Grid } from '@mui/material';
import PathwayCard from './PathwayCard';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: '20px',
    width: '100vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width:600px)': {
      width: '100vw',
    },
  },
}));


const PathwayGrid = ({ pathways }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      {pathways.map((pathway) => (
        <Grid item xs={12} sm={6} md={4} key={pathway._id}>
          <PathwayCard pathway={pathway} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PathwayGrid;
