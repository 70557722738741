
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    sentimentContainer: {
      zIndex: 1500, // Adjust z-index to be higher than the dialog
      position: 'fixed',
      right: 0,
      top: 0,
      width: '300px',
      height: '100%',
      overflowY: 'auto',
      backgroundColor: '#f0f0f0',
      padding: '10px',
      boxSizing: 'border-box',
    },
    dialogPaper: {
      zIndex: 1500, // Set z-index for the dialog
      // Add any additional styles you may need for the dialog here
    },
    // other styles
  });

  const SentimentManager = ({ loggedInUser, postId, tag}) => {
    const classes = useStyles();
    const [sentiments, setSentiments] = useState([]);
    const [newSentiment, setNewSentiment] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dialogueOpen, setDialogueOpen] = useState(true);
  
    useEffect(() => {
      const fetchSentiments = async () => {
        setIsLoading(true);
        setError(null);
        try {
          const response = await fetch(`/api/sentiments/${postId}`);
          if (response.ok) {
            const data = await response.json();
            setSentiments(data.sentiments || []);
          } else {
            throw new Error('Failed to fetch sentiments');
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };
      fetchSentiments();
    }, [postId]);
  
    const handleAddSentiment = async (event) => {
      event.preventDefault();
      try {
        const response = await fetch(`/api/sentiments/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            postId,
            sentiment: newSentiment,
            username: loggedInUser,
            tag
          }),
        });
        if (response.ok) {
          setSentiments(prev => [...prev, newSentiment]);
          setNewSentiment('');
        } else {
          throw new Error('Failed to add sentiment');
        }
      } catch (error) {
        setError(error.message);
      }
    };
  
    if (isLoading) return <p>Loading sentiments...</p>;
    if (error) return <p>Error: {error}</p>;
    console.log("Is Dialog Open:", dialogueOpen); // Add this line to check the dialog open state

    return (
      <Dialog open={dialogueOpen}
      classes={{ paper: classes.dialogPaper }} // Apply custom class to Dialog
    >
        <DialogTitle>Sentiments</DialogTitle>
        <DialogContent>
          <div>
            {sentiments.map((sentiment, index) => (
              <div key={index}>{sentiment}</div>
            ))}
          </div>
          <form onSubmit={handleAddSentiment}>
            <input
              onInput={(e) => setNewSentiment(e.currentTarget.textContent)}
              style={{ border: '1px solid gray', minHeight: '20px', padding: '5px', marginTop: '10px' }}
            ></input>
            <Button type="submit" color="primary" style={{ marginTop: '10px' }}>
              Add Sentiment
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default SentimentManager;