import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, CardActions, CardContent, List, ListItem, TextField, Typography, Container, Box, CircularProgress, makeStyles, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    backgroundColor: 'transparent',
    padding: '2em',
    borderRadius: '15px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    color: '#fff',
  },
  formBox: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#004d40',
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: theme.shadows[2],
    color: '#fff',
  },
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
    border: `1px solid #004d40`,
    borderRadius: '10px',
    boxShadow: theme.shadows[2],
    transition: 'transform 0.2s ease-in-out',
    backgroundColor: '#004d40',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  cardContent: {
    backgroundColor: 'transparent',
    color: '#fff',
  },
  cardActions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  responseButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#004d40',
    color: '#fff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#00332f',
    },
  },
  feedbackText: {
    whiteSpace: 'pre-line',
    color: '#fff',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#004d40',
    color: '#fff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#00332f',
    },
  },
  feedbackDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    color: '#fff',
  },
  voteCounts: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#004d40',
    padding: theme.spacing(1),
    borderRadius: '10px',
    boxShadow: theme.shadows[1],
    color: '#fff',
  },
  voteCountText: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
    color: '#fff',
  },
  loading: {
    margin: 'auto',
    display: 'block',
    color: '#fff',
  },
  filterBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#004d40',
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: theme.shadows[2],
    color: '#fff',
  },
  searchField: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#004d40',
    borderRadius: '10px',
    color: '#fff',
    '& .MuiInputBase-root': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#fff',
    },
  },
  title: {
    backgroundColor: '#004d40',
    color: '#fff',
    padding: theme.spacing(2),
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
}));

const AnythingList = ({ username }) => {
  const classes = useStyles();
  const [feedbacks, setFeedbacks] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [newFeedback, setNewFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOption, setFilterOption] = useState('all');

  useEffect(() => {
    fetchFeedbacks();
  }, [username]);

  useEffect(() => {
    filterFeedbacks();
  }, [feedbacks, searchTerm, filterOption]);

  const fetchFeedbacks = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get('/api/anything-poll');
      if (Array.isArray(data)) {
        const enrichedFeedbacks = await Promise.all(data.map(feedback => enrichFeedback(feedback)));
        setFeedbacks(enrichedFeedbacks);
      } else {
        console.error('Fetched data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
    } finally {
      setLoading(false);
    }
  };

  const enrichFeedback = async (feedback) => {
    try {
      const response = await axios.get(`/api/check-anything-poll/${feedback._id}/response?username=${username}`);
      const votes = await axios.get(`/api/anything-poll/${feedback._id}/votes`);
      return { ...feedback, userResponse: response.data.response, votes: votes.data };
    } catch (error) {
      console.error('Error enriching feedback:', error);
      return { ...feedback, userResponse: null, votes: { yes: 0, no: 0 } };
    }
  };

  const handleResponse = async (id, response) => {
    try {
      await axios.post(`/api/anything-poll/${id}/response`, { response, username });
      await fetchFeedbacks(); // Refresh feedback list to show updated responses
    } catch (error) {
      console.error('Error sending response:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.post('/api/anything-poll', { text: newFeedback, username });
      setFeedbacks([...feedbacks, data]);
      setNewFeedback('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const filterFeedbacks = () => {
    let tempFeedbacks = feedbacks;

    if (searchTerm) {
      tempFeedbacks = tempFeedbacks.filter(feedback => feedback.text.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (filterOption === 'responded') {
      tempFeedbacks = tempFeedbacks.filter(feedback => feedback.userResponse);
    } else if (filterOption === 'notResponded') {
      tempFeedbacks = tempFeedbacks.filter(feedback => !feedback.userResponse);
    }

    setFilteredFeedbacks(tempFeedbacks);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Typography variant="h4" className={classes.title}>Anything</Typography>
      <form onSubmit={handleSubmit}>
        <Box className={classes.formBox}>
          <TextField
            label="Anything"
            value={newFeedback}
            onChange={(e) => setNewFeedback(e.target.value)}
            fullWidth
            required
            multiline
            rows={4}
            variant="outlined"
            placeholder=""
            className={classes.searchField}
          />
        </Box>
        <Button type="submit" variant="contained" className={classes.submitButton}>Submit</Button>
      </form>
      <Box className={classes.filterBox}>
        <TextField
          label="Search Anything"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          variant="outlined"
          className={classes.searchField}
        />
      </Box>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <List>
          {filteredFeedbacks.map(feedback => (
            <ListItem key={feedback._id} disablePadding>
              <Card className={classes.card} variant="outlined">
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" className={classes.feedbackText}>{feedback.text}</Typography>
                  {feedback.userResponse && (
                    <Box className={classes.feedbackDetails}>
                      <Box className={classes.voteCounts}>
                        <Typography variant="body2" className={classes.voteCountText}>
                          Certainly! {feedback.votes.yes}
                        </Typography>
                        <Typography variant="body2" className={classes.voteCountText}>
                          Maybe Not {feedback.votes.no}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </CardContent>
                <CardActions className={classes.cardActions} disableSpacing>
                  {feedback.userResponse ? (
                    <Typography variant="body2" color="textPrimary">
                      You responded: {feedback.userResponse === 'yes' ? 'Certainly!' : 'Maybe Not'}
                    </Typography>
                  ) : (
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Button onClick={() => handleResponse(feedback._id, 'yes')} variant="contained" className={classes.responseButton}>Certainly!</Button>
                      <Button onClick={() => handleResponse(feedback._id, 'no')} variant="contained" className={classes.responseButton}>Maybe Not</Button>
                    </Box>
                  )}
                </CardActions>
              </Card>
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
};

export default AnythingList;
