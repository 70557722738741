// Frontend: Updated SentimentManager Component
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  dialogPaper: {
    zIndex: 1500,
    backgroundColor: '#F5F5F5',
    color: '#2F4F4F',
    borderRadius: '10px',
    padding: '20px',
    width: '320px', // Fixed width to accommodate spinners
  },
  sentimentList: {
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '10px',
    backgroundColor: '#FAFAFA',
    borderRadius: '5px',
    marginBottom: '20px',
    border: '1px solid #E0E0E0',
  },
  sentimentItem: {
    padding: '10px',
    marginBottom: '10px',
    borderBottom: '1px solid #E0E0E0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  sentimentText: {
    color: '#2F4F4F',
  },
  sentimentUsername: {
    color: '#008080',
    fontWeight: 'bold',
    display: 'block',
  },
  sentimentDate: {
    color: '#A9A9A9',
    fontStyle: 'italic',
    fontSize: '0.8em',
    display: 'block',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    position: 'relative', // For overlaying spinner
  },
  input: {
    border: '1px solid #B0C4DE',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '10px',
    color: '#2F4F4F',
    resize: 'vertical',
    minHeight: '60px', // Ensure consistent height
    '&:focus': {
      borderColor: '#008080',
      outline: 'none',
    },
  },
  addButton: {
    backgroundColor: '#40E0D0',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#20B2AA',
    },
    position: 'relative',
    height: '40px', // Fixed height for consistency
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
  },
  addButtonDisabled: {
    backgroundColor: '#B0C4DE',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#B0C4DE',
    },
  },
  errorText: {
    color: '#FF6347',
    marginTop: '10px',
    textAlign: 'center',
  },
  noSentimentsText: {
    color: '#2F4F4F',
    textAlign: 'center',
  },
  refreshButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px', // Fixed height for consistency
    marginTop: '10px',
  },
  refreshButton: {
    backgroundColor: '#008080',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#006666',
    },
    textTransform: 'none',
    position: 'relative',
    height: '40px', // Fixed height
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  refreshButtonDisabled: {
    backgroundColor: '#B0C4DE',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#B0C4DE',
    },
  },
  spinnerOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px', // Fixed height to prevent resizing
  },
});

const SentimentManager = ({
  open,
  onClose,
  sentiments,
  newSentiment,
  onSentimentChange,
  sentimentAlreadySubmitted,
  svgInputRef,
  isSentimentDialogOpen,
  postId,
  loggedInUser,
}) => {
  const classes = useStyles();
  const [localSentiments, setLocalSentiments] = useState(sentiments || []);
  const [localNewSentiment, setLocalNewSentiment] = useState(newSentiment || '');
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false); // State for refreshing
  const [isSubmitting, setIsSubmitting] = useState(false); // State for submitting
  const [error, setError] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const tag = window.location.pathname.split('/').pop();

  // Fetch sentiments from the server
  const fetchSentiments = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`/api/sentiments/${postId}`);
      if (response.ok) {
        const data = await response.json();
        setLocalSentiments(data.sentiments || []);
      } else {
        // Attempt to parse error message from response
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch sentiments');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Check if the user has already submitted a sentiment
  const checkUserSubmission = async () => {
    try {
      const response = await fetch(`/api/sentiments/check/${postId}/${loggedInUser}`);
      if (response.ok) {
        const data = await response.json();
        setHasSubmitted(data.submitted);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to check submission');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Fetch sentiments and check user submission when the dialog opens or postId changes
  useEffect(() => {
    if (open) {
      fetchSentiments();
      checkUserSubmission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, open]); // Added postId and open as dependencies

  // Handle adding a new sentiment
  const handleAddSentiment = async (event) => {
    event.preventDefault();
    if (!localNewSentiment.trim()) {
      setError('Please provide a sentiment.');
      return;
    }
    setIsSubmitting(true);
    setError(null);
    try {
      const response = await fetch(`/api/sentiments/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId,
          sentiment: localNewSentiment,
          username: loggedInUser,
          tag,
        }),
      });
      if (response.ok) {
        setLocalNewSentiment('');
        await fetchSentiments();
        await checkUserSubmission();
        setHasSubmitted(true); // Ensure hasSubmitted is true after submission
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add sentiment');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle refreshing sentiments
  const handleRefresh = async () => {
    setIsRefreshing(true);
    setError(null);
    try {
      await fetchSentiments();
      await checkUserSubmission();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsRefreshing(false);
    }
  };

  // Format the date string
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: '2-digit',
    });
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
      <DialogContent>
        {/* Sentiments Section */}
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : error && localSentiments.length === 0 ? (
          // Show error only if there are no sentiments
          <p className={classes.noSentimentsText}>Please place a sentiment for this entry</p>
        ) : localSentiments.length > 0 && !hasSubmitted ? (
          // User has not submitted and there are existing sentiments
          <p className={classes.noSentimentsText}>Please place a sentiment for this entry</p>
        ) : localSentiments.length === 0 ? (
          // No sentiments exist
          <p className={classes.noSentimentsText}>
            Would you like to add the first Sentiment for this entry?
          </p>
        ) : error ? (
          // Show error if there are sentiments and an error occurred
          <p className={classes.errorText}>Error: {error}</p>
        ) : (
          // Display sentiments list if user has submitted or no conditions above met
          <div className={classes.sentimentList}>
            {localSentiments.map((sentiment, index) => (
              <div key={index} className={classes.sentimentItem}>
                <p className={classes.sentimentText}>
                  <span className={classes.sentimentDate}>
                    {formatDate(sentiment.createdDate)}
                  </span>
                  <span className={classes.sentimentUsername}>
                    {sentiment.username}
                  </span>
                  {sentiment.sentiment}
                </p>
              </div>
            ))}
          </div>
        )}

        {/* Refresh Button or Spinner */}
        {hasSubmitted && (
          <div className={classes.refreshButtonContainer}>
            <Button
              onClick={handleRefresh}
              className={isRefreshing ? classes.refreshButtonDisabled : classes.refreshButton}
              variant="contained"
              disabled={isRefreshing}
            >
              {isRefreshing ? (
                <CircularProgress size={24} className={classes.spinnerOverlay} />
              ) : (
                'Refresh'
              )}
            </Button>
          </div>
        )}

        {/* Add Sentiment Form */}
        <form onSubmit={handleAddSentiment} className={classes.form}>
          <textarea
            value={localNewSentiment}
            onChange={(e) => setLocalNewSentiment(e.target.value)}
            className={classes.input}
            placeholder="Thoughts?"
            disabled={isSubmitting}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleAddSentiment(e);
              }
            }}
          />
        </form>

        {/* Display error if any and there are existing sentiments */}
        {error && localSentiments.length > 0 && (
          <p className={classes.errorText}>Error: {error}</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SentimentManager;
