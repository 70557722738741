import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  Button,
  Grid,
  CircularProgress,
  IconButton,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { GiTreeBranch } from "react-icons/gi";
import { GiBrain } from "react-icons/gi";
import useStyles from './AddStylin';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const AddPathwayForm = ({ onAdd, onClose }) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0); // For tab selection

  const [formData, setFormData] = useState({
    goal: '',
    password: '',
    imageData: '', // Pathway-level image
    imagePreview: null, // For image preview
    mainSteps: [{ _id: uuidv4(), description: '', resources: [] }],
  });

  const [aiFormData, setAiFormData] = useState({
    goal: '',
    password: '',
    imageData: '',
    imagePreview: null,
  });

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [aiError, setAiError] = useState('');
  const [generating, setGenerating] = useState(false);

  // Retrieve username from localStorage
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const username = readifyUserKey ? readifyUserKey.username : 'anonymous';

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setError('');
    setAiError('');
  };

  // Handle Image Upload for "You" tab
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type 
      if (!file.type.startsWith('image/')) {
        setError('Only image files are allowed.');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          imageData: reader.result, // Base64 string
          imagePreview: reader.result, // For preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle media removal (for pathway-level image)
  const handleMediaRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      imageData: '',
      imagePreview: null,
    }));
  };

  // Handle AI Form Image Upload
  const handleAiImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        setAiError('Only image files are allowed.');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setAiFormData((prevData) => ({
          ...prevData,
          imageData: reader.result, // Base64 string
          imagePreview: reader.result, // For preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle AI Form Media Remove
  const handleAiMediaRemove = () => {
    setAiFormData((prevData) => ({
      ...prevData,
      imageData: '',
      imagePreview: null,
    }));
  };

  // Handle adding a new main step (limit to 3)
  const handleAddMainStep = () => {
    if (formData.mainSteps.length >= 3) return;
    setFormData((prevData) => ({
      ...prevData,
      mainSteps: [
        ...prevData.mainSteps,
        { _id: uuidv4(), description: '', resources: [] },
      ],
    }));
  };

  // Handle removing a main step (ensure at least 1)
  const handleRemoveMainStep = (index) => {
    if (formData.mainSteps.length === 1) return;
    setFormData((prevData) => ({
      ...prevData,
      mainSteps: prevData.mainSteps.filter((_, idx) => idx !== index),
    }));
  };

  // Handle main step description change
  const handleMainStepChange = (index, value) => {
    setFormData((prevData) => {
      const newMainSteps = [...prevData.mainSteps];
      newMainSteps[index].description = value;
      return {
        ...prevData,
        mainSteps: newMainSteps,
      };
    });
  };

  // Handle adding a resource to a main step
  const handleAddResource = (mainStepIndex, file) => {
    // Determine media type based on file type
    const mediaType = file.type.startsWith('image/')
      ? 'image'
      : file.type.startsWith('video/')
      ? 'video'
      : 'document';

    const newResource = {
      _id: uuidv4(),
      file,
      previewUrl: URL.createObjectURL(file),
      mediaType,
      name: file.name,
      // mediaId and mediaLink will be assigned by the server
    };

    setFormData((prev) => {
      const pathwayCopy = _.cloneDeep(prev);
      pathwayCopy.mainSteps[mainStepIndex].resources.push(newResource);
      return pathwayCopy;
    });
  };

  // Handle removing a resource from a main step
  const handleRemoveResource = (mainStepIndex, resourceId) => {
    setFormData((prev) => {
      const pathwayCopy = _.cloneDeep(prev);
      pathwayCopy.mainSteps[mainStepIndex].resources = pathwayCopy.mainSteps[mainStepIndex].resources.filter(
        (res) => res._id !== resourceId
      );
      return pathwayCopy;
    });
  };

  // Handle form submission for "You" tab
  const handleSubmit = async () => {
    if (!formData.goal.trim()) {
      setError('Goal is required.');
      return;
    }

    if (!formData.imageData) {
      setError('An image is required.');
      return;
    }

    if (formData.mainSteps.some(step => !step.description.trim())) {
      setError('All main steps must have a description.');
      return;
    }

    setUploading(true);
    setError('');

    try {
      // Prepare the payload according to the new schema
      const payload = {
        goal: formData.goal.trim(),
        password: formData.password ? formData.password : undefined,
        image: formData.imageData, // Base64 string
        mainSteps: formData.mainSteps.map(step => ({
          _id: step._id,
          description: step.description.trim(),
          resources: step.resources.map(resource => ({
            _id: resource._id,
            mediaType: resource.mediaType,
            name: resource.name,
            // mediaId and mediaLink will be assigned by the server after upload
          })),
        })),
        createdBy: username,
      };

      // Send the payload to the backend using the appropriate API endpoint
      const response = await axios.post('/api/pathways/create', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        onAdd(response.data);
        // Reset form fields
        setFormData({
          goal: '',
          password: '',
          imageData: '',
          imagePreview: null,
          mainSteps: [{ _id: uuidv4(), description: '', resources: [] }],
        });
        onClose();
      } else {
        setError('Failed to create pathway. Please try again.');
      }
    } catch (err) {
      console.error("Error creating pathway:", err);
      setError(err.response?.data?.error || 'An error occurred while creating the pathway.');
    } finally {
      setUploading(false);
    }
  };

  // Handle AI Form Submission
  const handleAiSubmit = async () => {
    if (!aiFormData.goal.trim()) {
      setAiError('Goal is required.');
      return;
    }

    if (!aiFormData.imageData) {
      setAiError('An image is required.');
      return;
    }

    setGenerating(true);
    setAiError('');

    try {
      // Prepare the payload for AI generation
      const payload = {
        goal: aiFormData.goal.trim(),
        password: aiFormData.password ? aiFormData.password : undefined,
        image: aiFormData.imageData, // Base64 string
        createdBy: username,
      };

      // Send the payload to the backend AI generation endpoint
      const response = await axios.post('/api/pathways/create-ai', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        onAdd(response.data);
        // Reset form fields
        setAiFormData({
          goal: '',
          password: '',
          imageData: '',
          imagePreview: null,
        });
        onClose();
      } else {
        setAiError('Failed to create pathway. Please try again.');
      }
    } catch (err) {
      console.error('Error creating pathway:', err);
      setAiError(
        err.response?.data?.error ||
          'An error occurred while creating the pathway.'
      );
    } finally {
      setGenerating(false);
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="You" style={{ textTransform: 'none', color: '#004d40' }} />
<Tab label="Ai" style={{ textTransform: 'none', color: '#004d40' }} />
      </Tabs>
      <div className={classes.scrollableContainer}>
        {tabValue === 0 && (
          <Box p={2}>
            {/* "You" tab content */}
            <Grid container direction="column" spacing={2}>
              {/* Goal Input */}
              <Grid item>
                <TextField
                  label="Dream or Goal"
                  variant="outlined"
                  name="goal"
                  value={formData.goal}
                  onChange={(e) => setFormData((prevData) => ({ ...prevData, goal: e.target.value }))}
                  fullWidth
                  required
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GiBrain />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Password Input */}
              <Grid item>
                <TextField
                  label="Password (Optional)"
                  variant="outlined"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={(e) => setFormData((prevData) => ({ ...prevData, password: e.target.value }))}
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Image Upload (Now Required) */}
              <Grid item>
                <div className={classes.uploadContainer}>
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*" // Only accept images
                    multiple={false} // Limit to single image upload for pathway
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="image-upload" className={classes.uploadLabel}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      startIcon={<AddPhotoAlternateIcon />}
                      style={{ textTransform: 'none' }} // Remove text transformation
                    >
                      Add Image (Required)
                    </Button>
                  </label>
                </div>
                <div className={classes.previewContainer}>
                  {formData.imageData && (
                    <div className={classes.previewImageContainer}>
                      <img
                        src={formData.imageData}
                        alt="Uploaded Preview"
                        className={classes.previewImage}
                      />
                      <IconButton
                        onClick={handleMediaRemove}
                        className={classes.removeButton}
                      >
                        &times;
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>

              {/* Main Steps */}
              <Grid item>
                <div className={classes.scrollableSection}>
                  <Grid container direction="column">
                    {formData.mainSteps?.map((step, index) => (
                      <Grid
                        container
                        alignItems="center"
                        key={step._id}
                        className={classes.stepContainer}
                      >
                        <Grid item xs={10}>
                          <TextField
                            label={`Main Step ${index + 1}`}
                            variant="outlined"
                            value={step.description}
                            onChange={(e) =>
                              handleMainStepChange(index, e.target.value)
                            }
                            fullWidth
                            required
                            className={classes.textField}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <GiTreeBranch />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            color="secondary"
                            onClick={() => handleRemoveMainStep(index)}
                            disabled={formData.mainSteps.length === 1}
                          >
                            <RemoveCircle />
                          </IconButton>
                        </Grid>

                        {/* Media Upload for Main Step */}
                        <Grid item xs={12}>
                          <input
                            id={`mainstep-media-upload-${step._id}`}
                            type="file"
                            accept="image/*,video/*,application/pdf"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              if (e.target.files && e.target.files[0]) {
                                handleAddResource(index, e.target.files[0]);
                              }
                            }}
                          />
                          <label htmlFor={`mainstep-media-upload-${step._id}`}>
                            <Button
                              variant="outlined"
                              color="primary"
                              component="span"
                              startIcon={<AddPhotoAlternateIcon />}
                              style={{ textTransform: 'none' }} // Remove text transformation
                            >
                              Add Resource
                            </Button>
                          </label>
                        </Grid>

                        {/* Display Resources for Main Step */}
                        {step.resources && step.resources.length > 0 && (
                          <Grid item xs={12}>
                            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                              {step.resources.map((resource) => (
                                <Grid item key={resource._id} xs={12} sm={6} md={4}>
                                  <div style={{ position: 'relative' }}>
                                    {resource.mediaType === 'image' && (
                                      <img
                                        src={
                                          resource.previewUrl
                                            ? resource.previewUrl
                                            : resource.mediaLink
                                        }
                                        alt="Resource"
                                        style={{
                                          width: '100%',
                                          height: 'auto',
                                          borderRadius: '4px',
                                        }}
                                      />
                                    )}
                                    {resource.mediaType === 'video' && (
                                      <video
                                        controls
                                        style={{
                                          width: '100%',
                                          borderRadius: '4px',
                                        }}
                                      >
                                        <source
                                          src={
                                            resource.previewUrl
                                              ? resource.previewUrl
                                              : resource.mediaLink
                                          }
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                      </video>
                                    )}
                                    {resource.mediaType === 'document' && (
                                      <a
                                        href={
                                          resource.previewUrl
                                            ? resource.previewUrl
                                            : resource.mediaLink
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          textDecoration: 'none',
                                          color: '#1976d2',
                                        }}
                                      >
                                        {resource.name}
                                      </a>
                                    )}
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleRemoveResource(index, resource._id)
                                      }
                                      style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        backgroundColor: 'rgba(255,255,255,0.7)',
                                      }}
                                    >
                                      &times;
                                    </IconButton>
                                  </div>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <Button
                  variant="outlined"
                  startIcon={<AddCircle />}
                  onClick={handleAddMainStep}
                  className={classes.submitButton}
                  disabled={formData.mainSteps.length >= 3}
                  style={{ textTransform: 'none' }} // Remove text transformation
                >
                  Add Main Step
                </Button>
              </Grid>

              {/* Error Message */}
              {error && (
                <Grid item>
                  <span style={{ color: 'red' }}>{error}</span>
                </Grid>
              )}

              {/* Submit Button */}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={uploading}
                  className={classes.submitButton}
                  style={{ textTransform: 'none' }} // Remove text transformation
                >
                  {uploading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        {tabValue === 1 && (
          <Box p={2}>
            {/* "Ai" tab content */}
            <Grid container direction="column" spacing={2}>
              {/* Goal Input */}
              <Grid item>
                <TextField
                  label="Dream or Goal"
                  variant="outlined"
                  name="goal"
                  value={aiFormData.goal}
                  onChange={(e) =>
                    setAiFormData((prevData) => ({
                      ...prevData,
                      goal: e.target.value,
                    }))
                  }
                  fullWidth
                  required
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GiBrain />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Password Input */}
              <Grid item>
                <TextField
                  label="Password (Optional)"
                  variant="outlined"
                  name="password"
                  type="password"
                  value={aiFormData.password}
                  onChange={(e) =>
                    setAiFormData((prevData) => ({
                      ...prevData,
                      password: e.target.value,
                    }))
                  }
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Image Upload (Required) */}
              <Grid item>
                <div className={classes.uploadContainer}>
                  <input
                    id="ai-image-upload"
                    type="file"
                    accept="image/*" // Only accept images
                    multiple={false} // Limit to single image upload for pathway
                    style={{ display: 'none' }}
                    onChange={handleAiImageUpload}
                  />
                  <label htmlFor="ai-image-upload" className={classes.uploadLabel}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      startIcon={<AddPhotoAlternateIcon />}
                      style={{ textTransform: 'none' }} // Remove text transformation
                    >
                      Add Image (Required)
                    </Button>
                  </label>
                </div>
                <div className={classes.previewContainer}>
                  {aiFormData.imageData && (
                    <div className={classes.previewImageContainer}>
                      <img
                        src={aiFormData.imageData}
                        alt="Uploaded Preview"
                        className={classes.previewImage}
                      />
                      <IconButton
                        onClick={handleAiMediaRemove}
                        className={classes.removeButton}
                      >
                        &times;
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>

              {/* Error Message */}
              {aiError && (
                <Grid item>
                  <span style={{ color: 'red' }}>{aiError}</span>
                </Grid>
              )}

              {/* Submit Button */}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAiSubmit}
                  fullWidth
                  disabled={generating}
                  className={classes.submitButton}
                  style={{ textTransform: 'none' }} // Remove text transformation
                >
                  {generating ? <CircularProgress size={24} /> : 'Gather Steps'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </form>
  );
};

export default AddPathwayForm;
