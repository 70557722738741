export const fetchAuthorAvatar = async (username, setAuthorInfo) => {
  try {
    const response = await fetch(`/api/authors/avatars/${username}`);
    if (response.ok) {
      const avatarData = await response.json();
      setAuthorInfo((prev) => ({ ...prev, avatar: avatarData.avatar || null }));
    } else {
      console.error('Error fetching author avatar:', response.statusText);
    }
  } catch (error) {
    console.error('Error fetching author avatar:', error);
  }
};

export const handleImageClick = (imageLink, glowingBorderClass) => {
  const backdrop = document.createElement("div");
  backdrop.style.position = 'fixed';
  backdrop.style.top = '0';
  backdrop.style.left = '0';
  backdrop.style.width = '100vw';
  backdrop.style.height = '100vh';
  backdrop.style.zIndex = '10000';
  backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.69)';
  backdrop.style.display = 'flex';
  backdrop.style.alignItems = 'center';
  backdrop.style.justifyContent = 'center';

  const img = document.createElement("img");
  img.src = imageLink;
  img.style.top = '5vh';
  img.style.left = '5vw';
  img.style.height = 'fit-content';
  img.style.width = '90vw';
  img.style.maxHeight = '90vh';
  img.style.objectFit = 'fill';
  img.style.border = 'none';
  img.style.outline = 'none';
  img.style.background = 'none';
  img.style.boxShadow = 'none';
  img.className = glowingBorderClass;
  backdrop.appendChild(img);
  document.body.appendChild(backdrop);

  backdrop.addEventListener('click', (e) => {
    if (e.target === backdrop) {
      backdrop.remove();
    }
  });
};

export const handleKeepForSelf = async (entryId, username, domain) => {
  try {
    const response = await fetch('/api/keep-entry', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entryId,
        username,
        tag: "Architecture",
        domain,
      }),
    });

    if (response.ok) {
      console.log('Entry saved successfully');
    } else {
      console.error('Failed to save entry');
    }
  } catch (error) {
    console.error('Error saving entry:', error);
  }
};

export const handleKeepForPool = async (poolName, entryId, username, domain) => {
  try {
    const response = await fetch('/api/add-to-pool', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        poolName,
        entryId,
        username,
        entryTag: "Architecture",
        entryDomain: domain,
      }),
    });
 
    if (response.ok) {
      console.log('Entry added to pool successfully');
    } else {
      console.error('Failed to add entry to pool');
    }
  } catch (error) {
    console.error('Error adding entry to pool:', error);
  }
};

export const handleToggleFeedbackPopover = async (entryId, username, setFeedbackCountsVisible, setFeedbackData, setSelectedFeedback) => {
  try {
    const response = await fetch(`/api/feeling/${entryId}/${username}`);
    if (response.ok) {
      const feedbackCounts = await response.json();

      setFeedbackCountsVisible(true);
      setFeedbackData(feedbackCounts.feedbackCounts);
      setSelectedFeedback(feedbackCounts.feedbackType);
    } else {
      console.error('Failed to fetch feedback status');
    }
  } catch (error) {
    console.error('Error fetching feedback status:', error);
  }
};

export const handleFeedback = async (entryId, username, feedbackType, selectedFeedback, setSelectedFeedback, setFeedbackData, setIsSubmittingFeedback, setFeedbackCountsVisible) => {
  if (selectedFeedback === feedbackType) {
    setSelectedFeedback(null);
    return;
  }
  setIsSubmittingFeedback(true);

  try {
    const response = await fetch(`/api/feeling/${entryId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        feedbackType,
        username,
      }),
    });

    if (response.ok) {
      const updatedFeedback = await response.json();
      setFeedbackData(updatedFeedback.feedback);
      setSelectedFeedback(feedbackType);
      setFeedbackCountsVisible(true);
    } else {
      console.error('Failed to send feedback');
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsSubmittingFeedback(false);
  }
};

export const handleSentimentChange = async (newSentiment, entryId, username, setSentiments, setSentimentAlreadySubmitted) => {
  setSentimentAlreadySubmitted(false);
  if (!newSentiment) return;
  const response = await fetch(`/api/sentiment/${entryId}/${username}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sentiment: newSentiment,
    }),
  });
  if (response.ok) {
    const sentimentsData = await response.json();
    setSentiments(sentimentsData);
    setSentimentAlreadySubmitted(true);
  } else {
    console.error('Failed to submit sentiment');
  }
};
