// src/components/Pathways/MediaViewer.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

// Helper functions to determine media type based on mediaType field
const isVideo = (mediaType) => mediaType === 'video';
const isDocument = (mediaType) => mediaType === 'document';
const isImage = (mediaType) => mediaType === 'image';

// Define the keyframes for the breathing effect
const breatheAnimation = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(240, 240, 240, 0.0);
  }
  50% {
    box-shadow: 0 0 20px rgba(240, 240, 240, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(240, 240, 240, 0.0);
  }
`;

// Create a styled component for the media element
const StyledMedia = styled('div')(({ mediaType }) => ({
  display: 'inline-block', // Ensures the container wraps tightly around the media
  animation: `${breatheAnimation} 3s ease-in-out infinite`,
  borderRadius: '8px', // Optional: adds slight rounding to the border
  padding: mediaType === 'document' ? '10px' : '0', // Optional padding for documents
}));

const MediaViewer = ({ open, onClose, mediaList, initialIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const thumbnailsRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Update currentIndex when initialIndex changes
  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  // Early return if no media to display
  if (!mediaList || mediaList.length === 0) {
    return null;
  }

  const currentMedia = mediaList[currentIndex];

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + mediaList.length) % mediaList.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaList.length);
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      fullScreen={isMobile}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.96)' },
      }}
      PaperProps={{
        style: { backgroundColor: 'transparent', boxShadow: 'none' },
      }}
    >
      <DialogContent
        style={{
          position: 'relative',
          background: 'none',
          padding: 0,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Close Button */}
        <Button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: '#004d40',
            color: '#fff',
            zIndex: 1,
            padding: '8px 16px',
            textTransform: 'none',
            fontSize: isMobile ? '0.8rem' : '1rem',
          }}
          aria-label="Close"
        >
          Close
        </Button>

        {/* Media Display */}
        <Box
          style={{
            flex: '1 1 auto',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'none',
            overflow: 'hidden',
            paddingTop: 60, // Adjust for close button height
            paddingBottom: 120, // Ensure space for thumbnails
          }}
        >
          <StyledMedia mediaType={currentMedia.mediaType}>
            {isVideo(currentMedia.mediaType) ? (
              <video
                src={currentMedia.mediaLink}
                autoPlay
                controls
                muted 
                loop
                playsInline // Prevent fullscreen on autoplay
                controlsList="nodownload" // Remove download control
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : isDocument(currentMedia.mediaType) ? (
              <iframe
                src={`https://docs.google.com/gview?url=${encodeURIComponent(
                  currentMedia.mediaLink
                )}&embedded=true`}
                title="Document Viewer"
                style={{
                  width: isMobile ? '100vw' : '90vw',
                  height: isMobile ? '100vh' : '90vh',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  border: 'none',
                }}
              />
            ) : isImage(currentMedia.mediaType) ? (
              <img
                src={currentMedia.mediaLink}
                alt={`Media ${currentIndex + 1}`}
                style={{
                  maxWidth: '80vw', // 80% of viewport width
                  maxHeight: '80vh', // 80% of viewport height
                  objectFit: 'contain',
                }}
              />
            ) : (
              <Typography variant="h6" style={{ color: '#fff' }}>
                Unsupported Media Type
              </Typography>
            )}
          </StyledMedia>

          {/* Navigation Buttons */}
          {mediaList.length > 1 && (
            <>
              <IconButton
                onClick={handlePrevious}
                style={{
                  position: 'absolute',
                  left: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#fff',
                }}
                aria-label="Previous"
              >
                <ArrowBackIosIcon fontSize={isMobile ? 'small' : 'default'} />
              </IconButton>
              <IconButton
                onClick={handleNext}
                style={{
                  position: 'absolute',
                  right: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#fff',
                }}
                aria-label="Next"
              >
                <ArrowForwardIosIcon fontSize={isMobile ? 'small' : 'default'} />
              </IconButton>
            </>
          )}
        </Box>

        {/* Thumbnails */}
        <div
          ref={thumbnailsRef}
          style={{
            height: isMobile ? '80px' : '100px', // Adjusted height for mobile
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            backgroundColor: '#000',
            padding: '10px 0',
            flexShrink: 0,
          }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ width: 'max-content', margin: '0 auto' }}
          >
            {mediaList.map((media, index) => (
              <Grid item key={index}>
                <div
                  style={{
                    position: 'relative',
                    width: isMobile ? '60px' : '80px',
                    height: isMobile ? '60px' : '80px',
                    cursor: 'pointer',
                    border:
                      currentIndex === index
                        ? '2px solid #0ABAB5'
                        : 'none',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}
                  onClick={() => handleThumbnailClick(index)}
                >
                  {isVideo(media.mediaType) ? (
                    <video
                      src={media.mediaLink}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      muted
                      controlsList="nodownload" // Disables download option
                    />
                  ) : isDocument(media.mediaType) ? (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f0f0f0',
                      }}
                    >
                      <InsertDriveFileIcon
                        style={{ fontSize: '2rem', color: '#e53935' }}
                      />
                    </div>
                  ) : isImage(media.mediaType) ? (
                    <img
                      src={media.mediaLink}
                      alt={`Thumbnail ${index + 1}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ color: '#fff' }}>
                      N/A
                    </Typography>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MediaViewer;
